"use client";

import SectionContainer from "../../../components/SectionContainer";
import React, { useRef, useState } from "react";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import DropZone from "./DropZone";
import { useS3UploadJobsSelector } from "@/context/S3UploadJobContext";
import { Media } from "@knowt/syncing/graphql/schema";
import { themeColors } from "@/utils/themeColors";
import LandingTabSwitcher from "../LandingTabSwitcher";
import br from "@/styles/breakpoints.module.css";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { OutstreamVideoAd } from "@/features/Ads";
import KnowtStats from "../../../components/KnowtStats";
import UploadDisclaimer from "../UploadDisclaimer";
import { MediaEditForm } from "@/features/MediaEditPage";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { spacing } from "@/utils/spacing";
import toast from "react-hot-toast";
import localKeyValueStore from "@/utils/LocalKeyValueStore";
import { TIME } from "@/utils/dateTimeUtils";
import { useRouter } from "next13-progressbar";
import { AuthEvent, useAuthPopupContextSelector } from "@/features/Auth";
import { LandingPageVideoSummarizeLink } from "@knowt/syncing/constants/storage";
import { STORAGE_KEYS } from "@knowt/syncing/constants";

const DropSectionAds = () => {
    return (
        <FlexColumn
            className={br.mdDownDisplayNone}
            style={{
                flex: 1,
                margin: "auto",
                padding: "0 2rem",
            }}>
            <OutstreamVideoAd />
        </FlexColumn>
    );
};

type DropSectionProps = {
    serverMedia?: Media;
    title: string;
    subtitle: React.ReactNode;
    dropZoneInstruction: string;
    tapInstruction: string;
    isWithLinkOption?: boolean;
};

const DropSection = ({
    serverMedia,
    title,
    subtitle,
    dropZoneInstruction,
    tapInstruction,
    isWithLinkOption = false,
}: DropSectionProps) => {
    const router = useRouter();
    const isUploading = useS3UploadJobsSelector(state => state.isUploading);

    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);

    const [droppedFile, setDroppedFile] = useState<File | null>(null);

    const showMediaEditPage = isUploading || serverMedia;

    const hideTabSwitcher = droppedFile;

    const { user, canShowAds } = useCurrentUser();
    const shouldDisplayAds = canShowAds && !!droppedFile;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit = async () => {
        const videoUrl = inputRef.current?.value;

        if (!videoUrl) {
            toast.error("Please enter a video link");
            return;
        }

        await localKeyValueStore.setWithExpiry(
            STORAGE_KEYS.LANDING_PAGE_VIDEO_SUMMARIZE_LINK,
            { videoUrl } as LandingPageVideoSummarizeLink,
            TIME.HOUR
        );
        await localKeyValueStore.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);

        if (user) {
            router.push("/");
        } else {
            openAuthPopup({
                event: AuthEvent.LANDING_UPLOAD_VIDEO,
                title: "Sign up for free to view your video summary",
                description:
                    "Studying, finding & making flashcards on Knowt is always completely free unlike certain other websites 👀",
            });
        }
    };

    return (
        <>
            {!hideTabSwitcher && (
                <Flex
                    style={{
                        backgroundColor: themeColors.neutralWhite,
                        justifyContent: "center",
                    }}>
                    <LandingTabSwitcher />
                </Flex>
            )}
            <SectionContainer
                title={title}
                subtitle={subtitle}
                style={{ gap: "4rem" }}
                sectionStyle={{ maxWidth: shouldDisplayAds ? "unset" : "105rem" }}
                headerStyle={shouldDisplayAds ? { width: "100%", alignItems: "flex-start" } : undefined}
                titleStyle={droppedFile ? { fontSize: "2.5rem" } : undefined}
                subtitleStyle={
                    shouldDisplayAds ? { textAlign: "left" } : droppedFile ? { fontSize: "1.8rem" } : undefined
                }
                isPageHeader>
                <FlexRowAlignCenter style={{ width: "100%", justifyContent: "center" }}>
                    {showMediaEditPage ? (
                        <MediaEditForm
                            media={serverMedia}
                            uploadMode
                            style={{
                                backgroundColor: themeColors.background,
                            }}
                            inputStyle={{
                                backgroundColor: themeColors.neutralWhite,
                                borderWidth: 0,
                            }}
                            tagSelectorBackgroundColor={themeColors.neutralWhite}
                            tagSelectorBorderWidth={0}
                            nextBackgroundColor={themeColors.neutralBlack}
                        />
                    ) : (
                        <FlexColumnAlignJustifyCenter style={{ width: "100%" }}>
                            {isWithLinkOption && (
                                <FlexColumnAlignJustifyCenter
                                    style={{
                                        width: "100%",
                                        position: "relative",
                                        marginBottom: spacing.MD,
                                        gap: spacing.MD,
                                    }}>
                                    <FlexRowAlignCenter style={{ width: "100%", maxWidth: "100rem", gap: spacing.MD }}>
                                        <BorderColoredInput
                                            ref={inputRef}
                                            sx={{
                                                height: "6.4rem",
                                                backgroundColor: themeColors.neutralWhite,
                                                paddingLeft: spacing.MD,
                                            }}
                                            placeholder={"Paste a link from Youtube, Canvas, Google Drive, etc."}
                                            onKeyDown={async event => {
                                                if (event.key === "Enter") handleSubmit();
                                            }}
                                        />
                                        <CircularRectTextButton
                                            onClick={handleSubmit}
                                            sx={{
                                                backgroundColor: "#50d2c2",
                                                color: themeColors.primaryDark,
                                                borderRadius: "4rem",
                                                fontSize: "1.5rem",
                                                padding: `${spacing.SM} ${spacing.LG}`,
                                            }}>
                                            <span className="bodyBold1">summarize</span>
                                        </CircularRectTextButton>
                                    </FlexRowAlignCenter>
                                    <FlexRowAlignCenter style={{ gap: spacing.MD }}>
                                        <div
                                            style={{
                                                width: "17.3rem",
                                                height: "0.2rem",
                                                backgroundColor: themeColors.neutral1,
                                            }}
                                        />
                                        <span>or</span>
                                        <div
                                            style={{
                                                width: "17.3rem",
                                                height: "0.2rem",
                                                backgroundColor: themeColors.neutral1,
                                            }}
                                        />
                                    </FlexRowAlignCenter>
                                </FlexColumnAlignJustifyCenter>
                            )}
                            <DropZone
                                instruction={dropZoneInstruction}
                                tapInstruction={tapInstruction}
                                droppedFile={droppedFile}
                                setDroppedFile={setDroppedFile}
                            />
                        </FlexColumnAlignJustifyCenter>
                    )}
                    {shouldDisplayAds && <DropSectionAds />}
                </FlexRowAlignCenter>

                <UploadDisclaimer />

                <KnowtStats />
            </SectionContainer>
        </>
    );
};

export default DropSection;
