"use client";

import UnitButton from "./UnitButton";
import SectionContainer from "../../components/SectionContainer";
import { ExamFRQ } from "@knowt/syncing/graphql/schema";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { useState } from "react";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import ViewMoreOrLessButton from "./ViewMoreOrLessButton";

const scrollToElementWithPadding = unit => {
    const element = document.getElementById(unit);
    if (element) {
        element.scrollIntoView();
        window.scrollBy(0, -100);
    }
};

const UnitSection = ({ exam, examFRQ }: { exam: string; examFRQ: ExamFRQ[] }) => {
    const examFRQByUnit = examFRQ.reduce((acc, frq) => {
        if (!acc[frq.examUnit]) {
            acc[frq.examUnit] = [];
        }

        acc[frq.examUnit].push(frq);
        return acc;
    }, {});

    const allUnits = Object.keys(examFRQByUnit).sort();

    const [activeUnit, setActiveUnit] = useState<string | undefined>(undefined);

    const showAllInitState = Object.fromEntries(allUnits.map(unit => [unit, false]));
    const [showAll, setShowAll] = useState(showAllInitState);

    return (
        <SectionContainer
            title={`${exam} Free Response Prompts`}
            subtitle={"We have over 2 million resources across various exams, and subjects to refer to at any point."}>
            <FlexColumn style={{ width: "100%", gap: "3rem" }}>
                <FlexRowAlignCenter
                    className="scrollbar"
                    style={{ gap: "1.5rem", overflowX: "unset", overflowY: "scroll", paddingBottom: "1rem" }}>
                    <UnitButton
                        count={examFRQ.length}
                        activeUnit={activeUnit}
                        setActiveUnit={() => {
                            setActiveUnit(undefined);
                        }}
                    />
                    {allUnits.map(examUnit => (
                        <UnitButton
                            key={examUnit}
                            unit={examUnit}
                            count={examFRQByUnit[examUnit].length}
                            activeUnit={activeUnit}
                            setActiveUnit={unit => {
                                setActiveUnit(unit as string);
                            }}
                        />
                    ))}
                </FlexRowAlignCenter>
                <FlexColumn style={{ gap: "2rem" }}>
                    {(!activeUnit ? allUnits : [activeUnit]).map(unit => {
                        const unitsCount = examFRQByUnit[unit].length;
                        const countToShow = showAll[unit] ? unitsCount : Math.min(unitsCount, 4);

                        return (
                            <>
                                <h4 id={unit}>{unit}</h4>
                                <RepeatAutoFillGrid itemMinWidth="40rem" style={{ gap: "1rem" }}>
                                    {examFRQByUnit[unit].slice(0, countToShow).map(({ name, question }) => {
                                        return (
                                            <FlexColumn
                                                key={name}
                                                style={{
                                                    backgroundColor: themeColors.neutralWhite,
                                                    height: "13rem",
                                                    padding: "2rem",
                                                    borderRadius: borderRadius.card,
                                                    gap: "1rem",
                                                }}>
                                                <div
                                                    className="ellipsisText"
                                                    style={{
                                                        fontWeight: 700,
                                                        color: themeColors.neutralBlack,
                                                    }}>
                                                    {name}
                                                </div>
                                                <div
                                                    style={{
                                                        color: themeColors.neutralBlack,
                                                        overflow: "hidden",
                                                        WebkitLineClamp: "3",
                                                        display: "-webkit-box",
                                                        WebkitBoxOrient: "vertical",
                                                    }}>
                                                    {question}
                                                </div>
                                            </FlexColumn>
                                        );
                                    })}
                                </RepeatAutoFillGrid>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <ViewMoreOrLessButton
                                        isViewingMore={showAll[unit]}
                                        viewMore={() => {
                                            setShowAll({ ...showAllInitState, [unit]: !showAll[unit] });
                                            // setTimeout to ensure the grid has been updated
                                            setTimeout(() => {
                                                scrollToElementWithPadding(unit);
                                            }, 100);
                                        }}
                                    />
                                </div>
                            </>
                        );
                    })}
                </FlexColumn>
            </FlexColumn>
        </SectionContainer>
    );
};

export default UnitSection;
