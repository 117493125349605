"use client";

import { ArrowDown, ArrowUp } from "lucide-react";
import Circular from "@/components/Circular";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";

const ViewMoreOrLessButton = ({ viewMore, isViewingMore }) => {
    return (
        <CircularRectTextButton
            onClick={() => viewMore()}
            sx={{
                width: "16rem",
                height: "5.2rem",
                fontSize: "1.5rem",
                gap: "1rem",
            }}>
            {isViewingMore ? "View less" : "View more"}
            <Circular radius="4rem" style={{ backgroundColor: themeColors.primary }}>
                {isViewingMore ? (
                    <ArrowUp size={iconSizes.MD} color={"white"} />
                ) : (
                    <ArrowDown size={iconSizes.MD} color={"white"} />
                )}
            </Circular>
        </CircularRectTextButton>
    );
};

export default ViewMoreOrLessButton;
