import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Flex, FlexRowAlignCenter } from "@/components/Flex";
import Link from "@/components/wrappers/Link";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import styles from "../landing.module.css";

const CategoryHeaderPills = ({
    items,
    activeItem,
}: {
    items?: { label: string; onClick?: () => void; href?: string }[];
    activeItem?: string;
}) => {
    if (!items) {
        return null;
    }
    return (
        <FlexRowAlignCenter style={{ width: "100%", justifyContent: "center", marginTop: spacing.LG }}>
            <Flex
                className={styles.capsulesContainer}
                style={{ gap: spacing.SM, flexWrap: "wrap", justifyContent: "center" }}>
                {items.length > 0 &&
                    items.map((item, index) => {
                        const isSelected = activeItem === item.label;

                        const content = (
                            <CircularRectTextButton
                                key={`content-${index}`}
                                className={"body2"}
                                style={{
                                    padding: "1rem 1.6rem",
                                    borderRadius: borderRadius.shortInput,
                                    backgroundColor: isSelected ? themeColors.neutralBlack : themeColors.neutralWhite,
                                    color: isSelected ? themeColors.neutralWhite : themeColors.neutralBlack,
                                }}
                                onClick={item.onClick}>
                                {item.label}
                            </CircularRectTextButton>
                        );

                        if (item.href) {
                            return (
                                <Link key={`link-${index}`} href={item.href}>
                                    {content}
                                </Link>
                            );
                        }
                        return content;
                    })}
            </Flex>
        </FlexRowAlignCenter>
    );
};

export default CategoryHeaderPills;
