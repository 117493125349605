"use client";

import { useSearchParams } from "next/navigation";
import KnowtPagination from "@/components/KnowtPagination";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import { FlexRowAlignCenter } from "@/components/Flex";

const SubjectPagePagination = ({ totalPages }: { totalPages: number }) => {
    const searchParams = useSearchParams();
    const { addParamsPush } = useSafeQueryNavigation();

    return (
        <FlexRowAlignCenter as="ul" className="strippedList" style={{ gap: "1.2rem", justifyContent: "center" }}>
            <KnowtPagination
                count={totalPages}
                currentPage={searchParams?.get("page") ? +searchParams?.get("page") : 1}
                onChange={(_, page) => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    addParamsPush({ page: page.toString() });
                }}
            />
        </FlexRowAlignCenter>
    );
};

export default SubjectPagePagination;
