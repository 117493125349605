"use client";

import { Flex, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";
import { examNameToUrlComponent, getImageLink } from "@/hooks/exams/utils";
import ExamCard from "../../components/ExamCard";
import { ALL_EXAM_TYPES } from "../../ExamPages/AllExamsPage/utils";

const ExamTypesSection = ({ length = ALL_EXAM_TYPES.length }: { length: number }) => {
    const exams = ALL_EXAM_TYPES.slice(0, length);

    return (
        <FlexColumnAlignJustifyCenter style={{ gap: "2.4rem", width: "100%" }}>
            {/* Desktop */}
            <RepeatAutoFillGrid itemMinWidth={"26rem"} style={{ gap: "2.4rem" }}>
                {exams.map((type, idx) => {
                    const image = getImageLink(type);
                    return (
                        <ExamCard
                            key={idx}
                            exam={{ type, name: "", image, href: `/exams/${examNameToUrlComponent(type)}` }}
                            style={{ flex: 1 }}
                            title={`Knowt ${type} Hub`}
                        />
                    );
                })}
            </RepeatAutoFillGrid>

            <Flex style={{ alignSelf: "flex-end" }}>{/* <ViewAllButton section="exams" href="/exams" /> */}</Flex>
        </FlexColumnAlignJustifyCenter>
    );
};

export default ExamTypesSection;
