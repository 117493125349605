"use client";

import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { useSearchParams } from "next/navigation";
import { useS3UploadJobsSelector } from "@/context/S3UploadJobContext";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import { fileTypeFromMimeType } from "@/utils/fileTypeUtils";
import { report } from "@/utils/analytics/logging";
import Circular from "@/components/Circular";
import Image from "next/image";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import importFromDrive, { DriveImportType } from "@/utils/Google/Picker";
import { Waveform } from "@uiball/loaders";
import { ASSETS_URL } from "@/config/deployConstants";
import { assertTruthy } from "@/utils/assertions";
import { spacing } from "@/utils/spacing";
import { SetState } from "@knowt/syncing/types/common";
import clsx from "clsx";
import br from "@/styles/breakpoints.module.css";

const uploadIcon = `${ASSETS_URL}/images/upload-icon.svg`;
const videoIcon = `${ASSETS_URL}/images/video-icon.svg`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const xlsIcon = `${ASSETS_URL}/images/xlsx-icon.svg`;
const pptIcon = `${ASSETS_URL}/images/pptx-icon.svg`;
const pptxIcon = `${ASSETS_URL}/images/google-slides.svg`;
const xlsxIcon = `${ASSETS_URL}/images/xlsx-icon.svg`;

const acceptedVideoExtensions = [".mp4", ".mov", ".webm", ".mkv"];
const acceptedAudioExtensions = [".mp3", ".wav", ".ogg"];

const mapExtensionToMimeType = (extensions: string[], mimeType) => {
    return extensions.reduce((acc, ext) => {
        acc[`${mimeType}${ext}`] = [ext];
        return acc;
    }, {});
};

const ACCEPTED_FILES: Record<string, string[]> = {
    "application/pdf": [".pdf"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-excel": [".xls"],
    ...mapExtensionToMimeType(acceptedVideoExtensions, "video/"),
    ...mapExtensionToMimeType(acceptedAudioExtensions, "audio/"),
};

const FILES_ICONS = {
    "application/pdf": pdfIcon,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": pptxIcon,
    "application/vnd.ms-powerpoint": pptIcon,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": xlsxIcon,
    "application/vnd.ms-excel": xlsIcon,
    "video/*": videoIcon,
    "audio/*": videoIcon,
};

type DropZoneProps = {
    instruction: string;
    tapInstruction: string;
    droppedFile: File | null;
    setDroppedFile: SetState<File | null>;
};

const DropZone = ({ instruction, tapInstruction, droppedFile, setDroppedFile }: DropZoneProps) => {
    const { user } = useCurrentUser();
    const searchParams = useSearchParams();

    const folderId = searchParams?.get("folderId") ?? undefined;
    const classId = searchParams?.get("classId") ?? undefined;

    const confirmUpload = useS3UploadJobsSelector(state => state.confirmUpload);
    const clearUpload = useS3UploadJobsSelector(state => state.clearUpload);
    const uploadProgress = useS3UploadJobsSelector(state => state.uploadProgress);

    const [isDocUploading, setIsDocUploading] = useState(false);

    const getDropedFileIcon = () => {
        const isVideoFile = droppedFile?.type?.startsWith("video/");
        const isAudioFile = droppedFile?.type?.startsWith("audio/");

        return isVideoFile || isAudioFile ? videoIcon : FILES_ICONS[droppedFile?.type || ""];
    };

    const onDropAccepted = async acceptedFiles => {
        setDroppedFile(acceptedFiles[0]);
    };

    const onDropRejected = async rejectedFiles => {
        const { file } = rejectedFiles[0];
        toast.error(`File type not supported. ${file.name} cannot be uploaded.`);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        accept: ACCEPTED_FILES,
    });

    const onConfirmClick = async event => {
        event.stopPropagation();
        if (!droppedFile) return;

        await handleUploadMedia();
    };

    const handleUploadMedia = async () => {
        assertTruthy(droppedFile, "droppedFile should be defined");

        // if there's remains of previous upload job, clear it,
        // this component can only be shown for one upload job at a time.
        if (uploadProgress) clearUpload();

        try {
            const { cancelled } = await confirmUpload({ file: droppedFile, folderId, classId });
            toast.dismiss();

            if (cancelled) {
                toast.error("Upload cancelled");
                return;
            }
        } catch (e) {
            report(e, "media-confirmUpload-from-dropzone", {
                userId: user?.ID,
                fileName: droppedFile.name,
                extension: droppedFile.name.split(".")[1],
            });
            toast.error("Upload failed, please try again after reloading the page.");
        }
    };

    const cancelDropHandler = event => {
        event.stopPropagation();
        setDroppedFile(null);
    };

    const renderEmptyDropZone = () => (
        <React.Fragment>
            <input {...getInputProps()} />
            <Circular
                radius={"8rem"}
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    transition: "background-color 0.2s ease-in-out",
                }}>
                <Image src={uploadIcon} alt={"upload"} width={40} height={30} />
            </Circular>
            <FlexColumn style={{ gap: "1rem", textAlign: "center" }}>
                <p style={{ fontSize: "2rem" }} className={clsx(["knowtFontBold", br.smDownDisplayNone])}>
                    {instruction}
                </p>
                <p style={{ fontSize: "2rem" }} className={clsx(["knowtFontBold", br.smUpDisplayNone])}>
                    {tapInstruction}
                </p>
            </FlexColumn>
            <FlexColumnAlignJustifyCenter style={{ gap: "1.2rem" }}>
                <CircularRectTextButton
                    sx={{
                        width: "15rem",
                        height: "4rem",
                        backgroundColor: themeColors.primary,
                        color: "white",
                    }}>
                    {isDocUploading ? (
                        <Waveform size={20} color={themeColors.neutralBlack} />
                    ) : (
                        <p style={{ fontSize: "1.5rem", color: themeColors.primaryDark }} className={"knowtFontBold"}>
                            select files
                        </p>
                    )}
                </CircularRectTextButton>
                {!isDocUploading && (
                    <CircularRectTextButton
                        onClick={e => {
                            e.stopPropagation();
                            importFromDrive(
                                null,
                                ({ blob, title }) =>
                                    confirmUpload({
                                        file: blob,
                                        name: title,
                                        extension: fileTypeFromMimeType(blob.type),
                                        folderId,
                                        classId,
                                    }),
                                setIsDocUploading,
                                DriveImportType.MEDIAS
                            );
                        }}
                        sx={{
                            "&:hover": null,
                            "&:active": null,
                            backgroundColor: themeColors.background,
                        }}>
                        <p style={{ fontSize: "1.4rem", color: themeColors.primary4 }}>Or upload from Google Drive</p>
                    </CircularRectTextButton>
                )}
            </FlexColumnAlignJustifyCenter>
        </React.Fragment>
    );

    const renderDroppedFileInfo = () => (
        <React.Fragment>
            <FlexRowAlignCenter
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    columnGap: spacing.MD,
                    borderRadius: "2rem",
                    padding: spacing.SM,
                    maxWidth: "51.3rem",
                }}>
                <Flex
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "5rem",
                        height: "5rem",
                        borderRadius: "10px",
                        backgroundColor: "#F6E2E2",
                        padding: "1rem",
                    }}>
                    <Image src={getDropedFileIcon()} alt={"media"} width={"38"} height={"38"} />
                </Flex>
                <p
                    className={"body2"}
                    style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        maxHeight: "5.9rem",
                        overflow: "hidden",
                    }}>
                    {droppedFile?.name}
                </p>
            </FlexRowAlignCenter>
            <FlexColumnAlignJustifyCenter style={{ gap: "1rem" }}>
                <FlexColumn style={{ gap: "1rem", maxWidth: "90%", textAlign: "center" }}>
                    <p style={{ fontSize: "2.2rem", fontWeight: 600 }}>
                        Please confirm this is the file you’d like to upload
                    </p>
                    <p style={{ fontSize: "1.8rem", fontWeight: 400 }}>
                        The uploading may take a few seconds depending on file size.
                    </p>
                </FlexColumn>
            </FlexColumnAlignJustifyCenter>
            <FlexColumn style={{ rowGap: spacing.SM }}>
                <CircularRectTextButton
                    onClick={onConfirmClick}
                    sx={{
                        width: "15rem",
                        paddingInline: spacing.LG,
                        paddingBlock: spacing.SM,
                        gap: "1rem",
                        backgroundColor: themeColors.primary,
                        color: themeColors.primaryDark,
                    }}>
                    <p style={{ fontSize: "1.5rem" }} className={"knowtFontBold"}>
                        confirm
                    </p>
                </CircularRectTextButton>
                <CircularRectTextButton
                    onClick={cancelDropHandler}
                    sx={{
                        width: "auto",
                        paddingInline: spacing.LG,
                        paddingBlock: spacing.SM,
                        backgroundColor: "transparent",
                        gap: "1rem",
                    }}>
                    <p style={{ fontSize: "1.5rem" }} className={"bold1"}>
                        cancel
                    </p>
                </CircularRectTextButton>
            </FlexColumn>
        </React.Fragment>
    );

    return (
        <FlexColumnAlignJustifyCenter
            {...getRootProps()}
            style={{
                flex: 1,
                width: droppedFile ? undefined : "100%",
                paddingInline: droppedFile ? "6rem" : undefined,
                transition: "background-color 0.2s ease-in-out",
                borderRadius: "2rem",
                backgroundColor: themeColors.background,
                position: "relative",
                border: droppedFile ? "none" : "5px dashed #E5E5E5",
            }}>
            {droppedFile ? (
                <FlexColumnAlignJustifyCenter style={{ paddingBlock: "6rem", gap: "6rem" }}>
                    {renderDroppedFileInfo()}
                </FlexColumnAlignJustifyCenter>
            ) : (
                <FlexColumnAlignJustifyCenter style={{ height: "45rem", gap: "2.4rem" }}>
                    {renderEmptyDropZone()}
                </FlexColumnAlignJustifyCenter>
            )}
            {!droppedFile && (
                <Image
                    className={br.mdDownDisplayNone}
                    src="/images/kai-waving.svg"
                    alt="Kai is saying HI :)"
                    width={250}
                    height={250}
                    style={{ position: "absolute", bottom: 0, right: -180 }}
                />
            )}
        </FlexColumnAlignJustifyCenter>
    );
};

export default DropZone;
