export const ALL_EXAM_TYPES = [
    "AP",
    "IB",
    "GCSE",
    "A-Level",
    "GRE",
    "LSAT",
    "DAT",
    "MCAT",
    "SAT",
    "IGCSE",
    "ACT",
    "NCERT",
];
