"use client";

import Image from "next/image";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import KnowtStats from "../../components/KnowtStats";
import { Flex, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import SectionContainer from "../../components/SectionContainer";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import LandingTabSwitcher from "../../UploadLandingPages/components/LandingTabSwitcher";
import { useAuthSlideContextSelector } from "@/features/Auth";

const kaiBlackboard = `/images/kai-blackboard.svg`;

const BookTeacherVersionDemoSection = () => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);
    return (
        <>
            <Flex style={{ justifyContent: "center" }}>
                <LandingTabSwitcher />
            </Flex>
            <SectionContainer
                title="Best Quizlet alternative for teachers"
                subtitle="Create a classroom and share flashcards with your students. Students always study for free blah blah SEO text here"
                isPageHeader>
                <FlexColumnAlignJustifyCenter style={{ gap: spacing.LG_2 }}>
                    <CircularRectTextButton
                        onClick={() => openAuthSlide({ showSignupPage: true })}
                        style={{
                            backgroundColor: themeColors.primary,
                            color: themeColors.primaryDark,
                            padding: `${spacing.XS_2} ${spacing.MD}`,
                            width: "fit-content",
                        }}>
                        Sign up today
                    </CircularRectTextButton>

                    <Image src={kaiBlackboard} alt="Book a teacher version demo" width={506} height={340} />

                    <KnowtStats />
                </FlexColumnAlignJustifyCenter>
            </SectionContainer>
        </>
    );
};

export default BookTeacherVersionDemoSection;
