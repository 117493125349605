"use client";

import { ArrowRight } from "lucide-react";
import { useRouter } from "next13-progressbar";
import Circular from "@/components/Circular";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

const ViewMoreBtn = ({ href, style }: { href: string; style?: React.CSSProperties }) => {
    const router = useRouter();

    return (
        <CircularRectTextButton
            role="link"
            data-href={href}
            sx={{ ...style, height: "5.2rem", width: "16rem", fontSize: "1.5rem" }}
            onClick={() => router.push(href)}>
            View more{" "}
            <Circular radius="4rem" style={{ backgroundColor: themeColors.primary, marginLeft: "0.8rem" }}>
                <ArrowRight strokeWidth={strokeWidth.normal} size={iconSizes.MD} color={themeColors.pureWhite} />
            </Circular>
        </CircularRectTextButton>
    );
};

export default ViewMoreBtn;
