"use client";

import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { themeColors } from "@/utils/themeColors";
import Box from "@mui/material/Box";
import { iconSizes } from "@/utils/iconProps";
import { SearchIcon } from "lucide-react";
import { FlexRowAlignCenter } from "@/components/Flex";
import Image from "next/image";

import { useRouter } from "next13-progressbar";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { ASSETS_URL } from "@/config/deployConstants";
import { borderRadius } from "@/utils/borderRadius";

type SearchItem = {
    text: string;
    href: string;
    image?: string;
    onClick?: () => void;
};

const AutocompleteSearchBar = ({ placeholder, items }: { placeholder: string; items: SearchItem[] }) => {
    const router = useRouter();
    const [inputValue, setInputValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const renderInput = params => (
        <FlexRowAlignCenter
            ref={params.InputProps.ref}
            style={{
                padding: "1rem 2rem",
                width: "100%",
                borderRadius: borderRadius.longInput,
                backgroundColor: themeColors.neutralWhite,
                gap: "1.2rem",
                border: "2px solid",
                borderColor: isFocused ? themeColors.primary : themeColors.neutral2,
                transition: "border-color 0.2s ease-in-out",
            }}>
            <SearchIcon size={iconSizes.MD} />
            <Box
                as="input"
                placeholder={placeholder}
                {...params.inputProps}
                onFocus={() => {
                    params.inputProps?.onFocus?.();
                    setIsFocused(true);
                }}
                onBlur={() => {
                    params.inputProps?.onBlur?.();
                    setIsFocused(false);
                }}
                sx={{
                    backgroundColor: "transparent",
                    outline: "none",
                    border: "none",
                    fontSize: "1.6rem",
                    color: themeColors.neutralBlack,
                    ":hover": {
                        backgroundColor: "transparent",
                    },
                    fontFamily: "var(--knowt-font-name)",
                }}
            />
        </FlexRowAlignCenter>
    );

    const renderOption = (props, option: SearchItem, state) => {
        const { inputValue } = state;
        const matches = match(option.text, inputValue, { insideWords: true });
        const parts = parse(option.text, matches);
        return (
            <li {...props} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "1.5rem" }}>
                    {option.image && <Image src={option.image} alt={option.text} width={20} height={20} />}
                    <span>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: inputValue && part.highlight ? "600" : undefined }}>
                                {part.text}
                            </span>
                        ))}
                    </span>
                </div>
                <Image
                    className={"enter"}
                    src={`${ASSETS_URL}/images/enter.png`}
                    alt={option.text}
                    width={15}
                    height={10}
                />
            </li>
        );
    };

    return (
        <Autocomplete
            autoHighlight
            openOnFocus
            options={items}
            renderInput={renderInput}
            getOptionLabel={option => option.text}
            renderOption={renderOption}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            slotProps={{
                popper: {
                    style: { zIndex: 10, width: "100%", maxWidth: "80rem" },
                    placement: "bottom-start",
                    modifiers: [{ name: "flip", enabled: false }],
                },
            }}
            noOptionsText={
                <p
                    style={{
                        color: themeColors.neutralBlack,
                    }}>
                    No results found
                </p>
            }
            componentsProps={{
                paper: {
                    sx: {
                        fontSize: "1.6rem",
                        margin: "0.6rem 0 0.6rem",
                        padding: "1rem 1rem",
                        color: "var(--color-neutral-black)",
                        borderRadius: borderRadius.longInput,
                        boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.25)",
                        width: "100%",
                        minWidth: "32rem",
                        backgroundColor: "var(--color-neutral-white)",
                        "& .MuiAutocomplete-listbox::-webkit-scrollbar": {
                            display: "none",
                        },

                        font: "var(--knowt-font-name)",
                        zIndex: 999,
                    },
                },
            }}
            ListboxProps={{
                sx: {
                    "& .MuiAutocomplete-option.Mui-focused": {
                        backgroundColor: "var(--color-neutral-1)",
                    },
                },
            }}
            sx={{
                width: "100%",
                font: "var(--knowt-font-name)",
            }}
            onChange={(_event, option, reason) => {
                if (reason === "selectOption") {
                    if (option?.href) {
                        router.push(option.href);
                    } else {
                        option?.onClick?.();
                    }
                }
            }}
        />
    );
};

export default AutocompleteSearchBar;
