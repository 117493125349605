"use client";
import { Exam, FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import clsx from "clsx";
import { Share } from "lucide-react";
import Image from "next/image";
import React, { useState } from "react";
import CountdownTimer from "@/components/CountdownTimer";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import Link from "@/components/wrappers/Link";
import { shareOrCopy } from "@/utils/clipboard";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import DelayedComponent from "@/components/DelayedComponent";
import { getImageLink } from "@knowt/syncing/hooks/exams/utils";
import styles from "./examSubjectHeaderSection.module.css";
import br from "@/styles/breakpoints.module.css";

const ExamSubjectHeaderSection = ({
    examData,
}: {
    examData: Exam & { note: Note; flashcardSet: FlashcardSet; color: string };
}) => {
    const [isShareHovered, setIsShareHovered] = useState(false);

    const examDate = new Date(`${examData.date}${examData.time ? ` 0${examData.time}:00:00` : ""}`);
    const image = getImageLink(examData.name);

    const renderTitleAndDesc = () => (
        <FlexColumn
            style={{
                gap: spacing.SM,
                borderRadius: "3rem",
            }}>
            <FlexRowAlignCenter style={{ gap: spacing.SM }}>
                {image && (
                    <React.Fragment>
                        <Image
                            className={br.smDownDisplayNone}
                            src={image}
                            alt={examData.name + " image"}
                            width={50}
                            height={50}
                            style={{
                                objectFit: "contain",
                            }}
                        />
                        <Image
                            className={br.smUpDisplayNone}
                            src={image}
                            alt={examData.name + " image"}
                            width={25}
                            height={25}
                            style={{
                                objectFit: "contain",
                            }}
                        />
                    </React.Fragment>
                )}
                <h1 className="heading3">{examData.name}</h1>
            </FlexRowAlignCenter>
            <p
                title={examData.description ?? ""}
                className="body1"
                style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    fontWeight: 500,
                }}>
                {examData.description ?? ""}
            </p>
        </FlexColumn>
    );

    return (
        <FlexColumn style={{ gap: spacing.LG }}>
            {renderTitleAndDesc()}
            <Flex className={styles.headerBottomContainer} style={{ gap: spacing.SM }}>
                <div className={styles.timerBox} style={{ backgroundColor: themeColors.card, borderRadius: "2rem" }}>
                    <DelayedComponent delay={200}>
                        <CountdownTimer date={examDate} />
                    </DelayedComponent>
                </div>
                <FlexColumn className={styles.socialButtonsContainer} style={{ flex: 1, gap: spacing.XS }}>
                    <FlexRowAlignCenter
                        className={clsx(styles.socialButton)}
                        onClick={() => shareOrCopy({ title: examData.name })}
                        onPointerOver={() => setIsShareHovered(true)}
                        onPointerOut={() => setIsShareHovered(false)}
                        style={{
                            fontSize: "1.5rem",
                            gap: spacing.SM,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "2rem",
                        }}>
                        <CircularOutlineIcon Icon={Share} size={iconSizes.SM} overrideHover={isShareHovered} />
                        <p
                            className="bodyBold1"
                            style={{
                                color: "inherit",
                            }}>
                            Share with friends
                        </p>
                    </FlexRowAlignCenter>
                    <Link
                        href={"/discord"}
                        className={styles.socialButton}
                        style={{
                            fontSize: "1.5rem",
                            gap: spacing.SM,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "2rem",
                        }}>
                        <Image
                            src={`${ASSETS_URL}/images/discord-icon.svg`}
                            alt={"discord"}
                            width={iconSizes.MD}
                            height={iconSizes.MD}
                        />
                        <p
                            className="bodyBold1"
                            style={{
                                color: "inherit",
                            }}>
                            Join the Discord!
                        </p>
                    </Link>
                </FlexColumn>
            </Flex>
        </FlexColumn>
    );
};

export default ExamSubjectHeaderSection;
