"use client";

import { fetchFileBlob } from "@knowt/web/utils/fileUtils";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { createContext, useContextSelector } from "@/utils/use-context-selector";
import { uploadFile, waitForMediaEntryCreationOnUpload } from "@/hooks/media/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { cancelOngoingS3Upload } from "@/utils/s3";
import { platform } from "@/platform";
import { TIME } from "@/utils/dateTimeUtils";
import { STORAGE_KEYS } from "@/constants";
import { MediaUploadedData } from "@/constants/storage";

class PutResult {}

type S3UploadJobContextValue = {
    currentUploadJob: Promise<PutResult>;
    setCurrentUploadJob: Dispatch<SetStateAction<Promise<PutResult>>>;
    uploadProgress: number | null;
    setUploadProgress: Dispatch<SetStateAction<number | null>>;
    isUploading: boolean;
    setIsUploading: Dispatch<SetStateAction<boolean>>;
    uploadedFileId: string | null;
    confirmUpload: ({
        file,
        name,
        extension,
        folderId,
        classId,
    }: {
        file: File | Blob;
        name?: string;
        extension?: string;
        folderId?: string;
        classId?: string;
    }) => Promise<{
        cancelled?: boolean;
        id?: string;
    }>;
    cancelUpload: () => Promise<void>;
    clearUpload: () => void;
    fileName: string | null;
    uploadRemainingTime: string | null;
};

const S3UploadJobContext = createContext<S3UploadJobContextValue | null>(null);

export const S3UploadJobContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { userId } = useCurrentUser();

    const [currentUploadJob, setCurrentUploadJob] = useState<Promise<PutResult>>(null);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);
    const [uploadRemainingTime, setUploadRemainingTime] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const [fileName, setFileName] = useState<string | null>(null);

    const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);

    const clearUpload = useCallback(() => {
        setCurrentUploadJob(null);
        setUploadProgress(null);
        setIsUploading(false);
        setUploadedFileId(null);
        setUploadRemainingTime(null);
    }, []);

    const confirmUpload = useCallback(
        async ({
            file,
            name = file.name,
            extension: _extension,
            folderId,
            classId,
        }: {
            file: File | Blob;
            name?: string;
            extension?: string;
            folderId?: string;
            classId?: string;
        }) => {
            setIsUploading(true);
            setFileName(name);
            const lastDotIndex = name?.lastIndexOf(".");
            const extension = _extension ?? name?.substring(lastDotIndex + 1)?.toLowerCase();

            try {
                const fileBlob = file instanceof Blob ? file : await fetchFileBlob(file);

                const { id, cancelled, bucket } = await uploadFile({
                    fileBlob,
                    userId,
                    folderId,
                    classId,
                    setUploadProgress,
                    setCurrentUploadJob,
                    contentType: fileBlob.type,
                    setUploadRemainingTime,
                });

                if (cancelled) return { id, cancelled: true };

                if (userId) {
                    await waitForMediaEntryCreationOnUpload({ mediaId: id });
                } else {
                    const storage = await platform.storage();
                    await storage.setWithExpiry(
                        STORAGE_KEYS.MEDIA_UPLOADED_DATA,
                        { mediaId: id, extension, bucket } as MediaUploadedData,
                        TIME.HOUR
                    );
                    await storage.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);
                }

                setUploadedFileId(id);

                return { id, cancelled };
            } catch (e) {
                const { report } = await platform.analytics.logging();
                report(e, "media-confirmUpload", { userId, fileName, extension });
                throw e;
            }
        },
        // the other deps temp because of the report function
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [clearUpload, userId]
    );

    const cancelUpload = useCallback(async () => {
        const Mixpanel = await platform.analytics.mixpanel();
        Mixpanel.track(`media upload cancelled`, {
            userId,
            uploadRemainingTime,
            uploadProgress,
            fileName,
        });
        return await cancelOngoingS3Upload({
            uploadJob: currentUploadJob,
            setUploadJob: setCurrentUploadJob,
        })
            .then(() => {
                clearUpload();
            })
            .catch(async e => {
                const { report } = await platform.analytics.logging();
                report(e, "media-cancelUpload", { currentUploadJob });
            });

        // the other deps temp because of the report
        //  eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearUpload, currentUploadJob]);

    return (
        <S3UploadJobContext.Provider
            value={{
                currentUploadJob,
                setCurrentUploadJob,
                uploadProgress,
                setUploadProgress,
                isUploading,
                setIsUploading,
                uploadedFileId,
                confirmUpload,
                cancelUpload,
                clearUpload,
                fileName,
                uploadRemainingTime,
            }}>
            {children}
        </S3UploadJobContext.Provider>
    );
};

export const useS3UploadJobsSelector = <T,>(selector: (value: S3UploadJobContextValue) => T): T =>
    useContextSelector(S3UploadJobContext, selector);
