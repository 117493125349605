"use client";

import React from "react";
import ExamSubjectContentWithStudyOption from "./ExamSubjectContentWithStudyOption";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { HorizontallyCenteredRepeatableLeaderboardAdSlot } from "@/features/Ads";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { Exam, ExamUnit, FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { useParams } from "next/navigation";
import { examNameToUrlComponent } from "@knowt/syncing/hooks/exams/utils";

const PaddedAdWrapper = ({ children }) => <div style={{ margin: `${spacing.MD} 0rem` }}>{children}</div>;

const ExamSubjectStudyContentSection = ({
    examData,
    unitData,
}: {
    examData: Exam & { note: Note; flashcardSet: FlashcardSet; color: string };
    unitData: Record<string, ExamUnit & { note: Note; flashcardSet: FlashcardSet }>;
}) => {
    const params = useParams();

    return (
        <FlexColumn>
            {/* ULTIMATE KNOWT GUIDE*/}
            <ExamSubjectContentWithStudyOption
                key="ultimate"
                heading={
                    <span
                        className="heading4"
                        style={{
                            color: themeColors.primary,
                        }}>{`Ultimate ${examData.name} Study Guide`}</span>
                }
                examData={examData}
                isUltimateGuide
            />

            <PaddedAdWrapper key="ad-spacer">
                <HorizontallyCenteredRepeatableLeaderboardAdSlot
                    containerStyle={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    slotId={0}
                />
            </PaddedAdWrapper>

            {/* UNITS */}
            {unitData &&
                Object.values(unitData).map((unit, i) => {
                    const viewAllLink = `/exams/${params.type}/view/${params.urlComponent}/${examNameToUrlComponent(
                        unit.name
                    )}-flashcards`;

                    return (
                        <>
                            <ExamSubjectContentWithStudyOption
                                key={unit?.name}
                                examData={unit}
                                heading={
                                    <FlexRowAlignCenter
                                        className="heading5"
                                        key={unit?.name}
                                        style={{ gap: "1rem" }}
                                        onClick={() => {
                                            Mixpanel.track("Exam Page - Quick Access Clicked", {
                                                exam: examData?.name,
                                                type: examData?.type,
                                                unit: unit?.name,
                                            });
                                        }}>
                                        <span>
                                            <span style={{ fontWeight: 300 }}>
                                                {(unit?.note?.icon?.emoji ? `${unit?.note?.icon?.emoji} ` : "") +
                                                    examData.name}
                                            </span>
                                            <span style={{ fontWeight: 600 }}>{" " + unit?.name}</span>
                                        </span>
                                    </FlexRowAlignCenter>
                                }
                                viewAllLink={viewAllLink}
                            />

                            <PaddedAdWrapper>
                                <HorizontallyCenteredRepeatableLeaderboardAdSlot
                                    containerStyle={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    slotId={i + 1}
                                />
                            </PaddedAdWrapper>
                        </>
                    );
                })}
        </FlexColumn>
    );
};

export default ExamSubjectStudyContentSection;
