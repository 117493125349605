"use client";

import { useEffect } from "react";

export default function FallbackThemeSetup() {
    const getOSPreferredTheme = () => {
        const mql = window.matchMedia("(prefers-color-scheme: dark)");
        if (typeof mql.matches === "boolean") {
            return mql.matches ? "dark" : "light";
        }
    };

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", localStorage.theme || getOSPreferredTheme() || "light");
    }, []);

    return null;
}
