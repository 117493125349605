import { useRef } from "react";

const BANNER_CONTENT = [
    {
        color: "#FBE1E0",
        title: '"Whoever is happy will make others happy too." - Anne Frank',
        description:
            "Help make us happy by joining our supporter plan, or disabling your ad-blocker so we can build a mobile app!",
        alt: "cute",
    },
    {
        color: "#EAF2DE",
        title: '"Sometimes life hits you in the head with a brick. Don’t lose faith" - Steve Jobs',
        description:
            "It’s hard building Knowt but we never lose faith. Help us out by joining our supporter plan or disabling your ad-blocker!",
        alt: "wink",
    },
    {
        color: "#D9EBFE",
        title: '"No act of kindness no matter how small, is ever wasted" - Aesop',
        description:
            "We would be grateful if your act of kindness for today would be to join our supporter plan or disable ad blocker so we can build a mobile app!",
        alt: "smile",
    },
    {
        color: "#E5DFF2",
        title: '"The time is always right to do the right thing" - Martin Luther King Jr.',
        description:
            "Do the right thing and turn off your adblocker, or join the supporter plan, to help us build the mobile app quicker!",
        alt: "chick",
    },
    {
        color: "#FDF0E2",
        title: '"Me think, why waste time say lot word when few word do trick." - Kevin Malone',
        description: "We agree Kevin. Subscribe supporter plan or disable ad-blocker help us lots!",
        alt: "dog wink",
    },
    {
        color: "#FBE1E0",
        title: '"Work hard in silence, let your success be the noise." - Frank Ocean',
        description: "Help us make some noise by joining our supporter plan or disabling your ad-blocker!",
        alt: "headphone",
    },
    {
        color: "#EAF2DE",
        title: '"In the middle of every difficulty lies opportunity." ― Albert Einstein',
        description:
            "You have an opportunity to help us build the future by joining our supporter plan, or disabling your ad-blocker!",
        alt: "wink",
    },
    {
        color: "#D9EBFE",
        title: '"The way to get started is to quit talking and begin doing." - Walt Disney',
        description: "Help us to begin doing by joining our supporter plan or disabling your Ad blocker!",
        alt: "smile",
    },
    {
        color: "#E5DFF2",
        title: '"Today is your opportunity to build the tomorrow you want." - Ken Poirot',
        description:
            "You have an opportunity to help us build tomorrow by joining our supporter plan, or disabling your ad blocker!",
        alt: "chick",
    },
    {
        color: "#FDF0E2",
        title: '"I’m an early bird and I’m a night owl. So I’m wise and I have worms." ― Micael Scott',
        description:
            "You have an opportunity to help us build tomorrow by joining our supporter plan, or disabling your ad blocker!",
        alt: "dogWink",
    },
    {
        color: "#FBE1E0",
        title: '"Some people want it to happen, some wish it would happen, others make it happen" - Michael Jordan',
        description:
            "Help make us make the mobile app happen by disabling your ad-blocker, or joining our supporter plan!",
        alt: "basketball",
    },
    {
        color: "#EAF2DE",
        title: '"Before I do anything I ask myself, “Would an idiot do that?” And if the answer is yes, I do not do that thing" - Dwight Schrute',
        description:
            "Don’t be an idiot and join our supporter plan or turn off your ad-blocker to help us build the mobile app quicker!",
        alt: "glasses",
    },
    {
        color: "#D9EBFE",
        title: '"Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me." - Micheal Scott',
        description:
            "Show us how much you love us and join our supporter plan or turn off your ad-blocker to help us build the mobile app quicker!",
        alt: "tie",
    },
    {
        color: "#E5DFF2",
        title: `"Sometimes I'll start a sentence, and I don't even know where it's going. I just hope I find it along the way." - Micheal Scott`,
        description:
            "Let’s find our way together — help us out by joining our supporter plan or turning off your ad-blocker!",
        alt: "cloud",
    },
    {
        color: "#FDF0E2",
        title: '"It’s a moo point. It’s like a cow’s opinion; it doesn’t matter. It’s moo." - Joey Tribbian',
        description:
            "Help us make ads a moo point. Just join our supporter plan or disable your ad-blocker so we can build a mobile app quicker!",
        alt: "cow",
    },
];

const SHORT_QUOTES_INDEX = 9;

const useAdBlockerBannerContent = (isHorizontal?: boolean) => {
    const index = useRef<number>();

    if (!index.current) {
        const length = isHorizontal ? SHORT_QUOTES_INDEX : BANNER_CONTENT.length - 1;
        index.current = Math.floor(Math.random() * length);
    }

    const content = BANNER_CONTENT[index.current];

    return { content };
};

export default useAdBlockerBannerContent;
