import clsx from "clsx";
import { useRouter } from "next13-progressbar";
import Image from "next/image";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import styles from "./examSubjectContentWithStudyOption.module.css";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import UserContentCard from "@/components/cards/UserContentCard";
import Link from "@/components/wrappers/Link";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { ASSETS_URL } from "@/config/deployConstants";
import { Exam, ExamUnit, FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { ArrowRight, ChevronDown } from "lucide-react";
import br from "@/styles/breakpoints.module.css";
import CircularFilledIcon from "@/components/CircularButton/styled/CircularFilledIcon";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import { AuthEvent } from "@/features/Auth";

const brain = `${ASSETS_URL}/images/brain.svg`;
const test = `${ASSETS_URL}/images/test.svg`;

type APContentSectionComponentProps = {
    index?: number;
    heading: string | ReactElement;
    viewAllLink?: string;
    examData: Partial<(Exam | ExamUnit) & { note: Note | null; flashcardSet: FlashcardSet | null }>;
    isUltimateGuide?: boolean;
};

const ExamSubjectContentWithStudyOption = ({
    heading,
    viewAllLink,
    examData,
    isUltimateGuide,
}: APContentSectionComponentProps) => {
    const router = useRouter();
    const { checkAuth } = useCheckAuth();

    const [isCollapsed, setIsCollapsed] = useState(false);

    // for animated UI
    const [contentOpacity, setContentOpacity] = useState(1);
    const [contentHeight, setContentHeight] = useState(0);

    const contentBodyRef = useRef<HTMLDivElement | undefined>(undefined);
    const headingRef = useRef<HTMLDivElement | undefined>(undefined);

    const [collapsedHeight, setCollapsedHeight] = useState(0);

    const [targettedHeight, setTargettedHeight] = useState(0);

    const isEmpty = !examData?.note && !examData?.flashcardSet;
    const canCollapse = !isEmpty && !isUltimateGuide;

    const handlerContentBodyRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            contentBodyRef.current = node;

            const targettedHeight =
                (headingRef?.current?.getBoundingClientRect()?.height ?? 0) +
                (contentBodyRef?.current?.getBoundingClientRect()?.height ?? 0) +
                40 +
                2 * 40; // 40 is gap between heading and content, 2 * 40 is padding

            setTargettedHeight(targettedHeight);
            setContentHeight(targettedHeight);
        }
    }, []);

    const handlerHeadingRef = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            headingRef.current = node;
            setCollapsedHeight((headingRef?.current?.getBoundingClientRect()?.height ?? 0) + 2 * 40);
        }
    }, []);

    useEffect(() => {
        const updateHeights = () => {
            setCollapsedHeight((headingRef?.current?.getBoundingClientRect()?.height ?? 0) + 2 * 40);
            setTargettedHeight(
                (headingRef?.current?.getBoundingClientRect()?.height ?? 0) +
                    (contentBodyRef?.current?.getBoundingClientRect()?.height ?? 0) +
                    40 +
                    2 * 40
            );

            if (isCollapsed) {
                setContentHeight(collapsedHeight ?? 0);
            } else {
                setContentHeight(targettedHeight ?? 0);
            }
        };

        window.addEventListener("resize", updateHeights);

        return () => {
            window.removeEventListener("resize", updateHeights);
        };
    }, [collapsedHeight, isCollapsed, targettedHeight]);

    const toggleDropdown = () => {
        if (isCollapsed) {
            setIsCollapsed(false);
            setTimeout(() => {
                setContentHeight(targettedHeight ?? 0);
            }, 10);
            // wait for height to change
            setTimeout(() => {
                setContentOpacity(1);
            }, 100);
        }

        if (!isCollapsed) {
            setIsCollapsed(true);
            setContentOpacity(0);
            // wait for opacity to change
            setTimeout(() => {
                setContentHeight(collapsedHeight ?? 0);
            }, 100);
        }
    };

    const learnOptions = [
        {
            title: "Study with learn mode",
            desc: "A personalized and smart learning plan",
            image: brain,
            color: themeColors.videoLight,
            onClick: () =>
                checkAuth(() => router.push(`/study/flashcards/${examData?.flashcardSetId}/learn`), {
                    event: AuthEvent.EXAM_LEARN_MODE,
                }),
        },
        {
            title: "Take a practice test",
            desc: "Take a test on your terms and definitions",
            image: test,
            color: themeColors.subject1,
            onClick: () =>
                checkAuth(() => router.push(`/study/flashcards/${examData?.flashcardSetId}/test`), {
                    event: AuthEvent.EXAM_TEST_MODE,
                }),
        },
    ];

    const renderLearnOptions = () => {
        return (
            <Flex className={styles.learnOptionsContainer} style={{ width: "100%", gap: "2.4rem" }}>
                {learnOptions.map(option => (
                    <FlexRowAlignCenter
                        as={"button"}
                        onClick={option?.onClick}
                        className={clsx(styles.learnOption, "strippedBtn")}
                        key={option?.title}
                        style={{
                            borderRadius: "2rem",
                            justifyContent: "center",
                            flexDirection: "row",
                            transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
                        }}>
                        <Image src={option?.image} alt={option?.title} width={30} height={32} />
                        <p className="body1" style={{ color: "inherit" }}>
                            {option?.title}
                        </p>
                    </FlexRowAlignCenter>
                ))}
            </Flex>
        );
    };

    const renderEmptyUnitCard = () => (
        <FlexColumnAlignJustifyCenter
            style={{
                width: "100%",
                textAlign: "center",
                gap: "1.2rem",
                paddingTop: "4rem",
            }}>
            <p className="bodyBold1">We’re still working on this study guide! Check back later.</p>
            <p
                className="body2"
                style={{
                    maxWidth: "50rem",
                }}>
                P.S. If you’re interested in making some extra cash for writing notes like these, consider applying to
                be a KnowtTaker.
            </p>
            {viewAllLink && (
                <Link href={viewAllLink ?? ""}>
                    <OutlineTextButton
                        text={
                            <span
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                    fontSize: "1.3rem",
                                }}>
                                see all unit notes and flashcards
                                <ArrowRight size={iconSizes.SM} />
                            </span>
                        }></OutlineTextButton>
                </Link>
            )}
        </FlexColumnAlignJustifyCenter>
    );

    const renderContent = () => {
        if (isEmpty) {
            return renderEmptyUnitCard();
        }

        return (
            <FlexColumn
                ref={handlerContentBodyRef}
                style={{
                    flexDirection: "column",
                    transition: "opacity 0.1s ease-in-out , height 0.2s ease-in-out",
                    maxHeight: "fit-content",
                    gap: "4rem",
                    zIndex: 1,
                    ...(canCollapse && {
                        opacity: contentOpacity,
                        visibility: contentHeight === 0 ? "hidden" : "visible",
                    }),
                }}>
                <Flex
                    className={styles.noteAndFlashcardPair}
                    style={{
                        gap: "2.4rem",
                    }}>
                    {examData?.note && (
                        <FlexColumn className={styles.userContentCard}>
                            <p className="bodyBold2">Note</p>
                            <UserContentCard
                                note={examData.note}
                                style={{
                                    width: "100%",
                                    border: "2px solid ",
                                    borderColor: themeColors.neutral1,
                                }}
                            />
                        </FlexColumn>
                    )}
                    {examData?.flashcardSet && (
                        <FlexColumn className={styles.userContentCard}>
                            <p className="bodyBold2">Flashcards</p>
                            <UserContentCard
                                flashcardSet={examData.flashcardSet}
                                style={{ width: "100%", border: "2px solid ", borderColor: themeColors.neutral1 }}
                            />
                        </FlexColumn>
                    )}
                </Flex>
                {renderLearnOptions()}
                {viewAllLink && (
                    <Link
                        href={viewAllLink}
                        className={"bodyBold1"}
                        style={{
                            color: themeColors.primary4,
                            textAlign: "right",
                            width: "100%",
                            justifyContent: "flex-end",
                            display: "flex",
                            gap: "0.4rem",
                            alignItems: "center",
                        }}>
                        view all
                        <ArrowRight size={iconSizes.MD} color={themeColors.primary4} strokeWidth={"2px"} />
                    </Link>
                )}
            </FlexColumn>
        );
    };

    return (
        <FlexColumn
            style={{
                gap: spacing.LG,
                backgroundColor: themeColors.neutralWhite,
                borderRadius: "2rem",
                padding: "4rem",
                maxHeight: "fit-content",
                minHeight: "fit-content",
                overflow: "hidden",
                transition: "height 0.2s ease-in-out",
                border: "2px solid",
                borderColor: isUltimateGuide ? themeColors.primary : "transparent",
                maxWidth: "100%",
                position: "relative",
                boxSizing: "border-box",
                ...(canCollapse && {
                    height: canCollapse ? contentHeight || targettedHeight : "initial",
                }),
            }}
            as={"section"}>
            {isEmpty && (
                <CircularRectTextButton
                    disabled
                    className={clsx("bodyBold2", br.smUpDisplayNone)}
                    sx={{
                        width: "14rem",
                        height: "4.2rem",
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                        alignSelf: "flex-end",
                    }}>
                    coming soon
                </CircularRectTextButton>
            )}
            <Flex
                style={{ justifyContent: "space-between" }}
                ref={handlerHeadingRef}
                onClick={() => canCollapse && toggleDropdown()}>
                <FlexColumn
                    style={{
                        gap: "1.2rem",
                        cursor: canCollapse ? "pointer" : "default",
                    }}>
                    {isUltimateGuide && !isEmpty && (
                        <React.Fragment>
                            <Image
                                className={br.smDownDisplayNone}
                                src="/images/kai-waving.svg"
                                alt="kai waving"
                                width={183}
                                height={230}
                                style={{
                                    position: "absolute",
                                    right: "4rem",
                                    top: "2.5rem",
                                    zIndex: 0,
                                }}
                            />
                            <Image
                                src="/images/kai-waving.svg"
                                alt="kai waving"
                                width={183}
                                height={230}
                                className={br.smUpDisplayNone}
                                style={{
                                    zIndex: 0,
                                    margin: "0 auto -10rem auto",
                                    clipPath: "polygon(0 0, 100% 0, 100% 62%, 0 62%)",
                                }}
                            />
                        </React.Fragment>
                    )}
                    {heading}
                    {isUltimateGuide && (
                        <p
                            style={{
                                zIndex: 1,
                            }}>
                            The only resource you need to get a 5 on the {examData.name} exam.
                        </p>
                    )}
                </FlexColumn>
                {isEmpty && (
                    <CircularRectTextButton
                        disabled
                        className={clsx("bodyBold2", br.smDownDisplayNone)}
                        sx={{
                            width: "14rem",
                            height: "4.2rem",
                            backgroundColor: themeColors.neutralBlack,
                            color: themeColors.neutralWhite,
                        }}>
                        coming soon
                    </CircularRectTextButton>
                )}
                {canCollapse && (
                    <CircularFilledIcon
                        size={iconSizes.MD}
                        Icon={ChevronDown}
                        buttonColor={themeColors.neutral1}
                        hoverColor={themeColors.neutral1}
                        iconProps={{
                            color: themeColors.neutralBlack,
                            style: {
                                cursor: "pointer",
                                transform: !isCollapsed ? "rotate(180deg)" : "rotate(0deg)",
                                transition: "transform 0.3s ease-in-out",
                            },
                            strokeWidth: "2px",
                        }}
                        onClick={toggleDropdown}
                    />
                )}
            </Flex>
            {renderContent()}
        </FlexColumn>
    );
};

export default ExamSubjectContentWithStudyOption;
