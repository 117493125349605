import React from "react";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { LandingCenteredHalfSection } from "./styled/Containers";
import styles from "../landing.module.css";

type SectionContainerProps = {
    title?: string;
    subtitle?: React.ReactNode;
    children: React.ReactNode;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    subtitleStyle?: React.CSSProperties;
    isPageHeader?: boolean;
    sectionStyle?: React.CSSProperties;
    headerStyle?: React.CSSProperties;
};

const SectionContainer = ({
    title,
    subtitle,
    children,
    style,
    titleStyle,
    subtitleStyle,
    isPageHeader = false,
    sectionStyle,
    headerStyle,
}: SectionContainerProps) => {
    const renderTitle = () => {
        return isPageHeader ? (
            <h1 className="heading1" style={{ maxWidth: "90rem", ...titleStyle }}>
                {title}
            </h1>
        ) : (
            <h2 className="heading2">{title}</h2>
        );
    };

    return (
        <LandingCenteredHalfSection style={{ maxWidth: "105rem", width: "100%", ...sectionStyle }}>
            <FlexColumnAlignJustifyCenter style={{ width: "100%", gap: "5.5rem", ...style }}>
                <FlexColumnAlignJustifyCenter style={{ gap: "1.2rem", textAlign: "center", ...headerStyle }}>
                    {renderTitle()}
                    {subtitle && (
                        <p className={styles.sectionSubTitle} style={{ ...subtitleStyle }}>
                            {subtitle}
                        </p>
                    )}
                </FlexColumnAlignJustifyCenter>
                {children}
            </FlexColumnAlignJustifyCenter>
        </LandingCenteredHalfSection>
    );
};

export default SectionContainer;
