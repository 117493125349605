"use client";

import Image from "next/image";
import Box from "@mui/material/Box";
import { spacing } from "@/utils/spacing";
import br from "@/styles/breakpoints.module.css";
import CheckIcon from "@mui/icons-material/Check";
import { themeColors } from "@/utils/themeColors";
import { useAuthSlideContextSelector } from "@/features/Auth";
import { ASSETS_URL } from "@/config/deployConstants";
import SectionContainer from "../../components/SectionContainer";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { useRouter } from "next/navigation";

//now we have no plans for teachers and districts so we will use this as a temporary component and just signup on click
const TempPricingCard = ({
    icon,
    type,
    subtitle,
    intervalPrice,
    oldPrice,
    intervalText,
    buttonText,
    features,
    description,
    isDarkBackground = false,
    buttonBackgroundColor,
    onClick,
}) => {
    return (
        <FlexColumn
            as={Box}
            component="section"
            sx={{
                color: themeColors.neutralBlack,
                "&:hover": { transform: "translateY(-2px) scale(1.005) translateZ(0)" },
                transition: "transform 0.2s ease-out, box-shadow 0.2s ease-out",
                backgroundColor: themeColors.neutralWhite,
                padding: "4rem 3rem 5rem 3rem",
                borderRadius: "2rem",
                height: "70rem",
                width: "35rem",
                gap: "2rem",
                position: "relative",
            }}>
            <FlexRowAlignCenter style={{ gap: "1rem", fontSize: "2.6rem" }} className="knowtFontBold">
                <Image src={icon} width="42" height="42" alt={"plan type icon"} />
            </FlexRowAlignCenter>

            <h2 style={{ fontSize: "2.5rem" }}>{type}</h2>
            <div style={{ fontSize: "1.3rem", height: "3.5rem" }}>{subtitle}</div>

            <FlexColumn className="bold" as={"p"}>
                <FlexRowAlignCenter style={{ gap: "1.2rem" }} as="span">
                    <span style={{ fontWeight: "600", fontSize: "3.5rem" }}>{intervalPrice}</span>
                    {oldPrice && (
                        <s style={{ fontWeight: "600", fontSize: "2.5rem", color: themeColors.neutral2 }}>{oldPrice}</s>
                    )}
                </FlexRowAlignCenter>
                <span style={{ marginTop: "1rem", fontWeight: "400", fontSize: "1.2rem" }}>{intervalText}</span>
            </FlexColumn>

            <CircularRectTextButton
                onClick={onClick}
                sx={{
                    width: "20rem",
                    height: "4.2rem",
                    borderRadius: "5rem",
                    margin: "0 auto",
                    color: buttonBackgroundColor ? themeColors.primaryDark : themeColors.neutralBlack,
                    backgroundColor: buttonBackgroundColor || "transparent",
                    border: !buttonBackgroundColor ? `1px solid ${themeColors.neutralBlack}` : "none",
                }}>
                {buttonText}
            </CircularRectTextButton>

            <p style={{ fontSize: "1.5rem", fontWeight: "600" }}>{description}</p>
            <ul className="strippedList">
                {features.map((feature, index) => {
                    return (
                        <li key={index} style={{ marginBottom: "2rem" }}>
                            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                                <FlexColumnAlignJustifyCenter
                                    style={{
                                        borderRadius: "999px",
                                        height: "2.3rem",
                                        minWidth: "2.3rem",
                                        maxWidth: "2.3rem",
                                        backgroundColor: isDarkBackground ? themeColors.pureWhite : "#000",
                                    }}>
                                    <CheckIcon
                                        sx={{
                                            fontSize: "1.7rem",
                                            color: isDarkBackground ? "#000" : themeColors.pureWhite,
                                        }}
                                    />
                                </FlexColumnAlignJustifyCenter>
                                <p style={{ fontSize: "1.5rem" }}>{feature}</p>
                            </FlexRowAlignCenter>
                        </li>
                    );
                })}
            </ul>
        </FlexColumn>
    );
};

const pricingCards = [
    {
        icon: `${ASSETS_URL}/images/teacher-icon.svg`,
        type: "Teacher",
        subtitle: "Individual plans for teachers",
        intervalPrice: "Free",
        intervalText: "for first 6 months, no credit card required",
        buttonText: "Sign up",
        description: "Your perks",
        features: ["View class progress on flashcards", "Profile page customization"],
        oldPrice: "2.99$",
        buttonBackgroundColor: themeColors.primary,
        link: null,
    },
    {
        icon: `${ASSETS_URL}/images/district-icon.svg`,
        type: "District",
        subtitle: "Plans for your whole school district",
        intervalPrice: "Custom",
        intervalText: "sold by school year",
        buttonText: "contact us for pricing",
        description: "Your perks",
        features: [
            "Discount pricing for bulk teacher and student plans",
            "No ads for all your users",
            "Admin portal to manage all accounts",
            "Links with Clever, Edlink, and others for SSO",
        ],
        oldPrice: null,
        buttonBackgroundColor: null,
        link: "https://form.fillout.com/t/7ZQh3S6aWQus",
    },
];

const PricingSection = () => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);
    const router = useRouter();

    return (
        <SectionContainer
            title="Sign up now & get it free for the rest of the school year"
            subtitle="Want your school to purchase a plan? Submit our contact form.">
            <FlexRowAlignCenter className={br.smDownColumn} style={{ gap: spacing.LG_2 }}>
                {pricingCards.map((pricingCard, index) => (
                    <TempPricingCard
                        key={index}
                        onClick={() => {
                            if (pricingCard.link) {
                                router.push(pricingCard.link);
                            } else {
                                openAuthSlide({ showSignupPage: true });
                            }
                        }}
                        {...pricingCard}
                    />
                ))}
            </FlexRowAlignCenter>
        </SectionContainer>
    );
};

export default PricingSection;
