"use client";

import { useSearchParams } from "next/navigation";
import KnowtPagination from "@/components/KnowtPagination";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import { FlexRowAlignCenter } from "@/components/Flex";

const SearchPagePagination = ({ totalPages }: { totalPages: number }) => {
    const searchParams = useSearchParams();
    const { addParamsPush } = useSafeQueryNavigation();

    const currentPage = Number(searchParams?.get("page")) || 1;

    return (
        <FlexRowAlignCenter as="ul" className="strippedList" style={{ gap: "1.2rem", justifyContent: "center" }}>
            <KnowtPagination
                count={totalPages}
                currentPage={currentPage}
                onChange={(_, page) => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    addParamsPush({ page: String(page) });
                }}
            />
        </FlexRowAlignCenter>
    );
};

export default SearchPagePagination;
