"use client";

import Circular from "@/components/Circular";
import { themeColors } from "@/utils/themeColors";
import { Check, X } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { LandingCenteredHalfSection } from "../../../components/styled/Containers";
import br from "@/styles/breakpoints.module.css";
import styles from "../../uploadLandingPages.module.css";
import Image from "next/image";
import React from "react";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import SmallLinkedCard from "../SmallLinkedCard";
import { smartCardsLinkedCards } from "../../utils/constants";

type ComparedFeature = {
    desc: string;
    knowt: boolean;
    quizlet: boolean;
};

const FEATURE_LIST: ComparedFeature[] = [
    {
        desc: "Unlimited FREE Learn mode",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "Unlimited FREE Practice tests",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "Attach images to flashcards for FREE",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "Attach images to term side",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "FREE Flashcard Formatting",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "Spaced Repetition Mode",
        knowt: true,
        quizlet: false,
    },
    {
        desc: "Make your own flashcards",
        knowt: true,
        quizlet: true,
    },
];

const FeaturesComparison = ({
    title = "Comparing Knowt vs Quizlet",
    subtitle,
    hideFooter = false,
    features,
}: {
    title?: string;
    subtitle?: string;
    hideFooter?: boolean;
    features?: ComparedFeature[];
}) => {
    const linkedCards = smartCardsLinkedCards;
    const featuresToCompare = features || FEATURE_LIST;

    const getCheckMark = ({ checked }) => {
        return (
            <Circular
                radius="3.4rem"
                style={{ backgroundColor: checked ? themeColors.primary : themeColors.errorPrimary, margin: "0 auto" }}>
                {checked ? (
                    <Check size={iconSizes.MD} strokeWidth={strokeWidth.normal} color={themeColors.primaryDark} />
                ) : (
                    <X size={iconSizes.MD} strokeWidth={strokeWidth.normal} color={themeColors.neutralWhite} />
                )}
            </Circular>
        );
    };

    return (
        <LandingCenteredHalfSection style={{ maxWidth: "105rem" }}>
            <FlexColumnAlignJustifyCenter style={{ marginBottom: spacing.LG, gap: spacing.SM }}>
                <h2 className="heading2">{title}</h2>
                <span className="bodyXL">{subtitle}</span>
            </FlexColumnAlignJustifyCenter>
            <div className={styles.comparasionTableContainer} style={{ backgroundColor: themeColors.neutralWhite }}>
                <table className={styles.tableBorder}>
                    <thead>
                        <tr style={{ verticalAlign: "top" }}>
                            <th
                                className="bodyBold1"
                                style={{ textAlign: "left", paddingBottom: "2.4rem", width: "40rem" }}>
                                Features
                            </th>
                            <th className="bodyBold1" style={{ textAlign: "center" }}>
                                Knowt
                            </th>
                            <th className="bodyBold1" style={{ textAlign: "center" }}>
                                Quizlet
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {featuresToCompare.map((feature, index) => (
                            <tr key={feature.desc + index}>
                                <td className="body1" style={{ textAlign: "left", padding: "2.4rem 2.4rem 2.4rem 0" }}>
                                    {feature.desc}
                                </td>
                                <td style={{ textAlign: "center", width: "24.7rem" }}>
                                    {getCheckMark({ checked: feature.knowt })}
                                </td>
                                <td style={{ textAlign: "center", width: "24.7rem" }}>
                                    {getCheckMark({ checked: feature.quizlet })}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <FlexRowAlignCenter
                className={br.mdDownColumn}
                style={{ gap: spacing.MD, alignItems: "stretch", width: "100%", marginTop: spacing.MD }}>
                {linkedCards.map((linkedCard, index) => (
                    <SmallLinkedCard key={index} content={linkedCard} />
                ))}
            </FlexRowAlignCenter>
            {!hideFooter && (
                <FlexColumnAlignJustifyCenter style={{ width: "100%", marginTop: spacing.XL, position: "relative" }}>
                    {/* Mobile */}
                    <FlexColumn className={br.smUpDisplayNone} style={{ textAlign: "center", paddingBottom: "1.2rem" }}>
                        <p className={"bodyBold1"}>One site to take notes, study flashcards & more.</p>
                        <p>Get the functionality of Quizlet, Notion, Google Docs, and Fiveable in a single app.</p>
                    </FlexColumn>
                    {/* Desktop */}
                    <FlexColumn
                        className={br.smDownDisplayNone}
                        style={{ rowGap: spacing.SM, position: "absolute", top: 10, right: "10%", left: "40%" }}>
                        <h3 className={"heading3"}>One site to take notes, study flashcards & more.</h3>
                        <p>Get the functionality of Quizlet, Notion, Google Docs, and Fiveable in a single app.</p>
                    </FlexColumn>

                    <Image
                        className={br.smDownWidth100}
                        src={"/images/kai_crossing_no_text.svg"}
                        alt={"One study tool that replace the rest"}
                        height="500"
                        width="900"
                        sizes="100vw"
                        style={{ width: "90%", maxWidth: "90rem", height: "auto", margin: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
            )}
        </LandingCenteredHalfSection>
    );
};

export default FeaturesComparison;
