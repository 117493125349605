"use client";
import AutocompleteSearchBar from "@/components/AutocompleteSearchBar";
import { Flex, FlexColumn } from "@/components/Flex";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { examNameToUrlComponent, getImageLink } from "@knowt/syncing/hooks/exams/utils";
import LandingPageSearchHeader from "../../../components/LandingPageSearchHeader";
import CategoryHeaderPills from "../../../components/CategoryHeaderPills";

const ExamCategoryHeaderSection = ({ title, allExams, categories, type }) => (
    <FlexColumn>
        <LandingPageSearchHeader
            title={title}
            searchComponent={
                <AutocompleteSearchBar
                    items={allExams.map(exam => ({
                        text: exam,
                        href: `/exams/${type}/${examNameToUrlComponent(exam)}`,
                        image: getImageLink(exam),
                    }))}
                    placeholder={`Search for ${type} exams`}
                />
            }
            bottomComponent={
                <CategoryHeaderPills
                    items={categories.map(category => ({
                        label: category,
                        onClick: () => {
                            const element = document.getElementById(`category-${category}`);
                            element?.scrollIntoView({ behavior: "smooth", block: "center" });
                        },
                    }))}
                />
            }
        />
        <Flex style={{ maxWidth: "70.3rem", marginTop: spacing.LG, textAlign: "center", alignSelf: "center" }}>
            <span style={{ fontSize: "3.5rem", color: themeColors.neutralBlack, fontWeight: "600" }}>
                {`Click on any exam to study from those ${type} resources`}
            </span>
        </Flex>
    </FlexColumn>
);

export default ExamCategoryHeaderSection;
