"use client";

import LandingTabSwitcher from "./components/LandingTabSwitcher";
import { LandingCenteredHalfSection, LandingMainContainer } from "../components/styled/Containers";
import styles from "./components/medias/video-summarizer.module.css";
import DropSection from "./components/aiFlashcards/DropSection";
import FeaturesComparison from "./components/aiFlashcards/FeaturesComparison";
import DoMoreWithLessTime from "./components/medias/DoMoreWithLessTime";
import { LANDING_LINKS, LandingPageTopic } from "./utils/constants";
import UsersFeedbacks from "./components/UsersFeedbacks";
import EndlessWays from "./components/EndlessWays";
import ExploreSection from "./components/ExploreSection";
import Footer from "@/components/Footer";
import NavBar from "@/features/NavBar";
import LogPageView from "@/components/LogPageVisit";
import { Flex, FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { LocalUser } from "@knowt/syncing/hooks/user/types";
import FAQ from "@/components/FAQ";
import { useEffect } from "react";
import { wait } from "@knowt/syncing/utils/genericUtils";
import { useAuthSlideContextSelector } from "@/features/Auth";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { FrontendFeaturedCategory } from "@knowt/syncing/hooks/exams/utils";

const aiFlashcardsFAQs = [
    {
        question: "Why is Knowt the best free Quizlet alternative?",
        answer: "Over 1 Million students have switched to Knowt and trust it for a good reason! It’s super easy to bring over your existing Quizlets to Knowt and start studying them completely free in seconds. You can go through unlimited rounds of our free learn mode, matching game, spaced repetition or practice test mode. Plus, if you want, you can also create your own flashcards or have AI make Flashcards from your lecture videos, pdfs, notes, and more! If that's not for you, you can find millions of free flashcards made by other students at your fingertips. There’s a lot of great free study tools and resources to take advantage on Knowt that make it a great free quizlet alternative.",
    },
    {
        question: "How can I make new flashcards on Knowt?",
        answer: (
            <FlexColumn>
                <span>
                    Option 1: Easily make AI Flashcards from your lecture videos and notes in seconds using ai. It's as
                    easy as uploading your files and pressing a button!
                </span>
                <br />
                <span>
                    Option 2: Find a flashcard set on Quizlet and bring it over to Knowt to take advantage of our free
                    learn mode, free practice test mode and free games!
                </span>
                <br />
                <span>
                    Option 3: Just add a term and definition to make your flashcards, plus add images to flashcards for
                    free. You can also take advantage of our brand new AI Smart{" "}
                    <LinkWrapper href={LANDING_LINKS.AI_FLASHCARDS}>Flashcard maker</LinkWrapper> to save time!
                </span>
            </FlexColumn>
        ),
    },
    {
        question: "What features are paid on Quizlet?",
        answer: "As of 2024, learn mode, practice tests, adding images & formatting to sets is paid on Quizlet. But, when you import those Quizlets onto Knowt, you can study them completely free without having to pay.",
    },
    {
        question: "What's the difference the flashcard study modes on Knowt?",
        answer: [
            "Learn mode: If your exam is coming up in less than a week, this is the study more for you! It's more than just flipping through flashcards - pick your question types and get tested continuously until you completely master every card in your set!",
            "Spaced Repetition mode: The free spaced repetition study mode automatically sets the optimal revision schedule and tells you exactly how many flashcards to study each day.",
            "Practice Test mode: Use Practice Test mode once you're close to mastering all the flashcards and get your test graded automatically.",
            "Matching Game mode: Drag and drop corresponding terms to their definitions until you've matched every set. Your goal is to make all the cards disappear from the screen in as Little time as possible",
        ],
    },
    {
        question: "What is Knowt Match Mode?",
        answer: "If you're a visual learner, our free match game might be your new favorite way to study flashcards on Knowt! You’ll basically be racing against the clock to match you flashcards’ terms and definitions as quickly as you can. Each time you play, try to beat your top score and recall your flashcards faster.",
    },
    {
        question: "How do I use learn mode on Knowt?",
        answer: "Learn mode is a great way to master your flashcards, especially on a time crunch. To study your flashcards using learn mode, open your flashcard set and click on learn mode from the study modes on the left hand side. You can choose to turn on written mode or changes the question types of the flashcards using the Options button on th top right. All the customisability & options also makes this a great free quizlet alternative.",
    },
];

const UploadMainLandingPage = ({
    serverUser,
    featuredSubjectCategories,
    topNotes,
    topFlashcardSets,
    fromVerify = false,
    fromReset = false,
    fromLogin = false,
    fromSignup = false,
}: {
    serverUser: LocalUser;
    // TODO(types): make this into a stronger type
    featuredSubjectCategories: FrontendFeaturedCategory[];
    topNotes: Note[];
    topFlashcardSets: FlashcardSet[];
    fromVerify?: boolean;
    fromReset?: boolean;
    fromLogin?: boolean;
    fromSignup?: boolean;
}) => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    useEffect(() => {
        const openAuthFlow = async () => {
            await wait(500);
            if (fromVerify) {
                openAuthSlide({ showVerifyPage: true });
            } else if (fromReset) {
                openAuthSlide({ showResetPage: true });
            } else if (fromLogin) {
                openAuthSlide({ showLoginPage: true });
            } else if (fromSignup) {
                openAuthSlide({ showSignupPage: true });
            }
        };

        openAuthFlow();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <LogPageView page={"Smart Cards Landing Page - Visited"} />
            <NavBar serverUser={serverUser} />

            <Flex
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    justifyContent: "center",
                    paddingTop: spacing.MD,
                }}>
                <LandingTabSwitcher />
            </Flex>
            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                style={{
                    backgroundColor: themeColors.neutralWhite,
                    paddingTop: "0",
                }}>
                <DropSection />
            </LandingMainContainer>

            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                style={{ backgroundColor: themeColors.background, padding: "5rem 0" }}>
                <FeaturesComparison />
            </LandingMainContainer>

            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                style={{ alignItems: "center", backgroundColor: themeColors.neutralWhite }}>
                <DoMoreWithLessTime title={"Master your AI Flashcards faster"} topic={LandingPageTopic.AIFlashcards} />
            </LandingMainContainer>
            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                className={styles.feedbackContainer}
                style={{ backgroundColor: themeColors.background }}>
                <UsersFeedbacks />
            </LandingMainContainer>
            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                style={{ backgroundColor: themeColors.neutralWhite }}>
                <EndlessWays topic={LandingPageTopic.AIFlashcards} />
            </LandingMainContainer>
            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                className={styles.LandingMainContainer}
                style={{ backgroundColor: themeColors.background }}>
                <ExploreSection
                    subjectCategories={featuredSubjectCategories}
                    topNotes={topNotes}
                    topFlashcardSets={topFlashcardSets}
                />
            </LandingMainContainer>

            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                style={{
                    backgroundColor: themeColors.neutralWhite,
                }}>
                <LandingCenteredHalfSection style={{ maxWidth: "105rem" }}>
                    <FAQ
                        title="FAQ"
                        titleClassName="heading2"
                        description="We thought you might have some questions..."
                        containerStyle={{
                            backgroundColor: themeColors.neutralWhite,
                            margin: 0,
                            gap: "4rem",
                        }}
                        faqs={aiFlashcardsFAQs}
                    />
                </LandingCenteredHalfSection>
            </LandingMainContainer>
            <Footer />
        </>
    );
};

export default UploadMainLandingPage;
