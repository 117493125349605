import { getHtmlFromContent } from "@knowt/editor/helpers/getHtmlFromContent";
import { useFlashcardSet } from "@knowt/syncing/hooks/flashcards/useFlashcards";
import { useNote } from "@knowt/syncing/hooks/notes/useNotes";
import { UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { useRouter } from "next13-progressbar";
import Image from "next/image";
import { Rem } from "@/types/common";
import HtmlText from "../HtmlText/HtmlText";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LoadingAnimation from "@/components/LoadingAnimation";
import StarRating from "@/components/StarRating";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { borderRadius } from "@/utils/borderRadius";
import { getNoteUrl } from "@/utils/url";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const ContentPreviewerEmptyState = () => {
    return (
        <FlexColumnAlignJustifyCenter style={{ textAlign: "center", margin: "auto", paddingBottom: "7rem" }}>
            <Image
                src={`${ASSETS_URL}/images/kai-confused.svg`}
                alt="no content"
                width="190"
                height={244}
                style={{ objectFit: "contain", marginBottom: "3rem" }}
            />
            <h4 className="heading4" style={{ marginBottom: "1.5rem" }}>
                Look’s like this file is empty
            </h4>
            <p style={{ fontSize: "1.8rem" }}>The owner may not have added anything to it yet.</p>
        </FlexColumnAlignJustifyCenter>
    );
};

/**
 * @param parentPadding - The padding of the parent. We need this to calculate the content container scroll position
 */
const ContentPreviewer = ({ parentPadding }: { parentPadding: { right: Rem; bottom: Rem } }) => {
    const router = useRouter();

    const noteId = useCardPreviewContextSelector(state => state.noteId);
    const flashcardSetId = useCardPreviewContextSelector(state => state.flashcardSetId);

    const { note, isLoading: isLoadingNote } = useNote({ noteId });
    const { flashcardSet, loading: isLoadingFlashcardSet } = useFlashcardSet({ flashcardSetId });

    const isNote = !flashcardSet && noteId;
    const isFlashcardSet = !note && flashcardSetId;

    const isLoading = (isNote && isLoadingNote) || (isFlashcardSet && isLoadingFlashcardSet);

    const isNoContent =
        (isNote && (!note?.content || note?.content === "<p></p>")) ||
        (isFlashcardSet && !flashcardSet?.flashcards?.length);

    const renderContent = () => (
        <>
            {/* PREVIEW HEADER */}
            <FlexRowAlignCenter
                style={{
                    fontSize: "1.2rem",
                    gap: `calc(${spacing.SM} + 0.5rem)`,
                    marginBottom: `calc(${spacing.LG} + 0.6rem)`,
                }}>
                <FlexRowAlignCenter style={{ gap: "0.5rem" }}>
                    <span className="bold">{note?.rating?.toFixed(1) || "0.0"}</span>
                    <StarRating readOnly max={5} value={note?.rating || 0} sx={{ fontSize: "1.6rem" }} />
                    <span style={{ color: themeColors.neutral3 }}>{note?.ratingCount || 0}</span>
                </FlexRowAlignCenter>

                <FlexRowAlignCenter>
                    <Image
                        src={`${ASSETS_URL}/images/views-icon.svg`}
                        alt={"studied by"}
                        width={25}
                        height={25}
                        style={{ objectFit: "contain" }}
                    />
                    Studied by {note?.views ?? 0} {(note?.views ?? 0) === 1 ? "person" : "people"}
                </FlexRowAlignCenter>

                <CircularRectTextButton
                    onClick={() => {
                        if (isFlashcardSet) {
                            router.push(`/flashcards/${flashcardSetId}`);
                        } else if (isNote) {
                            router.push(getNoteUrl({ noteId: noteId as string, title: note?.title || UNTITLED }));
                        }
                    }}
                    sx={{
                        backgroundColor: themeColors.primary,
                        color: themeColors.pureWhite,
                        height: "3.6rem",
                        width: "9.8rem",
                        fontSize: "1.3rem",
                        marginLeft: "auto",
                    }}>
                    Open
                </CircularRectTextButton>
            </FlexRowAlignCenter>

            {/* EMPTY STATE */}
            {isNoContent && <ContentPreviewerEmptyState />}

            {!isNoContent && (
                <FlexColumn
                    className="scrollbar"
                    style={{
                        wordBreak: "break-all",
                        overflowY: "auto",
                        marginRight: `-${parentPadding.right}`,
                        marginBottom: `-${parentPadding.bottom}`,
                        paddingBottom: parentPadding.right,
                        paddingRight: parentPadding.bottom,
                    }}>
                    {isNote && (
                        <span style={{ fontSize: "5.5rem", marginBottom: "2.9rem", height: "6.6rem" }}>
                            {(!isLoading && note?.icon?.emoji) || "📝"}
                        </span>
                    )}

                    <h2 style={{ marginBottom: `calc(${spacing.MD} + 0.5rem)`, fontSize: "3.5rem" }}>
                        {(note || flashcardSet)?.title}
                    </h2>

                    {/* NOTE CONTENT */}
                    {isNote && (
                        <HtmlText
                            text={getHtmlFromContent({ content: note?.content || "", type: "note" })}
                            style={{ fontSize: "1.4rem" }}
                        />
                    )}

                    {/* LIST OF FLASCARDS */}
                    {isFlashcardSet && (
                        <FlexColumn style={{ gap: "2rem" }}>
                            {flashcardSet?.flashcards?.length === 0 && <b>No flashcards (0 terms/definition)</b>}

                            {flashcardSet?.flashcards?.map((flashcard, i) => (
                                <FlexColumn key={String(flashcard?.flashcardId) + i}>
                                    <HtmlText
                                        text={getHtmlFromContent({
                                            content: flashcard?.term || "",
                                            type: "flashcard",
                                        })}
                                        style={{ fontWeight: "600" }}
                                    />
                                    <HtmlText
                                        text={getHtmlFromContent({
                                            content: flashcard?.definition || "",
                                            type: "flashcard",
                                        })}
                                    />
                                </FlexColumn>
                            ))}
                        </FlexColumn>
                    )}
                </FlexColumn>
            )}
        </>
    );

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{isLoading ? <LoadingAnimation style={{ margin: "auto" }} /> : renderContent()}</>;
};

export const ContentPreviewerContainer = ({ children, style, ...divProps }: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            style={{
                backgroundColor: themeColors.card,
                borderRadius: borderRadius.card,
                minHeight: "50rem",
                maxHeight: "100%",
                height: "100%",
                ...style,
            }}
            {...divProps}>
            {children}
        </FlexColumn>
    );
};

export default ContentPreviewer;
