"use client";

import Image from "next/image";
import { spacing } from "@/utils/spacing";
import br from "@/styles/breakpoints.module.css";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import SectionContainer from "./SectionContainer";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Link from "@/components/wrappers/Link";

const compliants = [
    {
        name: "COPPA",
        icon: `${ASSETS_URL}/images/compliants/COPPA.svg`,
    },
    {
        name: "FERPA",
        icon: `${ASSETS_URL}/images/compliants/FERPA.svg`,
    },
    {
        name: "NY2D",
        icon: `${ASSETS_URL}/images/compliants/NY2D.svg`,
    },
    {
        name: "GDPR",
        icon: `${ASSETS_URL}/images/compliants/GDPR.svg`,
    },
    {
        name: "WCAG 2.0",
        icon: `${ASSETS_URL}/images/compliants/WCAG.svg`,
    },
    {
        name: "SOPPA",
        icon: `${ASSETS_URL}/images/compliants/SOPPA.svg`,
    },
];

const CompliantsSection = () => {
    return (
        <SectionContainer
            title="Knowt is compliant with student privacy guidelines"
            subtitle="Quizlet might not sign your DPA, but we will.">
            <FlexColumnAlignJustifyCenter style={{ gap: spacing.LG_2 }}>
                <FlexRowAlignCenter style={{ flexWrap: "wrap", gap: spacing.MD, justifyContent: "space-between" }}>
                    {compliants.map((compliant, index) => (
                        <FlexRowAlignCenter
                            key={index}
                            className={br.smDownWidth100}
                            style={{
                                width: `calc(33.33% - ${spacing.MD})`,
                                padding: spacing.SM,
                                borderRadius: borderRadius.card,
                                border: `2px solid ${themeColors.neutral1}`,
                                justifyContent: "center",
                                gap: spacing.SM,
                            }}>
                            <Image
                                src={compliant.icon}
                                alt={`${compliant.name} icon`}
                                width={40}
                                height={40}
                                objectFit="contain"
                            />
                            <span>{compliant.name} compliant</span>
                        </FlexRowAlignCenter>
                    ))}
                </FlexRowAlignCenter>

                <FlexRowAlignCenter
                    className={br.smDownColumn}
                    style={{
                        borderRadius: borderRadius.card,
                        border: `2px solid ${themeColors.neutral1}`,
                        width: "100%",
                        padding: spacing.MD,
                        justifyContent: "space-between",
                        gap: spacing.SM,
                    }}>
                    <FlexColumn>
                        <span className="bodyBold1">Want us to sign your DPA?</span>
                        <span className="body2">Send it to us and we’ll review it within a week.</span>
                    </FlexColumn>
                    <Link href="https://form.fillout.com/t/aymZi6NipYus" openInNewTab>
                        <CircularRectTextButton
                            component={"div"}
                            style={{
                                backgroundColor: themeColors.primary,
                                padding: `${spacing.XS_2} ${spacing.MD}`,
                                borderRadius: "1rem",
                                color: themeColors.primaryDark,
                            }}>
                            Send us your DPA
                        </CircularRectTextButton>
                    </Link>
                </FlexRowAlignCenter>
            </FlexColumnAlignJustifyCenter>
        </SectionContainer>
    );
};

export default CompliantsSection;
