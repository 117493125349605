"use client";
import { ConditionalLink } from "@/components/ConditionalLink";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import Image from "next/image";
import { LinkProps } from "next/link";
import { MouseEventHandler, useEffect, useState } from "react";

export type MediaFailureStatesProps = {
    reportEvent: () => void;
    title: string;
    description?: string;
    buttonProps: { text: string } & Omit<Omit<LinkProps, "href">, "onClick"> &
        (
            | {
                  href?: string;
                  onClick?: never;
              }
            | {
                  href?: never;
                  onClick: MouseEventHandler<HTMLButtonElement>;
              }
        );
};

const MediaFailureStates = ({ reportEvent, title, buttonProps, description }: MediaFailureStatesProps) => {
    const [errorReported, setErrorReported] = useState(false);

    useEffect(() => {
        if (errorReported) return;

        reportEvent();
        setErrorReported(true);
    }, [errorReported, reportEvent]);

    return (
        <FlexColumnAlignJustifyCenter
            style={{
                height: "100%",
                gap: "2rem",
            }}>
            <Image src="/images/kai-crying.svg" alt="Failed transcript" width={330} height={189} />
            <FlexColumnAlignJustifyCenter
                style={{
                    gap: "0.5rem",
                }}>
                <p className="bodyBold1" style={{ width: "60%", textAlign: "center" }}>
                    {title}
                </p>
                {description && (
                    <p className="secondaryText1" style={{ width: "60%", textAlign: "center" }}>
                        {description}
                    </p>
                )}
            </FlexColumnAlignJustifyCenter>
            <ConditionalLink
                className="strippedLink"
                href={buttonProps.href ?? ""}
                condition={!!buttonProps.href}
                {...buttonProps}>
                <CircularRectTextButton
                    onClick={buttonProps.onClick}
                    sx={{
                        padding: "1.3rem 3rem",
                        backgroundColor: themeColors.neutralBlack,
                        color: themeColors.neutralWhite,
                    }}>
                    {buttonProps.text}
                </CircularRectTextButton>
            </ConditionalLink>
        </FlexColumnAlignJustifyCenter>
    );
};

export default MediaFailureStates;
