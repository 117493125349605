"use client";
import Image from "next/image";
import { LandingCenteredHalfSection } from "../styled/Containers";
import { H3 } from "../styled/Headings";
import { H3Paragraph } from "../styled/Paragraphs";
import styles from "./downloadMobileApp.module.css";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import toast from "react-hot-toast";
import { ASSETS_URL } from "@/config/deployConstants";

const DownloadMobileApp = () => {
    return (
        <LandingCenteredHalfSection className={styles.smDownNoPadding}>
            <Flex
                className={styles.container}
                style={{
                    gap: "3.2rem",
                    backgroundColor: themeColors.primary2,
                    overflow: "hidden",
                }}>
                <Image
                    src={`${ASSETS_URL}/images/mobile_homescreen.svg`}
                    alt={"mobile download encouragement"}
                    width={"340"}
                    height={"370"}
                    style={{ objectFit: "contain", paddingTop: "3.8rem" }}
                />
                <FlexColumn style={{ margin: "auto", flex: 2 }}>
                    <H3
                        style={{
                            marginBottom: "1.6rem",
                            color: themeColors.primaryDark,
                            fontWeight: "800",
                        }}>
                        Study flashcards on the go with the new Knowt app
                    </H3>
                    <H3Paragraph style={{ color: themeColors.neutralBlack, marginBottom: "3.2rem" }}>
                        Your flashcards go wherever you go with the free Knowt app!
                    </H3Paragraph>
                    <FlexRowAlignCenter style={{ gap: "1.6rem" }}>
                        <div
                            // href={"#"}
                            onClick={() => {
                                toast.success("Coming soon!", { id: "MOBILE" });
                                setTimeout(() => {
                                    toast.dismiss("MOBILE");
                                }, 2000);
                            }}>
                            <Image
                                src={`${ASSETS_URL}/images/downloadon_appstore.png`}
                                alt={"mobile download"}
                                width={"121"}
                                height={"36"}
                                style={{ objectFit: "cover" }}
                            />
                        </div>
                        <div
                            // href={"#"}
                            onClick={() => {
                                toast.success("Coming soon!", { id: "MOBILE" });
                                setTimeout(() => {
                                    toast.dismiss("MOBILE");
                                }, 2000);
                            }}>
                            <Image
                                src={`${ASSETS_URL}/images/downloadon_gstore.png`}
                                alt={"mobile download"}
                                width={"121"}
                                height={"36"}
                                style={{ objectFit: "cover" }}
                            />
                        </div>
                    </FlexRowAlignCenter>
                </FlexColumn>
            </Flex>
        </LandingCenteredHalfSection>
    );
};

export default DownloadMobileApp;
