"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import ClickableText from "@/components/styled/button/ClickableText";
import { spacing } from "@/utils/spacing";
import { PlusIcon } from "lucide-react";
import br from "@/styles/breakpoints.module.css";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import dynamic from "next/dynamic";
import { useState } from "react";
import LazyLoaded from "@/components/LazyLoaded";
import { examNameToUrlComponent, getImageLink } from "@knowt/syncing/hooks/exams/utils";
import RepeatAutoFillGrid from "@/components/styled/div/RepeatAutoFillGrid";

// TODO(import): workaround to allow imports to work. https://github.com/vercel/next.js/issues/49757
import ExamCard from "../../../components/ExamCard";

const ExamSelectionPopup = dynamic(() => import("@/components/Popup/InitialAccountSetupPopup/ExamSelectionPopup"), {
    ssr: false,
});

const EmptyState = () => {
    const [isExamSelectionOpen, setIsExamSelectionOpen] = useState(false);

    return (
        <>
            <FlexRowAlignCenter className={br.smDownColumn} style={{ justifyContent: "center", gap: spacing.MD }}>
                <p className="body1">You’re not studying for any exams!</p>
                <CircularRectTextButton
                    style={{ padding: "1.2rem 2.4rem", gap: spacing.XS }}
                    onClick={() => setIsExamSelectionOpen(true)}>
                    <PlusIcon size={24} />
                    Add exam
                </CircularRectTextButton>
            </FlexRowAlignCenter>
            <LazyLoaded load={isExamSelectionOpen}>
                <ExamSelectionPopup isOpen={isExamSelectionOpen} onClose={() => setIsExamSelectionOpen(false)} />
            </LazyLoaded>
        </>
    );
};

const MyExams = ({ exams }) => {
    if (!exams) {
        return null;
    }

    const myExams = exams.map(exam => ({
        type: exam.split(" ")[0],
        name: exam,
        image: getImageLink(exam),
        href: `/exams/${examNameToUrlComponent(exam.split(" ")[0])}/${examNameToUrlComponent(exam)}`,
    }));

    return (
        <FlexColumn style={{ gap: spacing.SM }}>
            <RepeatAutoFillGrid style={{ marginBottom: "3.2rem", gap: "2.4rem" }} itemMinWidth="26rem">
                {myExams.map((exam, i) => (
                    <ExamCard exam={exam} key={i} />
                ))}
            </RepeatAutoFillGrid>
        </FlexColumn>
    );
};

const MyExamsSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const { user } = useCurrentUser();
    const myExams = user?.examHistory ? JSON.parse(user?.examHistory) : null;
    const examList = myExams ? Object.values(myExams).flat() : [];

    return (
        <FlexColumn style={{ gap: spacing.SM }}>
            {user ? (
                <>
                    <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                        <h4 className="heading4">My Exams</h4>
                        {!!examList.length && <ClickableText onClick={() => setIsPopupOpen(true)}>edit</ClickableText>}
                    </FlexRowAlignCenter>

                    {examList.length ? <MyExams exams={examList} /> : <EmptyState />}
                </>
            ) : null}

            <LazyLoaded load={isPopupOpen}>
                <ExamSelectionPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
            </LazyLoaded>
        </FlexColumn>
    );
};

export default MyExamsSection;
