"use client";

import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Eye } from "lucide-react";
import { iconSizes } from "@/utils/iconProps";

const UnitButton = ({
    unit,
    count,
    activeUnit,
    setActiveUnit,
}: {
    unit?: unknown;
    count: number;
    activeUnit?: unknown;
    setActiveUnit: (unit?: unknown) => void;
}) => {
    const isBtnActive = unit === activeUnit;

    return (
        <CircularRectTextButton
            sx={{
                borderColor: isBtnActive ? themeColors.neutralBlack : themeColors.neutralWhite,
                gap: spacing.XS,
                padding: spacing.SM,
                backgroundColor: isBtnActive ? themeColors.neutralBlack : themeColors.neutralWhite,
                height: "auto",
                width: "auto",
            }}
            onClick={() => setActiveUnit(unit)}>
            {!unit && <Eye size={iconSizes.SM} color={isBtnActive ? themeColors.neutralWhite : undefined} />}
            <p className="secondaryText1" style={{ color: isBtnActive ? themeColors.neutralWhite : undefined }}>
                {`${unit || "View all"} (${count})`}
            </p>
        </CircularRectTextButton>
    );
};

export default UnitButton;
