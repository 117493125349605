import { FlexColumnAlignJustifyCenter, FlexColumn } from "@/components/Flex";
import SectionContainer from "../../components/SectionContainer";
import FeaturedExamCategories from "../../ExamPages/components/FeaturedExamCategories";
import ExploreExams from "../../components/ExploreExams";
import { Section, SectionHeader } from "@/components/Containers";
import ExploreTopUserContents from "../../components/ExploreTopUserContents";
import { spacing } from "@/utils/spacing";
import { splitCategoriesIntoTwoColumns } from "../../ExamPages/utils/dataCleaning";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { FrontendFeaturedCategory } from "@knowt/syncing/hooks/exams/utils";

type ExploreSectionProps = {
    topNotes: Note[];
    topFlashcardSets: FlashcardSet[];
    subjectCategories: FrontendFeaturedCategory[];
    sectionTitle?: string;
    sectionSubTitle?: string;
    sectionContainerStyle?: React.CSSProperties;
};

const ExploreSection = ({
    topNotes,
    topFlashcardSets,
    subjectCategories,
    sectionTitle = "Oh you’re an explorer?",
    sectionSubTitle = "We have over 2 million resources across various exams, and subjects to refer to at any point. ",
    sectionContainerStyle,
}: ExploreSectionProps) => {
    const subjectCategoriesColumns = splitCategoriesIntoTwoColumns(subjectCategories);

    return (
        <SectionContainer title={sectionTitle} subtitle={sectionSubTitle}>
            <FlexColumnAlignJustifyCenter style={{ width: "100%", gap: spacing.MD, ...sectionContainerStyle }}>
                <FlexColumn
                    style={{
                        width: "100%",
                        gap: spacing.SM,
                    }}>
                    <SectionHeader>Browse by exam</SectionHeader>
                    <ExploreExams length={3} />
                </FlexColumn>

                <ExploreTopUserContents flashcardSets={topFlashcardSets} />
                <ExploreTopUserContents notes={topNotes} />

                <Section
                    style={{
                        gap: spacing.MD,
                        marginTop: spacing.MD,
                        width: "100%",
                    }}>
                    <SectionHeader>Explore subjects</SectionHeader>
                    <FeaturedExamCategories
                        featuredExamCategories={subjectCategories}
                        examCategoriesColumns={subjectCategoriesColumns}
                    />
                </Section>
            </FlexColumnAlignJustifyCenter>
        </SectionContainer>
    );
};

export default ExploreSection;
