"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import InputWithMenu from "@/components/InputWithMenu";
import { ASSETS_URL } from "@/config/deployConstants";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { examNameToUrlComponent } from "@knowt/syncing/hooks/exams/utils";
import { subjectNameToUrlComponent } from "@knowt/syncing/hooks/subjects/utils";
import { ChevronDown } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next13-progressbar";
import { useState } from "react";
import KnowtStats from "../../components/KnowtStats";
import SectionContainer from "../../components/SectionContainer";
import LandingTabSwitcher from "./LandingTabSwitcher";
import { AP_EXAMS_TO_UNITS_MAP, AP_EXAM_FRQ_MAP, AP_EXAM_MCQ_MAP } from "@knowt/syncing/hooks/exams/constants";
import { toast } from "react-hot-toast";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";

const kaiGameShow = `${ASSETS_URL}/images/kai-game-show.svg`;

const SelectInput = ({ label, options, selected, onChange, placeholder }) => {
    const handleChange = (_, newValue) => {
        onChange(newValue ? newValue.value : null);
    };

    return (
        <FlexColumn style={{ gap: spacing.XS_2 }}>
            <span className="bodyBold1">{label}</span>
            <InputWithMenu
                useAutocompleteProps={{
                    options: options,
                    value: options.find(({ value }) => value === selected.value),
                    onChange: handleChange,
                    getOptionLabel: option => option.label,
                }}
                inputProps={{
                    placeholder,
                    sx: {
                        borderRadius: 999,
                        color: themeColors.neutralBlack,
                        width: "100%",
                        padding: `${spacing.XS_2} ${spacing.SM}`,
                    },
                }}
                ulProps={{
                    sx: { borderRadius: "1.2rem", top: "5.6rem", boxShadow: "0 6px 20px 0 rgba(0,0,0,.08)" },
                }}
                rootProps={{
                    style: { width: "100%" },
                }}
                adornment={<ChevronDown size={24} />}
            />
        </FlexColumn>
    );
};

type ExamSelectorSectionProps = {
    sectionTitle: string;
    sectionSubTitle: string;
    type?: string;
    exam?: string | null;
    isResponseRoom?: boolean;
};

// Only AP exams are supported for now
const examTypes = ["AP"];
// Swap with only the AP exams supported at launch
const MCQ_EXAMS = Object.keys(AP_EXAM_MCQ_MAP);
const FRQ_EXAMS = Object.keys(AP_EXAM_FRQ_MAP);

const ExamSelectorSection = ({
    sectionTitle,
    sectionSubTitle,
    type = "AP",
    exam = null,
    isResponseRoom = false,
}: ExamSelectorSectionProps) => {
    const router = useRouter();
    const exams = isResponseRoom ? FRQ_EXAMS : MCQ_EXAMS;
    const [selectedUnit, setSelectedUnit] = useState("All");

    const units = exam ? AP_EXAMS_TO_UNITS_MAP[exam] : [];

    const handleChangeExamType = (newExamType: string) => {
        router.replace(
            `/exams/${examNameToUrlComponent(newExamType)}/${
                isResponseRoom ? "free-response-room" : "exam-practice-room"
            }`
        );
    };

    const handleSelectSubject = (newSubject: string) => {
        router.replace(
            `/exams/${examNameToUrlComponent(type)}/${subjectNameToUrlComponent(newSubject)}/${
                isResponseRoom ? "free-response-room" : "exam-practice-room"
            }`
        );
    };

    return (
        <>
            <Flex style={{ backgroundColor: themeColors.neutralWhite, justifyContent: "center" }}>
                <LandingTabSwitcher />
            </Flex>
            <SectionContainer title={sectionTitle} subtitle={sectionSubTitle} isPageHeader>
                <FlexRowAlignCenter style={{ gap: spacing.LG_2, width: "100%", justifyContent: "center" }}>
                    <Image src={kaiGameShow} alt="Pick your exam" width={279} height={315} />

                    <FlexColumnAlignJustifyCenter
                        style={{
                            minHeight: "40.5rem",
                            gap: spacing.XL,
                            paddingInline: spacing.LG,
                            width: "100%",
                            maxWidth: "44.2rem",
                        }}>
                        <FlexColumn style={{ gap: spacing.LG, width: "100%", maxWidth: "32rem" }}>
                            <SelectInput
                                label={"Which exam are you taking?"}
                                options={examTypes.map(name => ({ value: name, label: name }))}
                                selected={{ value: type, label: type }}
                                onChange={(value: string) => handleChangeExamType(value)}
                                placeholder={"Select exam"}
                            />

                            <SelectInput
                                label={"Which subject are you taking?"}
                                options={exams.map(exam => ({ value: exam, label: exam }))}
                                selected={{ value: exam, label: exam }}
                                onChange={(value: string) => handleSelectSubject(value)}
                                placeholder={"Select subject"}
                            />

                            <SelectInput
                                label={"Units to include"}
                                options={[
                                    { value: "All", label: "All" },
                                    ...units.map((name: string) => ({ value: name, label: name })),
                                ]}
                                selected={{ value: selectedUnit, label: selectedUnit }}
                                onChange={(value: string) => setSelectedUnit(value)}
                                placeholder={"Select units"}
                            />
                        </FlexColumn>

                        <FlexColumn style={{ gap: "1.5rem" }}>
                            <CircularRectTextButton
                                onClick={() => {
                                    if (!exam) {
                                        return toast.error("You must select a subject");
                                    }

                                    if (isResponseRoom) {
                                        alert("push to response room");
                                    }

                                    // TODO: in future we will push `selectedUnit` as an array instead
                                    const examUrl =
                                        `/exams/AP/${examNameToUrlComponent(exam)}/test` +
                                        createQueryStrings({ unit: selectedUnit === "All" ? undefined : selectedUnit });

                                    router.push(examUrl);
                                }}
                                sx={{
                                    backgroundColor: themeColors.primary,
                                    color: themeColors.primaryDark,
                                    padding: `${spacing.XS_2} ${spacing.MD}`,
                                }}>
                                <span>{isResponseRoom ? "Give me a random prompt" : "Start studying"}</span>
                            </CircularRectTextButton>
                            {isResponseRoom && (
                                <CircularRectTextButton
                                    onClick={() => alert("TODO: See all relevant prompts")}
                                    sx={{
                                        backgroundColor: themeColors.neutral1,
                                        color: themeColors.neutral3,
                                        padding: `${spacing.XS_2} ${spacing.MD}`,
                                    }}>
                                    <span>See all relevant prompts</span>
                                </CircularRectTextButton>
                            )}
                        </FlexColumn>
                    </FlexColumnAlignJustifyCenter>
                </FlexRowAlignCenter>
                <KnowtStats />
            </SectionContainer>
        </>
    );
};

export default ExamSelectorSection;
