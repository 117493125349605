"use client";

import React from "react";
import { usePathname, useSearchParams } from "next/navigation";
import TabSwitcher from "@/components/TabSwitcher";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { getSubjectNameAndType } from "@knowt/syncing/hooks/subjects/utils";

const SubjectPageTab = () => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const basePath = pathname.split("/").slice(0, -1).join("/");
    const { param: paramSubjectCategory, type } = getSubjectNameAndType(pathname.split("/").pop() as string);

    const closeCardPreview = useCardPreviewContextSelector(state => state.closeCardPreview);

    return (
        <TabSwitcher
            tabs={[
                {
                    label: "Flashcards",
                    href: `${basePath}/${paramSubjectCategory}-flashcards?${searchParams.toString()}`,
                },
                { label: "Notes", href: `${basePath}/${paramSubjectCategory}-notes?${searchParams.toString()}` },
            ]}
            selectedTab={type === "notes" ? 1 : 0}
            setSelectedTab={closeCardPreview}
            height={"5.5rem"}
            width={"11.15rem"}
            fontSize={"1.5rem"}
        />
    );
};

export default SubjectPageTab;
