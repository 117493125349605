"use client";

import SectionContainer from "../../../components/SectionContainer";
import { FlexRowAlignCenter } from "@/components/Flex";
import React, { useRef } from "react";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { AuthEvent, useAuthPopupContextSelector } from "@/features/Auth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import localKeyValueStore from "@/utils/LocalKeyValueStore";
import { TIME } from "@/utils/dateTimeUtils";
import { useRouter } from "next13-progressbar";
import { toast } from "react-hot-toast";
import Image from "next/image";
import br from "@/styles/breakpoints.module.css";
import KnowtStats from "../../../components/KnowtStats";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { LandingPageQuizletImportLink } from "@knowt/syncing/constants/storage";

const DropSection = () => {
    const { user } = useCurrentUser();
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);

    const router = useRouter();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit = async () => {
        const quizletUrl = inputRef.current?.value;
        if (!quizletUrl) {
            toast.error("Please enter a Quizlet link");
            return;
        }

        await localKeyValueStore.setWithExpiry(
            STORAGE_KEYS.LANDING_PAGE_QUIZLET_IMPORT_LINK,
            { quizletUrl } as LandingPageQuizletImportLink,
            TIME.HOUR
        );
        await localKeyValueStore.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);

        if (user) {
            router.push("/");
        } else {
            openAuthPopup({
                event: AuthEvent.LANDING_IMPORT_QUIZLET,
                title: "Sign up for free to access your imported set",
                description:
                    "Now you’ll be able to study your Quizlet set with learn mode, matching, and more for free",
            });
        }
    };

    return (
        <SectionContainer
            title={"#1 Free Quizlet Alternative"}
            subtitle={
                "Paste your Quizlet link below to bring it over in seconds! Study it with free learn mode, match game or other study modes."
            }
            style={{ gap: "0rem" }}
            isPageHeader>
            <FlexRowAlignCenter
                className={br.smDownColumn}
                style={{ marginTop: "4rem", width: "100%", maxWidth: "80rem", gap: spacing.MD }}>
                <BorderColoredInput
                    ref={inputRef}
                    sx={{
                        height: "5.6rem",
                        backgroundColor: themeColors.neutralWhite,
                        paddingLeft: spacing.MD,
                    }}
                    placeholder={"Just paste your Quizlet link here to bring it over"}
                    onKeyDown={async event => {
                        if (event.key === "Enter") handleSubmit();
                    }}
                />
                <CircularRectTextButton
                    onClick={handleSubmit}
                    sx={{
                        height: "5.6rem",
                        backgroundColor: "#50d2c2",
                        color: themeColors.primaryDark,
                        paddingInline: spacing.LG,
                        borderRadius: "4rem",
                        fontSize: "1.5rem",
                    }}>
                    Import from Quizlet
                </CircularRectTextButton>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter style={{ marginBottom: spacing.MD, justifyContent: "center" }}>
                <Image src="/images/rip_quizlet.svg" alt="Kai is saying HI :)" width={319} height={320} priority />
            </FlexRowAlignCenter>
            <KnowtStats />
        </SectionContainer>
    );
};

export default DropSection;
