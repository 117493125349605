import { FlexRowAlignCenter, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import br from "@/styles/breakpoints.module.css";
import styles from "./knowt-stats.module.css";

const stats = [
    {
        count: "1,000,000 +",
        description: "students & teachers",
    },
    {
        count: "1,000,000 +",
        description: "notes created",
    },
    {
        count: "3,600,000 +",
        description: "flashcards created",
    },
];

type NumbersProp = {
    count: string;
    description: string;
    numbersContainerStyle?: React.CSSProperties;
};

const Numbers = ({ count, description, numbersContainerStyle }: NumbersProp) => {
    return (
        <FlexColumnAlignJustifyCenter className={styles.numbersContainer} style={{ ...numbersContainerStyle }}>
            <p className={styles.countText} style={{ color: themeColors.primary4 }}>
                {count}
            </p>
            <p className={styles.descriptionText} style={{ color: themeColors.neutralBlack }}>
                {description}
            </p>
        </FlexColumnAlignJustifyCenter>
    );
};

type KnowtStatsProp = {
    containerStyle?: React.CSSProperties;
    numbersContainerStyle?: React.CSSProperties;
};

const KnowtStats = ({ containerStyle, numbersContainerStyle }: KnowtStatsProp) => {
    return (
        <FlexRowAlignCenter className={br.mdDownColumn} style={{ columnGap: "8.8rem", ...containerStyle }}>
            {stats.map((stat, index) => (
                <Numbers
                    key={index}
                    count={stat.count}
                    description={stat.description}
                    numbersContainerStyle={numbersContainerStyle}
                />
            ))}
        </FlexRowAlignCenter>
    );
};

export default KnowtStats;
