"use client";

import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import Image from "next/image";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { ArrowRight } from "lucide-react";
import { themeColors } from "@/utils/themeColors";
import styles from "./medias/video-summarizer.module.css";
import br from "@/styles/breakpoints.module.css";
import { spacing } from "@/utils/spacing";
import clsx from "clsx";

const SmallLinkedCard = ({ content }) => {
    const BottomLinkContent = () => {
        return (
            <>
                <p className={br.mdDownDisplayNone} style={{ fontWeight: "700" }}>
                    {content.linkText}
                </p>
                <p className={br.mdUpDisplayNone} style={{ fontWeight: "700" }}>
                    {content.shortLinkText}
                </p>
                <ArrowRight size={18} />
            </>
        );
    };

    return (
        <FlexColumnAlignJustifyCenter
            className={styles.smallLinkedCardContainer}
            style={{
                flex: 1,
                backgroundColor: themeColors.card,
            }}>
            <FlexRowAlignCenter style={{ flex: 1, flexWrap: "wrap", justifyContent: "center", gap: "1.2rem" }}>
                {content.images.map((image, index) => (
                    <Image
                        key={index}
                        alt={content.alt}
                        src={image}
                        width={content.imagesWidth}
                        height={content.imagesHeight}
                        className={content.images.length > 1 ? styles.smallLinkedCardImage : ""}
                        style={{ objectFit: "contain" }}
                    />
                ))}
            </FlexRowAlignCenter>
            <FlexColumnAlignJustifyCenter style={{ textAlign: "center", maxWidth: "32.8rem", gap: spacing.XS }}>
                <h4 className={clsx(["heading4", br.smDownDisplayNone])}>{content.title}</h4>
                <p className={clsx(["bodyBold1", br.smUpDisplayNone])}>{content.title}</p>
                <p className={styles.linkedCardDesc}>{content.description}</p>
            </FlexColumnAlignJustifyCenter>
            {!content.link ? (
                <FlexRowAlignCenter
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    style={{
                        display: "flex",
                        gap: "0.4rem",
                        flexDirection: "row",
                        color: themeColors.primary4,
                        cursor: "pointer",
                    }}>
                    <BottomLinkContent />
                </FlexRowAlignCenter>
            ) : (
                <LinkWrapper
                    href={content.link}
                    style={{ display: "flex", gap: "0.4rem", flexDirection: "row", color: themeColors.primary4 }}>
                    <BottomLinkContent />
                </LinkWrapper>
            )}
        </FlexColumnAlignJustifyCenter>
    );
};

export default SmallLinkedCard;
