"use client";

import React from "react";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { ConditionalLink } from "../../../components/ConditionalLink";

type TopicPickerProps = {
    topics: string[];
    selectedTopics?: string[];
    style?: React.CSSProperties;
    hrefFormatter: (topic: string) => string;
    onClick?: (e: React.MouseEvent, topic: string) => void;
};

const TopicPicker = ({ topics, selectedTopics, style, hrefFormatter, onClick }: TopicPickerProps) => {
    return (
        <React.Fragment>
            {topics.map((topic, i) => {
                const selected = selectedTopics?.includes(topic);
                const href = hrefFormatter(topic);

                return (
                    <ConditionalLink key={topic + i} condition={!!href} href={href}>
                        <CircularRectTextButton
                            component={"li"}
                            onClick={event => onClick?.(event, topic)}
                            className="knowtFontBold"
                            sx={{
                                width: "fit-content",
                                height: "5.2rem",
                                backgroundColor: selected ? "#50d2c2" : themeColors.card,
                                color: selected ? themeColors.pureWhite : themeColors.neutralBlack,
                                "&:hover": {
                                    border: "1px solid #50d2c2",
                                    backgroundColor: "#50d2c233",
                                    color: selected ? "#15504A" : themeColors.neutralBlack,
                                },
                            }}
                            style={{
                                padding: "0 2rem",
                                borderRadius: 9999,
                                ...style,
                            }}>
                            {topic}
                        </CircularRectTextButton>
                    </ConditionalLink>
                );
            })}
        </React.Fragment>
    );
};

export default TopicPicker;
