import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { useEffect, useState } from "react";
import useDOMEventListener from "@/hooks/useDOMEventListener";

const findNumOfElementsInFlexRow = (gridContainer?: HTMLDivElement) => {
    if (!gridContainer) return null;

    const grid = Array.from(gridContainer.children) as HTMLElement[];
    const baseOffset: number = grid[0].offsetTop;
    const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
    return breakIndex === -1 ? grid.length : breakIndex;
};

export const useNumOfItemInRow = (containerId: string) => {
    const [numOfItemInRow, setNumOfItemInRow] = useState<number | null>(null);
    useDOMEventListener("resize", () => {
        const numOfItemInRow = findNumOfElementsInFlexRow(document.getElementById(containerId) as HTMLDivElement);

        setNumOfItemInRow(numOfItemInRow);
    });
    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, []);

    return numOfItemInRow;
};

export const chunkItemForAds = <T = Note | FlashcardSet>({
    numOfItemInRow,
    items,
}: {
    numOfItemInRow: number;
    items: T[];
}) => {
    const itemsLength = items.length;
    const interval = (numOfItemInRow > 3 ? 2 : 3) * numOfItemInRow;
    const maxAds = Math.min(Math.floor((itemsLength - 1) / interval), 25);
    const chunks: T[][] = [];
    for (let i = 0; i <= maxAds; i++) {
        chunks.push(items.slice(i * interval, i * interval + interval));
    }

    return chunks;
};
