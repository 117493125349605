"use client";

import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CircularButton from "@/components/CircularButton";
import { LandingCenteredHalfSection } from "../../components/styled/Containers";
import { H1 } from "../../components/styled/Headings";
import { H1Paragraph } from "../../components/styled/Paragraphs";
import VimeoVideo from "@/components/VimeoVideo";
import { ASSETS_URL } from "@/config/deployConstants";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import Image from "next/image";
import pageStyles from "../offersPage.module.css";
import clsx from "clsx";
import { Circle } from "lucide-react";
import br from "@/styles/breakpoints.module.css";

const SOURCES = [
    {
        img: `${ASSETS_URL}/images/flashcard-icon.svg`,
        label: "Flashcards",
        desc: "Use our free learn mode to study your flashcards",
        index: 0,
        id: "889354245",
    },
    {
        img: `${ASSETS_URL}/images/note-icon.svg`,
        label: "Notes",
        desc: "Write beautiful notes, and have Kai create flashcards",
        index: 1,
        id: "889354303",
    },
    {
        img: `${ASSETS_URL}/images/video-icon.svg`,
        label: "AI Lectures",
        desc: "Import YouTube, Canvas, etc. and have Kai make notes & flashcards",
        index: 2,
        id: "889354324",
    },

    {
        img: `${ASSETS_URL}/images/pdf-icon.svg`,
        label: "AI Files",
        desc: "Upload Word, PowerPoint, Docs, Slides, etc. and have Kai make notes & flashcards",
        index: 3,
        id: "889354283",
    },
];

const SourceButton = ({ onClick, img, label, desc, isSelected }) => {
    return (
        <CircularButton
            style={{
                textAlign: "left",
                flexDirection: "row",
                alignItems: "center",
                borderRadius: "1.7rem",
                padding: spacing.MD,
                justifyContent: "space-between",
                minWidth: "5rem",
                minHeight: "7rem",
                cursor: "pointer",
                gap: "1rem",
                backgroundColor: isSelected ? themeColors.neutral2 : themeColors.neutral1,
                justifySelf: "center",
                alignSelf: "stretch",
            }}
            onClick={onClick}>
            <Flex style={{ alignItems: "center" }}>
                <Image
                    src={img}
                    width={35}
                    height={35}
                    alt={label}
                    style={{ marginRight: spacing.XS_2, objectFit: "contain" }}
                />
                <div>
                    <span style={{ fontWeight: "600", fontSize: "1.4rem" }}>{label}</span>
                    <p
                        className="secondaryText2"
                        style={{ width: "90%", marginTop: "0.5rem", fontFamily: "var(--knowt-font-name)" }}>
                        {desc}
                    </p>
                </div>
            </Flex>
        </CircularButton>
    );
};

const OffersAIVideos = () => {
    const [vid, updateVid] = useCombinedState(SOURCES[0]);

    return (
        <LandingCenteredHalfSection style={{ maxWidth: "120rem" }}>
            <div style={{ margin: "0 auto 3rem 0" }}>
                <H1 style={{ textAlign: "left", fontSize: "3.6rem" }}>Unlock the potential of unlimited AI</H1>
                <H1Paragraph style={{ textAlign: "left" }}>
                    Knowt uses AI to seamlessly create flashcards and practice problems from your notes, lecture videos,
                    and slides.
                </H1Paragraph>
            </div>

            <FlexRowAlignCenter className={br.mdDownColumnReverse} style={{ gap: "3rem" }}>
                {/* DESKTOP VIDEO CONTENTS UI */}
                <FlexColumn className={clsx(br.smDownWidth100, "mdDownDisplayNone")} style={{ gap: "1.5rem", flex: 1 }}>
                    {SOURCES.map(({ id, label, desc, img, index }) => {
                        return (
                            <SourceButton
                                key={label}
                                label={label}
                                desc={desc}
                                img={img}
                                onClick={() => {
                                    // setIsClicked(true);
                                    updateVid({ id, index, label, desc, img });
                                }}
                                isSelected={vid.id === id}
                            />
                        );
                    })}
                </FlexColumn>

                {/* MOBILE VIDEO CONTENTS UI */}
                <FlexColumn className={br.mdUpDisplayNone}>
                    <SourceButton
                        label={vid.label}
                        desc={vid.desc}
                        img={vid.img}
                        onClick={() => {
                            // setIsClicked(true);
                        }}
                        isSelected={true}
                    />
                    <FlexRowAlignCenter style={{ gap: "1rem", margin: "3rem auto 0" }}>
                        {SOURCES.map(({ id, index, label, desc, img }) => {
                            const isSelected = id === vid.id;
                            return (
                                <Circle
                                    key={id}
                                    size={12}
                                    color={isSelected ? themeColors.neutralBlack : themeColors.neutral2}
                                    fill={isSelected ? themeColors.neutralBlack : themeColors.neutral2}
                                    style={{ cursor: isSelected ? "default" : "pointer" }}
                                    onClick={() => updateVid({ id, index, label, desc, img })}
                                />
                            );
                        })}
                    </FlexRowAlignCenter>
                </FlexColumn>

                {/* THE VIDEO */}
                <div className={pageStyles.offersVidContainer} style={{ borderRadius: "1.7rem", marginBottom: "auto" }}>
                    <VimeoVideo id={vid.id} />
                </div>
            </FlexRowAlignCenter>
        </LandingCenteredHalfSection>
    );
};

export default OffersAIVideos;
