"use client";

import { FlexRowAlignCenter } from "@/components/Flex";
import TopicOrSubjectPicker from "../../components/TopicPicker";
import { useParams } from "next/navigation";
import { getSubjectNameAndType, subjectNameToUrlComponent } from "@knowt/syncing/hooks/subjects/utils";

const SubjectPageTopicPicker = ({ topics }: { topics: string[] }) => {
    const params = useParams();
    const { param: selectedTopic, type } = getSubjectNameAndType(params.subjectTopic as string);

    const subjectName = !params.subjectTopic
        ? subjectNameToUrlComponent(getSubjectNameAndType(params.subjectName as string).param as string)
        : params.subjectName;

    return (
        <FlexRowAlignCenter className="strippedList" as={"ul"} style={{ gap: "1.3rem", flexWrap: "wrap" }}>
            <TopicOrSubjectPicker
                topics={topics}
                style={{ fontSize: "1.5rem" }}
                hrefFormatter={topic =>
                    `/subject/${params.subjectCategory}/${subjectName}/${subjectNameToUrlComponent(topic)}-${type}`
                }
                selectedTopics={selectedTopic ? [selectedTopic] : []}
            />
        </FlexRowAlignCenter>
    );
};

export default SubjectPageTopicPicker;
