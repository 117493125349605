"use client";

import { useEffect, useState } from "react";
import { LandingCenteredHalfSection, LandingMainContainer } from "../components/styled/Containers";
import { H1, H2 } from "../components/styled/Headings";
import { H1Paragraph } from "../components/styled/Paragraphs";
import styles from "../landing.module.css";
import pageStyles from "./chromeHelpPage.module.css";
import NavBar from "@/features/NavBar";
import Footer from "@/components/Footer/Footer";
import LogPageView from "@/components/LogPageVisit";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import VimeoVideo from "@/components/VimeoVideo";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import br from "@/styles/breakpoints.module.css";
import Link from "@/components/wrappers/Link";
import { spacing } from "@/utils/spacing";
import LazyLoaded from "@/components/LazyLoaded";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import useCombinedState from "@/utils/hooks/useCombinedState";
import dynamic from "next/dynamic";
import { AuthEvent, useAuthPopupContextSelector } from "@/features/Auth";
import { LocalUser } from "@knowt/syncing/hooks/user/types";

const ImportFromQuizletPopupLandingPageFlow = dynamic(
    () => import("@/components/Popup/ImportFromQuizletPopup/ImportFromQuizletPopupLandingPageFlow")
);

const LearnToUseKnowtChrome = () => {
    return (
        <LandingCenteredHalfSection>
            <H1>Learn how to use Knowt Chrome</H1>
            <H1Paragraph style={{ maxWidth: "75rem" }}>
                Import flashcard sets, lecture videos, and other files in a single click. Use AI to create flashcards
                and practice problems from your materials.
            </H1Paragraph>
        </LandingCenteredHalfSection>
    );
};

const SOURCES = [
    {
        img: `${ASSETS_URL}/images/flashcard-icon.svg`,
        label: "Import Flashcards",
        index: 0,
        id: "889354245",
    },
    {
        img: `${ASSETS_URL}/images/video-icon.svg`,
        label: "Import Lectures",
        index: 1,
        id: "889354324",
    },
    {
        img: `${ASSETS_URL}/images/pdf-icon.svg`,
        label: "Import PDFs",
        index: 2,
        id: "889354283",
    },
];

const ChromeHelpVideos = () => {
    const [currVidId, setCurrVidId] = useState(SOURCES[0].id);

    const renderVideoButton = ({
        img,
        label,
        isSelected,
        onClick,
    }: {
        img: string;
        label: string;
        isSelected: boolean;
        onClick: () => void;
    }) => {
        return (
            <CircularRectTextButton
                onClick={onClick}
                sx={{
                    backgroundColor: isSelected ? themeColors.neutralBlack : themeColors.neutralWhite,
                    color: isSelected ? themeColors.neutralWhite : themeColors.neutralBlack,
                    minHeight: "6rem",
                    fontSize: "1.8rem",
                    border: `1px solid ${themeColors.neutralBlack}`,
                    borderRadius: "2rem",
                    gap: "1.2rem",
                    flex: 1,
                }}>
                <Image alt={label} src={img} width={24} height={24} style={{ objectFit: "contain" }} />
                {label}
            </CircularRectTextButton>
        );
    };

    const renderInstruction = ({ index, label }) => {
        return (
            <FlexColumnAlignJustifyCenter style={{ flex: 1 }}>
                <H2 className="heading4" style={{ marginBottom: "1.2rem", fontSize: "2.5rem" }}>
                    {index}.
                </H2>
                <p className="bodyBold1">{label}</p>
            </FlexColumnAlignJustifyCenter>
        );
    };

    return (
        <LandingCenteredHalfSection style={{ maxWidth: "100rem" }}>
            <FlexRowAlignCenter
                className={br.smDownColumnStretch}
                style={{ width: "100%", gap: "1.6rem", marginBottom: "4rem" }}>
                {SOURCES.map(({ label, img, id }) =>
                    renderVideoButton({ label, img, isSelected: id === currVidId, onClick: () => setCurrVidId(id) })
                )}
            </FlexRowAlignCenter>
            <FlexColumnAlignJustifyCenter
                className={pageStyles.chromeHelpVideoContainer}
                style={{
                    marginBottom: "4rem",
                    position: "relative",
                }}>
                <VimeoVideo id={currVidId} />
            </FlexColumnAlignJustifyCenter>
            <FlexRowAlignCenter className={br.smDownColumnStretch} style={{ gap: "1.6rem", textAlign: "center" }}>
                {renderInstruction({ index: 1, label: "Visit a webpage with flashcards, videos, or any other file." })}
                {renderInstruction({ index: 2, label: "Click the Knowt Chrome Extension icon." })}
                {renderInstruction({ index: 3, label: "Click “Open in Knowt”!" })}
            </FlexRowAlignCenter>
            <Link
                href="https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm"
                target="_blank"
                className="strippedLink">
                <CircularRectTextButton
                    component={"div"}
                    className="bodyBold1"
                    sx={{
                        backgroundColor: themeColors.docsDark,
                        color: themeColors.pureWhite,
                        padding: "1rem 2.4rem",
                        marginTop: spacing.XL,
                        "&:hover": {
                            backgroundColor: themeColors.docsMed,
                            color: "#004B88",
                        },
                        "&:active": {},
                    }}>
                    Add to Chrome
                </CircularRectTextButton>
            </Link>
        </LandingCenteredHalfSection>
    );
};

const ChromeHelpPage = ({ serverUser }: { serverUser: LocalUser }) => {
    const [importFromQuizletFlowStates, updateImportFromQuizletFlowStates] = useCombinedState({
        quizletUrl: null,
        isPopupOpen: false,
    });

    const user = serverUser.user;

    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);

    useEffect(() => {
        if (!user) {
            return openAuthPopup({
                event: AuthEvent.CHROME_IMPORT_POPUP,
                title: "Sign up for use our Chrome Extension to import from Quizlet",
                description:
                    "Now you’ll be able to study your Quizlet set with learn mode, matching, and more for free within seconds!",
                closeable: false,
            });
        }

        const asyncFunc = async () => {
            const landingPageQuizletImportLink = await LocalKeyValueStore.getWithExpiry("landingPageQuizletImportLink");

            if (landingPageQuizletImportLink) {
                const { quizletUrl } = landingPageQuizletImportLink;
                updateImportFromQuizletFlowStates({ quizletUrl, isPopupOpen: true });
            }
        };

        asyncFunc();
    }, []);

    return (
        <>
            <LogPageView page={"Chrome Extension Help Landing Page - Visited"} />
            <NavBar serverUser={serverUser} />
            <LandingMainContainer
                accentColorLight="none"
                accentColorDark="none"
                className={styles.gradientChromeHelp}
                style={{ overflow: "hidden" }}>
                <LearnToUseKnowtChrome />
                <div style={{ paddingBottom: "6rem" }} />
                <ChromeHelpVideos />
            </LandingMainContainer>
            <LazyLoaded load={!!user && importFromQuizletFlowStates.isPopupOpen}>
                <ImportFromQuizletPopupLandingPageFlow
                    quizletUrl={importFromQuizletFlowStates.quizletUrl}
                    isOpen={importFromQuizletFlowStates.isPopupOpen}
                    onClose={async () => {
                        updateImportFromQuizletFlowStates({ isPopupOpen: false });
                        await LocalKeyValueStore.remove("landingPageQuizletImportLink");
                    }}
                />
            </LazyLoaded>

            <Footer />
        </>
    );
};

export default ChromeHelpPage;
