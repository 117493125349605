import { Exam } from "@knowt/syncing/graphql/schema";

export const RESOURCES_DATA = {
    video: {
        title: "Vides about",
        description: "Click on any one of the circles below to watch our recommend videos on this topic!",
    },
    discord: {
        title: "Discord Community",
        description: "Study in groups, prepare for the exam, and more with students just like you.",
    },
};

export const faqQuestions = (_examData: Exam) => [
    `What is the ${_examData.name} exam format?`,
    `How do I study for ${_examData.name}?`,
    `What units are on ${_examData.name}?`,
    `What are the video resources?`,
    `Where can I ask ${_examData.name} questions?`,
    `What is ${_examData.name}?`,
];

export const FAQ_ANSWERS_MAP = {
    "AP Calculus AB": [
        "The exam format includes 45 multiple-choice questions, and 6 free-response questions. The exam is broken down with the multiple choice section weighing 50% of the total exam score and the free response taking up the other 50%. Students are allotted 1 hour and 45 minutes to complete the multiple-choice section, and 1 hour and 30 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Calculus study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Through this course, you will take a journey to comprehend the theoretical components while sharpening your problem-solving capabilities through the application of your acquired knowledge and skills. Once you take a look through the breakdown below, make sure to read through the AP Calculus AB study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Limits and Continuity",
            "You will begin the AP Calculus AB course by diving into the realm of limits, which will equip you to navigate problems involving change and enhance your grasp of mathematical logic in connection to functions.",
            "Unit 2: Differentiation: Definition and Fundamental Characteristics",
            "In this unit, you will begin to understand the concept of limits to establish the definition of derivatives. This will enable you to successfully calculate derivatives and further refine your capability and success in mathematical reasoning.",
            "Unit 3: Differentiations: Composite, Implicit, and Inverse Functions",
            "Within this unit, you will achieve mastery in employing the chain rule, cultivate novel techniques for differentiation, and be introduced to the concept of higher-order derivatives.",
            "Unit 4: Contextual applications of Differentiation",
            "In this unit, you will employ derivatives to formulate and resolve real-world scenarios concerning instantaneous rates of change. Furthermore, you will harness mathematical reasoning to understand the limits of specific indeterminate forms.",
            "Unit 5: Analytical Applications of Differentiation",
            "During this unit, with the understanding you gained into the connections between function graphs and their derivatives, you will begin to explore the skills needed to apply calculus in resolving optimization challenges.",
            "Unit 6: Integration and Accumulation of Change",
            "During this unit, you will acquire the ability to define definite integrals using limits and understand how the Fundamental Theorem establishes a link between integration and differentiation. Moreover, you will put to use properties of integrals and hone your skills in practical integration techniques.",
            "Unit 7: Differential Equations",
            "Within this unit, you will acquire the proficiency to solve specific differential equations, and through this acquired knowledge, enhance your comprehension of exponential growth and decay phenomena.",
            "Unit 8: Integrative Applications",
            "In this unit, you will make mathematical connections that enable you to resolve a varying collection of challenges concerning net change across time intervals. Furthermore, you will acquire the capability to determine areas of regions and volumes of solids defined through functions.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Calculus AB study guides. These channels can be a great way to get a better understanding of the intricate principles, techniques, and pragmatic applications of differential and integral calculus within this course. Embark on an enlightening journey to master the theoretical underpinnings, simultaneously honing your prowess in problem-solving by practically employing your acquired knowledge and skills.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Calculus AB review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Calculus AB notes, and discuss any tricky problems with fellow AP Calculus AB students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Calculus AB explores the core principles, methods, and real-world utilities of differential and integral calculus through this course. Embark on a journey to comprehend the theoretical foundations while refining your problem-solving capabilities through the practical application of your newly acquired knowledge and skills.On this page, you’ll find AP Calculus AB resources to help you with your AP Calculus AB review.",
    ],
    "AP Calculus BC": [
        "The exam format includes 55 multiple-choice questions, three short answer questions, and 6 free-response questions. The exam weight is broken down with the multiple choice portion taking up 50% of the exam weight, and the free response taking up the other 50%. Students are allotted 1 hour and 45 minutes to complete the multiple-choice section,  and 1 hour and 30 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Calculus BC study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In this class, you will engage in hands-on experiments, delve into investigative studies, and tackle challenges by effectively applying your acquired knowledge and skills. Once you take a look through the breakdown below, make sure to read through the AP Calculus BC study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Limits and Continuity ",
            "In this unit, we'll kick off our exploration by delving into limits. These mathematical concepts will empower you to tackle problems related to change and deepen your grasp of the logical underpinnings behind functions. Get ready to enhance your mathematical reasoning skills.",
            "Unit 2: Differentiation: Definitions and Fundamental Properties",
            "In this unit, we'll immerse ourselves in the world of differentiation. By utilizing limits, we'll define derivatives and equip you with the prowess to adeptly compute them. Along the way, your mathematical reasoning abilities will continue to evolve. Get ready to master the art of differentiation.",
            "Unit 3: Exploring Differentiation: Composite, Implicit, and Inverse Functions",
            "In this unit, we'll delve deeper into differentiation techniques. You'll become a master of the chain rule, discover novel methods of differentiation, and even step into the realm of higher-order derivatives. Get ready to elevate your differentiation game.",
            "Unit 4: Contextual Applications of Differentiation ",
            "In this unit, we'll apply derivatives to real-life situations, unraveling challenges involving instantaneous rates of change. Moreover, we'll employ mathematical reasoning to unveil the limits of specific indeterminate forms. Get ready to see differentiation in action.",
            "Unit 5: Analytical Applications of Differentiations ",
            "Following an exploration of the intricate connections between function graphs and their derivatives, you'll acquire the skills to employ calculus for tackling optimization problems. Get ready to put your analytical prowess to the test.",
            "Unit 6: Integration and Accumulation of Change",
            "In this unit, you'll delve into the art of applying limits to define definite integrals and uncover how the Fundamental Theorem establishes a profound connection between integration and differentiation. By exploring integral properties and honing practical integration techniques, you'll be well-equipped to navigate the world of integration.",
            "Unit 7: Differential Equations",
            "In this unit, you'll delve into the world of solving specific differential equations. You'll then harness this newfound knowledge to delve deeper into concepts like exponential growth, decay, and logistic models. Get ready to unlock the secrets of differential equations!",
            "Unit 8: Applications of Integration",
            "In this unit, you'll forge mathematical connections that empower you to solve a diverse array of challenges. From calculating net changes over time intervals to determining lengths of curves, areas of regions, and volumes of solids defined by functions, you'll discover the practical power of integration. Get ready to put your integration skills to meaningful use.",
            "Unit 9:  Parametric Equations, Polar Coordinates, and Vector-Valued Functions",
            "In this unit, you'll unravel the world of parametric equations, polar coordinates, and vector-valued functions. By leveraging your grasp of differentiation and integration, you'll conquer the challenges posed by these mathematical constructs. Additionally, you'll delve into the intricacies of straight-line motion, applying these concepts to solve curve-related problems. Get ready for a multidimensional journey of exploration.",
            "Unit 10: Infinite Sequences and Series",
            "In this final unit, you'll dive into the fascinating world of infinite sequences and series. You'll dissect the behaviors of convergence and divergence exhibited by these mathematical constructs. Moreover, you'll gain the skills to represent well-known functions as infinite series. As a culmination of your journey, you'll also master the art of calculating the maximum potential error in specific approximations tied to series. Get ready for a captivating exploration of mathematical infinity",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Calculus BC study guides. These channels can be a great way to take a journey through the world of differential and integral calculus, where you'll delve into fundamental principles, techniques, and real-world applications. This adventure covers a range of subjects including parametric, polar, and vector functions, along with series. You'll roll up your sleeves for hands-on experiments, dive into in-depth investigations, and put your acquired knowledge and skills to the test in tackling various challenges.",
        " Connect with like-minded students who are also preparing for the exam and delve into the world of AP Calculus BC review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Calculus BC notes, and discuss any tricky problems with fellow AP Calculus BC students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!\n",
        "AP Calculus BC explores the exciting realm of differential and integral calculus! In this course you’ll uncover the core principles, methods, and practical uses of these mathematical tools. From parametric, polar, and vector functions to series, we'll explore a diverse range of topics. Get hands-on with experiments, dig deep into investigations, and sharpen your problem-solving skills by applying what you've learned. Let's embark on this mathematical adventure together! On this page, you’ll find AP Calculus BC resources to help you with your AP Calculus BC review.",
    ],
    "AP Statistics": [
        "The exam format includes 40 multiple-choice questions and 6 free response questions. The exam is broken down weight-wise with 50% being allotted to the multiple choice section and 50% allotted to free response questions.. Students are allotted 90 minutes to complete the multiple-choice section and 90 minutes to complete the free response section. To make sure you’re prepared enough to finish in time, take a look through our free AP Statistics study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP Statistics study guide above with all the key points you should know for each unit.\n",
        [
            "Unit 1: Introduction to One-Variable Data Exploration",
            "In this unit, you will learn how statisticians analyze variation and represent data. You will also learn how to describe distributions and draw conclusions using theoretical distributions.",
            "Unit 2: Two-Variable Data Exploration",
            "Building on what you learned in Unit 1, this unit focuses on representing and comparing two-variable data. You will learn how to describe relationships between variables and make predictions using models.",
            "Unit 3: Introduction to Data Collection",
            "This unit introduces study design, with a focus on the importance of randomization. You will learn how to interpret the results of well-designed studies and draw appropriate conclusions.",
            "Unit 4: Probability, Random Variables, and Distributions",
            "Unit 4 covers the fundamentals of probability and introduces probability distributions, which are key to statistical inference.",
            "Unit 5: Sampling Distributions",
            "In this unit, you will develop an understanding of sampling distributions, which form the basis for estimating population characteristics and determining confidence levels.",
            "Unit 6: Inference for Categorical Data: Proportions",
            "Unit 6 focuses on inference procedures for categorical variables. You will build a solid understanding of statistical inference, a concept that will be further explored throughout the course.",
            "Unit 7: Inference for Quantitative Data: Means",
            "Building on Unit 6, this unit dives into analyzing quantitative data to make inferences about population means.",
            "Unit 8: Inference for Categorical Data: Chi-Square",
            "Unit 8 introduces chi-square tests, which are used when working with two or more categorical variables.",
            "Unit 9: Inference for Quantitative Data: Slopes",
            "In this unit, you will learn that the slope of a regression model is not necessarily the true slope but is based on a single sample from a sampling distribution. You will also learn how to construct confidence intervals and perform significance tests for this slope.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Statistics study guides. These channels can be a great way to get a better understanding of major concepts and tools in Statistics used for collecting, analyzing, and drawing conclusions from data ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Statistics review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Statistics notes, and discuss any tricky problems with fellow AP Statistics students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Statistics explores the major concepts and tools used for collecting, analyzing, and drawing conclusions from data. On this page, you’ll find AP Statistics resources to help you with your AP Statistics review.",
    ],
    "AP Precalculus": [
        "The exam format includes 40 multiple-choice questions, three short answer questions, and 4 free-response questions. The exam is broken down by the multiple choice exam being weighed at 63% of the exam, and the FRQ section being weighed at 37% of the exam total. Students are allotted 2 hours to complete the multiple-choice section, and 1 hour to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Precalculus study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up.Throughout the course, you'll navigate real-world scenarios with the aid of mathematical concepts and perspectives. By delving into modeling, functions, and diverse representations, you'll foster a profound comprehension of mathematical principles. This course framework meticulously outlines the essential content and skills required for diverse career paths, ranging from mathematics, physics, and biology to health science, social science, and data science. Once you take a look through the breakdown below, make sure to read through the AP Precalculus  study guide above with all the key points you should know for each unit.\n",
        [
            "Unit 1: Exploring Polynomial and Rational Functions",
            "In this unit, you will enhance your comprehension of polynomial and rational functions by examining their applications in modeling and exploring diverse rates of change.",
            "Unit 2: Investigating Exponential and Logarithmic Functions",
            "In this unit, you will further your comprehension of inverses by delving into the correlation between exponential and logarithmic functions.",
            "Unit 3: Trigonometric and Polar Functions",
            "In this unit, you will engage in the modeling and exploration of periodic phenomena by applying transformations to trigonometric functions.",
            "Unit 4: Functions Involving Parameters, Vectors, and Matrices",
            "In this unit, you will enhance your grasp of the concept of functions by delving into a range of novel function types.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Precalculus study guides. These channels can be a great way to get a better understanding of college-level math and science. You'll use math to solve real-world problems. By studying modeling, functions, and different ways to show math, you'll really understand how it works. This course covers what you need for careers in various fields like math, physics, biology, health science, social science, and data science.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Precalculus review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Precalculus notes, and discuss any tricky problems with fellow AP Precalculus students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Precalculus explores advanced college-level math and science. You'll apply math to solve real-world problems. Through studying modeling, functions, and different math approaches, you'll deeply grasp its concepts. The course covers essential content and skills needed in careers such as math, physics, biology, health science, social science, and data science.On this page, you’ll find AP  Precalculus resources to help you with your AP Precalculus review.",
    ],
    "AP Computer Science A": [
        "The exam format includes 40 multiple-choice questions and 4 free response questions. Students are allotted 90 minutes to complete the multiple-choice section and 90 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Computer Science A study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP Computer Science A study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Primitive Types",
            "In this unit, you will gain a foundation in the Java programming language and other essential coding concepts.",
            "Unit 2: Using Objects",
            "Discover how to represent real-world objects in the digital space using reference data, and explore more advanced operations.",
            "Unit 3: Boolean Expressions and if Statements",
            "Focus on conditional statements to solve problems and control outcomes, as you explore the building blocks of algorithms.",
            "Unit 4: Iteration",
            "Learn the importance of repetition in algorithms and how to effectively use iteration.",
            "Unit 5: Writing Classes",
            "Explore the organization of behaviors and attributes into classes to represent real-world interactions digitally, while considering the legal and ethical implications.",
            "Unit 6: Array",
            "Gain techniques and standard algorithms for working with collections of related data structures.",
            "Unit 7: ArrayList",
            "Take a closer look at larger data sets and the use of ArrayList objects, while considering privacy concerns.",
            "Unit 8: 2D Array",
            "Expand your understanding of arrays to include 2D arrays, which represent data sets in a table format.",
            "Unit 9: Inheritance",
            "Learn how to manipulate code without altering existing code by using subclasses to create a hierarchical structure.",
            "Unit 10: Recursion",
            "Solve larger problems by breaking them down into smaller, simpler versions using recursive methods.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Computer Science A study guides. These channels can be a great way to get familiar with the concepts and tools of computer science as you learn a subset of the Java programming language. You'll do hands-on labs to design, write, and test computer programs that solve problems or accomplish tasks.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Computer Science A review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP  Computer Science A notes, and discuss any tricky problems with fellow AP Computer Science A students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Computer Science A explores the concepts and tools of computer science as you learn a subset of the Java programming language. On this page, you’ll find AP  Computer Science A resources to help you with your AP  Computer Science A review.\n",
    ],
    "AP Computer Science Principles": [
        "The exam includes 2 components : the Create Performance task and the end of course multiple choice exam. The performance task is 30% of your final score and you’ll be given at least 12 hours in class to work on it. For this part of the exam, you’ll develop a computer program of your choice: one that solves a problem, enables an innovation, or helps you express personal interests. The end-of-course multiple-choice exam is a paper-and-pencil written exam with 70 multiple choice questions that makes up 70% of your final score. To make sure you’re prepared enough to finish in time, take a look through our free AP Computer Science Principles study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP Computer Science Principles study guide above with all the key points you should know for each unit.",
        [
            "Big Idea 1: Creative Development",
            "In this unit, you'll understand the significance of collaboration in program development and how to use an iterative approach in your work.",
            "Big Idea 2: Data",
            "You'll delve into how computers handle data and how data can be utilized to generate fresh information and solve problems.",
            "Big Idea 3: Algorithms and Programming",
            "You'll gain proficiency in utilizing algorithms and abstractions to create programs that solve problems or express your creativity.",
            "Big Idea 4: Computer Systems and Networks",
            "You'll explore the functioning of computer systems and networks, as well as how multiple computers can be employed to divide tasks and expedite processes.",
            "Big Idea 5: Impact of Computing",
            "You'll analyze the ramifications of computing on societies, economies, and cultures, and contemplate the legal and ethical responsibilities that programmers bear.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Computer Science Principles study guides. These channels can be a great way to learn the principles that underlie the science of computing and develop the thinking skills that computer scientists use. You’ll work on your own and as part of a team to creatively address real-world issues using the tools and processes of computation.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Computer Science Principles review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Computer Science Principles notes, and discuss any tricky problems with fellow AP Computer Science Principles students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Computer Science Principles explores the skills that computer scientists need to use by working real world coding problems. On this page, you’ll find AP  Computer Science Principles resources to help you with your AP  Computer Science Principles review.\n",
    ],
    "AP Macroeconomics": [
        "The exam format includes 60 multiple-choice questions, and 3 free-response questions. The exam is broken down so the multiple choice section of the exam takes up 66% of the exam weight, and the free response takes up 33% of the exam weight. Students are allotted 1 hour and 10 minutes to complete the multiple-choice section, and 1 hour to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Macroeconomics study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In this class, you will  employ graphs, charts, and data to dissect, depict, and elucidate economic concepts through analysis and explanation. Once you take a look through the breakdown below, make sure to read through the AP Macroeconomics study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Basic Economic Concepts",
            "You will begin the course with an initial exploration of essential economic concepts, principles, and models that will lay the groundwork for your study of macroeconomics.",
            "Unit 2: Economic Indicators and the Business Cycle",
            "In this unit, you will delve into the examination of how economic phenomena, such as employment and inflation, are quantified and assessed.",
            "Unit 3: National Income and Price Determination",
            "During this unit, you will delve into the investigation of how alterations in overall spending and production, economic changes, and policy interventions impact national income, unemployment rates, and inflation levels.",
            "Unit 4: Financial Sector",
            "Within this unit, you will scrutinize the financial sector, explaining the implementation and development of monetary policy through the banking system.",
            "Unit 5: Long-Term Consequences of Stabilization Measures",
            "In this unit, you will dedicate additional time to the exploration of outcomes stemming from fiscal and monetary policy interventions. Furthermore, you will delve into the concept of economic growth.",
            "Unit 6: Open Economy—International Trade and Finance",
            "During this unit, you will dive into the exploration of an open economy scenario, where a nation engages with the global community through both product and financial markets.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Macroeconomics study guides. These channels can be a great way to explore the core principles of economics applicable to a complete economic system. Utilize graphs, charts, and data to deconstruct, illustrate, and clarify economic concepts, employing analysis and explanation.\n",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Macroeconomics review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Macroeconomics notes, and discuss any tricky problems with fellow AP Macroeconomics students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Macroeconomics explores the foundational principles of economics that relate to an entire economic system. Utilize graphs, charts, and data to analyze, portray, and clarify economic concepts through thorough examination and explanatory discussions. On this page, you’ll find AP Macroeconomics resources to help you with your AP Macroeconomics review.",
    ],
    "AP Microeconomics": [
        "The exam format includes 60 multiple-choice questions, and 3 free-response questions. The exam is broken down so the multiple choice section is worth 66% of the exam weight, and the free response section is worth 33% of the exam weight. Students are allotted 1 hour and 10 minutes to complete the multiple-choice section, and 1 hour to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Microeconomics study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. You will learn to utilize graphs, charts, and data to dissect, depict, and explain economic concepts through analysis, description, and explanation. Once you take a look through the breakdown below, make sure to read through the AP Microeconomics study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Basic Economic Concepts",
            "During this unit, you will begin to investigate the fundamental aspects of microeconomic reasoning. This exploration includes learning how to assess decisions through the lens of constraints and trade-offs, as well as making economically rational choices.",
            "Unit 2: Supply and Demand",
            "In this unit, you will acquire the foundation for comprehending market operations through an introduction to the supply and demand model.",
            "Unit 3: Production, Cost and the Perfect Competition Model",
            "During this unit, you will investigate the factors influencing corporate actions and do an investigation into the many aspects of the perfect competition model.",
            "Unit 4: Imperfect Competition",
            "In this unit, you will grasp the mechanics of markets characterized by imperfect competition and gain insight into the role of game theory within economic models.",
            "Unit 5: Factor Markets ",
            "During this unit, you will uncover how concepts like supply and demand, as well as marginal decision-making, are relevant within the realm of factor markets.",
            "Unit 6: Market Disruptions and Government Involvement",
            "In this unit, you will evaluate a variation of scenarios in which markets might falter and expand on the potential outcomes of government engagement within market dynamics.",
            "Video Resources",
            "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Microeconomics study guides. These channels can be a great way to get a better understanding of the economic principles relevant to individual actions within an economic system. These can help you know how to employ graphs, charts, and data to analyze, articulate, and expand upon economic concepts.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Microeconomics study guides. These channels can be a great way to get a better understanding of the economic principles relevant to individual actions within an economic system. These can help you know how to employ graphs, charts, and data to analyze, articulate, and expand upon economic concepts.\n",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Microeconomics review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Microeconomics notes, and discuss any tricky problems with fellow AP Microeconomics students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Microeconomics explores the economic principles that are applicable to the actions of individuals within an economic system. This course utilizes graphs, charts, and data to analyze, depict, and understand economic concepts through description and explanation. On this page, you’ll find AP Microeconomics resources to help you with your AP Microeconomics review.",
    ],
    "AP Chinese Language and Culture": ["", "", [""], "", "", ""],
    "AP French Language and Culture": [
        "The exam format includes 30 multiple-choice questions, 35 Multiple choice Audio questions, 2 Free response written questions and 2 free response spoken questions. The exam is broken down with the multiple choice taking 23% of the weight, the multiple choice audio being 27%, the free response written being 25% and finally the free response spoken being 25%. Students are allotted 40 minutes to complete the multiple-choice section, 55 minutes for the multiple choice Audio section, 1 hour and 10 minutes for the written FRQ section and finally 18 minutes for the FRQ spoken section.  To make sure you’re prepared enough to finish in time, take a look through our free AP French Language and Culture study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In this class, you will engage in conversational practice, delve into authentic resources like newspaper articles, films, music, and literature to gain insight into real-world contexts. Once you take a look through the breakdown below, make sure to read through the AP French Language and Culture study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Familial Dynamics in Different Societies",
            "Embark on a linguistic journey through the lens of family life and values within French-speaking societies. In this unit, you'll hone your French language skills while delving into the intricate tapestry of family dynamics. Discover contemporary beliefs and the myriad challenges that families encounter in these diverse societies. Get ready for a rich exploration that bridges language and culture.",
            "Unit 2: The Influence of Language and Culture on Identity",
            "In this unit, you'll expand your vocabulary while delving into the profound interplay between language, culture, and identity in French-speaking communities. Discover how language and culture intricately shape and mirror the evolving notions of identity. Prepare to unravel the fascinating connection between words, culture, and the diverse identities that thrive within these societies.",
            "Unit 3: Art and Beauty's Cultural Impact",
            "Dive into the captivating world of Unit 3, where you'll uncover the powerful influences of beauty and art within French-speaking societies. Immerse yourself in the exploration of how artistic expressions and notions of beauty intricately shape and mirror the cultural tapestry of these communities. Get ready to embark on a journey where creativity, culture, and aesthetics converge to weave a rich narrative of artistic influence and cultural reflection.",
            "Unit 4: Impact of Science and Technology on Daily Life",
            "In Unit 4, you'll delve into the fascinating realm of science and technology's influence on the daily lives of French-speaking societies. Navigate through the intricate web of advancements and innovations that shape the way people live, work, and interact within these communities. As your language skills continue to flourish, you'll also master the art of interpreting complex texts, allowing you to explore the multifaceted world of scientific and technological developments.",
            "Unit 5: Factors that Impact the Quality of Life ",
            "Dive into Unit 5, where you'll uncover the intricacies of contemporary life within French-speaking communities. Explore the dynamic interplay between social status, cultural outlooks, and the availability of opportunities in shaping the overall quality of life. Through this exploration, you'll gain a deeper understanding of the diverse factors that contribute to the well-being and experiences of individuals in these societies.",
            "Unit 6: Environmental, Political, and Societal Changes ",
            "In Unit 6, you'll delve into the intricate web of environmental, political, and societal challenges that impact the lives of individuals in French-speaking communities. Through thoughtful exploration and engaging discussions, you'll not only gain insight into the complex global issues these societies face but also work collaboratively to propose potential solutions. This unit will encourage you to critically analyze the multifaceted nature of these challenges and foster a deeper understanding of their implications.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP French Language and Culture study guides. These channels can be a great way to get a better understanding of the French Language and Culture as impacted by various aspects of culture including family dynamics, Science and Technology, Art, and more. By learning the cultural elements of French society, you will also gain a further understanding of the French Language. \n",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP French Language and Culture review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP French Language and Culture notes, and discuss any tricky problems with fellow AP French Language and Culture students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  French Language and Culture explores AP French language and Culture explores the French language and culture, exploring how diverse cultural aspects like quality of life, societal changes, beauty, and more shape and influence them. By delving into the cultural dimensions of French society, you will concurrently deepen your comprehension of the French Language. On this page, you’ll find AP French Language and Culture resources to help you with your AP French Language and Culture review.",
    ],
    "AP German Language and Culture": [
        "The exam format includes 30 multiple-choice questions, 35 Multiple choice Audio questions, 2 Free response written questions and 2 free response spoken questions. The exam is broken down with the multiple choice taking 23% of the weight, the multiple choice audio being 27%, the free response written being 25% and finally the free response spoken being 25%. Students are allotted 40 minutes to complete the multiple-choice section, 55 minutes for the multiple choice Audio section, 1 hour and 10 minutes for the written FRQ section and finally 18 minutes for the FRQ spoken section. To make sure you’re prepared enough to finish in time, take a look through our free AP German Language and Culture study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. This course will involve practical German communication practice and an exploration of authentic resources like newspaper articles, films, music, and books. Once you take a look through the breakdown below, make sure to read through the AP German Language and Culture study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Family Dynamics Across Cultures",
            "In this unit, you will acquire German language skills while exploring the study of family structures and values within German-speaking societies. Additionally, you will explore modern perspectives and obstacles that families encounter in German Culture. ",
            "Unit 2: Language and Culture's Impact on Identity",
            "During this unit, you will expand your vocabulary while delving into the ways in which language and culture interact to mold and mirror evolving concepts of identity within German-speaking communities.",
            "Unit 3: Influences of Beauty and Art",
            "In this unit, you will investigate the complex connections between notions of beauty and art, examining how these concepts both shape and mirror the cultural landscapes of German-speaking societies.",
            "Unit 4: How Science and Technology Affect our Lives ",
            "During this unit, you will analyze the ways in which scientific and technological advancements impact everyday life within German-speaking communities. Your expanding language abilities will aid in deciphering unfamiliar vocabulary and navigating more complex texts related to these topics.",
            "Unit 5: Factors Affecting Quality of Life",
            "In this unit, you will dive deeper into the intricacies of modern life within German-speaking countries. This exploration will encompass an understanding of how social status, cultural viewpoints, and opportunities for employment and resources can significantly shape and influence the overall quality of life.",
            "Unit 6: Environmental, Political, and Societal Changes",
            "In this unit, you will examine the intricate web of global challenges impacting individuals within German-speaking societies. Through your exploration, you will participate in in-depth discussions aimed at coming up with potential solutions to address these complex issues.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP German Language and Culture study guides. These channels can be a great way to understand the German Language and Culture as impacted by various aspects of culture including family dynamics, Science and Technology, Art, and more. By learning the cultural elements of German society, you will also gain a further understanding of the German Language. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP German Language and Culture review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP German Language and Culture notes, and discuss any tricky problems with fellow AP German Language and Culture students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP German language and Culture explores the German language and culture, exploring how diverse cultural aspects like quality of life, societal changes, beauty, and more shape and influence them. By delving into the cultural dimensions of German society, you will concurrently deepen your comprehension of the German Language. On this page, you’ll find AP German Language and Culture resources to help you with your AP German Language and Culture review.",
    ],
    "AP Italian Language and Culture": ["", "", [""], "", "", ""],
    "AP Japanese Language and Culture": ["", "", [""], "", "", ""],
    "AP Latin": [
        "The exam format includes 50 multiple-choice questions, and 5 free-response questions. The exam weight is broken down equally between the multiple choice section and free response section, both making up 50% of the exams weight. Students are allotted 1 hour to complete the multiple-choice section, and 2 hours to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Latin study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Through this course, you will engage in translating excerpts from the works of Vergil and Caesar, subsequently subjecting them to scrutiny via classroom discussions, debates, and presentations. Once you take a look through the breakdown below, make sure to read through the AP Latin study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Exploration of Vergil's Aeneid, Book 1",
            "Within this unit, you will begin to understand excerpts from the Aeneid that present Vergil's depiction of leadership dynamics, as well as the interplay of gods and various civilizations in the challenges faced by the Trojans.",
            "Unit 2: Exploration of Caesar's Gallic War, Books 1 and 6",
            "In this unit, you will delve into excerpts from the Gallic War that offer insights into Caesar's interesting depiction of non-Roman individuals in comparison to his representation of Roman ideals.",
            "Unit 3: Exploring Vergil's Aeneid, Book 2",
            "In this unit, you will re-engage with Vergil's work,  investigating Aeneas's narrative of the tragic downfall of Troy. Through this exploration, you will also analyze Vergil's depiction of both easily seen and concealed influences that shape the destinies and fates of the characters within the story.",
            "Unit 4: Delving into Caesar's Gallic War, Book 4",
            "During this unit, you will explore the account of the Romans' first invasion of Britain in the Gallic War. Through this exploration, you will examine themes of leadership, war, and empire that are interwoven within the readings.",
            "Unit 5: Immersing in Vergil's Aeneid, Book 4",
            "In this unit, you will revisit Vergil's narrative, focusing on the passionate romance between Queen Dido and Aeneas. Through this exploration, you will analyze the perspectives of non-Roman characters depicted in the readings, deciphering how these viewpoints serve to illustrate and shed light on Roman values.",
            "Unit 6: In-Depth Analysis of Caesar's Gallic War, Book 5, Part I",
            "During this unit, you will closely examine the initial portion of readings from Book 5, focusing on the themes of history and memory as depicted within Caesar's literary genre and distinctive style.",
            "Unit 7: Comprehensive Exploration of Caesar's Gallic War, Book 5 (Part II), Book 6, and Book 7",
            "In this unit, your exploration will persist as you delve into the latter half of the readings from Book 5 of Caesar's Gallic War. Throughout this study, you will also evaluate the theme of leadership within the context of war, gaining insights from the textual material.",
            "Unit 8: Rediscovering Vergil's Aeneid, Books 6, 8, and 12",
            "In this unit, you will once again look into the works of Vergil, focusing on themes of literary genre and style, as well as the complex relationship between human beings and the divine, as portrayed within the readings.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Latin study guides. These channels can be a great way to gain proficiency in comprehending Latin literature while diving into the rich past of ancient Roman history and culture. This course will help you to translate passages from the writings of Vergil and Caesar, which will then be subject to thorough analysis through class discussions, debates, and presentations.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Latin review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Latin notes, and discuss any tricky problems with fellow AP Latin students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Latin explores Latin literature and immerses you in the captivating history and culture of ancient Rome. This course is designed to ease your translation of excerpts from the works of Vergil and Caesar, followed by an examination through classroom discussions, debates, and presentations. On this page, you’ll find AP Latin resources to help you with your AP Latin review.",
    ],
    "AP Spanish Language and Culture": [
        "The exam format includes 30 multiple-choice questions, 35 Multiple choice Audio questions, 2 Free response written questions and 2 free response spoken questions. The exam is broken down with the multiple choice taking 23% of the weight, the multiple choice audio being 27%, the free response written being 25% and finally the free response spoken being 25%. Students are allotted 40 minutes to complete the multiple-choice section, 55 minutes for the multiple choice Audio section, 1 hour and 10 minutes for the written FRQ section and finally 18 minutes for the FRQ spoken section.  To make sure you’re prepared enough to finish in time, take a look through our free AP Spanish Language and Culture study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Through this course, you will engage in practical Spanish communication exercises and dive into authentic materials such as newspaper articles, films, music, and literature, enabling a comprehensive understanding of both language and culture. Once you take a look through the breakdown below, make sure to read through the AP Spanish Language and Culture study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Family Dynamics Across Cultures",
            "In this unit, you will acquire Spanish language skills while exploring the study of family structures and values within Spanish-speaking societies. Additionally, you will explore modern perspectives and obstacles that families encounter in Spanish Culture. ",
            "Unit 2: Language and Culture's Impact on Identity",
            "During this unit, you will expand your vocabulary while delving into the ways in which language and culture interact to mold and mirror evolving concepts of identity within Spanish-speaking communities.",
            "Unit 3: Influences of Beauty and Art",
            "In this unit, you will investigate the complex connections between notions of beauty and art, examining how these concepts both shape and mirror the cultural landscapes of Spanish-speaking societies.",
            "Unit 4: How Science and Technology Affect our Lives ",
            "During this unit, you will analyze the ways in which scientific and technological advancements impact everyday life within Spanish-speaking communities. Your expanding language abilities will aid in deciphering unfamiliar vocabulary and navigating more complex texts related to these topics.",
            "Unit 5: Factors Affecting Quality of Life",
            "In this unit, you will dive deeper into the intricacies of modern life within Spanish-speaking countries. This exploration will encompass an understanding of how social status, cultural viewpoints, and opportunities for employment and resources can significantly shape and influence the overall quality of life.",
            "Unit 6: Environmental, Political, and Societal Changes",
            "In this unit, you will examine the intricate web of global challenges impacting individuals within Spanish-speaking societies. Through your exploration, you will participate in in-depth discussions aimed at coming up with potential solutions to address these complex issues.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Spanish Language and Culture study guides. These channels can be a great way to understand the Spanish Language and Culture as impacted by various aspects of culture including family dynamics, Science and Technology, Art, and more. By learning the cultural elements of Spanish society, you will also gain a further understanding of the Spanish Language. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Spanish Language and Culture review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Spanish Language and Culture notes, and discuss any tricky problems with fellow AP Spanish Language and Culture students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Spanish language and Culture explores the Spanish language and culture, exploring how diverse cultural aspects like quality of life, societal changes, beauty, and more shape and influence them. By delving into the cultural dimensions of Spanish society, you will concurrently deepen your comprehension of the Spanish Language. On this page, you’ll find AP Spanish Language and Culture resources to help you with your AP Spanish Language and Culture review.",
    ],
    "AP Spanish Literature and Culture": ["", "", [""], "", "", ""],
    "AP English Language and Composition": [
        "The exam format includes 45 multiple-choice questions, and 3 free-response questions. The weight is broken down with the multiple choice section being weighed at 45% of your exam score and the FRQ section being weighed at 55%. Students are allotted 1 hour to complete the multiple-choice section, and 2 hours and 15 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP English Language and Composition study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Throughout this course, you will engage with nonfiction texts spanning different eras and analyzing them. You will also craft essays with varied objectives, such as explaining concepts, advocating viewpoints, or convincing your readers on various subjects.  Once you take a look through the breakdown below, make sure to read through the AP English Language and Composition study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Developing Analytical Skills",
            "In this unit, you will begin to learn the ability to recognize and assess the arguments authors have presented within a text. Furthermore, you will understand whether the author backs-up these claims through reasoning and supporting evidence.",
            "Unit 2: Exploring Organizational Strategies",
            "During this unit, you will dive into the ways in which writers structure information and evidence to enhance their arguments while aiming to grab the attention of a specific target audience.",
            "Unit 3: Investigating Diverse Viewpoints",
            "In this unit, you will navigate the difference between perspectives surrounding a given topic. Additionally, you will delve into the interconnectedness of different arguments and how they can interact and correspond with each other.",
            "Unit 4: Analyzing Argumentative Strategies",
            "Within this unit, you will examine the decision-making process that writers undergo when deciding how to construct arguments, craft introductions, and formulate conclusions.",
            "Unit 5: Precision in Argument Integration",
            "During this unit, your attention will be directed towards the specific decisions that writers make to seamlessly combine all the components of an argument into a cohesive whole.",
            "Unit 6: Exploring Position and Perspective",
            "In this unit, you will engage in comprehending the distinction between position and perspective. Moreover, you will learn techniques for evaluating bias and master the skill of effectively incorporating and addressing various viewpoints within an argument.",
            "Unit 7: Evaluating Argument Scope",
            "During this unit, you will reflect on the extensive and complex nature of arguments encompassing a particular subject. Furthermore, you will analyze the factors contributing to the success or lack thereof of each argument.",
            "Unit 8: Analyzing Stylistic Decisions",
            "In this unit, you will dive into the array of stylistic options available to writers and examine the ways in which these choices impact the effectiveness of an argument.",
            "Unit 9: Embracing Diverse Viewpoints in Complex Argumentation",
            "Within this unit, you will expand your horizons by contemplating multiple perspectives while constructing complex arguments.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP English Language and Composition study guides. These channels can be a great way to refine your grasp of written composition elements while improving your critical reading and writing skills. Throughout the duration of this course, you will interact with nonfiction texts from various time periods, subjecting them to analysis. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP English Language and Composition review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP English Language and Composition notes, and discuss any tricky problems with fellow AP English Language and Composition students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP English Language and Composition explores your understanding of the components of written composition while refining your critical reading and writing skills. Throughout the course, you will engage with nonfiction texts spanning different historical periods, subjecting them to in-depth analysis. Moreover, you will craft essays with a range of objectives, including explaining concepts, advocating viewpoints, and persuading readers on various subjects. On this page, you’ll find AP English Language and Composition resources to help you with your AP English Language and Composition review.",
    ],
    "AP English Literature and Composition": [
        "The exam format includes 55 multiple-choice questions and three free-response questions. Students are allotted 60 minutes to complete the multiple-choice section and 120 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP English Literature and Composition study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP English Literature and Composition study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Critical Reading Skills for Short Fiction",
            "In this unit, you will develop critical reading skills that enable you to analyze, interpret, and comprehend prose effectively.",
            "Unit 2: Exploring Poetry through Critical Reading",
            "This unit will focus on expanding your critical reading skills to analyze different elements within a wide range of poems.",
            "Unit 3: Analyzing Longer Fiction or Drama",
            "You will observe how literary techniques unfold in longer works and examine the development and interaction of characters over the course of a narrative.",
            "Unit 4: Advanced Analysis of Short Fiction",
            "Building upon previous units, this unit will delve deeper into the roles of character and conflict in fiction, as well as explore the influence of a narrator's perspective on storytelling.",
            "Unit 5: Examining Poetry in Depth",
            "This unit will delve into different forms of poetry, studying how structure and figurative language contribute to meaning and impact.",
            "Unit 6: Complex Analysis of Longer Fiction or Drama",
            "You will analyze the progression of various literary techniques in longer works and explore the changes that characters undergo as they respond to plot developments.",
            "Unit 7: Fiction's Reflection of Society",
            "This unit will examine how works of fiction interact with and reflect the world and society in which the authors live or lived.",
            "Unit 8: Advancing Interpretation of Poetry",
            "By exploring contrasts, ambiguous language, and other techniques, this unit will deepen your interpretation and understanding of poetry.",
            "Unit 9: Comprehensive Analysis of Longer Fiction or Drama",
            "Drawing upon the skills and interpretations learned in prior units, you will conduct a nuanced analysis of complex longer narratives as a whole.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP English Literature and Composition study guides. These channels can be a great way to better understand how to understand and evaluate works of fiction, poetry, and drama from various periods and cultures..",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP English Literature and Composition review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP  English Literature and Composition notes, and discuss any tricky problems with fellow AP English Literature and Composition students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  English Literature and Composition explores how to understand and evaluate works of fiction, poetry, and drama from various periods and cultures. On this page, you’ll find AP English Literature and Composition resources to help you with your AP  English Literature and Composition  review.",
    ],
    "AP Art History": [
        "The exam format includes 80 multiple-choice questions, and 6 free-response questions. The exam is weighted equally between the multiple choice questions versus the FRQs with both being 50% of the total exam weight. Students are allotted 60 minutes to complete the multiple-choice section, 120 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Art History study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. This course entails a thorough examination of artistic creations, employing methods such as observation, discussion, reading, and research to dissect and interpret various artworks.  Once you take a look through the breakdown below, make sure to read through the AP Art History study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Worldwide Prehistoric Art, 30,000–500 BCE",
            "In this unit, you will delve into the beginnings of artistic expression among humans across different regions during the stone ages.",
            "Unit 2: Ancient Mediterranean Art, 3500 BCE–300 CE",
            "During this unit, you will immerse yourself in the exploration of diverse artistic creations originating from the civilizations of the ancient Near East and Egypt, as well as the cultural contributions of the Greek, Etruscan, and Roman societies.",
            "Unit 3: Early Europe and Colonial Americas Art, 200–1750 CE",
            "In this unit, you will embark on an exploration of the artistic progression spanning from the early Middle Ages to the mid-18th century, encompassing both Europe and the Americas.",
            "Unit 4: Later Europe and the Americas, 1750–1980 CE",
            "Within this unit, you will engage in an in-depth study of the profound influence of rapid societal transformations on the artistic landscape during the modern era, both in Europe and the Americas. Diving into this complex period, you will gain insights into how evolving cultural, technological, and ideological shifts have shaped and reshaped the creative expressions that emerged during these times.",
            "Unit 5: Indigenous American Art, 1000 BCE–1980 CE",
            "In this unit, you will attain a grasp of the origins and progression of art within indigenous American cultures, spanning from the ancient civilizations of Central and South America to the artistic traditions of Native North American societies. Through this exploration, you will gain a deeper understanding of the diverse creative expressions that have shaped the visual narrative of these rich and diverse cultural landscapes.",
            "Unit 6: Exploring African Art, 1100–1980 CE",
            "During this unit, you will delve into the intricate realm of African art, uncovering its remarkable diversity and the pivotal role it has played within the multifaceted societies across the continent. Through this exploration, you will gain insights into the rich cultural tapestry woven by the artistic expressions that have flourished across various African communities.",
            "Unit 7: West and Central Asia, 500 BCE–1980 CE",
            "Within this unit, you will acquire knowledge regarding the methodologies, materials, and the dynamic evolution that has characterized art across the regions of West and Central Asia. Through comprehensive exploration, you will uncover the diverse creative expressions that have emerged from these cultural landscapes, providing insights into the rich artistic heritage of this area.",
            "Unit 8: South, East, and Southeast Asia, 300 BCE–1980 CE",
            "During this unit, you will engage in a comprehensive study of the rich tradition of Asian art, spanning from its prehistoric origins to the creation of contemporary works. Through this exploration, you will gain insights into the diverse artistic expressions that have flourished across the dynamic cultural landscapes of South, East, and Southeast Asia.",
            "Unit 9: Pacific Artistry, 700-1980 CE",
            "In this unit, you will embark on a study of the diverse array of artistic forms that have emerged from the expansive network of islands spanning the Pacific Ocean, extending from Australia to Hawaii. ",
            "Unit 10: Exploring Contemporary Global Art, 1980 CE to Present",
            "Within this unit, you will dive into an in-depth study of the multifaceted realm of contemporary art on a global scale, spanning from 1980 CE to the present day. Through this exploration, you will gain insights into the diverse and evolving forms of artistic expression that have emerged from various corners of the world during this dynamic period.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Art History study guides. These channels can be a great way to get a better understanding of the history of Art by investigating how the world of art has progressed from the stone age to current day, evaluating how art has been influenced by political and cultural changes through history. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Art History review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Art History notes, and discuss any tricky problems with fellow AP Art History students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Art History explores Art history by delving into the evolution of artistic developments, tracing the journey from the Stone Age to the present day. This investigation will involve assessing the profound impact of political and cultural shifts throughout history on the realm of art.On this page, you’ll find AP Art History resources to help you with your AP Art History review.",
    ],
    "AP Music Theory": [
        "The exam format includes 75 multiple-choice questions, 7 written FRQs, and 2 visual-singing free response questions. The exam wight is broken down with the multiple choice section being weighted at 65%, the written FRQ at 45% and the visual-singing FRQs at 10% Students are allotted 1 hour and 20 minutes to complete the multiple-choice section, 1 hour and 10 minutes for the written short answer section, and 10 minutes to compose their responses to the visual-singing free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Music Theory study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up.  This course will facilitate these skills through activities such as attentive listening, reading, expressive writing, and engaging in musical performances across a diverse spectrum of genres and styles. Once you take a look through the breakdown below, make sure to read through the AP Music Theory study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Foundations of Music I: Pitch, Major Scales, Key Signatures, Rhythm, Meter, and Expressive Elements",
            "During this initial unit, you will delve into the core principles of music, including the interplay between pitch and rhythm, which combine to form melodies and establish meter, thereby serving as the building blocks for the creation of intricate musical compositions.",
            "Unit 2: Foundations of Music II: Minor Scales, Key Signatures, Melodic Construction, Timbre, and Texture",
            "Expanding upon the concepts introduced in Unit 1, this unit will further develop your understanding of pitch patterns and relationships by transitioning from major scales to the intricacies of minor scales.",
            "Unit 3: Foundations of Music III: Triads and Seventh Chords",
            "Expanding upon your current understanding of pitch relationships, this unit will introduce you to the foundational aspects of harmony, encompassing the study of triads and seventh chords.",
            "Unit 4: Exploring Harmony and Voice Leading I: Chord Function, Cadence, and Phrase",
            "Within this unit, your understanding of harmonic components and processes will be further enriched. Additionally, you will delve into the intricacies of 18th-century style voice leading, thereby broadening your expertise in musical composition.",
            "Unit 5: Exploring Harmony and Voice Leading II: Chord Progressions and Predominant Function",
            "During this unit, you will delve into the comprehensive study of advanced harmonic progressions, enabling you to describe, analyze, and craft intricate compositions in the form of four-part (SATB: soprano, alto, tenor, and bass) voice leading.",
            "Unit 6: Navigating Harmony and Voice Leading III: Embellishments, Motives, and Melodic Devices",
            "In this unit, your exploration of harmony and voice leading will be the main point of focus, as you delve further into advanced skills and concepts within these subjects.",
            "Unit 7: Advancing Harmony and Voice Leading IV: Secondary Function",
            "Expanding upon your existing knowledge of harmonic relationships and procedures, this unit will delve deeper into the realms of keys, scale degrees, and chords, thereby further enriching your comprehension of these essential musical components.",
            "Unit 8: Modes and Musical Structure",
            "During this unit, you will endeavor into an in-depth study of the conventions that shape the character of music, encompassing elements such as modes, relationships between musical phrases, and diverse forms that contribute to the overall structure of compositions.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Music Theory study guides. These channels can be a great way to get a better understanding of Music Theory and break down the components of what makes music including pitch, rhythm, harmonies, structures, and many more aspects of Music Theory. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Music Theory review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Music Theory notes, and discuss any tricky problems with fellow AP Music Theory students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Music Theory explores the depths of Music Theory to gain a comprehensive understanding of its building blocks. Explore pitch, rhythm, harmonies, structures, and more, unraveling the intricacies that shape music's essence. Through systematic analysis, develop a nuanced perception, enriching your appreciation and interpretation of musical compositions.On this page, you’ll find AP Music Theory resources to help you with your AP Music Theory review.",
    ],
    "AP Comparative Government and Politics": [
        "The exam format includes 55 multiple-choice questions and 4 free-response questions. Students are allotted 60 minutes to complete the multiple-choice section and 90 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Comparative Government study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP Comparative Government study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Political Systems, Regimes, and Governments",
            "This unit will teach you the skills and concepts utilized by political scientists, which you will then apply when analyzing data related to the six course countries.",
            "Unit 2: Political Institutions",
            "In this unit, we will examine the political structure and branches of government in each of the six course countries.",
            "Unit 3: Political Culture and Participation",
            "Using the six course countries as examples, you will study how the citizens interact with, influence, and are influenced by their respective governments.",
            "Unit 4: Party and Electoral Systems and Citizen Organizations",
            "Within the context of the political structures, events, and issues of the six course countries, you will learn about the workings of individuals, parties, and citizen organizations in their pursuit of influence and power.",
            "Unit 5: Political and Economic Changes and Development",
            "This unit delves into how the political systems and power structures of the six course countries are affected by and interact with the global context.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Comparative Government study guides. These channels can be a great way to examine the political structures; policies; and political, economic, and social challenges of six selected countries: China, Iran, Mexico, Nigeria, Russia, and the United Kingdom.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Comparative Government review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP  Comparative Government notes, and discuss any tricky problems with fellow AP Comparative Government students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Comparative Government uses a comparative approach to examine the political structures; policies; and political, economic, and social challenges of six selected countries: China, Iran, Mexico, Nigeria, Russia, and the United Kingdom. You will cultivate your understanding of comparative government and politics through analysis of data and text-based sources as they explore topics like power and authority, legitimacy and stability, democratization, internal and external forces, and methods of political analysis. On this page, you’ll find AP Comparative Government resources to help you with your AP  Comparative Government  review.\n",
    ],
    "AP European History": [
        "The exam format includes 55 multiple-choice questions, 3 short answer questions, and 2 free-response questions. The exam is broken down weight-wise with 40% being allotted to the multiple choice section, 20% to the short answer sections, and 40% to the FRQs. Students are allotted 55 minutes to complete the multiple-choice section, 40 minutes for the short answer section, and 1 hour and 40 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP European History study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. This class involves investigating texts, visual materials, and other historical artifacts, enabling you to construct historical arguments through essay writing while delving into the intricate tapestry of Europe's evolution over time. Once you take a look through the breakdown below, make sure to read through the AP European History study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Renaissance and Age of Exploration",
            "In this unit, you will delve into the swift transformations ignited by the Renaissance and follow their lasting impact as they intertwine with the eras of colonialism and a commercial revolution, completely reshaping the fabric of European society.",
            "Unit 2: Age of Reformation",
            "During this unit, you will navigate the intricate paths of Christianity's evolution in Europe and dive into the immense and widespread consequences of the Reformation, which reverberated through both everyday existence and the political landscape.",
            "Unit 3: Absolutism and Constitutionalism",
            "In this unit, you will engage in an exploration of the change in political power and their intricate impact on the dynamics between states and individuals, as well as among states themselves. Additionally, you will delve into the multifaceted outcomes of economic advancements in Europe, shedding light on the resultant social, political, and cultural conditions.",
            "Unit 4: Scientific, Philosophical, and Political Transformations",
            "During this unit, you will examine the intertwining of scientific reasoning, emerging political ideologies, and technological progress that precipitated far-reaching changes across European society during the 18th century.",
            "Unit 5: Conflict, Crisis, and Reaction in the Late 18th Century",
            "In this unit, you will trace the expansion of England's influence within European affairs and explore the wide-reaching effects of the French Revolution as they resonated across the European continent.",
            "Unit 6: Industrialization and Its Effects",
            "Within this unit, you will delve into the paradigm-shifting consequences of the Industrial Revolution, technological advancements, and urbanization, and how these elements collectively reshaped both European society and the realm of political thought.",
            "Unit 7: 19th-Century Views and Political Evolution",
            "In this unit, you will dive into the dynamics of nationalism and the impacts of alliances, dissecting how these political forces came together to create the backdrop against which the stage was set for the advent of World War I.",
            "Unit 8: 20th-Century Worldwide Struggles",
            "During this unit, you will get an understanding into the monumental disruptions that marked the 20th century, alongside cultural advancements and the evolving landscape of political frameworks.",
            "Unit 9: Cold War Era and Contemporary Europe",
            "In this unit, you will delve into the exploration of how conflicting ideologies and the influence of global superpowers played a defining role in shaping the trajectory of the late portion of the 20th century.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP European History study guides. These channels can be a great way to get a better understanding of European History and how cultural and political uprisings such as Absolutism and industrialization have impacted European culture since 1450. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP European History review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP European History notes, and discuss any tricky problems with fellow AP European History students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP European History explores and deepens your comprehension of European history by examining the intricate interplay of cultural and political upheavals, including instances like Absolutism and industrialization, and their profound influence on European society since the year 1450. On this page, you’ll find AP European History resources to help you with your AP European History review.",
    ],
    "AP Human Geography": [
        "The exam format includes 60 multiple-choice questions and three free response questions. Students are allotted 60 minutes to complete the multiple-choice section and 1 hour and 15 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Human Geography study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown above, make sure to read through the AP Human Geography study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Thinking Geographically",
            "This unit covers the tools and methods utilized by geographers to study places. Topics include different map types and their interpretations, data collection and usage techniques, spatial relationships and patterns, data analysis at various scales (global, national, local), and the process of defining regions.",
            "Unit 2: Population and Migration Patterns and Processes",
            "This unit delves into the study of human populations and their associated patterns. Topics include population density and its impacts on society and the environment, theories of population growth and decline, the effects of population and immigration policies, and the causes and consequences of migration.",
            "Unit 3 : Cultural Patterns and Processes",
            "This unit centers on the spread of language, religion, and cultural practices across space and time. Topics encompass the various mechanisms of cultural diffusion, the influence of historical forces like colonialism and trade on cultural patterns, the impact of contemporary forces such as globalization on cultural practices, and an exploration of the diverse ways in which religions spread.",
            "Unit 4 : Political Patterns and Processes",
            "This unit expands on your understanding of populations and cultural patterns and delves into the political organization of the world. Topics include the exploration of political entities like nations and nation-states, the historical ideas and forces that influenced modern political boundaries, various forms of government such as unitary and federal states, and the factors contributing to state fragmentation.",
            "Unit 5 : Agriculture and Rural Land-Use Patterns and Processes",
            "This unit focuses on the development and diffusion of agriculture, exploring its origins and spread worldwide. Topics cover the influence of physical geography on farming practices, the historical context of agriculture's emergence, the significance of the Green Revolution, and the impacts of farming practices on both the environment and society.",
            "Unit 6: Cities and Urban Land-Use Patterns and Processes",
            "This unit delves into the origins and impacts of urbanization, investigating cities worldwide and their significant role in the process of globalization. Topics encompass the driving factors behind the growth of cities and suburbs, the relationship between globalization and urban centers, the crucial aspect of city infrastructure, and the various urban design initiatives and practices shaping modern urban landscapes.",
            "Unit 7: Industrial and Economic Development Patterns and Processes",
            "This unit focuses on the study of industrialization's origins and impact on economic development. Topics include the Industrial Revolution, the examination of economic sectors and patterns, the effects of economic development on women's roles, and the significance of trade in the global economy.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Human Geography study guides. These channels can be a great way to get a better understanding of human geography through data and geographic analyses as they explore topics like patterns and spatial organization, human impacts and interactions with their environment, and spatial processes and societal changes.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Human Geography review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Human Geography notes, and discuss any tricky problems with fellow AP Human Geography students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!\n" +
            "\n",
        "AP Human Geography explores human geography through data and geographic analyses as they explore topics like patterns and spatial organization, human impacts and interactions with their environment, and spatial processes and societal changes. On this page, you’ll find AP Human Geography resources to help you with your APHUG review.",
    ],
    "AP Psychology": [
        "The exam format includes 100 multiple-choice questions, and 2 free-response questions. The Multiple-choice section of the exam is worth 67% of the total exam weight, and the FRQ section is counted for 33% of the total exam weight. Students are allotted 70 minutes to complete the multiple-choice section, and 50 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Psychology study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. This course investigates the realm of psychology, where you will engage in reading, discourse, and the evaluation of data derived from various psychological research endeavors. Once you take a look through the breakdown below, make sure to read through the AP Psychology study guide above with all the key points you should know for each unit.\n",
        [
            "Unit 1: Scientific Foundations of Psychology ",
            "In this unit, you will begin to study and understand the fundamental underpinnings of psychological theories, leading to the examination of behaviors and mental processes in both humans and animals. Additionally, you will develop insights into the methodologies used by psychologists for the creation and execution of research studies.",
            "Module 2: Biological Bases of Behavior",
            "Within this unit, you will investigate behaviors and cognitive functions through a biological lens, researching the interaction between human biology and the external environment. This exploration will showcase how impactful this interaction is on our behaviors and mental processes.",
            "Module 3: Sensation and Perception",
            "This unit explores the intricate mechanics of human sensory perception, expanding on the process through which sensory input is transformed into meaningful perceptions that significantly shape our cognitive processes and behaviors.",
            "Module 4: The Study of Learning ",
            "In this unit, you will explore the unit of psychology dedicated to understanding the mechanisms through which humans and other creatures acquire knowledge and skills. You will also investigate how the process of learning transforms throughout a lifetime.",
            "Module 5: Cognitive Psychology",
            "Within this unit you will begin to evaluate the small and intricate aspects of memory, intelligence, and various other cognitive processes, unraveling how strongly they influence human behavior.",
            "Unit 6: Developmental Psychology",
            "In this unit, you will explore how both physical developments and social dynamics throughout the human lifespan alter and impact behaviors and cognitive functions. This complicated investigation will be approached from multiple points of view, showcasing the complex confluence between development and mental processes.",
            "Unit 7: Motivation, Emotion, and Personality ",
            "Within this unit you will begin to study and comprehend personality through the unique perspectives of behavior and cognitive processes, unraveling the complicated system that gives an individual their distinctive character. Additionally, this unit will investigate the realms of motivation and emotion, examining their significant roles in shaping human experiences and actions.",
            "Unit 8: Clinical Psychology ",
            "In this unit, you will go deeper into the subjects of clinical psychology, gaining insights into the methodologies employed by psychologists to assess, investigate, and provide treatment for a multitude of psychological disorders.",
            "Module 9: Social Psychology ",
            "Within this unit, you will explore the world of social psychology, discovering the intricate dynamics of human interactions within groups and social contexts. Additionally, you will unravel the ways in which external influences shape an individual's behavior and cognitive processes.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Psychology study guides. These channels can be a great way to get a better grasp of AP Psychology. These videos do an informative breakdown and explain the intricacies that are the units of AP psychology, including breakdowns of biological behavior, cognitive psychology, developmental psychology, clinical psychology and social psychology. \n",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Psychology review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Psychology notes, and discuss any tricky problems with fellow AP Psychology students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Psychology explores the human mind, our cognitive processes, and evaluates all the intricacies that make up an individual's personality. This course delves into the nature versus nurture that lends to our upbringings and our characteristics. Within AP Psychology you will gain an innate understanding of why people react and feel the way they do. On this page, you’ll find AP Psychology resources to help you with your AP Psychology review.",
    ],
    "AP United States Government and Politics": [
        "The exam format includes 55 multiple-choice questions, three short answer questions, and two free-response questions. Students are allotted 55 minutes to complete the multiple-choice section, 40 minutes for the short answer section, and 1 hour and 40 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP US Government and Politics study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Throughout this course, you will actively immerse yourself in the study of the U.S. Constitution and related documents, honing your skills in reading, analysis, and discussion. Additionally, you will have the opportunity to undertake a research or applied civics project, allowing you to apply your understanding in a practical context. This approach ensures a rich understanding of the American political landscape, fostering both academic growth and real-world application. Once you take a look through the breakdown below, make sure to read through the AP US Government and Politics study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Foundations of American Democracy",
            "In the beginning of this course you will embark on a journey to uncover how the framers of the U.S. Constitution meticulously established a durable governmental framework designed to withstand the trials of history. Delve into the intricate compromises that shaped this foundation, leaving certain matters unresolved, which persist as subjects of ongoing contemporary debates.",
            "Unit 2: Interactions Among Government Branches",
            "In unit 2 you will continue your exploration as you delve further into how the government formulates and implements policies. Gain insights into the intricate complexities that characterize this multifaceted process.",
            "Unit 3: Civil Liberties and Civil Rights",
            "The content of unit 3 includes the forging of connections between your understanding of the foundational principles of our government and ongoing debates surrounding the optimal equilibrium between individual freedom and societal order.",
            "Unit 4: American Political Ideologies and Belief Systems",
            "In this unit you will begin an exploration of the diverse ideologies that shape the perspectives of U.S. citizens regarding government. Investigate the factors influencing the formation of these beliefs and their impact on the preferences citizens hold for specific policies.",
            "Unit 5: Engaging in Political Participation",
            "Finally, in this unit you will discover the myriad avenues through which U.S. citizens exert their influence on government decisions in this unit. Explore the multifaceted ways individuals can actively shape the political landscape.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP US Government and Politics study guides. These channels can be a great way to get a better understanding of the essential components and values of the U.S. political system and culture. Analyze foundational documents like the U.S. Constitution and engage in discussions. Conclude with a research or civics project to apply your learning practically.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP US Government and Politics review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP US Government and Politics notes, and discuss any tricky problems with fellow AP US Government and Politics students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP US Government and Politics explores the core elements and societal values of the U.S. political system and culture. Engage in analysis of pivotal documents such as the U.S. Constitution and participate in meaningful discussions. Conclude your studies with a hands-on research or civics project, translating your knowledge into practical application. On this page, you’ll find AP US Government and Politics resources to help you with your AP US Government and Politics review.",
    ],
    "AP United States History": [
        "The exam format includes 55 multiple-choice questions, three short answer questions, and two free-response questions. Students are allotted 55 minutes to complete the multiple-choice section, 40 minutes for the short answer section, and 1 hour and 40 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free APUSH study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown above, make sure to read through the AP US History study guide above with all the key points you should know for each unit.\n",
        [
            "Unit 1: Period 1: 1491–1607",
            "In this unit, you will learn about Native American societies as well as how and why Europeans first explored, and then began to colonize, the Americas. This will cover how Native American societies were before European contact, European exploration in the New World as well as the Columbian Exchange. You will also become familiar with labor, slavery and caste in the Spanish colonial system and cultural interactions between Europeans, Native Americans and Africans.",
            "Unit 2: Period 2: 1607–1754",
            "You'll study the colonies established in the New World by the Spanish, French, Dutch, and British. You’ll also explore how different European colonies were developed and expanded, what happened in the Transatlantic trade, interactions between American Indians and Europeans, slavery in the British colonies and colonial society and culture.",
            "Unit 3: Period 3: 1754–1800",
            "You'll explore the events that led to the American Revolution and the formation of the United States and examine the early years of the republic. This unit explores what happened during the Seven Years’ War, the American Revolution, the Articles of Confederation, the creation and ratification of the Constitution Developing an American identity and immigration to and migration within America.",
            "Unit 4: Period 4: 1800–1848",
            "You’ll examine how the young nation developed politically, culturally, and economically in this period. During this time, you will learn about the rise of political parties, American foreign policy, innovations in technology, agriculture, and business Debates about federal power, the Second Great Awakening, reform movements and better understand the experience of African Americans.",
            "Unit 5: Period 5: 1844–1877",
            "You’ll learn how the nation expanded and you’ll explore the events that led to the secession of Southern states and the Civil War. This unit explores Manifest Destiny, the Mexican–American War, attempts to resolve conflicts over the spread of slavery The election of 1860 and Southern secession, the Civil War and reconstruction.",
            "Unit 6: Period 6: 1865–1898",
            'You’ll examine the nation’s economic and demographic shifts in this period and their links to cultural and political changes. This unit will make you familiar with the settlement of the West, the "New South", the rise of industrial capitalism Immigration and migration Reform movements, and debates about the role of government.',
            "Unit 7: Period 7: 1890–1945",
            "You’ll examine America’s changing society and culture and the causes and effects of the global wars and economic meltdown of this period. This unit focuses on debates over imperialism, the Progressive movement, World War I, innovations in communications and technology in the 1920s, the Great Depression, the New Deal, World War II and Postwar diplomacy.",
            "Unit 8: Period 8: 1945–1980",
            "You’ll learn about the rivalry between the Soviet Union and the United States, the growth of various civil rights movements, and the economic, cultural, and political transformations of this period. This will include the Cold War and the Red Scare, America as a world power, the Vietnam War, the Great Society, the African American civil rights movement and Youth culture of the 1960s.",
            "Unit 9: Period 9: 1980–Present",
            "You’ll learn about the advance of political conservatism, developments in science and technology, and demographic shifts that had major cultural and political consequences in this period. In this unit, you will learn about Reagan and conservatism, the end of the Cold War, shifts in the economy, migration and immigration and challenges of the 21st century.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP United States History study guides. These channels can be a great way to get a better understanding of United States history in the context of the World Wars, European colonization of America, the expansion of the West, and major shifts in the treatments of various groups within the United States.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of APUSH review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP US History notes, and discuss any tricky problems with fellow AP US History students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP United States History explores history in the context of cultural, economic, political, and social developments that have shaped the United States from c. 1491 to the present. On this page, you’ll find AP United States History resources to help you with your APUSH review.",
    ],
    "AP World History: Modern": [
        "The exam format includes 55 multiple-choice questions, three short answer questions, and two free-response questions. Students are allotted 55 minutes to complete the multiple-choice section, 40 minutes for the short answer section, and 1 hour and 40 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP World History study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP World History study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: The Global Tapestry (1200-1450)",
            "You'll explore how states formed, expanded, and declined in areas of the world during the period c. 1200–c. 1450 and the related political, social, and cultural developments of that time. You’ll also learn about states in Africa, Afro-Eurasia, East Asia, Europe, South and Southeast Asia and the Americas, as well as Global and regional religions and belief systems.",
            "Unit 2 : Networks of Exchange",
            "In this unit, you’ll learn how areas of the world were linked through trade and how these connections affected people, cultures, and environments. This will cover the Silk Roads, the Mongol Empire, the Indian Ocean trading network, the trans Saharan trade routes and the effects of cross cultural interactions.",
            "Unit 3 : Land Based Empires",
            "You'll begin your study of the period c. 1450–c. 1750 with an exploration of the empires that held power over large contiguous areas of land. This will include the development of the Manchu, Mughal, Ottoman, and Safavid empires, as well as how the rulers of each empire maintained their powder and religious developments in each of the empires.",
            "Unit 4: Transoceanic Interconnections",
            "In this unit, you’ll learn about advances in ocean exploration, the development of new maritime empires, and the effects of new cross-cultural encounters. This will cover the influence of scientific learning and technological innovation, The Columbian Exchange, the development and expansion of maritime empires, internal and external challenges to state power and changes to social hierarchies linked to the spread of empires.",
            "Unit 5 : Revolutions",
            "You’ll start your study of the period c. 1750–c. 1900 by exploring the new political ideas and developments in technology that led to large-scale changes in governments, society, and economies. This covers the Enlightenment, revolutions against existing governments and the birth of new nation-states, the Industrial Revolution, trade policies and the development of industrial economies.",
            "Unit 6 : Consequences of Industrialization",
            "You'll continue to investigate the period c. 1750–c. 1900 and learn how the different states acquired and expanded control over colonies and territories. This will also cover state expansion in the 18th and 19th centuries, resistance to imperialism, the growth of the global economy, economic imperialism, as well as the causes and effects of next migration patterns.",
            "Unit 7 : Global Conflict",
            "You'll begin your study of the period c. 1900–present by learning about the global conflicts that dominated this era. In this unit, you will study the changes in global political order after 1900, World War 1 (causes and how it was fought), the interwar period, world war 2 and mass atrocities after 1900.",
            "Unit 8 : Cold War and Decolonization",
            "As you continue exploring the period c. 1900–present, you’ll learn about colonies’ pursuits of independence and the global power struggle between capitalism and communism. This will cover the causes and effects of the Cold War, spread of communism, a look at how colonies in Asia and Africa achieved independence, the creation of new states after decolonization and the end of the Cold War.",
            "Unit 9 : Globalization",
            "You'll continue your study of the period c. 1900–present by investigating the causes and effects of the unprecedented connectivity of the modern world. In this unit, you will study advances in technology and their effects, disease, environment, economic change, movements for reform, how globalization changed culture and new international institutions.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP World History study guides. These channels can be a great way to get a better understanding of world history in the context of humans and the environment, cultural developments and interactions, governance, economic systems, social interactions and organization, and technology and innovation.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP World History review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP World History notes, and discuss any tricky problems with fellow AP World History students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP World History explores world history in the context of humans and the environment, cultural developments and interactions, governance, economic systems, social interactions and organization, and technology and innovation.. On this page, you’ll find AP World History resources to help you with your AP World History review.",
    ],
    "AP African American Studies": ["", "", [""], "", "", ""],
    "AP Biology": [
        "The exam format includes 60 multiple-choice questions, and 6 free-response questions. Both the multiple choice section and the free response questions take up 50% of the exam's weight. Students are allotted 1 hour and 30 minutes to complete the multiple-choice section, and 1 hour and 30 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Biology study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. This class will involve immersive laboratory experiences, where you will actively investigate and analyze natural phenomena firsthand. Once you take a look through the breakdown below, make sure to read through the AP Biology study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: The Biochemistry of Existence",
            "During this unit, you will begin an exploration into the crucial role of water as the foundation of life, as well as begin an understanding of the roles and functions of macromolecules such as lipids and proteins.",
            "Unit 2: Exploring Cellular Structure and Function, and the Fundamentals of Evolution",
            "In this unit, you will begin a comprehensive study of cellular composition, diving deep into the architecture that defines cells. Additionally, you will also immerse yourself in the fundamental concepts of evolution, unraveling the mechanisms that makeup the diversity and development of life forms over time.",
            "Unit 3: Investigating Cellular Energetics and Interactions with the Environment",
            "Within this unit, you will begin to investigate the study of cellular energetics, diving into understanding the mechanisms that govern how cells harness and utilize energy. Additionally, you will begin to understand how cells interact in tandem with their environment, understanding the biological processes that operate at the cellular level.",
            "Unit 4: Cellular Communication, Growth, and Reproduction",
            "In this unit, you will gain an in-depth understanding of the processes that control cell growth and reproduction. Also, you will understand the complex world of cellular communication, unraveling the mechanisms through which cells interact and coordinate their activities.",
            "Unit 5: Exploring Heredity and the Transmission of Traits across Generations",
            "During this unit, you will delve into the complex mechanisms that control the passage of traits from one generation to the next. Through a well rounded exploration, you will gain a profound understanding of how genetic information shapes the characteristics of living organisms over time.",
            "Unit 6: Investigating Gene Expression, Transmission, and Regulation of Hereditary Information",
            "Within this unit, you will engage in an in-depth study of the processes through which hereditary information is transmitted from parents to offspring. Additionally, you will begin to explore the world of trait expression, unraveling the mechanisms that govern how genetic traits manifest in living organisms.",
            "Unit 7: Natural Selection",
            "In this unit, you will dive into the comprehensive study of natural selection, immersing yourself in the intricacies of Darwin's groundbreaking theory. Additionally, you will gain a profound understanding of the concept of evolution, unraveling the mechanisms through which species adapt and change over time.",
            "Unit 8: Ecology ",
            "Within this unit, you will embark on an extensive exploration of ecological principles, expanding your understanding of biological concepts beyond the cellular level. You will gain insights into the intricate interactions that shape populations within ecosystems, contributing to your comprehension of the delicate balance of life within these intricate biological communities.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Biology study guides. These channels can be a great way to get a better understanding of the Biological environment, cell exploration and understanding, as well as the comprehension of hereditary mutations and adaptations, as well as natural selection and ecology.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Biology review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Biology notes, and discuss any tricky problems with fellow AP Biology students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Biology is an exploration and Comprehension of the Biological Environment, Cellular Dynamics, Hereditary Mutations, Adaptations, Natural Selection, and Ecology Understanding. On this page, you’ll find AP Biology resources to help you with your AP Biology review.",
    ],
    "AP Chemistry": [
        "The exam format includes 60 multiple-choice questions, and 7 free-response questions. The exam weight is broken down 50% for the multiple choice section and 50% for the FRQ. Students are allotted 1 hour and 30 minutes to complete the multiple-choice section, and 1 hour and 45 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Chemistry study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In this course you will participate in practical laboratory investigations to gain a firsthand experience, and utilize chemical calculations to solve a range of problems. Once you take a look through the breakdown below, make sure to read through the AP Chemistry study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Atomic Structures and Properties ",
            "In this unit, you will begin to understand the complex makeup of atoms and acquire an understanding of the methodologies scientists use to quantify and classify these fundamental molecular properties",
            " Unit 2: Molecular and Ionic Compound Structure and Properties",
            "Within this unit, you will embark on a journey of discovery into the realm of chemical bonding, encompassing a diverse spectrum of bonds. Furthermore, you will uncover how the structural arrangements of these bonds influence the inherent properties of the molecules that come out of this process.",
            "Unit 3 : Intermolecular Forces and Properties ",
            "In this unit, you will engage in an exploration of the united forces that assemble atoms into the varied states of matter: solids, liquids, and gasses. Additionally, you will explore the extreme influence of subatomic forces that dictate the diverse properties exhibited by the elements and compounds that surround you.",
            "Unit 4: Chemical Reactions",
            "Within this unit, you will gain the skills to understand the difference between physical and chemical processes, as well as acquire the knowledge to quantitatively represent and communicate chemical reactions through the use of chemical equations.",
            "Unit 5: Kinetics",
            "During this unit, you will embark on an exploration to discover the various techniques to observe and analyze the many changes taking place within chemical reactions. Furthermore, you will delve into the consequences of sequences of reactions, gaining insights into the complex effects that unfold within these processes.",
            "Unit 6: Thermodynamics",
            "In this unit, you will begin to gain knowledge regarding the changes in energy that occur during chemical reactions, as well as gain an understanding of how the exchange of energy can induce transformations in the physical attributes of substances.",
            "Unit 7: Equilibrium",
            "Within this unit, you will map out the vaste evolution of chemical reactions, investigating the factors that lead substances to attain a state of equilibrium. Furthermore, you will dive into the complex responses exhibited by systems when this equilibrium is perturbed or disrupted.",
            "Unit 8: Acids and Bases",
            "In this unit, you will investigate further into the realm of pH, immersing yourself in the characteristics and attributes of both acids and bases. Through comprehensive exploration, you will gain an understanding of how these fundamental substances interact within chemical reactions.",
            "Unit 9: Applications of Thermodynamics",
            'During this unit, you will become acquainted with the notion of "thermodynamic favorability" concerning reactions, which entails assessing their likelihood to alter or change based on energy variations and environmental conditions.',
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Chemistry study guides. These channels can be a great way to get a better understanding of the world around us on a molecular level, what this course does is breakdown complex aspects of the molecular world around us and showcase not only what it is comprised of but what can occur in the world to change or alter even the smallest aspects of the world. ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Chemistry review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Chemistry notes, and discuss any tricky problems with fellow AP Chemistry students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Chemistry explores a deeper comprehension of the intricate molecular realm that surrounds us. This course aims to deconstruct the elements of the molecular world, revealing not only their constitutive components but also shedding light on the potential alterations and transformations that can occur, even within the smallest aspects of our environment. On this page, you’ll find AP Chemistry resources to help you with your AP Chemistry review.",
    ],
    "AP Environmental Science": [
        "The exam format includes 80 multiple-choice questions, and 3 free response questions. The multiple choice section is weighted to be 60% of your total grade while the free response questions will weigh to be 40% of your final grade. Students are allotted 1 hour and 30 minutes to complete the multiple-choice section, 1 hour and 10 minutes for the free response section. To make sure you’re prepared enough to finish in time, take a look through our free AP Environmental Science study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. Once you take a look through the breakdown below, make sure to read through the AP Environmental Science study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: The Living World: Ecosystems ",
            "In this module, you will begin exploring and understanding Earth as a whole, consisting of diverse regional ecosystems intricately woven together by interdependent environmental attributes, dynamic processes, and intricate species interactions.",
            "Unit 2: Biodiversity in the Living World",
            "In this unit, you will explore the significance of biodiversity in ecosystems and the effects of external factors on the development of organisms.",
            "Unit 3: Exploring Populations in Ecosystems",
            "During this unit, you will delve into the nature of populations within ecosystems, gaining insights into the processes that drive their evolution over time. By examining the interaction of various elements, you will uncover a comprehensive understanding of the many factors that influence the growth and fluctuations of populations within these biological systems.",
            "Unit 4: Understanding Earth Systems and Resources",
            "In this unit, you will embark on a journey of exploration into the elemental aspects that make our environment. This encompasses a comprehensive investigation of geological attributes, atmospheric dynamics, and climatic patterns, providing you with a holistic comprehension of the intricate systems that shape our planet.",
            "Unit 5: Exploring Resource Utilization on Land and in Water",
            "Within this unit, you will explore an in-depth exploration of human interactions with natural resources, encompassing both utilization and consumption practices. By understanding the dynamics of these actions, you will gain insights into the diverse ways in which ecosystems are impacted, ranging from constructive to detrimental effects, as a result of human influence.",
            "Unit 6: Energy Resources and Utilization",
            "In this unit, you will learn about both renewable and nonrenewable energy sources. This will encompass a comprehensive exploration of these energy sources respective applications and geographical implementations, along with a thorough assessment of their ecological ramifications.",
            "Unit 7: Exploring Atmospheric Contamination",
            "Within this unit, you will expand your understanding of atmospheric pollution. This will include an exploration of the causal relationship between human activities and air pollution, alongside an in-depth analysis of the legislative measures designed to oversee emissions and enhance the overall quality of the air we breathe.",
            "Unit 8: Investigating Terrestrial and Aquatic Pollution",
            "In this unit, you will begin an exploration of the consequences of pollution on both terrestrial and aquatic ecosystems. Additionally, you will learn the skills necessary to understand the origins of pollution, empowering you to identify its sources within these intricate ecological systems.",
            "Unit 9: Grasping Global Transformation",
            "Within this unit, you will attain a comprehensive understanding of the worldwide consequences stemming from human activities on local and regional scales. Furthermore, you will participate in assessments and the formulation of potential remedies, thereby contributing to the endeavor of addressing these complex global challenges.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Environmental Science study guides. These channels can be a great way to get a better understanding of environmental science, and the human impact that we as a population are challenged with remedying ",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Environmental Science review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Environmental Science notes, and discuss any tricky problems with fellow AP Environmental Science students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP  Environmental Science explores Environmental Science through various lens including Biodiversity, aquatic and terrestrial life, as well as expanding in energy resources, Environmental Science gives you an understanding of past, present, and future dilemmas that we as a community will be facing and challenges you to find a means to help aid in an ongoing Earth crisis.  On this page, you’ll find AP Environmental Science resources to help you with your AP Environmental Science review.",
    ],
    "AP Physics 1: Algebra-Based": [
        "The exam format includes 50 multiple-choice questions, and 5 free-response questions. The exam weight is broken down so the multiple choice question section is worth 50% of the exam score, and the other 50% comes directly from the FRQs. Students are allotted 1 hour and 30 minutes to complete the multiple-choice section, and 1 hour and 30 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Physics 1: Algebra-Based study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. To prepare for this exam, you will also engage in practical laboratory work to actively investigate various phenomena. Once you take a look through the breakdown below, make sure to read through the AP Physics 1: Algebra-Based study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Exploring Kinematics ",
            "Embark on a captivating journey as you step into Unit 1, where you will initiate your exploration into the fascinating realm of kinematics, the scientific investigation of motion. Throughout this unit, you will delve into the intricate intricacies of objects in motion, uncovering the fundamental principles that govern their trajectories and velocities. Prepare to unravel the secrets of how things move and lay the groundwork for a comprehensive understanding of the captivating field of kinematics.",
            "Unit 2: Dynamics",
            "In Unit 2, we revisit the subjects covered in Unit 1's by reevaluating concepts to delve into dynamics, focusing on force as the influential interaction between objects.",
            "Unit 3: Exploring Circular Motion and Gravitation",
            "In this unit you will be expanding upon your comprehension of motion and force, this unit delves into more intricate motion models, exemplified by the orbital movement of a satellite around a planet.",
            "Unit 4: Energy",
            "In this unit, you will grasp the meanings of energy, work, and power, along with their interconnectedness and correlations.",
            "Unit 5: Momentum",
            "During this unit, you will delve into the connections among force, time, and momentum. Additionally, you will acquire the skills to apply the principle of momentum conservation for the analysis of various physical scenarios.",
            "Unit 6: Simple Harmonic Motion ",
            "Applying the knowledge and techniques assimilated from earlier units, you will analyze a distinct motion pattern known as simple harmonic motion in this unit.",
            "Unit 7: Delving into Torque and Rotational Motion",
            "This unit will involve an in-depth examination of the dynamics of objects in rotational motion around an axis. Furthermore, you will delve into the concept of torque, which quantifies the force's ability to induce rotational movement.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Physics 1: Algebra-Based study guides. These channels can be a great way to get a better understanding of the core principles of physics by delving into topics such as Newtonian mechanics, work, energy, power, mechanical waves, sound, and introductory concepts in simple circuits. Participate in hands-on laboratory experiments to proactively explore a range of phenomena.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Physics 1: Algebra-Based review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Physics 1: Algebra-Based notes, and discuss any tricky problems with fellow AP Physics 1: Algebra-Based students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Physics 1: Algebra-Based explores the foundational principles of physics through the examination of subjects like Newtonian mechanics, work, energy, power, mechanical waves, sound, and introductory concepts in simple circuits. Immerse yourself in practical laboratory work that enables the active exploration of diverse phenomena.On this page, you’ll find AP  Physics 1: Algebra-Based resources to help you with your AP Physics 1: Algebra-Based review.",
    ],
    "AP Physics 2: Algebra-Based": [
        "The exam format includes 50 multiple-choice questions, and 4 free-response questions. The exam weight is split 50/50, meaning both the multiple choice and the FRQ section are weighted at 50%. Students are allotted 1 hour and 30 minutes to complete the multiple-choice section, and 1 hour and 30 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Physics 2: Algebra-Based study guide that covers the most important material you should know.\n",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up.  The topics covered include fluid mechanics, thermodynamics, the dynamics of electric force, fields, and potential, the intricacies of electric circuits, the phenomena of magnetism and electromagnetic induction, the realms of geometric and physical optics, and the depths of quantum, atomic, and nuclear physics. Once you take a look through the breakdown below, make sure to read through the AP Physics 2: Algebra-Based study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Fluids: Pressure and Forces Exploration",
            "In this unit, you will explore the attributes of fluids, discovering how the internal arrangement and interplay of particles within a fluid shape these distinct attributes.",
            "Unit 2: Thermodynamics ",
            "In this unit, you will engage in the study of heat, temperature, and thermal energy within various contexts, including their applications in heat engines, heat pumps, and refrigerators.",
            "Module 3: Electric Force, Field, and Potential",
            "In this unit you will begin on your exploration of electromagnetism as you acquaint yourself with essential principles like electric charge and the dynamics of electric forces in this unit.",
            "Module 4: Electrical Pathways - Understanding Electric Circuits",
            "In this unit you will begin building upon your understanding of charged particle behavior, this unit delves further into the components comprising a circuit and the route taken by an electric current.",
            "Unit 5: Magnetism and Electromagnetic Induction",
            "Expanding upon your grasp of electrostatic forces and fields, this unit delves into the intricate connections between the motion of electric charges—electric currents—and the magnetic forces and fields they give rise to.",
            "Unit 6: Geometric and Physical Optics ",
            "In this unit, you will be introduced to a diverse array of methodologies for conceptualizing and modeling electromagnetic waves, commonly referred to as light. Through this exploration, you will gain a comprehensive understanding of the multifaceted nature of optics and the various frameworks through which light phenomena can be comprehended and analyzed.",
            "Unit 7: Quantum, Atomic, and Nuclear Physics",
            "Embark on a journey into the realm of modern physics in this unit, where you will encounter groundbreaking concepts that unveil a new understanding of the physical world. Delve into the intricacies of quantum, atomic, and nuclear physics as you explore revolutionary models that not only address the unresolved queries of Newtonian physics but also shed light on previously elusive mysteries. ",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Physics 2: Algebra-Based study guides. These channels can be a great way to get a better understanding of physics by delving into topics like fluids, thermodynamics, electric and magnetic forces, circuits, optics, and quantum physics. Participate in hands-on activities and lab work for practical exploration of these phenomena, promoting a deeper understanding of the natural world.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Physics 2: Algebra-Based review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Physics 2: Algebra-Based notes, and discuss any tricky problems with fellow AP Physics 2: Algebra-Based students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Physics 2: Algebra-Based explores your physics comprehension by exploring a wide array of subjects including fluids, thermodynamics, electric forces and circuits, magnetism, optics, and quantum physics. Hands-on activities and laboratory investigations will further your practical understanding of these phenomena, fostering a more profound insight into the workings of the natural world.On this page, you’ll find AP Physics 2: Algebra-Based resources to help you with your AP Physics 2: Algebra-Based review.",
    ],
    "AP Physics C: Electricity and Magnetism": [
        "The exam format includes 35 multiple-choice questions, and 3 free-response questions. The questions sections are weighted equally, so the multiple choice section has an exam weight of 50% and the FRQ section is the weight of the other 50%. Students are allotted 45 minutes to complete the multiple-choice section, and 45 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Physics C: Electricity and Magnetism study guide that covers the most important material you should know.\n",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In class, you will engage in hands-on labs and classroom activities to investigate these phenomena, applying calculus to problem-solving. Once you take a look through the breakdown below, make sure to read through the AP Physics C: Electricity and Magnetism study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Introduction to Electrostatics",
            "Begin the course by diving into the realm of electric forces as you initiate your exploration with a focus on electric charges.",
            "Unit 2: Study of Conductors, Capacitors, and Dielectrics",
            "In this unit, you will begin an exploration into the realm of electric charge mobility within objects and examine the determinants influencing the dynamics of charge movement.",
            "Unit 3: Unraveling Electric Circuits",
            "In this unit you will take another step in the fundamentals of Physics, expand upon your comprehension of electrical elements as you delve into the intricacies of electric circuits. Explore the characteristics of current, resistance, and power within this domain.",
            "Unit 4: Magnetic Fields",
            "In this unit you will shift slightly and veer into the realm of magnetism as you initiate your exploration by grasping the generation, behavior, and intricate relationship of magnetic fields with electricity.",
            "Unit 5: Electromagnetism",
            "In this unit you will expand upon your foundational understanding of charges, currents, electric fields, and magnetic fields as you delve into the domain of electromagnetic forces. Explore the unique properties and dynamics inherent in these forces.",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Physics C: Electricity and Magnetism study guides. These channels can be a great way to get a better understanding of fundamental topics such as electrostatics, conductors, capacitors, dielectrics, electric circuits, magnetic fields, and electromagnetism. Experience practical learning through hands-on labs and interactive classroom activities, while employing calculus to solve intricate problems.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Physics C: Electricity and Magnetism review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Physics C: Electricity and Magnetism notes, and discuss any tricky problems with fellow AP Physics C: Electricity and Magnetism students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Physics C: Electricity and Magnetism explores essential subjects like electrostatics, conductors, capacitors, dielectrics, electric circuits, magnetic fields, and electromagnetism. Engage in hands-on laboratory work and class activities to actively investigate these phenomena, using calculus to tackle problem-solving challenges.On this page, you’ll find AP Physics C: Electricity and Magnetism resources to help you with your AP Physics C: Electricity and Magnetism review.",
    ],
    "AP Physics C: Mechanics": [
        "The exam format includes 35 multiple-choice questions, and 3 free-response questions. The questions sections are weighted equally, so the multiple choice section has an exam weight of 50% and the FRQ section is the weight of the other 50%. Students are allotted 45 minutes to complete the multiple-choice section, and 45 minutes to compose their responses to the free response questions. To make sure you’re prepared enough to finish in time, take a look through our free AP Physics C: Mechanics study guide that covers the most important material you should know.",
        "You’ve likely covered a lot of material during your course this year, but to get a 5 on the AP exam, it’s important you understand how often each topic shows up. In this class, you will engage in immersive hands-on laboratory experiments and dynamic in-class activities to delve into these phenomena actively. Employ the power of calculus to solve intricate problems, bridging theoretical understanding with practical application. Once you take a look through the breakdown below, make sure to read through the AP Physics C: Mechanics study guide above with all the key points you should know for each unit.",
        [
            "Unit 1: Embarking on Kinematics",
            "Your exploration into the realm of motion begins here, as you delve into the fundamental aspects of object movement: position, velocity, acceleration, and the dimension of time.",
            "Unit 2: Unveiling Newton's Laws of Motion",
            "Embark on an exploration of Newton's laws of motion, which unveil the intricate connections between moving objects and the dynamic forces influencing their behavior.",
            "Unit 3: Exploring Work, Energy, and Power",
            "Embark on a journey to define and compute work, energy, and power. Acquaint yourself with the principle of conservation, a cornerstone concept in physics, as you delve into this foundational unit.",
            "Unit 4: Grasping Systems of Particles and Linear Momentum",
            "In this unit, you'll be introduced to essential notions like the center of mass, impulse and momentum, and the principle of conservation of linear momentum.",
            "Unit 5: Exploring Rotation",
            "Dive into the world of rotational motion, as you delve deeply into topics such as torque and rotational statics, kinematics, and dynamics, enhancing your understanding of this fascinating domain.",
            "Unit 6: Exploring Oscillations and Harmonic Motion",
            "Delve into the captivating world of oscillations in this unit. Investigate the fundamental forces that orchestrate objects into rhythmic, repetitive patterns of motion. Through the study of harmonic motion, uncover the delicate interplay between restoring forces, equilibrium positions, and periodicity. Gain insights into the prevalence of oscillations in both natural phenomena and engineered systems. This exploration combines theoretical understanding, practical experimentation, and conceptual analysis to enhance your appreciation and comprehension of oscillatory motion.",
            "Unit 7: Unveiling Gravitation",
            "In this unit you will begin an exploration of gravitational forces, where you will unravel the intricate dynamics governing the connections between celestial bodies such as planets, satellites, and their elliptical orbits.",
            "",
        ],
        "We’ve handpicked some of our favorite youtube channels and videos that align with the key topics and themes covered in our AP Physics C: Mechanics study guides. These channels can be a great way to get a better understanding of fundamental principles encompassing kinematics, Newton's laws of motion, work, energy, power, systems of particles, linear momentum, rotation, oscillations, and gravitation. Immerse yourself in hands-on laboratory work and dynamic in-class activities, investigating these phenomena firsthand. Utilize the calculus toolkit to tackle complex problems, connecting theory to real-world problem-solving.",
        "Connect with like-minded students who are also preparing for the exam and delve into the world of AP Physics C: Mechanics review together. By joining our Discord community, you can collaborate, exchange questions, discuss AP Physics C: Mechanics notes, and discuss any tricky problems with fellow AP Physics C: Mechanics students. Together, you can better understand challenging concepts, share helpful resources, and support each other on your way to getting a 5!",
        "AP Physics C: Mechanics explores essential concepts such as kinematics, Newton's laws of motion, work, energy, power, systems of particles, linear momentum, rotation, oscillations, and gravitation. Engage in immersive hands-on laboratory experiences and dynamic in-class activities that allow you to explore these phenomena directly. Master the use of calculus as a powerful tool to tackle intricate challenges, bridging theory with practical problem-solving in a dynamic and engaging manner. On this page, you’ll find AP Physics C: Mechanics resources to help you with your AP Physics C: Mechanics review.\n",
    ],
};

// turn the 2nd index into an array of strings, split by /n
