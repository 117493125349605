import { ACCENT_COLOR_VAR } from "../utils";
import { LandingCenteredHalfSection } from "./styled/Containers";
import { H3 } from "./styled/Headings";
import { H3Paragraph } from "./styled/Paragraphs";
import { themeColors } from "@/utils/themeColors";
import { Flex, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import QuoteIcon from "@/components/QuoteIcon";

const BlockQuote = ({
    title,
    desc,
    quoteColor,
    style,
}: {
    title: React.ReactNode;
    desc: React.ReactNode;
    quoteColor: string;
    style?: React.CSSProperties;
}) => {
    return (
        <LandingCenteredHalfSection style={{ width: "100%" }}>
            <FlexColumnAlignJustifyCenter
                style={{
                    position: "relative",
                    padding: "4.8rem 8rem",
                    borderRadius: "4rem",
                    backgroundColor: ACCENT_COLOR_VAR,
                    width: "100%",
                    textAlign: "center",
                    ...style,
                }}>
                <Flex style={{ gap: "2.2rem" }}>
                    <QuoteIcon fill={quoteColor} color={quoteColor} opening />
                    <H3 style={{ fontSize: "2.8rem", position: "relative", textAlign: "center", lineHeight: "3.8rem" }}>
                        {title}
                    </H3>
                    <QuoteIcon fill={quoteColor} color={quoteColor} closing />
                </Flex>
                <H3Paragraph style={{ fontSize: "2rem", color: themeColors.neutral3, lineHeight: "3.2rem" }}>
                    {desc}
                </H3Paragraph>
            </FlexColumnAlignJustifyCenter>
        </LandingCenteredHalfSection>
    );
};

export default BlockQuote;
