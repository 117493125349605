"use client";

import { EditorContent } from "@knowt/editor/react";
import { useEditorContextSelector } from "@/components/FullPageEditor/EditorContext";
import SlashMenu from "@/components/FullPageEditor/SlashMenu/SlashMenu";
import noop from "@/utils/noop";
import SelectionMenu from "@/components/FullPageEditor/SelectionMenu";
import LinkEditorMenu from "@/components/FullPageEditor/LinkEditorMenu";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { Flex, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import importFromDrive, { DriveImportType } from "@/utils/Google/Picker";
import localKeyValueStore from "@/utils/LocalKeyValueStore";
import { TIME } from "@/utils/dateTimeUtils";
import { AuthEvent, useAuthPopupContextSelector } from "@/features/Auth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { createAndOpenNewNote } from "@/utils/navigation/notes";
import { useRouter } from "next13-progressbar";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { convertBlobToNote } from "@/utils/importFile";
import { fetchFileBlob } from "@/utils/fileUtils";
import { fileTypeFromMimeType } from "@/utils/fileTypeUtils";
import { Waveform } from "@uiball/loaders";
import Image from "next/image";
import { UploadIcon } from "lucide-react";
import { spacing } from "@/utils/spacing";
import br from "@/styles/breakpoints.module.css";

import "@/components/FullPageEditor/richEditor.css";
import "katex/dist/katex.min.css";
import styles from "./styles.module.css";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { LandingPageNoteData } from "@knowt/syncing/constants/storage";

const Editor = () => {
    const router = useRouter();

    const { isDarkMode } = useTheme();

    const { user } = useCurrentUser();
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);

    const editor = useEditorContextSelector(context => context.editor);
    const slashMenuProps = useEditorContextSelector(context => context.slashMenuProps);
    const slashMenuRef = useEditorContextSelector(context => context.slashMenuRef);
    const handleOpenAiInputMenu = useEditorContextSelector(context => context.handleOpenAiInputMenu);

    const [isImportingFromDrive, setIsImportingFromDrive] = useState(false);
    const [isImportingFromFile, setIsImportingFromFile] = useState(false);

    const startNonAuthNoteFlow = async ({ title, content }: { title?: string; content: string }) => {
        // to avoid using search params for static rendering
        const folderId = new URLSearchParams(window.location.search).get("folderId");

        await localKeyValueStore.setWithExpiry(
            STORAGE_KEYS.LANDING_PAGE_NOTE_DATA,
            {
                title,
                content,
                ...(folderId && { folderId }),
            } as LandingPageNoteData,
            TIME.HOUR
        );
        await localKeyValueStore.setWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS, true, TIME.MINUTE * 15);

        openAuthPopup({
            event: AuthEvent.LANDING_UPLOAD_NOTE,
            title: "Sign up for free to access your note & the flashcards we made from it",
            description: "Take a few seconds, and save hours of your day -- every day. ",
        });
    };

    const handleNoteCreation = async ({ title, content }: { title?: string; content: string }) => {
        if (user) await createAndOpenNewNote({ content, title }, user, router, { autoCreateFlashcards: true });
        else await startNonAuthNoteFlow({ content, title });
    };

    const onSelectFileAccepted = async (files: File[]) => {
        const file = files[0];
        setIsImportingFromFile(true);

        const { content } = await convertBlobToNote({
            blob: await fetchFileBlob(file),
            fileType: fileTypeFromMimeType(file.type),
        });

        await handleNoteCreation({ content, title: file.name });
        setIsImportingFromFile(false);
    };

    const onSelectFileRejected = (fileRejections: FileRejection[]) => {
        const { file, errors } = fileRejections[0];

        for (const error of errors) {
            handleError(error, file);
        }

        function handleError(error: FileRejection["errors"][0], file: File) {
            if (error.code === ErrorCode.FileInvalidType) {
                toast.error(`File type ${file.type} is not supported`);
            } else if (error.code === ErrorCode.FileTooLarge) {
                toast.error(`File is too large`);
            } else if (error.code === ErrorCode.FileTooSmall) {
                toast.error(`File is too small`);
            } else if (error.code === ErrorCode.TooManyFiles) {
                toast.error(`Too many files, max number of files is ${file.size}`);
            } else {
                toast.error("Error uploading file! Please try again");
            }
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "text/plain": [".txt"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        },
        multiple: false,
        onDropAccepted: onSelectFileAccepted,
        onDropRejected: onSelectFileRejected,
    });

    return (
        <div style={{ position: "relative", display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
            <div
                style={{
                    backgroundColor: themeColors.background,
                    borderRadius: 20,
                    overflow: "hidden",
                }}>
                <div
                    className={"scrollbar"}
                    style={{ paddingInline: "2.4rem", paddingBlock: "3rem 2.4rem", height: "25rem", overflow: "auto" }}>
                    <EditorContent
                        editor={editor}
                        style={{ width: "100%", height: "100%", flex: 1 }}
                        className={styles.editor}
                    />
                </div>
            </div>
            <FlexRowAlignCenter
                className={br.mdDownColumn}
                style={{ flexDirection: "row-reverse", justifyContent: "space-between", rowGap: spacing.XS }}>
                <div>{editor ? editor.storage.characterCount.characters() : 0}/10,000 characters</div>
                <FlexRowAlignCenter style={{ columnGap: spacing.XS_2 }}>
                    <CircularRectTextButton
                        {...getRootProps()}
                        sx={{
                            height: "4.4rem",
                            alignSelf: "stretch",
                            display: "flex",
                            alignItems: "center",
                            columnGap: spacing.XS,
                            backgroundColor: themeColors.background,
                            paddingInline: spacing.MD,
                            borderRadius: "1rem",
                        }}>
                        <input {...getInputProps()} />
                        {isImportingFromFile ? (
                            <Waveform size={20} color={themeColors.neutralBlack} />
                        ) : (
                            <>
                                <UploadIcon size={16} />
                                select file
                            </>
                        )}
                    </CircularRectTextButton>
                    <CircularRectTextButton
                        sx={{
                            height: "4.4rem",
                            alignSelf: "stretch",
                            display: "flex",
                            alignItems: "center",
                            columnGap: spacing.XS,
                            backgroundColor: themeColors.background,
                            paddingInline: spacing.MD,
                            borderRadius: "1rem",
                        }}
                        onClick={() => {
                            importFromDrive(
                                ({ title, content }) => handleNoteCreation({ title, content }),
                                async () => noop(),
                                setIsImportingFromDrive,
                                DriveImportType.DOCS
                            );
                        }}>
                        {isImportingFromDrive ? (
                            <Waveform size={20} color={themeColors.neutralBlack} />
                        ) : (
                            <>
                                <Image src="/images/drive.svg" alt="Import from google drive" width={16} height={16} />
                                upload from drive
                            </>
                        )}
                    </CircularRectTextButton>
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
            <Flex style={{ width: "100%", justifyContent: "center" }}>
                <CircularRectTextButton
                    sx={{
                        backgroundColor: "#50d2c2",
                        color: themeColors.primaryDark,
                        paddingBlock: spacing.SM,
                        paddingInline: spacing.LG,
                        borderRadius: "4rem",
                        fontSize: "1.5rem",
                    }}
                    onClick={async () => {
                        await handleNoteCreation({ content: editor?.getHTML() });
                    }}>
                    create note & flashcards
                </CircularRectTextButton>
            </Flex>
            {editor && (
                <SlashMenu
                    ref={slashMenuRef}
                    command={slashMenuProps.command ?? noop}
                    isActive={slashMenuProps.isOpen ?? false}
                    view={editor.view}
                    search={slashMenuProps.query ?? null}
                    groups={slashMenuProps.items ?? []}
                    clientRect={slashMenuProps.clientRect ?? null}
                    isDarkMode={isDarkMode}
                />
            )}
            {editor && (
                <SelectionMenu
                    editor={editor}
                    handleOpenAiInputMenu={handleOpenAiInputMenu}
                    showForTextSelection={true}
                />
            )}
            <LinkEditorMenu />
            <Image
                className={br.mdDownDisplayNone}
                src="/images/kai-waving.svg"
                alt="Kai is saying HI :)"
                width={250}
                height={250}
                style={{ position: "absolute", bottom: 50, right: -200 }}
            />
        </div>
    );
};

export default Editor;
