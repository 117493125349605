import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import FeaturesCard from "./FeaturesCard";
import { UserFeedback } from "./UserFeedbackList";
import br from "@/styles/breakpoints.module.css";
import clsx from "clsx";
import styles from "../landing.module.css";

type FeaturesWithFeedbacksProps = {
    content: {
        leftComponents: {
            title: string;
            desc: string;
            linkText?: string;
            link?: string;
            bottomChild?: React.ReactNode;
            src?: string;
        }[];
        rightComponent?: {
            title: string;
            desc: string;
            linkText: string;
            link: string;
            comingSoon?: boolean;
            backgroundColor?: string;
            bottomChild?: React.ReactNode;
        };
        feedbacks: {
            name: string;
            grade: string;
            desc: string;
            src: string;
        }[];
    };
    feedbacksOnLeft?: boolean;
};

const FeaturesWithFeedbacks = ({ content, feedbacksOnLeft }: FeaturesWithFeedbacksProps) => {
    const { leftComponents, rightComponent, feedbacks } = content;

    return (
        <FlexRowAlignCenter
            className={clsx(br.mdDownColumn, styles.featuresWithFeedbacksContainer)}
            style={{ alignItems: "stretch", width: "100%" }}>
            <FlexColumnAlignJustifyCenter style={{ gap: "2.4rem", flex: 2 }}>
                {leftComponents.map((leftComponent, index) => (
                    <FeaturesCard
                        key={index}
                        title={leftComponent.title}
                        desc={leftComponent.desc}
                        linkText={leftComponent.linkText}
                        link={leftComponent.link}
                        bottomChild={leftComponent.bottomChild}
                        containerStyle={{ paddingBottom: leftComponent.src ? "0rem" : "5rem" }}
                    />
                ))}
                {feedbacksOnLeft &&
                    feedbacks.map((feedback, index) => (
                        <UserFeedback
                            key={index}
                            name={feedback.name}
                            grade={feedback.grade}
                            desc={feedback.desc}
                            src={feedback.src}
                        />
                    ))}
            </FlexColumnAlignJustifyCenter>
            <FlexColumnAlignJustifyCenter style={{ gap: "2.4rem", flex: 1 }}>
                {rightComponent && (
                    <FeaturesCard
                        title={rightComponent.title}
                        desc={rightComponent.desc}
                        linkText={rightComponent.linkText}
                        link={rightComponent.link}
                        comingSoon={rightComponent.comingSoon}
                        bottomChild={rightComponent.bottomChild}
                        cardBackgroundColor={rightComponent.backgroundColor}
                        containerStyle={{ paddingBottom: "5rem", flex: "unset" }}
                    />
                )}
                {!feedbacksOnLeft &&
                    feedbacks.map((feedback, index) => (
                        <UserFeedback
                            key={index}
                            name={feedback.name}
                            grade={feedback.grade}
                            desc={feedback.desc}
                            src={feedback.src}
                        />
                    ))}
            </FlexColumnAlignJustifyCenter>
        </FlexRowAlignCenter>
    );
};

export default FeaturesWithFeedbacks;
