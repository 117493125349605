"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import Link from "@/components/wrappers/Link";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";

const SchoolTeacherUserFeedbacksHeader = () => {
    return (
        <FlexColumnAlignJustifyCenter>
            <h2 className={"heading2"} style={{ marginBottom: "1.6rem" }}>
                Trusted by teachers across the globe
            </h2>
            <p className={"bodyXl"}>Our community of teachers trust Knowt to create and study on.</p>
            <Link href="https://form.fillout.com/t/aymZi6NipYus" openInNewTab>
                <CircularRectTextButton
                    component={"div"}
                    style={{
                        backgroundColor: themeColors.primary,
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                        color: themeColors.primaryDark,
                        width: "fit-content",
                        margin: spacing.MD,
                    }}>
                    Book a demo
                </CircularRectTextButton>
            </Link>
        </FlexColumnAlignJustifyCenter>
    );
};

export default SchoolTeacherUserFeedbacksHeader;
