"use client";

import Image from "next/image";
import { FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { copyToClipboard } from "@/utils/clipboard";

const DownloadButtons = () => {
    return (
        <FlexRowAlignCenter style={{ gap: "3.2rem", marginBottom: "3.2rem" }}>
            <div
                onClick={() => {
                    copyToClipboard("https://apps.apple.com/us/app/knowt-ai-flashcards-notes/id6463744184");
                    window.open("https://apps.apple.com/us/app/knowt-ai-flashcards-notes/id6463744184", "_blank");
                }}>
                <Image
                    src={`${ASSETS_URL}/images/downloadon_appstore.png`}
                    alt={"mobile download"}
                    width={"141"}
                    height={"42"}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                />
            </div>
            <div
                onClick={() => {
                    copyToClipboard("https://play.google.com/store/apps/details?id=com.knowt.app");
                    window.open("https://play.google.com/store/apps/details?id=com.knowt.app", "_blank");
                }}>
                <Image
                    src={`${ASSETS_URL}/images/downloadon_gstore.png`}
                    alt={"mobile download"}
                    width={"141"}
                    height={"42"}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                />
            </div>
        </FlexRowAlignCenter>
    );
};

export default DownloadButtons;
