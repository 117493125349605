"use client";

import { useRouter } from "next13-progressbar";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import TabSwitcher from "@/components/TabSwitcher";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

enum SearchTabs {
    ALL,
    FLASHCARDS,
    NOTES,
    USERS,
}

const TABS = ["all", "flashcards", "notes", "users"];

const SearchPageTab = () => {
    const searchParams = useSearchParams();
    const router = useRouter();
    const closeCardPreview = useCardPreviewContextSelector(state => state.closeCardPreview);
    const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined);

    useEffect(() => {
        closeCardPreview();
    }, [selectedTab]);

    useEffect(() => {
        const type = TABS.indexOf(searchParams?.get("type") || "all");
        setSelectedTab(type);
    }, [searchParams]);

    if (selectedTab === undefined) {
        const type = TABS.indexOf(searchParams?.get("type") || "all");
        setSelectedTab(type);
    }

    const changeCurrentTab = (newTab: number) => {
        setSelectedTab(newTab);
        closeCardPreview();

        if (newTab === SearchTabs.ALL) {
            router.push(`/search?q=${searchParams?.get("q")}`);
        } else {
            router.push(`/search?q=${searchParams?.get("q")}&type=${TABS[newTab]}`);
        }
    };

    const { smDown } = useBreakPoints();

    return (
        <TabSwitcher
            tabs={[{ label: "All results" }, { label: "Flashcards" }, { label: "Notes" }, { label: "Users" }]}
            selectedTab={selectedTab}
            setSelectedTab={i => changeCurrentTab(i)}
            height={smDown ? "4.5rem" : "5.5rem"}
            width={smDown ? "7.5rem" : "11rem"}
            fontSize={smDown ? "1.1rem" : "1.5rem"}
        />
    );
};

export default SearchPageTab;
