import { themeColors } from "@/utils/themeColors";

export const H1Paragraph = ({ children, style }: React.HTMLAttributes<HTMLParagraphElement>) => {
    return (
        <p
            style={{
                textAlign: "center",
                color: themeColors.neutralBlack,
                fontSize: "2rem",
                fontWeight: "400",
                lineHeight: "2.6rem",
                ...style,
            }}>
            {children}
        </p>
    );
};

/**
 * H1Paragraph and H2Paragraph are just them same since:
 * UI wise h1 and h2 are just the same, it's just different in tag being used for SEO purposed
 */
export const H2Paragraph = ({ children, ...props }: React.HTMLAttributes<HTMLParagraphElement>) => {
    return <H1Paragraph {...props}>{children}</H1Paragraph>;
};

export const H3Paragraph = ({ children, style }: React.HTMLAttributes<HTMLParagraphElement>) => {
    return (
        <p
            style={{
                color: themeColors.neutralBlack,
                fontSize: "1.8rem",
                fontWeight: "400",
                lineHeight: "2.6rem",
                marginBottom: "1.2rem",
                ...style,
            }}>
            {children}
        </p>
    );
};
