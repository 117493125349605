export const AP_EXAMS_TO_UNITS_MAP = {
    "AP African American Studies": [
        "Unit 1: Origins of the African Diaspora",
        "Unit 2: Freedom, Enslavement, and Resistance",
        "Unit 3: The Practice of Freedom",
        "Unit 4: Movements and Debates",
    ],
    "AP Art History": [
        "Unit 1: Global Prehistory, 30,000–500 BCE",
        "Unit 2: Ancient Mediterranean, 3500 BCE–300 CE",
        "Unit 3: Early Europe and Colonial Americas, 200–1750 CE",
        "Unit 4: Later Europe and Americas, 1750–1980 CE",
        "Unit 5: Indigenous Americas, 1000 BCE–1980 CE",
        "Unit 6: Africa, 1100–1980 CE",
        "Unit 7: West and Central Asia, 500 BCE–1980 CE",
        "Unit 8: South, East, and Southeast Asia, 300 BCE–1980 CE",
        "Unit 9: The Pacific, 700-1980 CE",
        "Unit 10: Global Contemporary, 1980 CE to Present",
    ],
    "AP Biology": [
        "Unit 1: Chemistry of Life",
        "Unit 2: Cell Structure and Function",
        "Unit 3: Cellular Energetics",
        "Unit 4: Cell Communication and Cell Cycle",
        "Unit 5: Heredity",
        "Unit 6: Gene Expression and Regulation",
        "Unit 7: Natural Selection",
        "Unit 8: Ecology",
    ],
    "AP Calculus AB": [
        "Unit 1: Limits and Continuity",
        "Unit 2: Differentiation: Definition and Fundamental Properties",
        "Unit 3: Differentiation: Composite, Implicit, and Inverse Functions",
        "Unit 4: Contextual Applications of Differentiation",
        "Unit 5: Analytical Applications of Differentiation",
        "Unit 6: Integration and Accumulation of Change",
        "Unit 7: Differential Equations",
        "Unit 8: Applications of Integration",
    ],
    "AP Calculus BC": [
        "Unit 1: Limits and Continuity",
        "Unit 2: Differentiation: Definition and Fundamental Properties",
        "Unit 3: Differentiation: Composite, Implicit, and Inverse Functions",
        "Unit 4: Contextual Applications of Differentiation",
        "Unit 5: Analytical Applications of Differentiation",
        "Unit 6: Integration and Accumulation of Change",
        "Unit 7: Differential Equations",
        "Unit 8: Applications of Integration",
        "Unit 9: Parametric Equations, Polar Coordinates, and Vector-Valued Functions",
        "Unit 10: Infinite Sequences and Series",
    ],
    "AP Chemistry": [
        "Unit 1: Atomic Structure and Properties",
        "Unit 2: Molecular and Ionic Compound Structure and Properties",
        "Unit 3: Intermolecular Forces and Properties",
        "Unit 4: Chemical Reactions",
        "Unit 5: Kinetics",
        "Unit 6: Thermodynamics",
        "Unit 7: Equilibrium",
        "Unit 8: Acids and Bases",
        "Unit 9: Applications of Thermodynamics",
    ],
    "AP Chinese Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP Comparative Government and Politics": [
        "Unit 1: Political Systems, Regimes, and Governments",
        "Unit 2: Political Institutions",
        "Unit 3: Political Culture and Participation",
        "Unit 4: Party and Electoral Systems and Citizen Organizations",
        "Unit 5: Political and Economic Changes and Development",
    ],
    "AP Computer Science A": [
        "Unit 1: Primitive Types",
        "Unit 2: Using Objects",
        "Unit 3: Boolean Expressions and if Statements",
        "Unit 4: Iteration",
        "Unit 5: Writing Classes",
        "Unit 6: Array",
        "Unit 7: ArrayList",
        "Unit 8: 2D Array",
        "Unit 9: Inheritance",
        "Unit 10: Recursion",
    ],
    "AP Computer Science Principles": [
        "Big Idea 1: Creative Development",
        "Big Idea 2: Data",
        "Big Idea 3: Algorithms and Programming",
        "Big Idea 4: Computer Systems and Networks",
        "Big Idea 5: Impact of Computing",
    ],
    "AP English Language and Composition": [
        "Unit 1: Rhetoric and the Elements of Style",
        "Unit 2: Basic Rhetorical Modes",
        "Unit 3: Complex Rhetorical Modes",
        "Unit 4: Rhetorical Fallacies",
    ],
    "AP English Literature and Composition": [
        "Unit 1: Short Fiction I",
        "Unit 2: Poetry I",
        "Unit 3: Longer Fiction or Drama I",
        "Unit 4: Short Fiction II",
        "Unit 5: Poetry II",
        "Unit 6: Longer Fiction or Drama II",
        "Unit 7: Short Fiction III",
        "Unit 8: Poetry III",
        "Unit 9: Longer Fiction or Drama III",
    ],
    "AP Environmental Science": [
        "Unit 1: The Living World: Ecosystems",
        "Unit 2: The Living World: Biodiversity",
        "Unit 3: Populations",
        "Unit 4: Earth Systems and Resources",
        "Unit 5: Land and Water Use",
        "Unit 6: Energy Resources and Consumption",
        "Unit 7: Atmospheric Pollution",
        "Unit 8: Aquatic and Terrestrial Pollution",
        "Unit 9: Global Change",
    ],
    "AP European History": [
        "Unit 1: Renaissance and Exploration",
        "Unit 2: Age of Reformation",
        "Unit 3: Absolutism and Constitutionalism",
        "Unit 4: Scientific, Philosophical, and Political Developments",
        "Unit 5: Conflict, Crisis, and Reaction in the Late 18th Century",
        "Unit 6: Industrialization and Its Effects",
        "Unit 7: 19th-Century Perspectives and Political Developments",
        "Unit 8: 20th-Century Global Conflicts",
        "Unit 9: Cold War and Contemporary Europe",
    ],
    "AP French Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP German Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP Human Geography": [
        "Unit 1: Thinking Geographically",
        "Unit 2: Population and Migration Patterns and Processes",
        "Unit 3: Cultural Patterns and Processes",
        "Unit 4: Political Patterns and Processes",
        "Unit 5: Agriculture and Rural Land-Use Patterns and Processes",
        "Unit 6: Cities and Urban Land-Use Patterns and Processes",
        "Unit 7: Industrial and Economic Development Patterns and Processes",
    ],
    "AP Italian Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP Japanese Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP Latin": [
        "Unit 1: Vergil, Aeneid, Book 1",
        "Unit 2: Caesar, Gallic War, Books 1 and 6",
        "Unit 3: Vergil, Aeneid, Book 2",
        "Unit 4 : Caesar, Gallic War, Book 4",
        "Unit 5: Vergil, Aeneid, Book 4",
        "Unit 6: Caesar, Gallic War, Book 5, Part I",
        "Unit 7: Caesar, Gallic War, Book 5, Part II, Book 6, and Book 7",
        "Unit 8: Vergil, Aeneid, Books 6, 8, and 12",
    ],
    "AP Macroeconomics": [
        "Unit 1: Basic Economic Concepts",
        "Unit 2: Economic Indicators and the Business Cycle",
        "Unit 3: National Income and Price Determination",
        "Unit 4: Financial Sector",
        "Unit 5: Long-Run Consequences of Stabilization Policies",
        "Unit 6: Open Economy—International Trade and Finance",
    ],
    "AP Microeconomics": [
        "Unit 1: Basic Economic Concepts",
        "Unit 2: Supply and Demand",
        "Unit 3: Production, Cost, and the Perfect Competition Model",
        "Unit 4: Imperfect Competition",
        "Unit 5: Factor Markets",
        "Unit 6: Market Failure and the Role of Government",
    ],
    "AP Music Theory": [
        "Unit 1: Music Fundamentals I: Pitch, Major Scales and Key Signatures, Rhythm, Meter, and Expressive Elements",
        "Unit 2: Music Fundamentals II: Minor Scales and Key Signatures, Melody, Timbre, and Texture",
        "Unit 3: Music Fundamentals III: Triads and Seventh Chords",
        "Unit 4: Harmony and Voice Leading I: Chord Function, Cadence, and Phrase",
        "Unit 5: Harmony and Voice Leading II: Chord Progressions and Predominant Function",
        "Unit 6: Harmony and Voice Leading III: Embellishments, Motives, and Melodic Devices",
        "Unit 7: Harmony and Voice Leading IV: Secondary Function",
        "Unit 8: Modes and Form",
    ],
    "AP Physics 1: Algebra-Based": [
        "Unit 1: Kinematics",
        "Unit 2: Dynamics",
        "Unit 3: Circular Motion and Gravitation",
        "Unit 4: Energy",
        "Unit 5: Momentum",
        "Unit 6: Simple Harmonic Motion",
        "Unit 7: Torque and Rotational Motion",
    ],
    "AP Physics 2: Algebra-Based": [
        "Unit 1: Fluids: Pressure and Forces",
        "Unit 2: Thermodynamics",
        "Unit 3: Electric Force, Field, and Potential",
        "Unit 4: Electric Circuits",
        "Unit 5: Magnetism and Electromagnetic Induction",
        "Unit 6: Geometric and Physical Optics",
        "Unit 7: Quantum, Atomic, and Nuclear Physics",
    ],
    "AP Physics C: Electricity and Magnetism": [
        "Unit 1: Electrostatics",
        "Unit 2: Conductors, Capacitors, Dielectrics",
        "Unit 3: Electric Circuits",
        "Unit 4: Magnetic Fields",
        "Unit 5: Electromagnetism",
    ],
    "AP Physics C: Mechanics": [
        "Unit 1: Kinematics",
        "Unit 2: Newton’s Laws of Motion",
        "Unit 3: Work, Energy, and Power",
        "Unit 4: Systems of Particles and Linear Momentum",
        "Unit 5: Rotation",
        "Unit 6: Oscillations",
        "Unit 7: Gravitation",
    ],
    "AP Precalculus": [
        "Unit 1: Polynomial and Rational Functions",
        "Unit 2: Exponential and Logarithmic Functions",
        "Unit 3: Trigonometric and Polar Functions",
        "Unit 4: Functions Involving Parameters, Vectors, and Matrices",
    ],
    "AP Psychology": [
        "Unit 1: Scientific Foundations of Psychology",
        "Unit 2: Biological Bases of Behavior",
        "Unit 3: Sensation and Perception",
        "Unit 4: Learning",
        "Unit 5: Cognitive Psychology",
        "Unit 6: Developmental Psychology",
        "Unit 7: Motivation, Emotion, and Personality",
        "Unit 8: Clinical Psychology",
        "Unit 9: Social Psychology",
    ],
    "AP Spanish Language and Culture": [
        "Unit 1: Families in Different Societies",
        "Unit 2: The Influence of Language and Culture on Identity",
        "Unit 3: Influences of Beauty and Art",
        "Unit 4: How Science and Technology Affect Our Lives",
        "Unit 5: Factors That Impact the Quality of Life",
        "Unit 6: Environmental, Political, and Societal Challenges",
    ],
    "AP Spanish Literature and Culture": [
        "Unit 1: La época medieval",
        "Unit 2: El siglo XVI",
        "Unit 3: El siglo XVII",
        "Unit 4: La literatura romántica, realista y naturalista",
        "Unit 5: La Generación del 98 y el Modernismo",
        "Unit 6: Teatro y poesía del siglo XX",
        "Unit 7: El Boom latinoamericano",
        "Unit 8: Escritores contemporáneos de Estados Unidos y España",
    ],
    "AP Statistics": [
        "Unit 1: Exploring One-Variable Data",
        "Unit 2: Exploring Two-Variable Data",
        "Unit 3: Collecting Data",
        "Unit 4: Probability, Random Variables, and Probability Distributions",
        "Unit 5: Sampling Distributions",
        "Unit 6: Inference for Categorical Data: Proportions",
        "Unit 7: Inference for Quantitative Data: Means",
        "Unit 8: Inference for Categorical Data: Chi-Square",
        "Unit 9: Inference for Quantitative Data: Slopes",
    ],
    "AP United States Government and Politics": [
        "Unit 1: Foundations of American Democracy",
        "Unit 2: Interactions Among Branches of Government",
        "Unit 3: Civil Liberties and Civil Rights",
        "Unit 4: American Political Ideologies and Beliefs",
        "Unit 5: Political Participation",
    ],
    "AP United States History": [
        "Unit 1: Period 1: 1491–1607",
        "Unit 2: Period 2: 1607–1754",
        "Unit 3: Period 3: 1754–1800",
        "Unit 4: Period 4: 1800–1848",
        "Unit 5: Period 5: 1844–1877",
        "Unit 6: Period 6: 1865–1898",
        "Unit 7: Period 7: 1890–1945",
        "Unit 8: Period 8: 1945–1980",
        "Unit 9: Period 9: 1980–Present",
    ],
    "AP World History: Modern": [
        "Unit 1: The Global Tapestry",
        "Unit 2: Networks of Exchange",
        "Unit 3: Land-Based Empires",
        "Unit 4: Transoceanic Interconnections",
        "Unit 5: Revolutions",
        "Unit 6: Consequences of Industrialization",
        "Unit 7: Global Conflict",
        "Unit 8: Cold War and Decolonization",
        "Unit 9: Globalization",
    ],
};

export const IB_EXAMS_TO_UNITS_MAP = {
    "IB Biology (HL)": [
        "Cell biology",
        "Molecular biology",
        "Genetics",
        "Ecology",
        "Evolution and biodiversity",
        "Human physiology",
        "Nucleic acids",
        "Metabolism, cell respiration and photosynthesis",
        "Plant biology",
        "Genetics and evolution",
    ],
    "IB Biology (SL)": [
        "Cell biology",
        "Molecular biology",
        "Genetics",
        "Ecology",
        "Evolution and biodiversity",
        "Human physiology",
        "Neurobiology and behaviour",
        "Biotechnology and bioinformatics",
        "Ecology and conservation",
    ],
    "IB Business & Management (HL)": [
        "Business organisation and environment",
        "Human resource management",
        "Accounts and finance",
        "Marketing",
        "Operations management",
    ],
    "IB Business & Management (SL)": [
        "Business organisation and environment",
        "Human resource management",
        "Accounts and finance",
        "Marketing",
        "Operations management",
    ],
    "IB Chemistry (HL)": [
        "Stoichiometric Relationships",
        "Atomic Structure",
        "Periodicity",
        "Chemical Bonding and Structure",
        "Energetics/Thermochemistry",
        "Chemical Kinetics",
        "Equilibrium",
        "Acids and Bases",
        "Redox Processes",
        "Organic Chemistry",
    ],
    "IB Chemistry (SL)": [
        "Stoichiometric Relationships",
        "Atomic Structure",
        "Periodicity",
        "Chemical Bonding and Structure",
        "Energetics/Thermochemistry",
        "Chemical Kinetics",
        "Equilibrium",
        "Acids and Bases",
        "Redox Processes",
        "Organic Chemistry",
    ],
    "IB Computer Science (HL)": [
        "System fundamentals",
        "Computer organization",
        "Networks",
        "Computational thinking, problem-solving and programming",
        "Abstract data structures",
        "Resource management",
        "Control",
        "Databases",
        "Modeling and simulation",
        "Web science",
    ],
    "IB Computer Science (SL)": [
        "System fundamentals",
        "Computer organization",
        "Networks",
        "Computational thinking, problem-solving and programming",
        "Databases",
        "Modeling and simulation",
        "Web science",
        "Object-oriented programming (OOP)",
    ],
    "IB Dance (HL)": ["Composition and analysis", "World dance studies", "Performance"],
    "IB Dance (SL)": ["Composition and analysis", "World dance studies", "Performance"],
    "IB Design Technology (HL)": [
        "Human factors and ergonomics",
        "Resource management and sustainable production",
        "Modelling",
        "Raw material to final product",
        "Innovation and design",
        "Classic design",
        "User-centred design (UCD)",
        "Sustainability",
        "Innovation and markets",
        "Commercial production",
    ],
    "IB Design Technology (SL)": [
        "Human factors and ergonomics",
        "Resource management and sustainable production",
        "Modelling",
        "Raw material to final product",
        "Innovation and design",
        "Classic design",
    ],
    "IB Economics (HL)": ["Introduction to economics", "Microeconomics", "Macroeconomics", "The global economy"],
    "IB Economics (SL)": ["Introduction to economics", "Microeconomics", "Macroeconomics", "The global economy"],
    "IB Environmental systems and societies (SL)": [
        "Foundations of environmental systems and societies",
        "Ecosystems and ecology",
        "Biodiversity and conservation",
        "Water and aquatic food production systems and societies",
        "Soil systems and terrestrial food production systems and societies",
        "Atmospheric systems and societies",
        "Climate change and energy production",
        "Human systems and resource use",
    ],
    "IB Film (HL)": [
        "Reading film",
        "Contextualizing film",
        "Exploring film production roles",
        "Collaboratively producing film",
    ],
    "IB Film (SL)": ["Reading film", "Contextualizing film", "Exploring film production roles"],
    "IB Geography (HL)": [
        "Patterns and change",
        "Freshwater—issues and conflicts",
        "Oceans and their coastal margins",
        "Extreme environments",
        "Hazards and disasters—risk assessment and response",
        "Leisure, sport and tourism",
        "The geography of food and health",
        "Urban environments",
        "Global interactions",
        "Fieldwork",
    ],
    "IB Geography (SL)": [
        "Patterns and change",
        "Freshwater—issues and conflicts",
        "Oceans and their coastal margins",
        "Extreme environments",
        "Hazards and disasters—risk assessment and response",
        "Leisure, sport and tourism",
        "The geography of food and health",
        "Urban environments",
        "Fieldwork",
    ],
    "IB Global Politics (HL)": ["People, Power, and Politics", "Global Political Challenges"],
    "IB Global Politics (SL)": ["People, Power, and Politics"],
    "IB History (HL)": [
        "Military leaders",
        "Conquest and its impact",
        "The move to global war",
        "Rights and protest",
        "Conflict and intervention",
        "Society and economy (750–1400)",
        "Causes and effects of medieval wars (750–1500)",
        "Dynasties and rulers (750–1500)",
        "Societies in transition (1400–1700)",
        "Early Modern states (1450–1789)",
    ],
    "IB History (SL)": [
        "Military leaders",
        "Conquest and its impact",
        "The move to global war",
        "Rights and protest",
        "Conflict and intervention",
        "Society and economy (750–1400)",
        "Causes and effects of medieval wars (750–1500)",
        "Dynasties and rulers (750–1500)",
        "Societies in transition (1400–1700)",
        "Early Modern states (1450–1789)",
    ],
    "IB Information Technology in a Global Society (HL)": [
        "Social and ethical significance",
        "Application to specific scenarios",
        "IT systems",
        "IT systems in organizations",
        "Robotics and artificial intelligence",
        "Information systems specific to the annually issued case study",
    ],
    "IB Information Technology in a Global Society (SL)": [
        "Social and ethical significance",
        "Application to specific scenarios",
        "IT systems",
    ],
    "IB Mathematics: Analysis and Approaches (HL)": [
        "Number and algebra",
        "Functions",
        "Geometry and trigonometry",
        "Statistics and probability",
        "Calculus",
    ],
    "IB Mathematics: Analysis and Approaches (SL)": [
        "Number and algebra",
        "Functions",
        "Geometry and trigonometry",
        "Statistics and probability",
        "Calculus",
    ],
    "IB Mathematics: Applications and Interpretation (HL)": [
        "Number and algebra",
        "Functions",
        "Geometry and trigonometry",
        "Statistics and probability",
        "Calculus",
    ],
    "IB Mathematics: Applications and Interpretation (SL)": [
        "Number and algebra",
        "Functions",
        "Geometry and trigonometry",
        "Statistics and probability",
        "Calculus",
    ],
    "IB Music (HL)": [
        "Exploring music in context",
        "Experimenting with music",
        "Presenting music",
        "The contemporary music maker",
    ],
    "IB Music (SL)": ["Exploring music in context", "Experimenting with music", "Presenting music"],
    "IB Philosophy (HL)": [
        "Being Human",
        "Aesthetics",
        "Epistemology",
        "Ethics",
        "Philosophy and contemporary society",
        "Philosophy of religion",
        "Philosophy of science",
        "Political philosophy",
        "Exploring philosophical activity",
    ],
    "IB Philosophy (SL)": [
        "Being Human",
        "Aesthetics",
        "Epistemology",
        "Ethics",
        "Philosophy and contemporary society",
        "Philosophy of religion",
        "Philosophy of science",
        "Political philosophy",
    ],
    "IB Physics (HL)": [
        "Measurements and mathematical foundations",
        "Mechanics",
        "Thermal physics",
        "Oscillations and waves",
        "Electricity and magnetism",
        "Circular motion and gravitation",
        "Atomic and nuclear physics",
        "Energy production",
        "Wave phenomena",
        "Fields",
    ],
    "IB Physics (SL)": [
        "Measurements and mathematical foundations",
        "Mechanics",
        "Thermal physics",
        "Oscillations and waves",
        "Electricity and magnetism",
        "Circular motion and gravitation",
        "Atomic and nuclear physics",
        "Energy production",
        "Relativity",
        "Engineering physics",
    ],
    "IB Psychology (HL)": [
        "Biological approach to understanding behaviour",
        "Cognitive approach to understanding behaviour",
        "Sociocultural approach to understanding behaviour",
        "Approaches to researching behaviour",
        "Abnormal psychology",
        "Developmental psychology",
        "Health psychology",
        "Psychology of human relationships",
    ],
    "IB Psychology (SL)": [
        "Biological approach to understanding behaviour",
        "Cognitive approach to understanding behaviour",
        "Sociocultural approach to understanding behaviour",
        "Approaches to researching behaviour",
        "Abnormal psychology",
        "Developmental psychology",
        "Health psychology",
        "Psychology of human relationships",
    ],
    "IB Social and cultural anthropology (HL)": [
        "What is anthropology?",
        "Social and cultural organization",
        "Observation and critique exercise",
        "Theoretical perspectives in anthropology",
        "Fieldwork",
    ],
    "IB Social and cultural anthropology (SL)": [
        "What is anthropology?",
        "Social and cultural organization",
        "Observation and critique exercise",
    ],
    "IB Sports, Exercise, and Health Science (HL)": [
        "Anatomy",
        "Exercise physiology",
        "Energy systems",
        "Movement analysis",
        "Skill in sport",
        "Measurement and evaluation of human performance",
        "Optimizing physiological performance",
        "Psychology of sport",
        "Physical activity and health",
        "Nutrition for sport, exercise and health",
    ],
    "IB Sports, Exercise, and Health Science (SL)": [
        "Anatomy",
        "Exercise physiology",
        "Energy systems",
        "Movement analysis",
        "Skill in sport",
        "Measurement and evaluation of human performance",
        "Optimizing physiological performance",
        "Psychology of sport",
        "Physical activity and health",
        "Nutrition for sport, exercise and health",
    ],
};

/**
 * EXAM ROOM CONSTANTS
 */

export const AP_EXAM_MCQ_MAP: {
    [key: string]: string[];
} = {
    "AP Biology": [
        "Unit 1: Chemistry of Life",
        "Unit 2: Cell Structure and Function",
        "Unit 3: Cellular Energetics",
        "Unit 4: Cell Communication and Cell Cycle",
        "Unit 5: Heredity",
        "Unit 6: Gene Expression and Regulation",
        "Unit 7: Natural Selection",
        "Unit 8: Ecology",
    ],
    "AP Calculus AB": [
        "Unit 1: Limits and Continuity",
        "Unit 2: Differentiation: Definition and Fundamental Properties",
        "Unit 3: Differentiation: Composite, Implicit, and Inverse Functions",
        "Unit 4: Contextual Applications of Differentiation",
        "Unit 5: Analytical Applications of Differentiation",
        "Unit 6: Integration and Accumulation of Change",
        "Unit 7: Differential Equations",
        "Unit 8: Applications of Integration",
    ],
    "AP Calculus BC": [
        "Unit 1: Limits and Continuity",
        "Unit 2: Differentiation: Definition and Fundamental Properties",
        "Unit 3: Differentiation: Composite, Implicit, and Inverse Functions",
        "Unit 4: Contextual Applications of Differentiation",
        "Unit 5: Analytical Applications of Differentiation",
        "Unit 6: Integration and Accumulation of Change",
        "Unit 7: Differential Equations",
        "Unit 8: Applications of Integration",
        "Unit 9: Parametric Equations, Polar Coordinates, and Vector-Valued Functions",
        "Unit 10: Infinite Sequences and Series",
    ],
    "AP Chemistry": [
        "Unit 1: Atomic Structure and Properties",
        "Unit 2: Molecular and Ionic Compound Structure and Properties",
        "Unit 3: Intermolecular Forces and Properties",
        "Unit 4: Chemical Reactions",
        "Unit 5: Kinetics",
        "Unit 6: Thermodynamics",
        "Unit 7: Equilibrium",
        "Unit 8: Acids and Bases",
        "Unit 9: Applications of Thermodynamics",
    ],
    "AP Comparative Government and Politics": [
        "Unit 1: Political Systems, Regimes, and Governments",
        "Unit 2: Political Institutions",
        "Unit 3: Political Culture and Participation",
        "Unit 4: Party and Electoral Systems and Citizen Organizations",
        "Unit 5: Political and Economic Changes and Development",
    ],
    "AP Computer Science Principles": [
        "Big Idea 1: Creative Development",
        "Big Idea 2: Data",
        "Big Idea 3: Algorithms and Programming",
        "Big Idea 4: Computer Systems and Networks",
        "Big Idea 5: Impact of Computing",
    ],
    "AP Environmental Science": [
        "Unit 1: The Living World: Ecosystems",
        "Unit 2: The Living World: Biodiversity",
        "Unit 3: Populations",
        "Unit 4: Earth Systems and Resources",
        "Unit 5: Land and Water Use",
        "Unit 6: Energy Resources and Consumption",
        "Unit 7: Atmospheric Pollution",
        "Unit 8: Aquatic and Terrestrial Pollution",
        "Unit 9: Global Change",
    ],
    "AP European History": [
        "Unit 1: Renaissance and Exploration",
        "Unit 2: Age of Reformation",
        "Unit 3: Absolutism and Constitutionalism",
        "Unit 4: Scientific, Philosophical, and Political Developments",
        "Unit 5: Conflict, Crisis, and Reaction in the Late 18th Century",
        "Unit 6: Industrialization and Its Effects",
        "Unit 7: 19th-Century Perspectives and Political Developments",
        "Unit 8: 20th-Century Global Conflicts",
        "Unit 9: Cold War and Contemporary Europe",
    ],
    "AP Human Geography": [
        "Unit 1: Thinking Geographically",
        "Unit 2: Population and Migration Patterns and Processes",
        "Unit 3: Cultural Patterns and Processes",
        "Unit 4: Political Patterns and Processes",
        "Unit 5: Agriculture and Rural Land-Use Patterns and Processes",
        "Unit 6: Cities and Urban Land-Use Patterns and Processes",
        "Unit 7: Industrial and Economic Development Patterns and Processes",
    ],
    "AP Macroeconomics": [
        "Unit 1: Basic Economic Concepts",
        "Unit 2: Economic Indicators and the Business Cycle",
        "Unit 3: National Income and Price Determination",
        "Unit 4: Financial Sector",
        "Unit 5: Long-Run Consequences of Stabilization Policies",
        "Unit 6: Open Economy—International Trade and Finance",
    ],
    "AP Microeconomics": [
        "Unit 1: Basic Economic Concepts",
        "Unit 2: Supply and Demand",
        "Unit 3: Production, Cost, and the Perfect Competition Model",
        "Unit 4: Imperfect Competition",
        "Unit 5: Factor Markets",
        "Unit 6: Market Failure and the Role of Government",
    ],
    "AP Physics 1: Algebra-Based": [
        "Unit 1: Kinematics",
        "Unit 2: Dynamics",
        "Unit 3: Circular Motion and Gravitation",
        "Unit 4: Energy",
        "Unit 5: Momentum",
        "Unit 6: Simple Harmonic Motion",
        "Unit 7: Torque and Rotational Motion",
    ],
    "AP Physics C: Electricity and Magnetism": [
        "Unit 1: Electrostatics",
        "Unit 2: Conductors, Capacitors, Dielectrics",
        "Unit 3: Electric Circuits",
        "Unit 4: Magnetic Fields",
        "Unit 5: Electromagnetism",
    ],
    "AP Physics C: Mechanics": [
        "Unit 1: Kinematics",
        "Unit 4: Systems of Particles and Linear Momentum",
        "Unit 5: Rotation",
        "Unit 6: Oscillations",
        "Unit 7: Gravitation",
    ],
    "AP Psychology": [
        "Unit 1: Scientific Foundations of Psychology",
        "Unit 2: Biological Bases of Behavior",
        "Unit 3: Sensation and Perception",
        "Unit 4: Learning",
        "Unit 5: Cognitive Psychology",
        "Unit 6: Developmental Psychology",
        "Unit 7: Motivation, Emotion, and Personality",
        "Unit 8: Clinical Psychology",
        "Unit 9: Social Psychology",
    ],
    "AP Statistics": [
        "Unit 1: Exploring One-Variable Data",
        "Unit 2: Exploring Two-Variable Data",
        "Unit 3: Collecting Data",
        "Unit 4: Probability, Random Variables, and Probability Distributions",
        "Unit 5: Sampling Distributions",
        "Unit 6: Inference for Categorical Data: Proportions",
        "Unit 7: Inference for Quantitative Data: Means",
        "Unit 8: Inference for Categorical Data: Chi-Square",
        "Unit 9: Inference for Quantitative Data: Slopes",
    ],
    "AP United States Government and Politics": [
        "Unit 1: Foundations of American Democracy",
        "Unit 2: Interactions Among Branches of Government",
        "Unit 3: Civil Liberties and Civil Rights",
        "Unit 4: American Political Ideologies and Beliefs",
        "Unit 5: Political Participation",
    ],
    "AP United States History": [
        "Unit 1: Period 1: 1491–1607",
        "Unit 2: Period 2: 1607–1754",
        "Unit 3: Period 3: 1754–1800",
        "Unit 4: Period 4: 1800–1848",
        "Unit 5: Period 5: 1844–1877",
        "Unit 6: Period 6: 1865–1898",
        "Unit 7: Period 7: 1890–1945",
        "Unit 8: Period 8: 1945–1980",
        "Unit 9: Period 9: 1980–Present",
    ],
    "AP World History: Modern": [
        "Unit 1: The Global Tapestry",
        "Unit 2: Networks of Exchange",
        "Unit 3: Land-Based Empires",
        "Unit 4: Transoceanic Interconnections",
        "Unit 5: Revolutions",
        "Unit 6: Consequences of Industrialization",
        "Unit 7: Global Conflict",
        "Unit 8: Cold War and Decolonization",
        "Unit 9: Globalization",
    ],
};

export const AP_EXAM_FRQ_MAP = {
    "AP Comparative Government and Politics": [
        "Unit 1: Political Systems, Regimes, and Governments",
        "Unit 2: Political Institutions",
        "Unit 3: Political Culture and Participation",
        "Unit 4: Party and Electoral Systems and Citizen Organizations",
        "Unit 5: Political and Economic Changes and Development",
    ],
    "AP European History": [
        "Unit 1: Renaissance and Exploration",
        "Unit 2: Age of Reformation",
        "Unit 3: Absolutism and Constitutionalism",
        "Unit 4: Scientific, Philosophical, and Political Developments",
        "Unit 5: Conflict, Crisis, and Reaction in the Late 18th Century",
        "Unit 6: Industrialization and Its Effects",
        "Unit 7: 19th-Century Perspectives and Political Developments",
        "Unit 8: 20th-Century Global Conflicts",
        "Unit 9: Cold War and Contemporary Europe",
    ],
    "AP Human Geography": [
        "Unit 1: Thinking Geographically",
        "Unit 2: Population and Migration Patterns and Processes",
        "Unit 3: Cultural Patterns and Processes",
        "Unit 4: Political Patterns and Processes",
        "Unit 5: Agriculture and Rural Land-Use Patterns and Processes",
        "Unit 6: Cities and Urban Land-Use Patterns and Processes",
        "Unit 7: Industrial and Economic Development Patterns and Processes",
    ],
    "AP Psychology": [
        "Unit 1: Scientific Foundations of Psychology",
        "Unit 2: Biological Bases of Behavior",
        "Unit 3: Sensation and Perception",
        "Unit 4: Learning",
        "Unit 5: Cognitive Psychology",
        "Unit 6: Developmental Psychology",
        "Unit 7: Motivation, Emotion, and Personality",
        "Unit 8: Clinical Psychology",
        "Unit 9: Social Psychology",
    ],
    "AP United States Government and Politics": [
        "Unit 1: Foundations of American Democracy",
        "Unit 2: Interactions Among Branches of Government",
        "Unit 3: Civil Liberties and Civil Rights",
        "Unit 4: American Political Ideologies and Beliefs",
        "Unit 5: Political Participation",
    ],
    "AP United States History": [
        "Unit 1: Period 1: 1491–1607",
        "Unit 2: Period 2: 1607–1754",
        "Unit 3: Period 3: 1754–1800",
        "Unit 4: Period 4: 1800–1848",
        "Unit 5: Period 5: 1844–1877",
        "Unit 6: Period 6: 1865–1898",
        "Unit 7: Period 7: 1890–1945",
        "Unit 8: Period 8: 1945–1980",
        "Unit 9: Period 9: 1980–Present",
    ],
    "AP World History: Modern": [
        "Unit 1: The Global Tapestry",
        "Unit 2: Networks of Exchange",
        "Unit 3: Land-Based Empires",
        "Unit 4: Transoceanic Interconnections",
        "Unit 5: Revolutions",
        "Unit 6: Consequences of Industrialization",
        "Unit 7: Global Conflict",
        "Unit 8: Cold War and Decolonization",
        "Unit 9: Globalization",
    ],
};
