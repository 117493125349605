"use client";

import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { LandingCenteredHalfSection } from "../../components/styled/Containers";
import { H3 } from "../../components/styled/Headings";
import { H3Paragraph } from "../../components/styled/Paragraphs";
import TabSwitcher from "@/components/TabSwitcher";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import { useState } from "react";
import br from "@/styles/breakpoints.module.css";

enum Tabs {
    APP_STORE,
    PLAY_STORE,
}

const MobileDownloadQRCode = () => {
    const [currentTab, setCurrentTab] = useState(Tabs.APP_STORE);

    return (
        <LandingCenteredHalfSection>
            <FlexRowAlignCenter
                style={{ gap: "2.4rem 10.3rem", flexDirection: "row" }}
                className={br.smDownColumnReverse}>
                <FlexColumn className={br.smDownTextAlignCenter} style={{ flex: 1 }}>
                    <H3>Available on IOS and Android</H3>
                    <H3Paragraph>scan the QR code with your device to install</H3Paragraph>
                </FlexColumn>
                <FlexColumn style={{ justifyContent: "start", alignItems: "start" }}>
                    <TabSwitcher
                        tabs={[
                            { label: "App Store", tooltip: "Download for iOS" },
                            { label: "Play Store", tooltip: "Download for Android" },
                        ]}
                        selectedTab={currentTab}
                        setSelectedTab={i => setCurrentTab(i)}
                        width={"15rem"}
                        height="5.6rem"
                        fontSize={"1.4rem"}
                        containerStyle={{ marginBottom: "2.4rem" }}
                    />
                    <div style={{ width: "29.7rem", height: "29.7rem" }}>
                        <Image
                            src={
                                currentTab === Tabs.APP_STORE
                                    ? `${ASSETS_URL}/images/mobile-qrcode-ios.svg`
                                    : `${ASSETS_URL}/images/mobile-qrcode-android.svg`
                            }
                            alt="QR Code"
                            width="300"
                            height="300"
                            style={{ objectFit: "contain", width: "100%", height: "100%" }}
                        />
                    </div>
                </FlexColumn>
            </FlexRowAlignCenter>
        </LandingCenteredHalfSection>
    );
};

export default MobileDownloadQRCode;
