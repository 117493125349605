import { LandingCenteredHalfSection } from "../../components/styled/Containers";
import { themeColors } from "@/utils/themeColors";
import { FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { StudentRelyOnKnowt } from "../../LandingMainPage/LandingMainPage";
import styles from "./medias/video-summarizer.module.css";
import br from "@/styles/breakpoints.module.css";
import React from "react";

const DefaultHeader = () => {
    return (
        <FlexColumnAlignJustifyCenter className={styles.feedbacksHeader} style={{ textAlign: "center" }}>
            {/* Desktop */}
            <div className={br.smDownDisplayNone}>
                <h2 className={"heading2"} style={{ marginBottom: "1.6rem" }}>
                    Trusted by millions across the globe
                </h2>
                <p className={"bodyXl"}>Our community of students and teachers trust Knowt to create and study on.</p>
            </div>

            {/* Mobile */}
            <div className={br.smUpDisplayNone}>
                <p className={"bodyBold1"}>Trusted by millions across the globe</p>
                <p className={"body2"}>Our community of students and teachers trust Knowt to create and study on.</p>
            </div>
        </FlexColumnAlignJustifyCenter>
    );
};

const UsersFeedbacks = ({ header, isWithBubbles = true }: { header?: React.ReactNode; isWithBubbles?: boolean }) => {
    return (
        <LandingCenteredHalfSection style={{ maxWidth: "105rem", padding: "0rem" }}>
            <StudentRelyOnKnowt
                header={header || <DefaultHeader />}
                isWithBubbles={isWithBubbles}
                containerStyle={{ maxWidth: "unset", overflow: "hidden" }}
                feedbacksListContainerStyle={styles.feedbacksListContainer}
                feedbackCardsStyle={{ backgroundColor: themeColors.neutralWhite }}
            />
        </LandingCenteredHalfSection>
    );
};

export default UsersFeedbacks;
