import Image from "next/image";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import br from "@/styles/breakpoints.module.css";
import { ASSETS_URL } from "@/config/deployConstants";
import styles from "../landing.module.css";
import React from "react";
import clsx from "clsx";
import { spacing } from "@/utils/spacing";

type User = {
    name: string;
    grade: string;
    desc: string;
    src: string;
};

export const UserFeedback = ({
    name,
    grade,
    desc,
    src,
    style,
}: {
    name: string;
    grade: string;
    desc: string;
    src: string;
    style?: React.CSSProperties;
}) => {
    return (
        <FlexColumn
            style={{
                flex: 1,
                padding: "1.6rem",
                backgroundColor: themeColors.background,
                borderRadius: "2rem",
                color: themeColors.neutralBlack,
                textAlign: "left",
                ...style,
            }}>
            <FlexRowAlignCenter style={{ marginBottom: "1.6rem", gap: spacing.SM }}>
                {src && (
                    <Image
                        alt={name + " profile picture"}
                        src={src}
                        height={68}
                        width={68}
                        style={{ objectFit: "cover", borderRadius: "50%", overflow: "hidden" }}
                    />
                )}
                <FlexColumn>
                    <span style={{ fontSize: "1.6rem", fontWeight: "600", marginBottom: "0.4rem" }}>{name}</span>
                    <span style={{ fontSize: "1.3rem", fontWeight: "400" }}>{grade}</span>
                </FlexColumn>
            </FlexRowAlignCenter>
            <p style={{ fontSize: "1.8rem", lineHeight: "2.8rem" }}>{desc}</p>
        </FlexColumn>
    );
};

const ProfileBubble = ({ src, className, style }: { src: string; className?: string; style?: React.CSSProperties }) => (
    <div className={className}>
        <Image
            className={styles.userBubble}
            alt={"profile bubble"}
            src={src}
            height={114}
            width={115}
            style={{ objectFit: "cover", borderRadius: "50%", minWidth: "4rem", minHeight: "4rem", ...style }}
        />
    </div>
);

export const CollapsedUserFeedbackList = ({
    title,
    desc,
    mainFeedbackUser,
}: {
    title: string;
    desc: string;
    mainFeedbackUser: User;
}) => {
    const CollapsedBubbles = () => {
        return (
            <FlexRowAlignCenter
                className={styles.collpasedBubblesContainer}
                style={{ width: "100%", paddingTop: "3.2rem" }}>
                {[...Array(10)].map((_, i) => (
                    <ProfileBubble
                        key={i}
                        src={`${ASSETS_URL}/images/feedbacks-profile-${i + 1}.svg`}
                        className={i !== 0 ? styles.collapsedBubble : undefined}
                        style={{ width: "6rem", height: "6rem" }}
                    />
                ))}
            </FlexRowAlignCenter>
        );
    };

    return (
        <FlexRowAlignCenter className={clsx(br.mdDownColumn, styles.collapsedFeedbacksContainer)}>
            <FlexColumn style={{ flex: 1, marginRight: spacing.SM }}>
                <h4 className="heading4">{title}</h4>
                <span className="body1">{desc}</span>
                <CollapsedBubbles />
            </FlexColumn>
            <UserFeedback style={{ backgroundColor: themeColors.card, flex: 1 }} {...mainFeedbackUser} />
        </FlexRowAlignCenter>
    );
};

export const UserFeedbackList = ({
    users,
    isWithBubles,
    listContainerStyle,
    cardStyle,
    gridPattern = [
        [0, 3],
        [3, 6],
        [6, 9],
    ],
}: {
    users: User[];
    isWithBubles?: boolean;
    listContainerStyle?: string;
    cardStyle?: React.CSSProperties;
    gridPattern?: [number, number][];
}) => {
    return (
        <FlexColumnAlignJustifyCenter className={listContainerStyle}>
            {isWithBubles && (
                <FlexRowAlignCenter style={{ width: "100%", justifyContent: "space-between", paddingTop: "3.2rem" }}>
                    {[...Array(8)].map((_, i) => (
                        <ProfileBubble key={i} src={`${ASSETS_URL}/images/feedbacks-profile-${i + 1}.svg`} />
                    ))}
                </FlexRowAlignCenter>
            )}
            <Flex style={{ gap: "1.6rem" }}>
                {gridPattern.map(([start, end], index) => (
                    <FlexColumn key={index} className={br.mdDownDisplayNone} style={{ gap: "inherit", flex: 1 }}>
                        {users.slice(start, end).map(user => (
                            <UserFeedback key={user.name} style={cardStyle} {...user} />
                        ))}
                    </FlexColumn>
                ))}
            </Flex>
        </FlexColumnAlignJustifyCenter>
    );
};
