"use client";

import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { themeColors } from "@/utils/themeColors";

const ApplyButton = ({ label, href }) => {
    return (
        <CircularRectTextButton
            sx={{
                fontSize: "1.8rem",
                padding: "1.2rem 3.2rem",
                backgroundColor: themeColors.primary,
            }}
            onClick={() => {
                Mixpanel.track("KnowtTakers Page - Apply Clicked");
                window.open(href, "_blank");
            }}>
            <span className="body1 bold" style={{ color: themeColors.pureBlack }}>
                {label}
            </span>
        </CircularRectTextButton>
    );
};

export default ApplyButton;
