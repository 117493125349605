import { ASSETS_URL } from "@/config/deployConstants";

const KaiGameShow = `${ASSETS_URL}/images/kai-game-show.svg`;
const FlashcardIcon = `${ASSETS_URL}/images/flashcard-icon.svg`;
const ExamIcon = `${ASSETS_URL}/images/ExamIcon.png`;
const YoutubeIcon = `${ASSETS_URL}/images/logo_youtube.png`;
const CanvasIcon = `${ASSETS_URL}/images/logo_canvas.png`;
const ZoomIcon = `${ASSETS_URL}/images/logo_zoom.png`;
const PanoptaIcon = `${ASSETS_URL}/images/logo_panopta.png`;
const YujaIcon = `${ASSETS_URL}/images/logo_yuja.png`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const BrainIcon = `${ASSETS_URL}/images/brain.svg`;
const QuizletIcon = `${ASSETS_URL}/images/quizlet-icon.svg`;
const KnowtLogo = `${ASSETS_URL}/images/knowt-logo-minimal.svg`;
const ImportArrowIcon = `${ASSETS_URL}/images/import-arrow.svg`;
const PowerPointIcon = `${ASSETS_URL}/images/power-point-rounded.svg`;
const GoogleSlidesIcon = `${ASSETS_URL}/images/google-slides.svg`;
const ExcelIcon = `${ASSETS_URL}/images/excel-icon.svg`;
const GoogleSheetsIcon = `${ASSETS_URL}/images/google-sheets-icon.svg`;

export const LANDING_LINKS = {
    AI_VIDEO_SUMMARIZER: "/ai-video-summarizer",
    AI_PDF_SUMMARIZER: "/ai-pdf-summarizer",
    AI_POWERPOINT_SUMMARIZER: "/ai-powerpoint-summarizer",
    AI_SPREADSHEET_SUMMARIZER: "/ai-spreadsheet-summarizer",
    AI_NOTES: "/ai-notes",
    AI_FLASHCARDS: "/",
    MOBILE: "/mobile",
    LEARN_MODE: "/learn-mode",
    CHROME_EXTENSION:
        "https://chromewebstore.google.com/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm?hl=en",
};

export enum LandingPageTopic {
    VideoSummarizer = "Video Summarizer",
    PDFSummarizer = "PDF Summarizer",
    PPTSummarizer = "PPT Summarizer",
    SpreadSheetSummarizer = "Spreadsheet Summarizer",
    AINotes = "AI Notes",
    AIFlashcards = "AI Flashcards",
    School = "School",
}

type LinkedCard = {
    images: string[];
    alt: string;
    imagesWidth: number;
    imagesHeight: number;
    title: string;
    description: string;
    link?: string;
    linkText: string;
    shortLinkText: string;
};

type VideoWithTwoLinkedCard = {
    mainTitle: string;
    videoComponent: {
        title: string;
        subtitle: string;
        videoId: string;
    };
    linkedCards: LinkedCard[];
};

const learnWithTextingContent: VideoWithTwoLinkedCard = {
    mainTitle: "We will watch your lectures for you so you can learn quicker & better",
    videoComponent: {
        title: "Learn by texting?",
        subtitle: "It’s as simple as sending a text to Kai, and he’ll explain everything to you easily.",
        videoId: "899614007",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests on your videos",
            description: "Kai will watch your video and create a test that highlights the key concepts.",
            linkText: "Upload a video now",
            shortLinkText: "Upload video",
        },
        {
            images: [YoutubeIcon, CanvasIcon, ZoomIcon, PanoptaIcon, YujaIcon],
            alt: "press to download chrome extension",
            imagesWidth: 102,
            imagesHeight: 102,
            title: "Upload videos from sites in seconds",
            description: "Use our chrome extension to upload to our AI Video Summarizer from the most popular sites",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const pdfSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Read PDFs faster with our AI-powered PDF Summarizing Tool",
    videoComponent: {
        title: "The PDF Summarizer built to help you learn.",
        subtitle:
            "It’s as simple as sending a text to Kai, and he’ll explain anything in your PDF to you like a tutor would.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests generated from your PDF",
            description: "Kai will read through your PDF and create a test that highlights the key concepts.",
            linkText: "Upload a PDF now",
            shortLinkText: "Upload video",
        },
        {
            images: [pdfIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 140,
            title: "Upload PDFs from any site in seconds",
            description: "Use our Chrome extension to upload a PDF from any webpage to Knowt in seconds.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const spreadsheetSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Understand Spreadsheets faster with our AI-powered Spreadsheet Summarizing Tool",
    videoComponent: {
        title: "The Spreadsheet Summarizer built to help you learn.",
        subtitle:
            "It’s as simple as sending a text to Kai, and he’ll explain anything in your PDF to you like a tutor would.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload spreadsheet",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests generated from your Spreadsheets",
            description: "Kai will read through your spreadsheet and create a test that highlights the key concepts.",
            linkText: "Upload a spreadsheet now",
            shortLinkText: "Upload spreadsheet",
        },
        {
            images: [ExcelIcon, GoogleSheetsIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 140,
            title: "Upload Spreadsheets from any site in seconds",
            description: "Use our Chrome extension to upload a Spreadsheet from any webpage to Knowt in seconds.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const pptSummarizerToHelpContent: VideoWithTwoLinkedCard = {
    mainTitle: "Learn your powerpoints faster with our AI-powered PPT Summarizing Tool",
    videoComponent: {
        title: "The PPT Summarizer built to help you learn.",
        subtitle:
            "It’s as simple as sending a text to Kai, and he’ll explain anything in your PPT to you like a tutor would.",
        videoId: "899614017",
    },
    linkedCards: [
        {
            images: [KaiGameShow],
            alt: "press to upload video",
            imagesWidth: 195,
            imagesHeight: 220,
            title: "Take practice tests generated from your PPT",
            description: "Kai will read through your PPT and create a test that highlights the key concepts.",
            linkText: "Upload a PPT now",
            shortLinkText: "Upload video",
        },
        {
            images: [GoogleSlidesIcon, PowerPointIcon],
            alt: "press to download chrome extension",
            imagesWidth: 107,
            imagesHeight: 100,
            title: "Upload PPTs from any site in seconds",
            description: "Use our Chrome extension to upload a PPT from any webpage to Knowt in seconds.",
            link: LANDING_LINKS.CHROME_EXTENSION,
            linkText: "Download the chrome extension",
            shortLinkText: "Upload video",
        },
    ],
};

const aiNotesContent: VideoWithTwoLinkedCard = {
    mainTitle: "Turn your Notes into Flashcards with our AI-Powered Notes",
    videoComponent: {
        title: "The AI Flashcard Maker built to help you learn.",
        subtitle: "It’s as simple as pasting in your text and pressing a single button",
        videoId: "899614079",
    },
    linkedCards: [
        {
            images: [FlashcardIcon],
            alt: "turn your notes into flashcards instantly",
            imagesWidth: 80,
            imagesHeight: 87,
            title: "Turn your notes into flashcards instantly",
            description: "Upload your class notes or paste any text into the text box",
            linkText: "Create flashcards from a note",
            shortLinkText: "Create flashcards",
        },
        {
            images: [ExamIcon],
            alt: "turn your notes into practice quizzes",
            imagesWidth: 76,
            imagesHeight: 80,
            title: "Turn your notes into practice quizzes",
            description: "Test your understanding of the material by quizzing yourself.",
            linkText: "Take a practice quiz",
            shortLinkText: "Take a quiz",
        },
    ],
};

export const videoWithTwoLinkedCardsContent: Record<LandingPageTopic, VideoWithTwoLinkedCard | null> = {
    [LandingPageTopic.VideoSummarizer]: learnWithTextingContent,
    [LandingPageTopic.PDFSummarizer]: pdfSummarizerToHelpContent,
    [LandingPageTopic.PPTSummarizer]: pptSummarizerToHelpContent,
    [LandingPageTopic.SpreadSheetSummarizer]: spreadsheetSummarizerToHelpContent,
    [LandingPageTopic.AINotes]: aiNotesContent,
    [LandingPageTopic.AIFlashcards]: null, // TODO: fill this in
    [LandingPageTopic.School]: null,
};

type DoMoreWithLessTimeCard = {
    left1: {
        title: string;
        desc: string;
        linkText?: string;
        link?: string;
        src?: string;
        videoId?: string;
    };
    left2: {
        title: string;
        desc: string;
        linkText?: string;
        link?: string;
        src?: string;
        videoId?: string;
    };
    right: {
        title: string;
        desc: string;
        linkText: string;
        link: string;
        comingSoon?: boolean;
        src: string;
    };
};

const doMoreWithLessTimeVideo: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant Video Summaries",
        desc: "Kai will watch your video, find the key details for you and make you an outlined video summary on the important stuff.",
        linkText: "AI Video Summarizer",
        link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
        videoId: "899614007",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will watch your video, find the key details and make flashcards from the video for you to practice. ",
        linkText: "Upload a Video",
        link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
        videoId: "899614064",
    },
    right: {
        title: "Watch your lecture videos & practice on the go",
        desc: "Pick up where you left off on your videos by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        comingSoon: true,
        src: `${ASSETS_URL}/images/mobile-video-summarizer.svg`,
    },
};

const doMoreWithLessTimePDF: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant PDF Summaries",
        desc: "Kai will read your PDF, find the key details for you and make you a summary of the important stuff.",
        linkText: "AI PDF Summarizer",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will read your PDF, find the key details and make flashcards from for you to practice.",
        linkText: "Upload a PDF",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Mark up your PDF with annotations and comments",
        desc: "Our PDF editor lets you write directly on your uploaded files.",
        linkText: "Upload a PDF",
        link: LANDING_LINKS.AI_PDF_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/equations-screenshot.svg`,
    },
};

const doMoreWithLessTimePPT: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant PPT Summaries",
        desc: "Kai will read through your PPT, find the key details, and make you a summary of the important stuff.",
        linkText: "AI PPT Summarizer",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will read through your PPT, find the key details, and make flashcards for you to practice. ",
        linkText: "Upload a PPT",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Mark up your PPT with annotations and comments",
        desc: "Our PDF editor lets you write directly on your uploaded files.",
        linkText: "Upload a PPT",
        link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/ppt-screenshot.svg`,
    },
};

const doMoreWithLessTimeSpreadsheet: DoMoreWithLessTimeCard = {
    left1: {
        title: "Instant Spreadsheet Summaries",
        desc: "Kai will read through your Spreadsheet, find the key details, and make you a summary of the important stuff.",
        linkText: "Spreadsheet Summarizer",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        videoId: "899614027",
    },
    left2: {
        title: "Flashcards ready for you",
        desc: "Kai will read through your PPT, find the key details, and make flashcards for you to practice. ",
        linkText: "Upload a Spreadsheet",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        videoId: "899614041",
    },
    right: {
        title: "Mark up your Spreadsheet with annotations and comments",
        desc: "Our Spreadsheet editor lets you write directly on your uploaded files.",
        linkText: "Upload a Spreadsheet",
        link: LANDING_LINKS.AI_SPREADSHEET_SUMMARIZER,
        comingSoon: false,
        src: `${ASSETS_URL}/images/equations-screenshot.svg`,
    },
};

const doMoreWithLessTimeAINotes: DoMoreWithLessTimeCard = {
    left1: {
        title: "AI powered notetaking",
        desc: "Prompt the AI and ask it to help you kickstart your essay, outline, and more.",
        linkText: "Start a new note",
        link: LANDING_LINKS.AI_FLASHCARDS,
        videoId: "899614079",
    },
    left2: {
        title: "Note formatting",
        desc: "Highlight, Bold, Italicize, and more. It’s just like using Google Docs. ",
        linkText: "Start a new note",
        link: LANDING_LINKS.AI_FLASHCARDS,
        src: `${ASSETS_URL}/images/highlight-screenshot.svg`,
    },
    right: {
        title: "Write and review notes on the go",
        desc: "Pick up where you left off on your flashcard sets by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        comingSoon: false,
        src: `${ASSETS_URL}/images/note-screenshot.svg`,
    },
};

const doMoreWithLessTimeAIFlashcards: DoMoreWithLessTimeCard = {
    left1: {
        title: "Learn mode",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Learn mode helps you intelligently go through all your flashcards with multiple choice, written, and true and false questions.",
        videoId: "899614093",
    },
    left2: {
        title: "Practice test",
        link: LANDING_LINKS.LEARN_MODE,
        desc: "Practice test mode turns your flashcards into a test-like environment with multiple choice, true/false and other question types.",
        videoId: "899614101",
    },
    right: {
        title: "Study your AI Flashcards on the go",
        desc: "Pick up where you left off on your flashcard sets by studying from your phone.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/study-screenshot.svg`,
    },
};

const doMoreWithLessTimeSchool: DoMoreWithLessTimeCard = {
    left1: {
        title: "Track student progress on flashcards",
        desc: "View individual student’s mastery percentages,  how long they’ve spent studying, and when they last logged on.",
        src: `${ASSETS_URL}/images/progress-card.svg`,
    },
    left2: {
        title: "Share files with your students",
        desc: "Newly created files will be automatically shared with students in your class.",
        src: `${ASSETS_URL}/images/class-card.svg`,
    },
    right: {
        title: "Access your classroom on the go",
        desc: "Quickly see progress, share files with students, and more.",
        linkText: "Knowt mobile app",
        link: LANDING_LINKS.MOBILE,
        src: `${ASSETS_URL}/images/class-mobile-dashboard.svg`,
    },
};

export const doMoreWithLessTimeCardsContent: Record<LandingPageTopic, DoMoreWithLessTimeCard | null> = {
    [LandingPageTopic.VideoSummarizer]: doMoreWithLessTimeVideo,
    [LandingPageTopic.PDFSummarizer]: doMoreWithLessTimePDF,
    [LandingPageTopic.PPTSummarizer]: doMoreWithLessTimePPT,
    [LandingPageTopic.SpreadSheetSummarizer]: doMoreWithLessTimeSpreadsheet,
    [LandingPageTopic.AINotes]: doMoreWithLessTimeAINotes,
    [LandingPageTopic.AIFlashcards]: doMoreWithLessTimeAIFlashcards,
    [LandingPageTopic.School]: doMoreWithLessTimeSchool,
};

export const smartCardsDoMoreWithLessTimeFeedbacks = [
    {
        name: "Macie Allen",
        grade: "College Freshman",
        desc: "I loved knowt for vocab flashcards! Especially to import from quizlet with a link literally chefs kisss.",
        src: `${ASSETS_URL}/images/marcie-allen-profile.svg`,
    },
    {
        name: "Sam Loos",
        grade: "High School Senior",
        desc: "I used knowt to study for my apush midterm and it saved my butt! The import from quizlet feature helped a ton too. Slayed that test with an A!! 😻😻😻",
        src: `${ASSETS_URL}/images/sam-loos-profile.svg`,
    },
];

const schoolDoMoreWithLessTimeFeedbacks = [
    {
        name: "Utilize sections for sharing",
        desc: "Teach multiple periods of the same class? Use sections to separate students into separate groups.",
    },
];

export const doMoreWithLessTimeFeedbacks = {
    [LandingPageTopic.VideoSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.PDFSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.PPTSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.SpreadSheetSummarizer]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.AINotes]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.AIFlashcards]: smartCardsDoMoreWithLessTimeFeedbacks,
    [LandingPageTopic.School]: schoolDoMoreWithLessTimeFeedbacks,
};

export const smartCardsLinkedCards: LinkedCard[] = [
    {
        images: [BrainIcon],
        alt: "turn your notes into flashcards instantly",
        imagesWidth: 83,
        imagesHeight: 80,
        title: "Free Learn Mode",
        description: "Keep studying the way that works best for you - for free.",
        link: LANDING_LINKS.AI_FLASHCARDS,
        linkText: "Try Learn Mode",
        shortLinkText: "Try Learn Mode",
    },
    {
        images: [QuizletIcon, ImportArrowIcon, KnowtLogo],
        alt: "turn your notes into practice quizzes",
        imagesWidth: 61,
        imagesHeight: 63,
        title: "The only free Quizlet alternative you’ll need",
        description: "Import Quizlet sets into Knowt with a single click & study it for free.",
        link: LANDING_LINKS.AI_FLASHCARDS,
        linkText: "Import a set from Quizlet",
        shortLinkText: "Import a set from Quizlet",
    },
];

type EndlessWaysCard = {
    title: string;
    desc: string;
    linkText: string;
    link: string;
    src?: string;
    alt?: string;
};

const videoSummarizerData: EndlessWaysCard = {
    title: "Video Summarizer",
    desc: "Kai will watch your lecture videos, turn them into notes and flashcards instantly.",
    linkText: "AI Video Summarizer",
    link: LANDING_LINKS.AI_VIDEO_SUMMARIZER,
    src: `${ASSETS_URL}/images/video-summarizer-screenshot.svg`,
    alt: "Click to learn more about Video Summarizer",
};

const pdfSummarizerData: EndlessWaysCard = {
    title: "PDF Summarizer",
    desc: "Kai will read your PDF, find the key details for you and make notes and flashcards for you to practice",
    linkText: "AI PDF Summarizer",
    link: LANDING_LINKS.AI_PDF_SUMMARIZER,
    src: `${ASSETS_URL}/images/pdf-summarizer.svg`,
    alt: "Click to learn more about PDF Summarizer",
};

const pptSummarizerData: EndlessWaysCard = {
    title: "PPT Summarizer",
    desc: "Kai will read your PPT, make notes and flashcards instantly.",
    linkText: "AI PPT Summarizer",
    link: LANDING_LINKS.AI_POWERPOINT_SUMMARIZER,
    src: `${ASSETS_URL}/images/kai-sherlock.svg`,
    alt: "Click to learn more about PPT Summarizer",
};

const aiFlashcardsData: EndlessWaysCard = {
    title: "AI Flashcards",
    desc: "You can make flashcards and study them for free with learn mode, spaced repetition, and more.",
    linkText: "AI Flashcards",
    link: LANDING_LINKS.AI_FLASHCARDS,
};

const aiNotesData: EndlessWaysCard = {
    title: "AI Notes",
    desc: "You can take notes and Kai will make flashcards from them instantly. ",
    linkText: "AI Notes",
    link: LANDING_LINKS.AI_NOTES,
};

// TODO: revisit the data here, some of them are duplicated to save time
export const endlessWaysCardsContent: Record<
    LandingPageTopic,
    { card1: EndlessWaysCard; card2: EndlessWaysCard; card3: EndlessWaysCard; card4: EndlessWaysCard } | null
> = {
    [LandingPageTopic.VideoSummarizer]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [LandingPageTopic.PDFSummarizer]: {
        card1: videoSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [LandingPageTopic.PPTSummarizer]: {
        card1: pdfSummarizerData,
        card2: videoSummarizerData,
        card3: aiFlashcardsData,
        card4: aiNotesData,
    },
    [LandingPageTopic.SpreadSheetSummarizer]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: videoSummarizerData,
    },
    [LandingPageTopic.AINotes]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: aiFlashcardsData,
        card4: videoSummarizerData,
    },
    [LandingPageTopic.AIFlashcards]: {
        card1: pdfSummarizerData,
        card2: pptSummarizerData,
        card3: videoSummarizerData,
        card4: aiNotesData,
    },
};
