import { AICompletionType } from "@knowt/syncing/graphql/schema";
import Image from "next/image";
import React, { useEffect } from "react";
import { SparklesIcon } from "lucide-react";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { themeColors } from "@/utils/themeColors";
import {
    AIPrompt,
    AI_PROMPTS,
    AiEditorMutationType,
} from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { ASSETS_URL } from "@/config/deployConstants";
import GuidedWalkthrough, { useGuidedWalkthrough, WALKTHROUGH_LABELS } from "@/features/GuidedWalkthrough";

const notesImg = `${ASSETS_URL}/images/note-icon.svg`;
const driveImg = `${ASSETS_URL}/images/drive.svg`;

const WALKTRHOUGH_ASK_AI_BTN = "walkthrough-ask-knowt-ai-btn";
// eslint-disable-next-line padding-line-between-statements
const ChooseAIOptionGuidedWalkthrough = () => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CHOOSE_NOTE_TEMPLATE,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        if (location.href.includes("/media")) return;

        activateWalkthrough({});
    }, [activateWalkthrough, hasWalkthroughTaken, isWalkthroughActive]);

    return (
        <GuidedWalkthrough
            onFinish={doneWalkthrough}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Choose an option or start typing",
                    content:
                        "Write your own note or click on one of the quick start options to get a headstart with the help of AI.",
                    placement: "right-end",
                    target: "." + WALKTRHOUGH_ASK_AI_BTN,
                    onClick: () => doneWalkthrough(),
                },
            ]}
            disableScrollParentFix={true}
        />
    );
};

type HoverableSectionProps = {
    imgComponent: React.ReactNode;
    label: string;
    onClick: () => void;
    hoverSx?: SxProps;
    className?: string;
};

const HoverableSection = ({ imgComponent, label, onClick, hoverSx, className }: HoverableSectionProps) => {
    return (
        <FlexRowAlignCenter
            className={className}
            as={Box}
            role="button"
            onClick={onClick}
            sx={{
                gap: "1.5rem",
                padding: "1rem 3.5rem 1rem 1.5rem",
                fontSize: "1.7rem",
                borderRadius: "5rem",
                border: `1px solid ${themeColors.neutralBlack}`,
                cursor: "pointer",
                pointerEvents: "all", // by default, note editor `placeholder` is unclickable, so we need to enable pointer events to this "container"
                backgroundColor: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                width: "fit-content",
                "&:hover": {
                    backgroundColor: themeColors.neutralBlack,
                    color: themeColors.neutralWhite,
                    ...hoverSx,
                },
                transition: "all 0.2s ease",
            }}>
            {imgComponent}
            {label}
        </FlexRowAlignCenter>
    );
};

type AITemplateProps = {
    onStartWithEmptyPage: () => void;
    templates: (AICompletionType | AiEditorMutationType)[];
    openAiInputMenu: (prompt?: AIPrompt) => void;
    openImportPopup: () => void;
};

const AITemplate = ({ onStartWithEmptyPage, templates, openAiInputMenu, openImportPopup }: AITemplateProps) => {
    const prompts = templates.map(promptType => AI_PROMPTS[promptType]) as AIPrompt[];

    return (
        <FlexColumn>
            {/* PLACEHOLDER AT TOP */}
            <FlexColumn style={{ gap: "1rem" }}>
                <HoverableSection
                    onClick={onStartWithEmptyPage}
                    imgComponent={<Image src={notesImg} width="22" height="25" alt="start with empty page" />}
                    label={"Start with an Empty Page…"}
                />
                <div>
                    <HoverableSection
                        className={WALKTRHOUGH_ASK_AI_BTN}
                        onClick={() => openAiInputMenu()}
                        imgComponent={<SparklesIcon size={iconSizes.SM} color={themeColors.neutralBlack} />}
                        label={"Ask Knowt AI to start writing…"}
                        hoverSx={{
                            "& svg": {
                                stroke: themeColors.neutralWhite,
                            },
                        }}
                    />
                    <ChooseAIOptionGuidedWalkthrough />
                </div>
                <HoverableSection
                    onClick={() => openImportPopup()}
                    imgComponent={<Image src={driveImg} width="22" height="22" alt="ask ai" />}
                    label={"Create a note from Google Drive or file from your device..."}
                />
            </FlexColumn>

            {/* PLACEHOLDER AT BOTTOM */}
            <FlexColumn style={{ margin: "3rem 0 5rem", gap: "1rem" }}>
                <span
                    style={{
                        fontWeight: "600",
                        fontSize: "1.8rem",
                        marginBottom: "0.6rem",
                        color: themeColors.neutral2,
                    }}>
                    Quick Starts | Ask AI to
                </span>
                {prompts.map((prompt, i) => {
                    return (
                        <HoverableSection
                            key={prompt.type + i}
                            onClick={() => openAiInputMenu(prompt)}
                            imgComponent={<Image src={prompt.image} width="22" height="22" alt="quick start" />}
                            label={prompt?.placeholder || ""}
                        />
                    );
                })}
            </FlexColumn>
        </FlexColumn>
    );
};

export default AITemplate;
