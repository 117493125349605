"use client";

import { spacing } from "@/utils/spacing";
import br from "@/styles/breakpoints.module.css";
import { themeColors } from "@/utils/themeColors";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import Link from "@/components/wrappers/Link";

const ComplianceHeader = () => {
    return (
        <FlexRowAlignCenter
            className={br.smDownColumn}
            style={{
                backgroundColor: themeColors.primaryLight,
                justifyContent: "center",
                padding: `${spacing.SM}`,
                gap: spacing.LG_2,
            }}>
            <FlexColumn style={{ textAlign: "center" }}>
                <span className="bodyBold1" style={{ color: themeColors.pureBlack }}>
                    Knowt is compliant with FERPA, COPPA, NY2D and more.
                </span>
                <span className="body2" style={{ color: themeColors.pureBlack }}>
                    Quizlet may not sign your DPA, but we will.
                </span>
            </FlexColumn>
            <Link href="https://form.fillout.com/t/aymZi6NipYus" openInNewTab>
                <CircularRectTextButton
                    component={"div"}
                    style={{
                        backgroundColor: themeColors.primary,
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                        borderRadius: "1rem",
                    }}>
                    <span className="bodyBold2" style={{ color: themeColors.primaryDark }}>
                        Get in touch!
                    </span>
                </CircularRectTextButton>
            </Link>
        </FlexRowAlignCenter>
    );
};

export default ComplianceHeader;
