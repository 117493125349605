export type SearchContentGridProps = { children?: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>;

/**
 * A div with `display: grid; grid-template-columns: repeat(auto-fill, minmax(<number>,1fr));`
 * used to render a grid of cards
 */
const WithPreviewerContentGrid = ({ children, style, ...divProps }: SearchContentGridProps) => {
    return (
        <div
            {...divProps}
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, minmax(25rem,1fr))`,
                gap: "3rem 3rem",
                height: "fit-content",
                ...style,
            }}>
            {children}
        </div>
    );
};

export default WithPreviewerContentGrid;
