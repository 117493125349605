"use client";

import SectionContainer from "../../../components/SectionContainer";
import React from "react";
import { EditorContextProvider } from "@/components/FullPageEditor/EditorContext";
import Editor from "./Editor";
import KnowtStats from "../../../components/KnowtStats";
import UploadDisclaimer from "../UploadDisclaimer";

type DropSectionProps = {
    title: string;
    subtitle: React.ReactNode;
};

const DropSection = ({ title, subtitle }: DropSectionProps) => {
    return (
        <SectionContainer title={title} subtitle={subtitle} style={{ gap: "3.2rem" }} isPageHeader>
            <EditorContextProvider
                editable={true}
                content={""}
                disabledCustomPlaceholder={true}
                disableAiInputMenuTrigger={true}
                disableAiCommands={true}
                characterCountLimit={10_000}
                placeholder={"Paste your text here for Kai to read, or use ‘/’ for formatting"}>
                <Editor />
            </EditorContextProvider>
            <UploadDisclaimer />
            <KnowtStats />
        </SectionContainer>
    );
};

export default DropSection;
