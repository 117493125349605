"use client";

import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useAuthSlideContextSelector } from "@/features/Auth";
import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";

const LandingMainButton = ({
    primaryBg,
    secondaryColor,
    primaryColor,
    secondaryBg,
    children,
    sx,
    ...buttonProps
}: CircularRectTextButtonProps & {
    primaryColor?: string;
    primaryBg: string;
    secondaryColor: string;
    secondaryBg: string;
}) => {
    const { user } = useCurrentUser();
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    return !user ? (
        <CircularRectTextButton
            {...buttonProps}
            onClick={() => openAuthSlide({ showSignupPage: true })}
            sx={{
                backgroundColor: primaryBg,
                color: primaryColor || themeColors.pureWhite,
                fontSize: "2.4rem",
                height: "6.5rem",
                padding: "0 1.8rem",
                "&:hover": {
                    backgroundColor: secondaryBg,
                    color: secondaryColor,
                },
                "&:active": {},
                ...sx,
            }}>
            {children}
        </CircularRectTextButton>
    ) : null;
};

export default LandingMainButton;
