"use client";

import { Flex, FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { LandingCenteredHalfSection } from "../../components/styled/Containers";
import { H1 } from "../../components/styled/Headings";
import { H1Paragraph } from "../../components/styled/Paragraphs";
import styles from "../../landing.module.css";
import { themeColors } from "@/utils/themeColors";
import Image from "next/image";
import BlockQuote from "../../components/BlockQuote";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { useSearchParams } from "next/navigation";
import clsx from "clsx";
import br from "@/styles/breakpoints.module.css";
import VimeoVideo from "@/components/VimeoVideo";
import pageStyles from "../offersPage.module.css";
import { PRICING_OPTIONS } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { fetchPostJSON } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import getStripe from "@/utils/stripe/stripeJS";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import toast from "react-hot-toast";
import { AuthEvent, SS_OFFERS_PROMO } from "@/features/Auth";
import { OfferTypes } from "@/features/PlansPages";
import { Suspense } from "react";

const knowtXunidaysImg = "/images/knowtXunidays.svg";
const knowtXstudentbeansImg = "/images/knowtXstudentbeans.svg";
const studyToolImg = "/images/kai-study-tool.svg";

const ClaimOfferButton = ({ offerType }: { offerType: OfferTypes }) => {
    const { user, subscriptionType } = useCurrentUser();
    const { checkAuth } = useCheckAuth();
    const searchParams = useSearchParams();

    const code = searchParams?.get("promoCode");

    return (
        <CircularRectTextButton
            onClick={() => {
                if (!code) {
                    return toast.error(`No promo code found! Please return to the ${offerType} page.`);
                }

                if (!user) {
                    window.sessionStorage.setItem(SS_OFFERS_PROMO(offerType), code);
                }

                checkAuth(
                    async () => {
                        if (subscriptionType !== SubscriptionType.BASIC) {
                            return toast.error("You already have a subscription!");
                        }

                        const response = await fetchPostJSON("/api/checkout_sessions", {
                            priceId: PRICING_OPTIONS.MONTHLY_LIMITLESS.priceId,
                            email: user?.Email,
                            customerId: user?.customerId,
                            // code param
                            code: code,
                            ...(offerType && { offerType }),
                        });

                        if (response.statusCode === 500) {
                            return;
                        }

                        const stripe = await getStripe();

                        await stripe?.redirectToCheckout({
                            sessionId: response.data.id,
                        });
                    },
                    {
                        event:
                            offerType === OfferTypes.UNIDAYS ? AuthEvent.UNIDAYS_PROMO : AuthEvent.STUDENT_BEANS_PROMO,
                    }
                );
            }}
            sx={{
                backgroundColor: themeColors.primary,
                color: themeColors.primaryDark,
                borderRadius: "5rem",
                fontWeight: "600",
                fontSize: "1.8rem",
                padding: "1rem 2rem",
                width: "23rem",
                height: "5rem",
            }}>
            {"Start your free trial"}
        </CircularRectTextButton>
    );
};

export const OffersSection = ({ offerType }: { offerType: OfferTypes }) => {
    const src = offerType === OfferTypes.UNIDAYS ? knowtXunidaysImg : knowtXstudentbeansImg;
    return (
        <LandingCenteredHalfSection>
            <FlexRowAlignCenter className={clsx(br.mdDownColumnReverse)} style={{ gap: "5rem" }}>
                <FlexColumn className={styles.mdDownAlignJustifyCenter}>
                    <Image
                        src={src}
                        width={474}
                        height={63}
                        alt="knowtXunidays"
                        style={{
                            maxWidth: "85vw",
                            objectFit: "contain",
                        }}
                    />
                    <div
                        className={clsx(br.mdUpDisplayNone, pageStyles.offersVidContainer)}
                        style={{ borderRadius: "4rem", maxWidth: "62.3rem" }}>
                        <VimeoVideo id={"889354283"} />
                    </div>
                    <H1
                        className={br.mdDownTextAlignCenter}
                        style={{
                            maxWidth: "41.8rem",
                            fontSize: "4.1rem",
                            lineHeight: "7.2rem",
                            fontWeight: "700",
                            textAlign: undefined,
                            margin: "1.6rem 0 1.8rem",
                        }}>
                        Start your 1 month free trial of Limitless
                    </H1>

                    <H1Paragraph
                        className={br.mdDownTextAlignCenter}
                        style={{ marginBottom: "3.2rem", maxInlineSize: "42rem", textAlign: undefined }}>
                        {offerType} has partnered with Knowt to offer you 1 free month & an additional 15% of Pro and
                        Limitless after your trial.
                    </H1Paragraph>
                    <Suspense>
                        <ClaimOfferButton offerType={offerType} />
                    </Suspense>
                </FlexColumn>
                <div
                    className={clsx(br.mdDownDisplayNone, pageStyles.offersVidContainer)}
                    style={{ borderRadius: "4rem", maxWidth: "62.3rem", height: "35.2rem" }}>
                    <VimeoVideo id={"889354283"} />
                </div>
            </FlexRowAlignCenter>
        </LandingCenteredHalfSection>
    );
};

export const Statistics = () => {
    const renderDesktopBubbles = () => {
        return [
            { top: "60px", left: "50px" },
            { bottom: "-40px", left: "160px" },
            { bottom: "-40px", left: "35%" },
            { top: "60px", left: "57%" },
            { bottom: "-40px", left: "63%" },
            { top: "-40px", right: "10%" },
            { bottom: "-40px", right: "-40px" },
        ].map((position, index) => (
            <div
                key={index}
                style={{
                    zIndex: 1,
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: "#daf4f1",
                    height: "80px",
                    width: "80px",
                    ...position,
                }}
            />
        ));
    };

    const renderMobileBubbles = () => {
        return [
            { top: "30px", left: "-40px" },
            { top: "100px", right: "-20px" },
            { top: "170px", left: "0" },
            { top: "266px", right: "-40px" },
        ].map((position, index) => (
            <div
                key={index}
                style={{
                    zIndex: 1,
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: "#daf4f1",
                    height: "80px",
                    width: "80px",
                    ...position,
                }}
            />
        ));
    };

    return (
        <Flex
            style={{
                padding: "7rem 0",
                width: "100%",
                color: themeColors.primaryDark,
                position: "relative",
                backgroundColor: themeColors.primary,
                overflow: "hidden",
            }}>
            <div className={br.mdDownDisplayNone}>{renderDesktopBubbles()}</div>
            <div className={br.mdUpDisplayNone}>{renderMobileBubbles()}</div>
            <FlexRowAlignCenter
                className={br.mdDownColumn}
                style={{
                    justifyContent: "space-between",
                    gap: "2rem",
                    flex: 1,
                    maxWidth: "95rem",
                    margin: "0 auto",
                    zIndex: 2,
                }}>
                <FlexColumnAlignJustifyCenter>
                    <H1 style={{ fontSize: "3.6rem", margin: "0", color: themeColors.primaryDark }}>1,000,000 +</H1>
                    <div>students</div>
                </FlexColumnAlignJustifyCenter>
                <FlexColumnAlignJustifyCenter>
                    <H1 style={{ fontSize: "3.6rem", margin: "0", color: themeColors.primaryDark }}>2 million +</H1>
                    <div>flashcard sets</div>
                </FlexColumnAlignJustifyCenter>
                <FlexColumnAlignJustifyCenter>
                    <H1 style={{ fontSize: "3.6rem", margin: "0", color: themeColors.primaryDark }}>3.5 million +</H1>
                    <div>study sessions</div>
                </FlexColumnAlignJustifyCenter>
            </FlexRowAlignCenter>
        </Flex>
    );
};

export const StudyTool = ({ offerType }: { offerType: OfferTypes }) => {
    return (
        <LandingCenteredHalfSection style={{ margin: "auto", width: "100%" }}>
            <Image
                className={br.smDownWidth100}
                src={studyToolImg}
                alt={"One study tool that replace the rest"}
                height="500"
                width="500"
                sizes="100vw"
                style={{ width: "75%", maxWidth: "85rem", height: "auto", margin: "auto" }}
            />
            <BlockQuote
                style={{ marginTop: "6rem" }}
                quoteColor={themeColors.primary}
                title={
                    <span style={{ color: themeColors.primaryDark }}>
                        It’s so refreshing to have one app for everything. I wasted so much time moving between Google
                        Docs and Quizlet every day.
                    </span>
                }
                desc={<span style={{ color: themeColors.neutral3 }}>Neha Ravuri, College Junior</span>}
            />
            <Suspense>
                <ClaimOfferButton offerType={offerType} />
            </Suspense>
        </LandingCenteredHalfSection>
    );
};
