import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import {
    TaggingStyleModifier,
    SubjectTagging,
    TopicTagging,
    ExamTagging,
    ExamUnitTagging,
    ExamSectionTagging,
} from "@/features/Tagging";
import { spacing } from "@/utils/spacing";
import { useAllExams } from "@knowt/syncing/hooks/exams/useExams";
import { useSubjectsAsObject } from "@knowt/syncing/hooks/subjects/useSubjects";
import { Media } from "@knowt/syncing/graphql/schema";
import React from "react";

type UploadTaggingSectionProp = {
    media: Media;
    tagSelectorBackgroundColor?: string;
    tagSelectorBorderWidth?: string | number;
    onChange: (props: {
        subject?: string | null;
        topic?: string | null;
        exam?: string | null;
        examUnit?: string | null;
        examSection?: string | null;
    }) => void;
    tags: {
        subject: string | null;
        topic: string | null;
        exam: string | null;
        examUnit: string | null;
        examSection: string | null;
    };
};

const MediaEditTaggingSection = React.memo(
    ({ onChange, tags, tagSelectorBackgroundColor, tagSelectorBorderWidth }: UploadTaggingSectionProp) => {
        const { subject, topic, exam, examUnit, examSection } = tags;

        const subjects = useSubjectsAsObject();
        const doesSubjectHasTopics = subject && !!subjects?.[subject]?.topics?.length;

        const exams = useAllExams();
        const doesExamHasUnits = exam && !!Object.keys(exams?.[exam]?.units || {}).length;
        const doesExamHasSections = exam && examUnit && !!exams?.[exam]?.units?.[examUnit]?.sections?.length;

        return (
            <FlexColumn style={{ gap: spacing.XS_2 }}>
                <h4 style={{ fontWeight: 600 }}>Tags</h4>
                <FlexRowAlignCenter style={{ justifyContent: "space-between", gap: "1rem" }}>
                    <TaggingStyleModifier
                        labelStyle={{ display: "none" }}
                        inputStyle={{
                            borderWidth: tagSelectorBorderWidth ?? "1.5px",
                            backgroundColor: tagSelectorBackgroundColor ?? "transparent",
                        }}>
                        <SubjectTagging
                            containerStyle={{ flex: 1, maxWidth: "50%" }}
                            subject={subject}
                            onChange={newSubject => {
                                onChange({ subject: newSubject });
                            }}
                        />
                    </TaggingStyleModifier>
                    {doesSubjectHasTopics && (
                        <TaggingStyleModifier
                            labelStyle={{ display: "none" }}
                            inputProps={{
                                sx: {
                                    borderWidth: tagSelectorBorderWidth ?? "1.5px",
                                    backgroundColor: tagSelectorBackgroundColor ?? "transparent",
                                },
                            }}>
                            <TopicTagging
                                containerStyle={{ flex: 1 }}
                                topic={topic}
                                subject={subject}
                                onChange={newTopic => {
                                    onChange({ topic: newTopic });
                                }}
                            />
                        </TaggingStyleModifier>
                    )}
                </FlexRowAlignCenter>
                <FlexRowAlignCenter style={{ width: "100%", justifyContent: "space-between", gap: "1rem" }}>
                    <TaggingStyleModifier
                        labelStyle={{ display: "none" }}
                        inputStyle={{
                            borderWidth: tagSelectorBorderWidth ?? "1.5px",
                            backgroundColor: tagSelectorBackgroundColor ?? "transparent",
                        }}>
                        <ExamTagging
                            containerStyle={{ flex: 1, maxWidth: "50%" }}
                            exam={exam}
                            onChange={newExam => onChange({ exam: newExam })}
                        />
                    </TaggingStyleModifier>
                    {exam && doesExamHasUnits && (
                        <TaggingStyleModifier
                            inputStyle={{
                                borderWidth: tagSelectorBorderWidth ?? "1.5px",
                                backgroundColor: tagSelectorBackgroundColor ?? "transparent",
                            }}
                            labelStyle={{ display: "none" }}>
                            <ExamUnitTagging
                                containerStyle={{ flex: 1 }}
                                examUnit={examUnit}
                                exam={exam}
                                onChange={newUnit => onChange({ examUnit: newUnit })}
                            />
                        </TaggingStyleModifier>
                    )}
                    {exam && examUnit && doesExamHasSections && (
                        <TaggingStyleModifier
                            labelStyle={{ display: "none" }}
                            inputStyle={{
                                borderWidth: tagSelectorBorderWidth ?? "1.5px",
                                backgroundColor: tagSelectorBackgroundColor ?? "transparent",
                            }}>
                            <ExamSectionTagging
                                examSection={examSection}
                                exam={exam}
                                examUnit={examUnit}
                                onChange={newExamSection => onChange({ examSection: newExamSection })}
                            />
                        </TaggingStyleModifier>
                    )}
                </FlexRowAlignCenter>
            </FlexColumn>
        );
    }
);

MediaEditTaggingSection.displayName = "MediaEditTaggingSection";

export default MediaEditTaggingSection;
