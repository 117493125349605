import { BubbleMenu, Editor } from "@knowt/editor/react";
import { useState } from "react";
import { Menus, MENUS_STATE_META_KEY } from "../extensions/MenusState";
import { useEditorContextSelector } from "../EditorContext";
import LinkEditor from "./LinkEditor";

const LinkEditorMenu = () => {
    const [key, setKey] = useState(0);

    const editor = useEditorContextSelector(context => context.editor);
    if (!editor) return null;

    const handleClosed = () => {
        editor
            .chain()
            .focus()
            .setMeta(MENUS_STATE_META_KEY, { [Menus.LinkEditorMenu]: false })
            .run();
    };

    const shouldShow = ({ editor }: { editor: Editor }) => {
        const openedMenusSet = editor.storage.menusState?.openedMenusSet;
        if (!openedMenusSet) return false;

        return openedMenusSet.has(Menus.LinkEditorMenu);
    };

    return (
        <BubbleMenu
            editor={editor}
            shouldShow={shouldShow}
            tippyOptions={{
                moveTransition: "transform 0.15s ease-out",
                maxWidth: 1000,
                zIndex: 999,
                onHidden: handleClosed,
                onClickOutside: handleClosed,
                onShow: () => setKey(k => k + 1),
            }}>
            <LinkEditor key={key} editor={editor} handleClose={handleClosed} />
        </BubbleMenu>
    );
};

export default LinkEditorMenu;
