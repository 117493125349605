"use client";

import React from "react";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import WithPreviewerPageContentGrid from "../../../components/styled/WithPreviewerPageContentGrid";
import SearchPageUserCard from "../SearchPageUserCard";
import { chunkItemForAds, useNumOfItemInRow } from "./utils";
import { HorizontallyCenteredRepeatableLeaderboardAdSlot } from "@/features/Ads";

export const UsersTabGridWithAds = ({ users }: { users: UserDetails[] }) => {
    const numOfItemInRow = useNumOfItemInRow("search-content-grid");

    const renderChunk = (users: UserDetails[]) => (
        <WithPreviewerPageContentGrid id={"search-content-grid"}>
            {users.map((user, i) => (
                <SearchPageUserCard key={user.Email + user?.ID + i} user={user} />
            ))}
        </WithPreviewerPageContentGrid>
    );

    if (typeof numOfItemInRow === "number") {
        const chunks = chunkItemForAds({ numOfItemInRow, items: users });
        const userChunkElementArr = chunks.map(chunk => renderChunk(chunk));

        return (
            <React.Fragment>
                {userChunkElementArr.map((chunkElement, i) => {
                    return (
                        <React.Fragment key={i}>
                            {chunkElement}
                            <HorizontallyCenteredRepeatableLeaderboardAdSlot
                                containerStyle={{ width: "100%", margin: "5rem 0 5rem" }}
                                slotId={i}
                            />
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    return renderChunk(users);
};
