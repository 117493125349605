import { useTimer } from "@/utils/hooks/useTimer";
import styles from "./countdownTimer.module.css";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import clsx from "clsx";
import br from "@/styles/breakpoints.module.css";

const CountdownTimer = ({ date }: { date: Date }) => {
    const { days, minutes, seconds, hours } = useTimer(date.getTime());

    const time = { Days: days, Hours: hours, Minutes: minutes, Seconds: seconds };
    const isOver = Date.now() > date.getTime();

    return (
        <FlexRowAlignCenter
            style={{
                backgroundColor: isOver ? "#f18888" : themeColors.card,
                borderRadius: "2rem",
                gap: "0.5rem",
                width: "100%",
                justifyContent: "space-evenly",
            }}>
            {Object.keys(time).map((curTime, index) => {
                return (
                    <FlexRowAlignCenter key={index} style={{ gap: "0.5rem" }}>
                        <FlexColumn style={{ alignItems: "center" }}>
                            <p
                                className={styles.timeName}
                                style={{ color: isOver ? "#353535" : themeColors.neutralBlack }}>
                                {curTime.charAt(0).toUpperCase() + curTime.slice(1)}
                            </p>
                            <b className={styles.timeLabel}>{`${isOver && index === 0 ? "-" : ""}${time[curTime]}`}</b>
                        </FlexColumn>
                        {index < 3 && (
                            <b
                                className={clsx(styles.timeLabel, br.mdDownDisplayNone)}
                                style={{ alignSelf: "flex-end", paddingBottom: "1rem" }}>
                                :
                            </b>
                        )}
                    </FlexRowAlignCenter>
                );
            })}
        </FlexRowAlignCenter>
    );
};

export default CountdownTimer;
