"use client";

import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import Image from "next/image";
import br from "@/styles/breakpoints.module.css";
import styles from "../ExplorePage/styles.module.css";

const KaiSherlock = `${ASSETS_URL}/images/kai-sherlock.svg`;

const LandingPageSearchHeader = ({
    title,
    searchComponent,
    bottomComponent,
}: {
    title?: string;
    searchComponent: React.ReactNode;
    bottomComponent?: React.ReactNode;
}) => {
    return (
        <div
            className={styles.headerContainer}
            style={{ background: themeColors.neutral1, position: "relative", overflow: "hidden" }}>
            <div
                className={styles.headerTitleContainer}
                style={{
                    color: themeColors.neutralBlack,
                    fontSize: "2.5rem",
                    fontWeight: "600",
                    wordWrap: "break-word",
                    minHeight: "10rem",
                }}>
                <h1 className="heading3">{title}</h1>
            </div>
            <div style={{ position: "relative" }}>
                <div style={{ position: "relative", zIndex: 2 }}>{searchComponent}</div>
                <Image
                    className={br.mdDownDisplayNone}
                    src={KaiSherlock}
                    width={190}
                    height={212}
                    alt="Search flashcards or notes"
                    style={{ position: "absolute", right: "8rem", bottom: 0 }}
                    priority
                />
            </div>
            {bottomComponent}
            <Image
                className={br.mdUpDisplayNone}
                src={KaiSherlock}
                width={108}
                height={120}
                alt="Search flashcards or notes"
                style={{
                    marginTop: "2rem",
                    position: "absolute",
                    right: "-1.5rem",
                    transform: "rotate(-17deg)",
                    bottom: "-4rem",
                }}
            />
        </div>
    );
};

export default LandingPageSearchHeader;
