"use client";

import { useParams } from "next/navigation";
import { FlexRowAlignCenter } from "@/components/Flex";
import TopicOrSubjectPicker from "../../components/TopicPicker";
import { getSubjectNameAndType, subjectNameToUrlComponent } from "@knowt/syncing/hooks/subjects/utils";

const CategoryPageSubjectPicker = ({ subjects }: { subjects: string[] }) => {
    const params = useParams();

    const subjectCategory = subjectNameToUrlComponent(
        getSubjectNameAndType(params.subjectCategory as string).param as string
    );

    return (
        <FlexRowAlignCenter className="strippedList" as={"ul"} style={{ gap: "1.3rem", flexWrap: "wrap" }}>
            <TopicOrSubjectPicker
                topics={subjects}
                style={{ fontSize: "1.5rem" }}
                hrefFormatter={subject =>
                    `/subject/${subjectCategory}/${subjectNameToUrlComponent(subject)}-flashcards`
                }
                selectedTopics={[]}
            />
        </FlexRowAlignCenter>
    );
};

export default CategoryPageSubjectPicker;
