"use client";

import { wait } from "@knowt/syncing/utils/genericUtils";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import Image from "next/image";
import { useEffect, useRef } from "react";
import customStyles from "./landingMainPage.module.css";
import { UserFeedbackList } from "../components/UserFeedbackList";
import VideoSectionWithHeader from "./components/VideoSectionWithHeader";
import BlockQuote from "../components/BlockQuote";
import { LandingCenteredHalfSection, LandingMainContainer } from "../components/styled/Containers";
import DownloadMobileApp from "../components/DownloadMobileApp";
import { H1, H2, H3 } from "../components/styled/Headings";
import LandingMainButton from "../components/LandingMainButton";
import { H1Paragraph, H2Paragraph } from "../components/styled/Paragraphs";
import styles from "../landing.module.css";
import { useAuthSlideContextSelector } from "@/features/Auth";
import FAQ from "@/components/FAQ";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LogPageView from "@/components/LogPageVisit";
import { themeColors } from "@/utils/themeColors";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import { useTheme } from "@/utils/theme";
import DelayedComponent from "@/components/DelayedComponent";
import { ASSETS_URL } from "@/config/deployConstants";
import VimeoVideo from "@/components/VimeoVideo";
import br from "@/styles/breakpoints.module.css";
import LazyLoaded from "@/components/LazyLoaded";
import { useInView } from "react-intersection-observer";

const FAQS = [
    {
        question: "Is there an app for Knowt?",
        answer: [
            "Yes, the mobile app is coming soon! It will be available on IOS and Android, helping to transform the way you learn!",
        ],
    },
    {
        question: "Does Knowt have free learn mode?",
        answer: [
            "Yes! You can import your flashcards from other flashcard app like Quizlet and study them using our free learn mode, spaced repetition, flashcards mode, practice test mode or match game mode.",
        ],
    },
    {
        question: "How do I import from Quizlet to Knowt?",
        answer: [
            "Importing your quizlet sets onto knowt just got a lot easier. Download our new Knowt Chrome Extension, then head over to any quizlet set and open the extension. You'll be able to import the set to Knowt with a single click, open them up in Knowt and start studying!",
        ],
    },
    {
        question: "Where can I turn my notes into flashcards?",
        answer: [
            "Write or upload a note into Knowt and then ask Kai to make flashcards from them instantly. Save time and jump right into mastering the material by studying flashcards using learn mode or spaced repetition.",
        ],
    },
];

const SOURCES = [
    {
        src: `${ASSETS_URL}/images/flashcard-icon.svg`,
        label: "Study Flashcards",
        index: 0,
        img: `${ASSETS_URL}/images/landing/slide1.png`,
    },
    {
        src: `${ASSETS_URL}/images/video-icon.svg`,
        label: "AI Lectures",
        index: 1,
        img: `${ASSETS_URL}/images/landing/slide2.png`,
    },
    {
        src: `${ASSETS_URL}/images/note-icon.svg`,
        label: "Create Notes",
        index: 2,
        img: `${ASSETS_URL}/images/landing/slide3.png`,
    },
    {
        src: `${ASSETS_URL}/images/pdf-icon.svg`,
        label: "AI Files",
        index: 3,
        img: `${ASSETS_URL}/images/landing/slide4.png`,
    },
];

const MainHeader = () => {
    const [vid, updateVid] = useCombinedState(SOURCES[0]);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    // cycle through the videos every 6 seconds, until the user clicks on a button
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            updateVid(SOURCES[(vid.index + 1) % SOURCES.length]);
        }, 10000);

        return () => {
            clearTimeout(timeoutRef.current!);
        };
    }, [vid.index, updateVid]);

    const getLine = () => (
        <svg
            width="230"
            height="27"
            viewBox="0 0 230 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                position: "absolute",
                left: "11.5rem",
                top: "6rem",
            }}>
            <path
                d="M6 21C40.0422 11.4775 131.501 -3.76511 225 11.445"
                stroke="#50D2C2"
                strokeWidth="10"
                strokeLinecap="round"
            />
        </svg>
    );

    const getButtonVid = ({ src, label, index, img }) => {
        return (
            <OutlineTextButton
                key={index + label}
                onClick={() => {
                    // setIsClicked(true);
                    updateVid({ img, index, src });
                }}
                text={
                    <>
                        <Image src={src} alt={label + " image"} width={35} height={35} />
                        <span style={{ fontSize: "1.8rem" }}>{label}</span>
                    </>
                }
                sx={{
                    padding: "3rem 2rem",
                    gap: "1rem",
                    background: index === vid.index ? themeColors.neutralBlack : "transparent",
                    color: index === vid.index ? themeColors.neutralWhite : themeColors.neutralBlack,
                }}
            />
        );
    };

    return (
        <LandingCenteredHalfSection>
            <section style={{ maxWidth: "63rem" }}>
                <H1 style={{ fontSize: "5.4rem", marginBottom: "2.4rem", lineHeight: "7.2rem", position: "relative" }}>
                    The best free all-in-one AI study app
                    {getLine()}
                </H1>
                <H1Paragraph style={{ lineHeight: "3.2rem", marginBottom: "3.2rem" }}>
                    Knowt uses AI to seamlessly create flashcards and practice problems from your notes, lecture videos,
                    and slides.
                </H1Paragraph>
            </section>
            <LandingMainButton
                primaryBg={themeColors.primary}
                primaryColor={"#15504A"}
                secondaryBg={themeColors.primaryDark}
                secondaryColor={themeColors.primary}
                sx={{ width: "30.6rem", marginBottom: "5rem" }}>
                Get started for free
            </LandingMainButton>
            <FlexRowAlignCenter
                className={styles.videoBtnsContainer}
                style={{ gap: "2rem", marginBottom: "2rem", width: "100%", justifyContent: "center" }}>
                {SOURCES.map(source => getButtonVid(source))}
            </FlexRowAlignCenter>
            <FlexColumnAlignJustifyCenter
                className={styles.videoContainerSize}
                style={{ marginBottom: "3.2rem", position: "relative" }}>
                <Image
                    src={`${ASSETS_URL}/images/landingpage-mainarrow.png`}
                    alt={"arrow pointing to video"}
                    width="300"
                    height="300"
                    style={{ objectFit: "contain", position: "absolute", left: -300, top: -150 }}
                />
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
                    <Image src={vid.img} alt={"arrow pointing to video"} layout="fill" />
                </div>
            </FlexColumnAlignJustifyCenter>
            <section style={{ maxWidth: "63rem", textAlign: "center" }}>
                <H2 style={{ fontSize: "3.4rem", fontWeight: "600", lineHeight: "4.2rem" }}>
                    Create on Knowt, or import from Quizlet in seconds.
                </H2>
                <H2Paragraph style={{ fontSize: "2.2rem" }}>
                    Study with learn mode, spaced repetition, matching, and more for free.
                </H2Paragraph>
            </section>
        </LandingCenteredHalfSection>
    );
};

const StudyWithOurLearnMode = () => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0 });

    return (
        <LandingCenteredHalfSection style={{ width: "100%" }}>
            <section style={{ maxWidth: "63rem" }}>
                <H2>Study with our free learn mode</H2>
                <H2Paragraph style={{ marginBottom: "3.2rem" }}>
                    Multiple choice, writing, and T/F questions instantly made from your flashcards.
                </H2Paragraph>
            </section>
            <div ref={ref} className={styles.videoContainerSize}>
                <LazyLoaded load={inView}>
                    <VimeoVideo id={"889354275"} />
                </LazyLoaded>
            </div>
        </LandingCenteredHalfSection>
    );
};

const LectureVideos = () => {
    return (
        <LandingCenteredHalfSection>
            <section style={{ maxWidth: "63rem", marginBottom: "3.2rem" }}>
                <H2>Lecture videos you can interact with</H2>
                <H2Paragraph style={{ marginBottom: "3.2rem" }}>
                    Import your lecture video and ask AI to make notes and flashcards, or just chat with it to learn
                    more.
                </H2Paragraph>
            </section>
            <FlexRowAlignCenter
                className={br.smDownColumnStretch}
                style={{ gap: "2rem", marginBottom: "3.2rem", width: "100%" }}>
                <VideoSectionWithHeader
                    title={"Flashcards"}
                    desc={"Flashcards instantly from your lecture videos."}
                    vimeoId={"889354297"}
                />
                <VideoSectionWithHeader
                    title={"Notes"}
                    desc={"Summarize video findings in a single note."}
                    vimeoId={"889354261"}
                />
            </FlexRowAlignCenter>
            <FlexRowAlignCenter className={br.smDownColumnStretch} style={{ gap: "2rem", width: "100%" }}>
                <VideoSectionWithHeader
                    title={"Chat with AI"}
                    desc={"Ask Kai follow-up questions on video content."}
                    vimeoId={"889354268"}
                />
                <VideoSectionWithHeader
                    title={"Practice Test"}
                    desc={"Quiz yourself on video material."}
                    vimeoId={"889354311"}
                />
            </FlexRowAlignCenter>
        </LandingCenteredHalfSection>
    );
};

const AceYourExam = () => {
    const { isDarkMode } = useTheme();

    return (
        <LandingCenteredHalfSection style={{ width: "100%" }}>
            <section style={{ maxWidth: "63rem", marginBottom: "3.2rem" }}>
                <H2>Ace your exams with crafted study guides and flashcards</H2>
                <H2Paragraph>Jump into an exam by clicking on the exams tab and explore resources.</H2Paragraph>
            </section>
            {/* NOTE: this is a trick on how to apply image based on dark or light mode somehow */}
            {/* TODO: can we make component for this */}
            <picture>
                <DelayedComponent delay={50}>
                    {isDarkMode && <source srcSet={`${ASSETS_URL}/images/landing/explore_page_dark.png`} />}
                </DelayedComponent>
                <Image
                    src={`${ASSETS_URL}/images/landing/explore_page.png`}
                    alt="knowt flashcards exams"
                    width={"500"}
                    height={"500"}
                    sizes="100vw"
                    style={{ width: "100%", height: "auto" }}
                />
            </picture>
        </LandingCenteredHalfSection>
    );
};

export const StudentRelyOnKnowt = ({
    header,
    isWithBubbles,
    containerStyle,
    feedbacksListContainerStyle,
    feedbackCardsStyle,
}: {
    header: React.ReactNode;
    isWithBubbles?: boolean;
    containerStyle?: React.CSSProperties;
    feedbacksListContainerStyle?: string;
    feedbackCardsStyle?: React.CSSProperties;
}) => {
    const userList = [
        {
            name: "Kemisola Benson",
            grade: "12th grade",
            desc: "Knowt helped me tremendously with my AP exams, World History and Chem in particular. Taking notes and immediately having a study guide created was such a life saver when I was on a time crunch. I did way better than I expected thanks to Knowt!!",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-1.png`,
        },
        {
            name: "Victoria Buendia-Serrano",
            grade: "College freshman",
            desc: "Knowt’s quiz and spaced repetition features have been a lifesaver. I’m going to Columbia now and studying with Knowt helped me get there! ",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-2.png`,
        },
        {
            name: "Fernanda M",
            grade: "College freshman",
            desc: "This app really came in clutch when AP exams were around the corner. Especially with the spaced repetition method, it helped me remember the important concepts. :)",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-3.png`,
        },
        {
            name: "Val",
            grade: "College sophomore",
            desc: "Knowt has been a lifesaver! The learn features in flashcards let me find time and make studying a little more digestible. ",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-4.png`,
        },
        {
            name: "Matthew Novicky",
            grade: "11th grade",
            desc: "Absolutely excellent. Easy to use, much more visually pleasant than Cram and no rudimentary features are behind a paywall. It’s simply excellent. ",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-5.png`,
        },
        {
            name: "Sam Loos",
            grade: "12th grade",
            desc: "I used Knowt to study for my APUSH midterm and it saved my butt! The import from Quizlet feature helped a ton too. Slayed that test with an A!! 😻😻😻",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-6.png`,
        },
        {
            name: "Shamika Agrawal",
            grade: "12th grade",
            desc: "I loved the AI for flashcard writing, it saves so much time! The unique spaced repetition can really come in clutch for last minute studying and helps you remember everything!",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-7.png`,
        },
        {
            name: "Hannah DeLong",
            grade: "11th grade",
            desc: "I love Knowt so much! The platform is a dream and seeing all of the new features each update makes me so happy! Thank you, Knowt, for being so amazing :)",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-8.png`,
        },
        {
            name: "Milan Gudka",
            grade: "11th grade",
            desc: "Knowt has been an absolute life-saver for me - no other revision methods worked for me. It’s also incredible considering Knowt is free! The variety of tools available is perfect for any learning or revision type. Thanks, Knowt!",
            src: `${ASSETS_URL}/images/landing/testimonials/landing-testimonial-9.png`,
        },
    ];

    return (
        <LandingCenteredHalfSection style={{ ...containerStyle }}>
            {header}
            <UserFeedbackList
                users={userList}
                isWithBubles={isWithBubbles}
                listContainerStyle={feedbacksListContainerStyle}
                cardStyle={{ ...feedbackCardsStyle }}
            />
        </LandingCenteredHalfSection>
    );
};

const SectionGap = () => {
    return <span style={{ width: "100%" }} className={customStyles.gapBetweenSection} />;
};

const LandingMainPage = ({
    fromLogin,
    fromSignup,
    fromVerify,
    fromReset,
}: {
    fromLogin?: boolean;
    fromSignup?: boolean;
    fromVerify?: boolean;
    fromReset?: boolean;
}) => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);
    const { isDarkMode } = useTheme();

    useEffect(() => {
        const openAuthFlow = async () => {
            await wait(500);
            if (fromLogin) {
                openAuthSlide({ showLoginPage: true });
            } else if (fromSignup) {
                openAuthSlide({ showSignupPage: true });
            } else if (fromVerify) {
                openAuthSlide({ showVerifyPage: true });
            } else if (fromReset) {
                openAuthSlide({ showResetPage: true });
            }
        };

        openAuthFlow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <LandingMainContainer
                accentColorLight={"none"}
                accentColorDark={"none"}
                className={styles.gradientBgMainLanding}
                style={{ overflow: "hidden", backgroundColor: themeColors.background }}>
                <LogPageView page={"Landing Page - Visited"} />
                <MainHeader />
            </LandingMainContainer>

            <LandingMainContainer
                accentColorLight={"#DAF4F1"}
                accentColorDark={"#15504A"}
                style={{ paddingTop: 0, backgroundColor: themeColors.neutralWhite }}>
                <SectionGap />
                <StudyWithOurLearnMode />
                <SectionGap />
                <BlockQuote
                    quoteColor={themeColors.primaryDark}
                    title={
                        <span style={{ color: themeColors.primaryDark }}>
                            The test feature and spaced repetition are absolutely unreal. Will definitely be bringing
                            this helpful tool to college with me.
                        </span>
                    }
                    desc={<span style={{ color: themeColors.primaryDark }}>Caragh, College freshman</span>}
                />
                <SectionGap />
                <LectureVideos />
                <SectionGap />
                <FlexColumnAlignJustifyCenter as="picture">
                    {/* NOTE: this is a trick on how to apply image based on dark or light mode somehow */}
                    <DelayedComponent delay={50}>
                        {isDarkMode && <source srcSet={`${ASSETS_URL}/images/landing/study_cross_out_dark.png`} />}
                    </DelayedComponent>
                    <Image
                        className={br.smDownWidth100}
                        src={`${ASSETS_URL}/images/landing/study_cross_out.png`}
                        alt={"One study tool that replace the rest"}
                        height="500"
                        width="500"
                        sizes="100vw"
                        style={{ width: "75%", maxWidth: "85rem", height: "auto", margin: "auto" }}
                    />
                </FlexColumnAlignJustifyCenter>
                <SectionGap />
                <AceYourExam />
                <SectionGap />
                <BlockQuote
                    quoteColor={themeColors.primary}
                    title={
                        <span style={{ color: themeColors.primaryDark }}>
                            Knowt helped me cram for my AP Euro and AP Bio exam, I wouldn't have passed without their
                            study guides!
                        </span>
                    }
                    desc={<span style={{ color: themeColors.primaryDark }}>Simran Sahota, 10th grade</span>}
                />
                <SectionGap />
                <DownloadMobileApp />
                <SectionGap />
                <StudentRelyOnKnowt
                    header={
                        <H3 style={{ maxWidth: "63rem", marginBottom: "3.2rem", textAlign: "center" }}>
                            1,000,000+ students rely on Knowt to help them study
                        </H3>
                    }
                />
                <SectionGap />
                <LandingMainButton
                    primaryBg={themeColors.primary}
                    primaryColor={"#15504A"}
                    secondaryBg={themeColors.primaryDark}
                    secondaryColor={themeColors.primary}
                    sx={{ width: "30.6rem", marginBottom: "5rem", margin: "auto" }}>
                    Get started for free
                </LandingMainButton>
                <SectionGap />
                <LandingCenteredHalfSection>
                    <FAQ
                        title={"FAQ"}
                        description={null}
                        containerStyle={{ background: "transparent", margin: 0, padding: 0 }}
                        questStyle={{ fontSize: "2rem" }}
                        faqs={FAQS}
                    />
                </LandingCenteredHalfSection>
            </LandingMainContainer>
        </>
    );
};

export default LandingMainPage;
