import { FlexColumn } from "@/components/Flex";
import Link from "@/components/wrappers/Link";
import { themeColors } from "@/utils/themeColors";
import clsx from "clsx";
import { ArrowRight } from "lucide-react";
import Image from "next/image";
import br from "@/styles/breakpoints.module.css";
import styles from "./styles.module.css";

const ExamCard = ({
    exam,
    title,
    style,
    goForwardLabel,
}: {
    exam: any;
    title?: string;
    style?: React.CSSProperties;
    goForwardLabel?: string;
}) => {
    return (
        <Link
            href={exam.href}
            className={styles.examCardContainer}
            style={{
                height: "17rem",
                padding: "2.4rem",
                boxShadow: "0 0 25px 2px rgba(0, 0, 0, 0.05)",
                gap: "1.2rem",
                flex: 1,
                textDecoration: "none",
                borderRadius: "1rem",
                ...style,
            }}>
            <FlexColumn>
                <Image alt={exam.name + " exam"} src={exam.image} width={36} height={36} />
                <FlexColumn>
                    <p className={clsx(br.mdDownDisplayNone, "body1 bold")} style={{ margin: "1.2rem 0 0.4rem" }}>
                        {title ?? exam.name}
                    </p>
                    <p className={clsx(br.mdUpDisplayNone, "body2 bold")}>{title}</p>
                    <div
                        style={{
                            display: "flex",
                            gap: "0.4rem",
                            flexDirection: "row",
                            color: themeColors.primary4,
                            height: "2rem",
                            lineHeight: "2rem",
                        }}>
                        <p className={br.smDownDisplayNone} style={{ fontWeight: "bold" }}>
                            {goForwardLabel ?? `${exam.type} Study Guides`}
                        </p>
                        <p className={br.smUpDisplayNone} style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            {goForwardLabel ?? `Visit ${exam.type}`}
                        </p>
                        <ArrowRight size={18} />
                    </div>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

export default ExamCard;
