import { AICompletionType } from "@knowt/syncing/graphql/schema";
import useCombinedState, { SetPartialState } from "@/utils/hooks/useCombinedState";
import { Editor, useEditor } from "@knowt/editor/react";
import { SetState } from "@knowt/syncing/types/common";
import { uploadPictureToS3 } from "@knowt/syncing/utils/s3";
import React, { useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";
import AITemplate from "../NotePage/Document/AITemplate";
import { MenuProps as SlashMenuProps } from "./SlashMenu/types";
import { extensions } from "./extensions";
import { Menus, MENUS_STATE_META_KEY } from "./extensions/MenusState";
import { useCommandSave } from "@/hooks/useCommandSave";
import { AIPrompt } from "@/components/flashcards/FlashcardCard/EditableFlashcardCard/constants";
import { useAiPrivacyContextSelector } from "@/components/Popup/AiPopup/AiPrivacyPopupContext";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { getPasteTransformers } from "./utils";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";

export type AiInputMenuProps = {
    isOpen: boolean;
    selectedPrompt?: AIPrompt;
    optimisticText?: string;
};

type EditorContextValue = {
    editor: Editor | null;
    manuallySave: () => Promise<void>;
    slashMenuProps: SlashMenuProps;
    updateSlashMenuProps: (props: SlashMenuProps) => void;
    aiInputMenuProps: AiInputMenuProps;
    updateAiInputMenuProps: SetPartialState<AiInputMenuProps>;
    handleOpenAiInputMenu: (options?: { selectedPrompt?: AIPrompt; optimisticText?: string }) => void;
    handleCloseAiInputMenu: () => void;
    handleInsertImage: () => void;
    slashMenuRef: React.MutableRefObject<{ handleKeyDown: (event: KeyboardEvent) => boolean }>;
    printDocument?: () => void;
    hideToolbar: boolean;
    setHideToolbar: SetState<boolean>;
};

type EditorContextProps = {
    children: React.ReactNode;
    editable: boolean;
    content: string;
    saveContent?: (content: string) => Promise<void>;
    printDocument?: () => void;
    setImportPopupOpen?: SetState<boolean>;
    disabledCustomPlaceholder?: boolean;
    disableAiInputMenuTrigger?: boolean;
    characterCountLimit?: number;
    disableAiCommands?: boolean;
    placeholder?: string;
};

const EditorContext = createContext<EditorContextValue | null>(null);

export const EditorContextProvider = ({
    children,
    editable,
    content,
    saveContent,
    printDocument,
    disabledCustomPlaceholder,
    disableAiInputMenuTrigger,
    disableAiCommands,
    characterCountLimit,
    placeholder,
    setImportPopupOpen,
}: EditorContextProps) => {
    const setIsAiPrivacyPopupOpen = useAiPrivacyContextSelector(context => context.setIsAiPrivacyPopupOpen);
    const shouldDisplayAiPrivacyPopup = useAiPrivacyContextSelector(context => context.shouldDisplayAiPrivacyPopup);
    const setOpenAiInputMenu = useAiPrivacyContextSelector(context => context.setOpenAiInputMenu);

    const [slashMenuProps, updateSlashMenuProps] = useCombinedState<SlashMenuProps>({ isOpen: false });
    const slashMenuRef = useRef({ handleKeyDown: (_e: KeyboardEvent) => false });

    const [aiInputMenuProps, updateAiInputMenuProps] = useCombinedState<AiInputMenuProps>({
        isOpen: false,
        selectedPrompt: undefined,
        optimisticText: undefined,
    });

    const handleChooseAiPrompt = async (prompt: AIPrompt) => {
        switch (prompt.type) {
            case AICompletionType.GENERIC_NOTE:
                return handleOpenAiInputMenu();
            default:
                return handleOpenAiInputMenu({ selectedPrompt: prompt });
        }
    };

    const handleChooseAiPromptRef = useRef(handleChooseAiPrompt);
    useEffect(() => {
        handleChooseAiPromptRef.current = handleChooseAiPrompt;
    });

    const handleOpenAiInputMenu = ({
        selectedPrompt,
        optimisticText,
    }: {
        selectedPrompt?: AIPrompt;
        optimisticText?: string;
    } = {}) => {
        if (aiInputMenuProps.isOpen) return;

        if (shouldDisplayAiPrivacyPopup()) {
            setIsAiPrivacyPopupOpen(true);
            setOpenAiInputMenu(() => () => openAiInputMenu({ selectedPrompt, optimisticText }));
            return;
        }

        openAiInputMenu({ selectedPrompt, optimisticText });
    };

    const openAiInputMenu = ({ selectedPrompt, optimisticText }) => {
        editor?.commands.setMeta(MENUS_STATE_META_KEY, { [Menus.AiInputMenu]: true });
        updateAiInputMenuProps({ isOpen: true, selectedPrompt, optimisticText });
    };

    const handleCloseAiInputMenu = () => {
        if (!aiInputMenuProps.isOpen) return;

        editor?.commands.setMeta(MENUS_STATE_META_KEY, { [Menus.AiInputMenu]: false });
        updateAiInputMenuProps({ isOpen: false, selectedPrompt: undefined, optimisticText: undefined });
    };

    const handleOpenAiInputMenuRef = useRef(handleOpenAiInputMenu);
    useEffect(() => {
        handleOpenAiInputMenuRef.current = handleOpenAiInputMenu;
    });

    const handlePaste = (_, event: ClipboardEvent) => {
        if (!editor) return;
        if (!event.clipboardData) return;

        const pastedImage = Array.from(event.clipboardData.items).find(({ type }) => type.startsWith("image/"));
        if (!pastedImage) return;

        const blob = pastedImage.getAsFile();
        if (blob === null) return;

        startImageUpload(blob, editor);

        return true;
    };

    const handlePasteRef = useRef(handlePaste);
    useEffect(() => {
        handlePasteRef.current = handlePaste;
    });

    const handleInsertImage = () => {
        if (!editor) return;

        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = async () => {
            if (input.files?.length) {
                const file = input.files[0];
                startImageUpload(file, editor);
            }
        };
        input.click();
    };

    const handleInsertImageRef = useRef(handleInsertImage);
    useEffect(() => {
        handleInsertImageRef.current = handleInsertImage;
    });

    const startImageUpload = async (file: File, editor: Editor) => {
        const toastId = toast.loading("Inserting..");
        const url = await uploadPictureToS3(file, "knowt-user-attachments", file.type);
        toast.dismiss(toastId);
        editor.commands.setImage({ src: url });
    };

    const onStartWithEmptyPage = () => editor?.commands.focus();
    const onStartWithEmptyPageRef = useRef(onStartWithEmptyPage);
    useEffect(() => {
        onStartWithEmptyPageRef.current = onStartWithEmptyPage;
    });

    const editor = useEditor({
        content,
        extensions: extensions({
            characterCountLimit,
            slashCommandOptions: {
                handleInsertImageRef,
                updateMenuProps: updateSlashMenuProps,
                aiCommands: disableAiCommands
                    ? []
                    : [
                          AICompletionType.GENERIC_NOTE,
                          AICompletionType.MIND_MAP,
                          AICompletionType.OUTLINE,
                          AICompletionType.BRAINSTORM,
                          AICompletionType.BOOK_SUM,
                          AICompletionType.CREATE_NOTE,
                          AICompletionType.PROS_CONS,
                          AICompletionType.LIST,
                          AICompletionType.KEY_INFO,
                          AICompletionType.EXAMPLE,
                          AICompletionType.HIST_EVENT,
                          AICompletionType.FORMULA,
                          AICompletionType.COVER_LETTER,
                          AICompletionType.NEWSPAPER_ARTICLE,
                          AICompletionType.COLD_EMAIL,
                          AICompletionType.DEBATE_ARGUMENT,
                          AICompletionType.RESEARCH_QUESTIONS,
                          AICompletionType.MNEMONICS,
                          AICompletionType.KEY_VOCABULARY,
                          AICompletionType.CONTINUE_WRITING,
                      ],
                handleChooseAiPromptRef,
                componentRef: slashMenuRef,
            },
            aiInputMenuTriggerOptions: { openRef: handleOpenAiInputMenuRef, disabled: disableAiInputMenuTrigger },
            ...(!disabledCustomPlaceholder && {
                customPlaceholder: (
                    <AITemplate
                        onStartWithEmptyPage={() => onStartWithEmptyPageRef.current()}
                        openAiInputMenu={prompt => handleOpenAiInputMenuRef.current({ selectedPrompt: prompt })}
                        openImportPopup={() => setImportPopupOpen?.(true)}
                        templates={[
                            AICompletionType.MIND_MAP,
                            AICompletionType.CREATE_NOTE,
                            AICompletionType.RESEARCH_QUESTIONS,
                        ]}
                    />
                ),
            }),
            ...(placeholder && { placeholderOptions: { placeholder } }),
        }),
        editable,
        editorProps: {
            handlePaste: (_, event) => handlePasteRef.current(_, event),
            ...getPasteTransformers(),
        },
    });

    useEffect(() => {
        const transactionHandler = ({ transaction }) => {
            if (transaction.getMeta("characterCount")?.pastedContentSizeExceededLimit)
                toast.error("Pasted content too long!");
        };

        editor?.on("transaction", transactionHandler);
        return () => {
            editor?.off("transaction", transactionHandler);
        };
    }, [editor]);

    const debouncedSave = useDebouncedCallback((editor: Editor) => saveContent?.(editor.getHTML()));

    useEffect(() => {
        const updateHandler = ({ editor }) => {
            if (editor.isEditable) debouncedSave(editor);
        };

        editor?.on("update", updateHandler);
        return () => {
            editor?.off("update", updateHandler);
        };
    }, [debouncedSave, editor]);

    const [hideToolbar, _setHideToolbar] = useLocalStorage("note-page-hideToolbar", false, {
        raw: false,
        serializer: (value: boolean) => value.toString(),
        deserializer: (value: string) => value === "true",
    });

    const setHideToolbar = (value: boolean) => {
        Mixpanel.track(`Note - Formatting Bar`, { hidden: value });
        _setHideToolbar(value);
    };

    const manuallySave = async () => {
        if (editor) await saveContent(editor.getHTML());
    };

    useCommandSave({
        successMsg: "Note Saved!",
        errorMsg: "Could not Save Note",
        save: manuallySave,
    });

    return (
        <EditorContext.Provider
            value={{
                editor,
                manuallySave,
                slashMenuProps,
                updateSlashMenuProps,
                aiInputMenuProps,
                updateAiInputMenuProps,
                slashMenuRef,
                handleCloseAiInputMenu,
                handleOpenAiInputMenu,
                handleInsertImage,
                printDocument,
                hideToolbar,
                setHideToolbar,
            }}>
            {children}
        </EditorContext.Provider>
    );
};

export const useEditorContextSelector = <T,>(selector: (value: EditorContextValue) => T) =>
    useContextSelector(EditorContext, selector);
