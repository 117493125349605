"use client";

import { ArrowRight, ArrowLeft } from "lucide-react";
import usePagination from "@mui/material/usePagination";
import React from "react";
import { themeColors } from "@/utils/themeColors";
import CircularButton from "@/components/CircularButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";

type PaginationCircleButtonProps = {
    children: React.ReactNode;
    style?: React.CSSProperties;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const PaginationCircleButton = ({ children, style, ...props }: PaginationCircleButtonProps) => {
    return (
        <CircularButton {...props} style={{ fontSize: "1.6rem", fontWeight: "600", ...style }} radius="4.4rem">
            {children}
        </CircularButton>
    );
};

type KnowtPaginationProps = {
    count: number; // how many page are there
    onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
    currentPage: number;
    style?: React.CSSProperties;
};

const KnowtPagination = ({ count, onChange, currentPage, style }: KnowtPaginationProps) => {
    const { items } = usePagination({
        count,
        page: currentPage,
        onChange,
        boundaryCount: 1,
        siblingCount: 0,
    });

    return (
        <React.Fragment>
            {items.map(({ page, type, selected, ...item }, index) => {
                let children: React.ReactNode = null;

                if (type === "start-ellipsis" || type === "end-ellipsis") {
                    // ellipsis button (3)(...)(102)
                    children = (
                        <PaginationCircleButton
                            disabled
                            style={{ backgroundColor: themeColors.card, cursor: "default", ...style }}>
                            {"..."}
                        </PaginationCircleButton>
                    );
                } else if (type === "page") {
                    // normal page button (1)(2)(3)...
                    children = (
                        <PaginationCircleButton
                            {...item}
                            disabled={selected ? true : false}
                            style={{
                                backgroundColor: selected ? "#50d2c2" : themeColors.card,
                                color: selected ? themeColors.pureWhite : themeColors.neutralBlack,
                                cursor: "pointer",
                                ...style,
                            }}>
                            {page}
                        </PaginationCircleButton>
                    );
                } else {
                    // next or prev arrow button
                    const isPrevious = type === "previous";
                    children = (
                        <PaginationCircleButton
                            {...item}
                            style={{ backgroundColor: "#000", cursor: "pointer", ...style }}>
                            {isPrevious ? (
                                <ArrowLeft
                                    color={themeColors.pureWhite}
                                    strokeWidth={strokeWidth.normal}
                                    size={iconSizes.MD - 4}
                                />
                            ) : (
                                <ArrowRight
                                    color={themeColors.pureWhite}
                                    strokeWidth={strokeWidth.normal}
                                    size={iconSizes.MD - 4}
                                />
                            )}
                        </PaginationCircleButton>
                    );
                }

                // return the `children`
                return <li key={type === "page" ? `paginationbutton-${index}` : type}>{children}</li>;
            })}
        </React.Fragment>
    );
};

export default KnowtPagination;
