import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { FlexColumn } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";
import VimeoVideo from "@/components/VimeoVideo";
import LazyLoaded from "@/components/LazyLoaded";
import br from "@/styles/breakpoints.module.css";

const VideoSectionWithHeader = ({ title, desc, vimeoId }: { title: string; desc: string; vimeoId: string }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <FlexColumn
            ref={ref}
            style={{ borderRadius: "2.4rem", height: "34rem", overflow: "hidden", flex: 1 }}
            as="section">
            <FlexColumn style={{ padding: "2.4rem", backgroundColor: themeColors.background }}>
                <h3 style={{ fontSize: "2rem", fontWeight: "600", lineHeight: "2.8rem", marginBottom: "0.6rem" }}>
                    {title}
                </h3>
                <p
                    className={clsx("ellipsisText", br.smDownDisplayNone)}
                    title={desc}
                    style={{ fontSize: "1.6rem", lineHeight: "2rem" }}>
                    {desc}
                </p>
                <p className={br.smUpDisplayNone} style={{ fontSize: "1.6rem", lineHeight: "2rem" }}>
                    {desc}
                </p>
            </FlexColumn>
            <LazyLoaded load={inView}>
                <VimeoVideo
                    id={vimeoId}
                    style={{
                        borderRadius: "0",
                    }}
                />
            </LazyLoaded>
        </FlexColumn>
    );
};

export default VideoSectionWithHeader;
