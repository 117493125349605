"use client";

import { useEffect } from "react";
import { FlashcardSet, Note } from "@knowt/syncing/graphql/schema";
import { usePathname, useSearchParams } from "next/navigation";
import { useCardPreviewContextSelector } from "@/contexts/CardPreviewContext";
import ContentPreviewer, { ContentPreviewerContainer } from "@/components/ContentPreviewer";
import br from "@/styles/breakpoints.module.css";

export const PageSidePreviewer = ({
    initialFlashcardSet,
    initialNote,
    isNotesTab,
    isFlascardsTab,
}: {
    initialFlashcardSet?: FlashcardSet;
    initialNote?: Note;
    isNotesTab: boolean;
    isFlascardsTab: boolean;
}) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const url = String(pathname) + searchParams?.toString();

    const isOpen = useCardPreviewContextSelector(state => state.isOpen);
    const openCardPreview = useCardPreviewContextSelector(state => state.openCardPreview);
    const currSetInView = useCardPreviewContextSelector(state => state.flashcardSetId);
    const currNoteInView = useCardPreviewContextSelector(state => state.noteId);

    // we put timeout to make sure it won't clash with other `useCardPreviewContext` call upon url change
    // since `changeCurrentFilesTab` on "SearchPageTab" calls `closeCardPreview()` first
    useEffect(() => {
        setTimeout(() => {
            if (initialFlashcardSet && isFlascardsTab && (!isOpen || !currSetInView)) {
                openCardPreview({ flashcardSetId: initialFlashcardSet.flashcardSetId });
            }
        }, 50);
        // we only want to check this on first load only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    useEffect(() => {
        setTimeout(() => {
            if (initialNote && isNotesTab && (!isOpen || !currNoteInView)) {
                openCardPreview({ noteId: initialNote.noteId });
            }
        }, 50);
        // we only want to check this on first load only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return (
        <ContentPreviewerContainer
            className={br.mdDownDisplayNone}
            style={{
                padding: "3.3rem 4.1rem",
                position: "sticky",
                flex: 1,
                height: "calc(100vh - var(--navbar-height) - (3.2rem * 2))",
                width: "50%",
                alignSelf: "flex-start",
                top: `calc(var(--navbar-height) + 3.2rem`,
            }}>
            <ContentPreviewer parentPadding={{ right: "4.1rem", bottom: "3.3rem" }} />
        </ContentPreviewerContainer>
    );
};

export default PageSidePreviewer;
