import { useCallback, useEffect, useRef, useState } from "react";

export const useTimer = (_time: number) => {
    const calculateTimeRemaining = () => {
        const time = _time < 1000000000000 ? _time * 1000 : _time;
        const now = new Date().valueOf();
        const targetDate = new Date(time).valueOf();
        const difference = targetDate - now;

        const isPast = difference < 0;
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return {
            complete: isPast,
            days: ("00" + zeroIfNaN(days)).slice(zeroIfNaN(days) > 100 ? -3 : -2),
            hours: ("00" + zeroIfNaN(hours)).slice(-2),
            minutes: ("00" + zeroIfNaN(minutes)).slice(-2),
            seconds: ("00" + zeroIfNaN(seconds)).slice(-2),
        };
    };

    const zeroIfNaN = value => (isNaN(value) ? 0 : value);

    const [timer, setTimer] = useState(calculateTimeRemaining);

    useEffect(() => {
        // set time before interval to avoid delay
        setTimer(calculateTimeRemaining());

        const timer = setInterval(() => {
            setTimer(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(timer);
    }, [_time]);

    return timer;
};

export const useCountUpTimer = (props?: { interval?: number }) => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const countRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        return () => {
            clearInterval(countRef.current);
        };
    }, []);

    const handleStart = useCallback(() => {
        clearInterval(countRef.current);
        countRef.current = setInterval(() => {
            setElapsedTime(prev => prev + (props?.interval || 10));
        }, props?.interval || 10);
        setIsRunning(true);
    }, [props?.interval]);

    const handlePause = useCallback(() => {
        clearInterval(countRef.current);
        setIsRunning(false);
    }, []);

    const handleReset = useCallback(() => {
        clearInterval(countRef.current);
        setElapsedTime(0);
        handleStart();
    }, []);

    return {
        elapsedTime,
        isRunning,
        handleStart,
        handlePause,
        handleReset,
    };
};

export default useTimer;
