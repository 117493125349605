"use client";

import { genericUpdateMedias } from "@/hooks/media/utils";
import { callCreatePassword } from "@/hooks/sharing/graphqlUtils";
import { useS3UploadJobsSelector } from "@knowt/syncing/context/S3UploadJobContext";
import { useMedia } from "@knowt/syncing/hooks/media/useMedia";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { ItemType, Media, SubscriptionType } from "@knowt/syncing/graphql/schema";
import { ArrowRight } from "lucide-react";
import { usePathname, useSearchParams } from "next/navigation";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { ConditionalLink } from "@/components/ConditionalLink";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import LinearProgressBar from "@/components/LinearProgress";
import ToggleSwitch from "@/components/ToggleSwitch";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import BorderColoredInput from "@/components/styled/input/BorderColoredInput";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import { useRouter } from "next13-progressbar";
import LoadingAnimation from "@/components/LoadingAnimation";
import GuidedWalkthrough, { useGuidedWalkthrough, WALKTHROUGH_LABELS } from "@/features/GuidedWalkthrough";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { AuthEvent, useAuthPopupContextSelector } from "@/features/Auth";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import noop from "@knowt/syncing/utils/noop";
import MoreInfoTooltipButton from "@/components/CircularButton/styled/MoreInfoTooltipButton";
import { useAutoSave } from "@/hooks/useAutoSave";
import MediaEditTaggingSection from "./MediaEditTaggingSection";
import { TIME } from "@knowt/syncing/utils/dateTimeUtils";
import { MediaFailureStates } from "@/features/MediaPage";
import { useCountUpTimer } from "@knowt/syncing/utils/hooks/useTimer";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { MediaUploadedMetadata } from "@knowt/syncing/constants/storage";

const WALKTRHOUGH_MEDIA_UPLOAD_PROGRESS_BAR = "walkthrough-progress-bar";
// eslint-disable-next-line padding-line-between-statements
const UploadMediaProgressWalkthrough = ({ mediaType }: { mediaType: string }) => {
    const { isWalkthroughActive, activateWalkthrough, doneWalkthrough, hasWalkthroughTaken } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.MEDIA_UPLOAD_PROGRESS,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;

        setTimeout(() => activateWalkthrough({}), 300);
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: `Your ${mediaType.toLowerCase()} is uploading`,
                    content: `Once your ${mediaType.toLowerCase()} is done uploading, you’ll instantly get notes, flashcards and practice problems made from it!`,
                    placement: "bottom-end",
                    target: "." + WALKTRHOUGH_MEDIA_UPLOAD_PROGRESS_BAR,
                },
            ]}
        />
    );
};

const SIGN_UP_BLOCKER_CONTENT = {
    "/ai-pdf-summarizer": {
        event: AuthEvent.LANDING_UPLOAD_PDF,
        title: "Sign up for free to access the notes & flashcards made from your PDF",
        description: "Take a few seconds, and save hours of your day -- every day. ",
    },
    "/ai-video-summarizer": {
        event: AuthEvent.LANDING_UPLOAD_VIDEO,
        title: "Sign up for free to access the notes & flashcards made from your video",
        description: "Take a few seconds, and save hours of your day -- every day.",
    },
    "/ai-powerpoint-summarizer": {
        event: AuthEvent.LANDING_UPLOAD_PPT,
        title: "Sign up for free to access the notes & flashcards made from your PPT",
        description: "Take a few seconds, and save hours of your day -- every day.",
    },
    "/": {
        event: AuthEvent.LANDING_IMPORT_QUIZLET,
        title: "Sign up for free to access your imported set",
        description: "Now you’ll be able to study your Quizlet set with learn mode, matching, and more for free",
    },
};

type MediaProps = {
    media: Media;
    uploadMode: never;
    folderId?: string | null;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    tagSelectorBackgroundColor?: string;
    tagSelectorBorderWidth?: string | number;
    nextBackgroundColor?: string;
};

type NonMediaProps = {
    media?: Media | null;
    uploadMode?: boolean;
} & Omit<MediaProps, "media" | "uploadMode">;

type MediaEditFormProps = MediaProps | NonMediaProps;

const MediaEditForm = ({
    media: initialMedia,
    uploadMode,
    folderId,
    style,
    inputStyle,
    tagSelectorBackgroundColor,
    tagSelectorBorderWidth,
    nextBackgroundColor = themeColors.primary,
}: MediaEditFormProps) => {
    const { elapsedTime, handleStart, isRunning, handlePause } = useCountUpTimer({ interval: 1000 });
    const [timerKilled, setTimerKilled] = useState(false);
    const searchParams = useSearchParams();
    const typeFromSearchParams = searchParams?.get("type");
    const router = useRouter();
    const pathname = usePathname();

    //from upload page passed by chrome-extension
    const importedMediaId = searchParams?.get("mediaId");

    const isUploading = useS3UploadJobsSelector(state => state.isUploading);
    const cancelUpload = useS3UploadJobsSelector(state => state.cancelUpload);
    const uploadedFileId = useS3UploadJobsSelector(state => state.uploadedFileId);
    const uploadProgress = useS3UploadJobsSelector(state => state.uploadProgress);
    const fileName = useS3UploadJobsSelector(state => state.fileName);
    const uploadRemainingTime = useS3UploadJobsSelector(state => state.uploadRemainingTime);

    const { userId, subscriptionType } = useCurrentUser();
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);
    const { media: uploadedMedia } = useMedia({ mediaId: importedMediaId ?? uploadedFileId, revalidateOnFocus: true });

    const media = useMemo(() => initialMedia ?? uploadedMedia, [initialMedia, uploadedMedia]);
    const mediaType = media?.type ?? typeFromSearchParams ?? "video";

    const isFromChx = !!importedMediaId;
    const isNextButtonDisabled = isFromChx ? !media : !uploadedFileId;
    const isVerifyingUpload = !uploadedFileId && uploadProgress === 100;

    const [title, setTitle] = useState(media?.title ?? fileName?.slice(0, fileName.lastIndexOf(".")) ?? "");
    const [description, setDescription] = useState(media?.description);

    const { current: isPublic } = useRef(media?.public ?? false);
    const { current: password } = useRef(media?.password ? "••••••" : null);
    const { current: isPasswordSelected } = useRef(!!media?.password);

    const [tags, updateTags] = useCombinedState({
        subject: media?.subject,
        topic: media?.topic,
        exam: media?.exam_v2,
        examUnit: media?.examUnit,
        examSection: media?.examSection,
    });

    // start countdown for showing error state
    useEffect(() => {
        if (!isRunning && !timerKilled && isVerifyingUpload) handleStart();
    }, [isRunning, handleStart, timerKilled, isVerifyingUpload]);

    const showAuthPopup = () => {
        // WE DECIDED TO MODIFY THE POPUP CONTENT BASED ON CURRENT TAB
        // IF WE SEE LATER THAT THIS IS CONFUSING USERS, WE CAN DO IT BASED ON UPLOADED FILE TYPE

        // get proper slug from pathname
        const actualTab = "/" + pathname.split("/").at(-1)?.split("?").at(0);

        const { event, title, description } = SIGN_UP_BLOCKER_CONTENT[actualTab] ?? SIGN_UP_BLOCKER_CONTENT["/"];

        openAuthPopup({
            event,
            title,
            description,
        });
    };

    // Fake auto save to prevent user from losing data
    useAutoSave({
        isDirty: () => isUploading || !!uploadedFileId,
        save: async () => noop(),
        interval: 1000 * 60 * 5, // 5 minutes
        block: true,
    });

    const saveMedia = async () => {
        const mediaId = media?.mediaId ?? importedMediaId ?? uploadedFileId;
        if (!mediaId) {
            return;
        }
        toast.loading("Saving ...");

        try {
            if (password && subscriptionType !== SubscriptionType.BASIC) {
                await callCreatePassword({
                    itemId: media?.mediaId,
                    password,
                    type: ItemType.MEDIA,
                }).catch(() => {
                    toast.dismiss();
                    toast.error("Error creating password, try again later.");
                });
            }

            await genericUpdateMedias({
                mediaIds: [mediaId],
                userId,
                updatedFields: {
                    title,
                    description,
                    folderId,
                    subject: tags.subject,
                    topic: tags.topic,
                    exam_v2: tags.exam,
                    examUnit: tags.examUnit,
                    examSection: tags.examSection,
                } as Media,
            });
            toast.dismiss();
            toast.success("Saved!");

            if (!uploadMode) {
                router.push(`/media/${media?.mediaId}`);
            } else {
                Mixpanel.track("Media - Created", {
                    mediaId: media?.mediaId,
                    mediaType: media?.type,
                    subject: tags.subject,
                    topic: tags.topic,
                    exam: tags.exam,
                    examUnit: tags.examUnit,
                    examSection: tags.examSection,
                });
            }
        } catch {
            toast.dismiss();
            toast.error("Error saving, try again later.");
        }
    };

    const nextButtonClickHandler = () => {
        if (uploadMode && isNextButtonDisabled) {
            toast.error("File is still uploading.");
        } else if (!userId) {
            // TEMP SAVE MEDIA
            LocalKeyValueStore.setWithExpiry(STORAGE_KEYS.MEDIA_UPLOADED_METADATA, {
                title,
                description,
                subject: tags.subject,
                topic: tags.topic,
                exam_v2: tags.exam,
                examUnit: tags.examUnit,
                examSection: tags.examSection,
            } as MediaUploadedMetadata);
            showAuthPopup();
        } else {
            saveMedia();
        }
    };

    if (elapsedTime > 60 * TIME.SECOND) {
        return (
            <FlexColumnAlignJustifyCenter
                style={{
                    padding: "7rem",
                    backgroundColor: themeColors.neutralWhite,
                    borderRadius: "2rem",
                    minWidth: "50%",
                }}>
                <MediaFailureStates
                    title="Sorry the upload failed"
                    description="It should work again on reload, please try once more"
                    buttonProps={{ text: "Reload", onClick: () => location.reload() }}
                    reportEvent={() => {
                        Mixpanel.track("Media - Upload Verification Failed", { media, mediaType });
                        handlePause();
                        setTimerKilled(true);
                    }}
                />
            </FlexColumnAlignJustifyCenter>
        );
    }

    return (
        <FlexColumn
            style={{
                padding: " 5rem",
                gap: spacing.MD,
                alignSelf: "stretch",
                borderRadius: "2rem",
                backgroundColor: themeColors.neutralWhite,
                flex: 1,
                ...style,
            }}>
            {/* TITLE */}
            <FlexColumn>
                <h4 style={{ fontWeight: 600 }}>Title</h4>
                <BorderColoredInput
                    placeholder={"Enter a title, like “Chemistry - Chapter 22”"}
                    style={{
                        height: "4.3rem",
                        marginTop: spacing.XS_2,
                        backgroundColor: "transparent",
                        borderWidth: "1.5px",
                        fontFamily: "var(--knowt-font-name)",
                        ...inputStyle,
                    }}
                    value={title ?? ""}
                    onChange={e => setTitle(e.target.value)}
                />
            </FlexColumn>
            {/* DESC */}
            <FlexColumn>
                <h4 style={{ fontWeight: 600 }}>Description</h4>
                <BorderColoredInput
                    placeholder={"Add a description"}
                    style={{
                        height: "4.3rem",
                        marginTop: spacing.XS_2,
                        backgroundColor: "transparent",
                        borderWidth: "1.5px",
                        fontFamily: "var(--knowt-font-name)",
                        ...inputStyle,
                    }}
                    value={description ?? ""}
                    onChange={e => setDescription(e.target.value)}
                />
            </FlexColumn>
            {/* TAGGING */}
            <MediaEditTaggingSection
                onChange={updateTags}
                tags={tags}
                tagSelectorBackgroundColor={tagSelectorBackgroundColor}
                tagSelectorBorderWidth={tagSelectorBorderWidth}
            />

            {/* SHARING */}
            {!!userId && (
                <FlexColumn style={{ gap: spacing.XS_2, height: "fit-content" }}>
                    <h4 style={{ fontWeight: 600 }}>Sharing</h4>
                    <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                        <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                            <p style={{ fontSize: "1.6rem", fontWeight: 500 }}>
                                {`Share this ${mediaType?.toLowerCase()} with public`}
                            </p>
                            <MoreInfoTooltipButton
                                tooltip={"pick whether you want other students on Knowt to be able to see your video"}
                            />
                        </FlexRowAlignCenter>
                        <ToggleSwitch
                            sx={{ width: "5rem", height: "2.5rem" }}
                            checked={isPublic}
                            onChange={() => toast.error("coming soon!")}
                            isOn={isPublic}
                        />
                    </FlexRowAlignCenter>

                    <FlexColumn style={{ gap: spacing.XS_2 }}>
                        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                                <p style={{ fontSize: "1.6rem", fontWeight: 500 }}>
                                    {`Share this ${mediaType?.toLowerCase()} with a password`}
                                </p>
                                <MoreInfoTooltipButton
                                    tooltip={"Share this a friend & give them the password so only they can access it"}
                                />
                            </FlexRowAlignCenter>
                            <ToggleSwitch
                                sx={{ width: "5rem", height: "2.5rem" }}
                                checked={isPasswordSelected}
                                onChange={() => toast.error("coming soon!")}
                                isOn={isPasswordSelected}
                            />
                        </FlexRowAlignCenter>
                    </FlexColumn>
                </FlexColumn>
            )}

            <FlexRowAlignCenter
                style={{
                    justifyContent: uploadMode && isUploading ? "space-between" : "flex-end",
                    alignItems: "center",
                    marginTop: "2rem",
                    gap: "2rem",
                }}>
                {uploadMode && isUploading && (
                    <FlexColumn style={{ marginTop: "-1rem", gap: spacing.XS, width: "70%" }}>
                        {uploadRemainingTime && (
                            <h4 style={{ fontWeight: 600 }}>
                                {uploadProgress === 100
                                    ? !uploadedFileId
                                        ? `Verifying upload...`
                                        : "Successfully uploaded!"
                                    : `Time remaining: ${uploadRemainingTime ? uploadRemainingTime : "calculating..."}`}
                            </h4>
                        )}
                        <div
                            style={{
                                borderRadius: "3rem",
                                height: "2rem",
                                backgroundColor: themeColors.cardTertiary,
                                overflow: "hidden", // Ensure the progress bar stays within the container
                            }}>
                            <LinearProgressBar
                                className={WALKTRHOUGH_MEDIA_UPLOAD_PROGRESS_BAR}
                                variant="determinate"
                                barColor={themeColors.primary}
                                backgroundColor={themeColors.cardTertiary}
                                borderRadius={"3rem"}
                                height={"2rem"}
                                value={uploadProgress ?? 0}
                            />
                            <UploadMediaProgressWalkthrough mediaType={mediaType} />
                        </div>
                    </FlexColumn>
                )}
                {uploadMode && isUploading && (!uploadProgress || uploadProgress < 100) && (
                    <CircularRectTextButton
                        onClick={cancelUpload}
                        sx={{
                            backgroundColor: themeColors.errorPrimary,
                            width: "11rem",
                            height: "5rem",
                            gap: "0.5rem",
                            color: themeColors.pureWhite,
                        }}>
                        cancel
                    </CircularRectTextButton>
                )}
                {uploadMode ? (
                    <ConditionalLink
                        href={`/media/${importedMediaId ?? uploadedFileId}`}
                        condition={!isNextButtonDisabled && !!userId}>
                        <CircularRectTextButton
                            onClick={nextButtonClickHandler}
                            sx={{
                                backgroundColor: !isNextButtonDisabled ? themeColors.primary : nextBackgroundColor,
                                width: "11rem",
                                height: "5rem",
                                gap: "0.5rem",
                                color: themeColors.pureWhite,
                                opacity: isNextButtonDisabled ? 0.4 : 1,
                                cursor: isNextButtonDisabled ? "not-allowed" : "pointer",
                            }}>
                            {uploadProgress === 100 && !uploadedFileId && !importedMediaId ? (
                                <LoadingAnimation size={iconSizes.SM} color={themeColors.pureWhite} />
                            ) : (
                                <>
                                    {!importedMediaId ? (
                                        <span style={{ fontSize: "1.2rem" }}>
                                            {!uploadedFileId ? (uploadProgress ? `${uploadProgress}%` : "0%") : null}
                                        </span>
                                    ) : null}
                                    next
                                    <ArrowRight size={iconSizes.SM} color={themeColors.pureWhite} />
                                </>
                            )}
                        </CircularRectTextButton>
                    </ConditionalLink>
                ) : (
                    <CircularRectTextButton
                        onClick={saveMedia}
                        sx={{
                            backgroundColor: themeColors.primary,
                            width: "11rem",
                            height: "5rem",
                            gap: "0.5rem",
                            color: themeColors.pureWhite,
                            opacity: 1,
                            cursor: "pointer",
                        }}>
                        save
                    </CircularRectTextButton>
                )}
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default MediaEditForm;
