"use client";

import { ArrowDown, ArrowUp } from "lucide-react";
import { useSearchParams } from "next/navigation";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import CircularButton from "@/components/CircularButton";
import Select from "../../components/styled/WithPreviewerPageSelect";
import { iconSizes } from "@/utils/iconProps";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import { STORAGE_KEYS } from "@/config/constants";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";

const FilterHasFlashcards = () => {
    const VALID_VALUES = ["all", "true", "false"] as const;
    type Value = (typeof VALID_VALUES)[number];

    const { addParamsPush, removeParamsPush } = useSafeQueryNavigation();
    const searchParams = useSearchParams();

    const getValueFromParams = () => {
        const value = searchParams?.get("hasFlashcards") as Value;
        return VALID_VALUES.includes(value) ? value : "all";
    };

    const selectedValue = getValueFromParams();

    return (
        <FlexRowAlignCenter style={{ gap: "1.5rem" }}>
            Has Flashcards:
            <Select
                options={[
                    { label: "All", value: "all" },
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                ]}
                onChange={({ value }) => {
                    if (value === "all") removeParamsPush(["hasFlashcards"]);
                    else addParamsPush({ hasFlashcards: value });
                }}
                menuSx={{ width: "10rem" }}
                btnSx={{ width: "8.7rem" }}
                selected={{
                    label: selectedValue === "all" ? "All" : selectedValue === "true" ? "Yes" : "No",
                    value: selectedValue,
                }}
            />
        </FlexRowAlignCenter>
    );
};

const FilterMinNumOfTerms = () => {
    const { addParamsPush, removeParamsPush } = useSafeQueryNavigation();
    const selectedValue = Number(useSearchParams()?.get("minFlashcards")) || 0;

    return (
        <FlexRowAlignCenter style={{ gap: "1.5rem" }}>
            Min number of terms:
            <Select
                options={[
                    { label: "Any", value: 0 },
                    { label: "10", value: 10 },
                    { label: "20", value: 20 },
                    { label: "30", value: 30 },
                    { label: "40", value: 40 },
                    { label: "50", value: 50 },
                ]}
                onChange={({ value }) => {
                    if (value === 0) removeParamsPush(["minFlashcards"]);
                    else addParamsPush({ minFlashcards: String(value) });
                }}
                menuSx={{ width: "10rem" }}
                btnSx={{ width: "8.7rem" }}
                selected={{ label: selectedValue === 0 ? "Any" : String(selectedValue), value: selectedValue }}
            />
        </FlexRowAlignCenter>
    );
};

const FilterCreatedBy = () => {
    const { addParamsPush, removeParamsPush } = useSafeQueryNavigation();
    const isVerified = useSearchParams()?.get("verified") === "true";

    return (
        <FlexRowAlignCenter style={{ gap: "1.5rem" }}>
            Created by:
            <Select
                options={[
                    { label: "All users", value: "all" },
                    { label: "Verified", value: "verified" },
                ]}
                menuSx={{ width: "14rem" }}
                btnSx={{ width: "15rem" }}
                onChange={({ value }) => {
                    if (value === "all") removeParamsPush(["verified"]);
                    else addParamsPush({ verified: "true" });
                }}
                selected={{
                    label: isVerified ? "Verified" : "All users",
                    value: isVerified ? "verified" : "all",
                }}
            />
        </FlexRowAlignCenter>
    );
};

const SortField = ({ initialValue }: { initialValue?: string | null }) => {
    const VALID_VALUES = ["Updated", "Created", "Title"] as const;
    type Value = (typeof VALID_VALUES)[number];

    const searchParams = useSearchParams();
    const { addParamsPush } = useSafeQueryNavigation();

    const getSortFromParams = () => {
        const sort = (searchParams?.get("sort") ?? initialValue) as Value;
        return VALID_VALUES.includes(sort) ? sort : "Updated";
    };

    const selectedValue = getSortFromParams();

    return (
        <Select
            options={[
                { label: "Updated", value: "Updated" },
                { label: "Created", value: "Created" },
                { label: "Title", value: "Title" },
            ]}
            onChange={({ value }) => {
                addParamsPush({ sort: value });
                document.cookie = `${STORAGE_KEYS.SUBJECT_PAGE_SORT}=${value}; path=/; max-age=${TIME_SECONDS.YEAR}; secure`;
            }}
            menuSx={{ width: "15rem" }}
            btnSx={{
                width: "fit-content",
                backgroundColor: "transparent",
                padding: 0,
                borderRadius: "0.5rem",
                "&:hover, &:active": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.05)" },
            }}
            selected={{
                label: selectedValue === "Updated" ? "Updated" : selectedValue === "Created" ? "Created" : "Title",
                value: selectedValue === "Updated" ? "Updated" : selectedValue === "Created" ? "Created" : "Title",
            }}
            adornment={null}
        />
    );
};

const FilterOrder = ({ initialValue }: { initialValue?: string | null }) => {
    const VALID_DIRECTIONS = ["asc", "desc"] as const;
    type Direction = (typeof VALID_DIRECTIONS)[number];

    const searchParams = useSearchParams();
    const { addParamsPush } = useSafeQueryNavigation();

    const getDirectionFromParams = () => {
        const direction = (searchParams?.get("direction") ?? initialValue) as Direction;
        return VALID_DIRECTIONS.includes(direction) ? direction : "desc";
    };

    const direction = getDirectionFromParams();

    return (
        <CircularButton
            radius="3.6rem"
            sx={{
                backgroundColor: themeColors.background,
                "&:hover, &:active": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.05)" },
            }}
            onClick={() => {
                const newDirection = direction === "asc" ? "desc" : "asc";
                addParamsPush({ direction: newDirection });
                document.cookie = `${STORAGE_KEYS.SUBJECT_PAGE_DIRECTION}=${newDirection}; path=/; max-age=${TIME_SECONDS.YEAR}; secure`;
            }}
            tooltip={direction === "asc" ? "Ascending" : "Descending"}>
            {direction === "asc" ? <ArrowDown size={iconSizes.MD} /> : <ArrowUp size={iconSizes.MD} />}
        </CircularButton>
    );
};

const FilterShowSize = () => {
    const { addParamsPush } = useSafeQueryNavigation();

    return (
        <Select
            options={[
                { label: "Show 20", value: 20 },
                { label: "Show 30", value: 30 },
                { label: "Show 40", value: 40 },
                { label: "Show 50", value: 50 },
            ]}
            onChange={({ value }) => addParamsPush({ pagesize: String(value), page: "1" })}
            menuSx={{ width: "12rem" }}
            btnSx={{ width: "13rem" }}
            selected={{ label: "Show 20", value: 20 }}
        />
    );
};

type SubjectPageFilters = {
    sortInitialValue?: string | null;
    directionInitialValue?: string | null;
    type: "notes" | "flashcards";
};

const SubjectPageFilters = ({ sortInitialValue, directionInitialValue, type }: SubjectPageFilters) => {
    const notesTab = type === "notes";
    const flashcardsTab = type === "flashcards";

    return (
        <FlexColumn className="bold" style={{ fontSize: "1.7rem" }}>
            <span style={{ marginBottom: `calc(${spacing.MD} - 0.3rem)` }}>Filters</span>
            <FlexRowAlignCenter
                style={{
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: `calc(${spacing.MD} - 0.3rem) 5.2rem`,
                }}>
                <FlexRowAlignCenter style={{ gap: spacing.LG, flexWrap: "inherit" }}>
                    {flashcardsTab && <FilterMinNumOfTerms />}
                    {notesTab && <FilterHasFlashcards />}
                    <FilterCreatedBy />
                </FlexRowAlignCenter>

                <FlexRowAlignCenter style={{ gap: "1.1rem", flex: 1 }}>
                    <div style={{ marginLeft: "auto" }} />
                    <SortField initialValue={sortInitialValue} />
                    <FilterOrder initialValue={directionInitialValue} />
                    <FilterShowSize />
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default SubjectPageFilters;
