import Select, { SelectProps } from "@/components/Select";
import { themeColors } from "@/utils/themeColors";

const SearchPageSelect = <T extends { label: string; value: unknown }>({ btnSx, ...selectProps }: SelectProps<T>) => {
    return (
        <Select
            {...selectProps}
            btnSx={{
                width: "5rem",
                height: "4.4rem",
                backgroundColor: themeColors.neutralWhite,
                fontSize: "1.5rem",
                padding: "0 1.5rem",
                borderRadius: "999px",
                ...btnSx,
            }}
        />
    );
};

export default SearchPageSelect;
