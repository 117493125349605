import { themeColors } from "@/utils/themeColors";

export const H1 = ({ style, children, ...h1Props }: React.HTMLAttributes<HTMLHeadingElement>) => {
    return (
        <h1
            {...h1Props}
            style={{
                color: themeColors.neutralBlack,
                fontSize: "4.6rem",
                fontWeight: "800",
                marginBottom: "1.6rem",
                lineHeight: "6.4rem",
                textAlign: "center",
                ...style,
            }}>
            {children}
        </h1>
    );
};

/**
 * UI wise h1 and h2 are just the same, it's just different in tag being used for SEO purposed
 */
export const H2 = ({ style, children, ...h2Props }: React.HTMLAttributes<HTMLHeadingElement>) => {
    return (
        <h2
            {...h2Props}
            style={{
                color: themeColors.neutralBlack,
                fontSize: "4.6rem",
                fontWeight: "800",
                marginBottom: "1.6rem",
                textAlign: "center",
                lineHeight: "4.8rem",
                ...style,
            }}>
            {children}
        </h2>
    );
};

export const H3 = ({ style, children, ...h3Props }: React.HTMLAttributes<HTMLHeadingElement>) => {
    return (
        <h3
            {...h3Props}
            style={{
                color: themeColors.neutralBlack,
                fontSize: "3.4rem",
                fontWeight: "600",
                marginBottom: "1.2rem",
                lineHeight: "4.8rem",
                ...style,
            }}>
            {children}
        </h3>
    );
};

export const H4 = ({ style, children, ...h4Props }: React.HTMLAttributes<HTMLHeadingElement>) => {
    return (
        <h4
            {...h4Props}
            style={{
                color: themeColors.neutralBlack,
                fontSize: "2.6rem",
                fontWeight: "600",
                ...style,
            }}>
            {children}
        </h4>
    );
};

export const H5 = ({ style, children, ...h5Props }: React.HTMLAttributes<HTMLHeadingElement>) => {
    return (
        <h5
            {...h5Props}
            style={{
                color: themeColors.neutralBlack,
                fontSize: "2rem",
                fontWeight: "600",
                ...style,
            }}>
            {children}
        </h5>
    );
};
