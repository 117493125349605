import { Quote } from "lucide-react";
import { strokeWidth, iconSizes } from "@/utils/iconProps";

const QuoteIcon = ({
    opening,
    closing,
    fill,
    color,
    style,
}: { opening?: boolean; closing?: boolean } & React.SVGAttributes<SVGElement>) => {
    return (
        <Quote
            fill={fill}
            color={color}
            strokeWidth={strokeWidth.normal}
            size={iconSizes.LG}
            style={{
                transform: opening ? "scaleX(-1)" : closing ? "scaleX(1)" : undefined,
                marginTop: "0.4rem",
                width: "10rem",
                ...style,
            }}
        />
    );
};

export default QuoteIcon;
