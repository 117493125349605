"use client";

import { UserDetails } from "@knowt/syncing/graphql/schema";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import StarRating from "@/components/StarRating";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import UserNameWithBadge from "@/components/UserNameWithBadge";
import { themeColors } from "@/utils/themeColors";
import Link from "@/components/wrappers/Link";
import { spacing } from "@/utils/spacing";
import Circular from "@/components/Circular";

const Pill = ({
    children,
    ...btnProps
}: { children: React.ReactNode; disabled?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <Circular
            {...btnProps}
            className="bold"
            radius="auto"
            style={{
                height: "4rem",
                flexDirection: "row",
                backgroundColor: "#F4F4F4",
                color: "#000000",
                fontSize: "1.1rem",
                padding: "0 1.3rem",
                gap: "0.3rem",
            }}>
            {children}
        </Circular>
    );
};

const SearchPageUserCard = ({ user }: { user: UserDetails }) => {
    const { ID, pictureUrl, username, Name, rating = 0 } = user;
    return (
        <Link href={`/u/${username}`} key={ID}>
            <FlexColumn
                style={{
                    backgroundColor: themeColors.card,
                    padding: "1.8rem 2rem",
                    borderRadius: "2rem",
                    gap: spacing.SM,
                }}>
                <UserAvatar src={pictureUrl || undefined} radius={"7rem"} alt={Name || username || "U"} />
                <FlexColumn style={{ color: themeColors.neutralBlack, height: "3rem" }}>
                    <UserNameWithBadge
                        className="ellipsisText"
                        style={{ fontSize: "1.5rem", fontWeight: "600" }}
                        type="Name"
                        priority="verified"
                        user={user}
                        badgeSize={18}
                    />
                    <span style={{ fontSize: "1rem" }}>@{username}</span>
                </FlexColumn>
                <FlexColumn style={{ flexWrap: "wrap", gap: spacing.XS, alignItems: "flex-start" }}>
                    <FlexRowAlignCenter style={{ gap: "inherit" }}>
                        <Pill>{user?.numFollowers || 0} Followers</Pill>
                        <Pill>{user?.numFollowing || 0} Following</Pill>
                    </FlexRowAlignCenter>
                    <Pill>
                        <StarRating readOnly max={1} value={rating || 0} sx={{ fontSize: "1.4rem" }} />
                        {rating} Stars
                    </Pill>
                </FlexColumn>
            </FlexColumn>
        </Link>
    );
};

export default SearchPageUserCard;
