import React from "react";

export const VimeoVideo = ({
    id,
    style,
    iframeStyle,
}: {
    id: string;
    style?: React.CSSProperties;
    iframeStyle?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                position: "relative",
                width: style?.width ?? "100%",
                height: style?.height ?? "100%",
                maxHeight: style?.maxHeight ?? "unset",
                maxWidth: style?.maxWidth ?? "unset",
            }}>
            <iframe
                allowFullScreen={false}
                seamless={true}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: 0,
                    borderRadius: "1.5rem",
                    ...iframeStyle,
                }}
                src={`https://player.vimeo.com/video/${id}?background=1&amp;autoplay=1&amp;loop=1;dnt=1`}
                title="Magically transform your notes with AI"></iframe>
        </div>
    );
};

export default VimeoVideo;
