"use client";

import Image from "next/image";
import { CSSProperties } from "react";
import { FlexColumnAlignJustifyCenter } from "../../../components/Flex";
import LinkWrapper from "../../../components/wrappers/Link";

import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import useAdBlockerBannerContent from "@/hooks/useAdBlockerBannerContent";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { ASSETS_URL } from "@/config/deployConstants";

const sizes = {
    "100x600": {
        iconSize: "4rem",
        titleFontSize: "1.4rem",
        descriptionFontSize: "1.4rem",
        arrowButtonSize: 20,
        gap: "1.4rem",
    },
    "160x600": {
        iconSize: "7rem",
        titleFontSize: "1.8rem",
        descriptionFontSize: "1.8rem",
        arrowButtonSize: 20,
        gap: "2rem",
    },
    "300x600": {
        iconSize: "7rem",
        titleFontSize: "1.8rem",
        descriptionFontSize: "1.8rem",
        arrowButtonSize: 20,
        gap: "2rem",
    },
    "300x250": {
        iconSize: "4rem",
        titleFontSize: "1.4rem",
        descriptionFontSize: "1.4rem",
        arrowButtonSize: 20,
        gap: "1rem",
    },
    "468x60": {
        iconSize: "3rem",
        titleFontSize: "1rem",
        descriptionFontSize: "1rem",
        arrowButtonSize: 10,
        gap: "1rem",
    },
    "728x90": {
        iconSize: "4.5rem",
        titleFontSize: "1.3rem",
        descriptionFontSize: "1.3rem",
        arrowButtonSize: 20,
        gap: "1.4rem",
    },
};

type AdBlockerBannerProps = {
    height: number;
    width: number;
    source?: string;
    style?: CSSProperties;
    bannerStyle?: CSSProperties;
};

const AdBlockerBanner = ({ source, width, height, style, bannerStyle }: AdBlockerBannerProps) => {
    const { smDown } = useBreakPoints();
    const isHorizontal = width >= 468;
    const { content } = useAdBlockerBannerContent(isHorizontal);

    const measurements = sizes[`${width}x${height}`];
    if (!measurements || smDown) return null;

    const { iconSize, titleFontSize, descriptionFontSize, arrowButtonSize, gap } = measurements;

    const ArrowButton = () => {
        return (
            <FlexColumnAlignJustifyCenter
                style={{
                    minWidth: arrowButtonSize * 2,
                    minHeight: arrowButtonSize * 2,
                    borderRadius: "4rem",
                    backgroundColor: "black",
                }}>
                <Image
                    src={`${ASSETS_URL}/images/arrow-right.png`}
                    alt="arrow"
                    width={arrowButtonSize}
                    height={arrowButtonSize}
                />
            </FlexColumnAlignJustifyCenter>
        );
    };

    return (
        <FlexColumnAlignJustifyCenter
            style={{ marginInline: "auto", overflow: "hidden", position: "relative", ...style }}>
            <LinkWrapper
                href="/plans"
                onClick={() => Mixpanel.track("AdBlocker Conversion - Clicked", { source })}
                style={{
                    display: "flex",
                    position: "relative",
                    borderRadius: "2rem",
                    backgroundColor: content.color,
                    height,
                    width,
                    transition: "transform 0.2s ease-out, box-shadow 0.2s ease-out",
                    ...bannerStyle,
                }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        padding: "2rem",
                        flexDirection: isHorizontal ? "row" : "column",
                        justifyContent: "flex-start",
                        alignItems: isHorizontal ? "center" : "normal",
                        color: "black",
                        gap,
                    }}>
                    <Image src={content.icon} alt={content.alt} height={iconSize} width={iconSize} />
                    <div style={{ display: "flex", flexDirection: "column", gap: isHorizontal ? 0 : gap }}>
                        <div className={"knowtFontBold"} style={{ fontSize: titleFontSize }}>
                            {content.title}
                        </div>
                        <div
                            style={{
                                fontSize: descriptionFontSize,
                                fontWeight: "400",
                                marginTop: "0.5rem",
                            }}>
                            {content.description}
                        </div>
                    </div>
                </div>
                {isHorizontal ? (
                    <FlexColumnAlignJustifyCenter style={{ marginRight: gap }}>
                        {ArrowButton()}
                    </FlexColumnAlignJustifyCenter>
                ) : (
                    <div style={{ position: "absolute", bottom: gap, right: gap }}>{ArrowButton()}</div>
                )}
            </LinkWrapper>
        </FlexColumnAlignJustifyCenter>
    );
};

export default AdBlockerBanner;
