"use client";

import { useParams } from "next/navigation";
import { FlexRowAlignCenter } from "@/components/Flex";
import TopicOrSubjectPicker from "../../components/TopicPicker";
import { examNameToUrlComponent, getExamNameAndType } from "@knowt/syncing/hooks/exams/utils";

const ExamPageTopicPicker = ({ units }: { units: string[] }) => {
    const params = useParams();
    const { param: selectedUnit, type } = getExamNameAndType(params.unit as string);

    const examName = !params.unit
        ? examNameToUrlComponent(getExamNameAndType(params.urlComponent as string).param as string)
        : params.urlComponent;

    return (
        <FlexRowAlignCenter className="strippedList" as={"ul"} style={{ gap: "1.3rem", flexWrap: "wrap" }}>
            <TopicOrSubjectPicker
                topics={units}
                style={{ fontSize: "1.5rem" }}
                hrefFormatter={unit => `/exams/${params.type}/view/${examName}/${examNameToUrlComponent(unit)}-${type}`}
                selectedTopics={selectedUnit ? [selectedUnit] : []}
            />
        </FlexRowAlignCenter>
    );
};

export default ExamPageTopicPicker;
