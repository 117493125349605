import { SUPPORTED_NOTE_MIME_TYPES } from "./fileTypeUtils";
import { platform } from "@/platform";
import { retry } from "@/utils/genericUtils";
import { uploadPictureToS3 } from "@/utils/s3";

export const SUPPORTED_CONVERSIONS = ["pdf", "txt"];

const MAX_FILE_SIZE = 100 * 1024 * 1024;

const readFileAsText = (blob: Blob): Promise<string | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = reject;
        fileReader.readAsText(blob);
    });
};

export const isNoteImportableFile = (file: File | Blob) => {
    return SUPPORTED_NOTE_MIME_TYPES.includes(file.type);
};

/**
 * Returns the text contained in a file that the user has selected
 * @return {Promise<*|Promise<unknown>|Promise>}
 */
export const convertBlobToNote = async ({
    blob,
    fileType,
    getImgDimensions = async () => ({ width: 0, height: 0 }),
}: {
    blob: Blob;
    fileType?: string;
    getImgDimensions?: (url: string) => Promise<{ width: number; height: number }>;
}): Promise<{ content: string }> => {
    if (!SUPPORTED_NOTE_MIME_TYPES.includes(blob.type)) {
        throw { customMessage: "This file cannot be made into a note." };
    }

    if (blob?.size > MAX_FILE_SIZE) {
        throw { customMessage: "Sorry please upload a file less than 10mb to study from it" };
    }

    const lowercaseFileType = fileType.toLowerCase();

    if (lowercaseFileType === "txt") {
        const content = (await readFileAsText(blob)) as string;
        return { content };
    }

    if (lowercaseFileType === "docx") {
        const [arrayBuffer, { convertToHtml, images }] = await Promise.all([blob.arrayBuffer(), import("mammoth")]);

        const imageSizesMap = {};

        const getImageDetails = async img => {
            const { altText, contentType, read } = img;

            const url = await uploadPictureToS3(await read(), "knowt-user-attachments", contentType);

            const { width: _width, height: _height } = await getImgDimensions(url);

            // if its too big, scale it down and keep the aspect ratio
            const MAX_DIMS = 800;
            const widthRatio = _width / MAX_DIMS;
            const heightRatio = _height / MAX_DIMS;
            const isTooLarge = widthRatio > 1 || heightRatio > 1;

            // take the largest ratio and scale both down by that
            const ratio = Math.max(widthRatio, heightRatio);
            const width = isTooLarge ? Math.floor(_width / ratio) : _width;
            const height = isTooLarge ? Math.floor(_height / ratio) : _height;
            imageSizesMap[url] = { width, height };
            return {
                src: `${url}`,
                alt: altText || "",
            };
        };

        const result = await convertToHtml(
            { arrayBuffer },
            {
                convertImage: images.imgElement(img => retry(() => getImageDetails(img))),
            }
        );

        const { getHtmlFromContent } = await platform.dataCleaning();
        const _content = getHtmlFromContent({ content: result.value, type: "note" });

        // Add image sizes to the img tags, if available.
        const content = Object.keys(imageSizesMap).length
            ? _content
                  // add the correct width and height to the img tags
                  .replace(/<img[^>]*>/g, img => {
                      const src = img.match(/src="([^"]*)"/)[1];
                      const { width, height } = imageSizesMap[src];
                      return `<img width="${width}" height="${height}" src="${src}">`;
                  })
                  // replace img tags that are wrapped in a p tag to remove the extra line break
                  .replace(/<p><img[^>]*><\/p>/g, img => img.replace(/<p>/g, "").replace(/<\/p>/g, ""))
            : _content;

        return { content };
    }

    return null;
};
