import Link from "@/components/wrappers/Link";

const UploadDisclaimer = () => {
    return (
        <span style={{ textAlign: "center", marginBottom: "3.2rem", maxWidth: "75%" }}>
            By uploading your file to Knowt, you acknowledge that you agree to Knowt’s{" "}
            <Link
                href={"/terms"}
                style={{
                    color: "inherit",
                    fontWeight: "bold",
                    textDecoration: "none",
                }}>
                <b>Terms of Service & Community Guidelines.</b>
            </Link>{" "}
            Please be sure not to violate others’ copyright or privacy rights.{" "}
            <Link
                href={"/privacy"}
                style={{
                    color: "inherit",
                    fontWeight: "bold",
                    textDecoration: "none",
                }}>
                <b>Learn more</b>
            </Link>
        </span>
    );
};

export default UploadDisclaimer;
