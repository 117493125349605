"use client";
import { FlexRowAlignCenter } from "@/components/Flex";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { themeColors } from "@/utils/themeColors";
import { ChevronRight } from "lucide-react";

type LearnMoreButtonProps = {
    href?: string;
};

const LearnMoreButton = ({ href }: LearnMoreButtonProps) => {
    return (
        <LinkWrapper href={href || ""} openInNewTab>
            <FlexRowAlignCenter>
                <span className="body1 bold" style={{ color: themeColors.primary4 }}>
                    Learn more
                </span>
                <ChevronRight size={24} color={themeColors.primary4} />
            </FlexRowAlignCenter>
        </LinkWrapper>
    );
};

export default LearnMoreButton;
