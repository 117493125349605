"use client";

import Image from "next/image";
import SectionContainer from "./SectionContainer";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { ASSETS_URL } from "@/config/deployConstants";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import br from "@/styles/breakpoints.module.css";
import { Box } from "@mui/material";
import LinkWrapper from "@/components/wrappers/Link/Link";

const Book1on1WithCEO = () => {
    return (
        <SectionContainer
            title="Book a 1 on 1 with the CEO for free to learn more!"
            subtitle="Subscribe to a plan made for individual teachers or personalize one for your school district.">
            <FlexColumn style={{ gap: spacing.LG }}>
                <FlexRowAlignCenter className={br.smDownColumn} style={{ gap: spacing.LG_2 }}>
                    <FlexColumn>
                        <Image
                            src={`${ASSETS_URL}/images/profiles/abheek.svg`}
                            alt="book a 1on1 with the CEO"
                            width={148}
                            height={148}
                            style={{ marginBottom: spacing.SM }}
                        />
                        <span className="bodyBold1">Abheek Pandoh</span>
                        <span className="secondaryText1">CEO of Knowt</span>
                    </FlexColumn>

                    <FlexColumn
                        style={{
                            padding: spacing.LG_2,
                            border: `2px solid ${themeColors.neutral1}`,
                            borderRadius: borderRadius.card,
                            gap: spacing.XS,
                        }}>
                        <span className="bodyBold1">
                            I founded Knowt in 2019 as a student to help other students. Now, my team and I want to help
                            teachers.
                        </span>
                        {
                            "My mom was a teacher even before I was born, and I’ve grown up understanding the challenges around both teaching and learning. I want to work with you to make sure your students learn better, and you aren’t overworked. I am really excited to learn about what I can do for you!"
                        }
                    </FlexColumn>
                </FlexRowAlignCenter>

                <FlexRowAlignCenter
                    style={{
                        padding: spacing.SM,
                        border: `2px solid ${themeColors.neutral1}`,
                        borderRadius: borderRadius.card,
                        justifyContent: "space-between",
                    }}>
                    <FlexColumn style={{ gap: spacing.XS }}>
                        <span className="bodyBold1">Intro call with Abheek</span>
                        <span className="secondaryText1">30 minutes</span>
                    </FlexColumn>
                    <LinkWrapper href={"https://calendly.com/knowt-abheek/knowt-teachers"} openInNewTab>
                        <Box
                            sx={{
                                backgroundColor: themeColors.primary,
                                padding: `${spacing.XS_2} ${spacing.MD}`,
                                borderRadius: "1rem",
                                transition: "all 0.1s ease-in-out",
                                "&:hover": { boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.35)" },
                            }}>
                            <span className="bodyBold2" style={{ color: themeColors.primaryDark }}>
                                Book
                            </span>
                        </Box>
                    </LinkWrapper>
                </FlexRowAlignCenter>
            </FlexColumn>
        </SectionContainer>
    );
};

export default Book1on1WithCEO;
