"use client";
import { Exam, ExamResourceType } from "@knowt/syncing/graphql/schema";
import Image from "next/image";
import React from "react";
import { Flex, FlexColumn } from "@/components/Flex";
import Mixpanel from "@/utils/analytics/Mixpanel";
import CircularButton from "@/components/CircularButton";
import { EXAMS_BUCKET } from "@knowt/syncing/hooks/exams/utils";
import Link from "@/components/wrappers/Link";
import { themeColors } from "@/utils/themeColors";
import styles from "./examSubjectResourceSection.module.css";
import { RESOURCES_DATA } from "../utils";

const ExamSubjectResourceSection = ({
    type,
    items,
    circularItem,
    examData,
}: {
    type: "video" | "discord";
    items?: (ExamResourceType | null | undefined)[] | null | undefined;
    circularItem?: boolean;
    examData: Exam;
}) => {
    const [hoveredIndex, setHoveredIndex] = React.useState<number | null>(null);

    return (
        <FlexColumn style={{ marginBottom: "5rem" }} as={"section"}>
            <FlexColumn as={"section"} style={{ gap: "1rem" }}>
                <p className="heading4">{RESOURCES_DATA[type].title + " " + examData.name}</p>
            </FlexColumn>

            {/* CONTENT */}
            <Flex
                className={styles.resourceSection}
                style={{ marginTop: "3rem", gap: "3rem", width: "100%", display: "grid" }}>
                {items?.map((item, index) =>
                    item?.link ? (
                        <Link
                            href={item.link}
                            key={item.name}
                            target="_blank"
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            style={{
                                width: "100%",
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                padding: "2.4rem",
                                gap: "2.4rem",
                                borderRadius: "2rem",
                                backgroundColor:
                                    hoveredIndex === index ? themeColors.neutralBlack : themeColors.neutralWhite,
                                color: hoveredIndex === index ? themeColors.neutralWhite : themeColors.neutralBlack,
                                height: "12rem",
                                transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
                            }}
                            onClick={() => {
                                Mixpanel.track("Exam Page - Resource Clicked", {
                                    resource: item.name,
                                    resourceType: type,
                                    exam: examData?.name,
                                    type: examData?.type,
                                });
                            }}>
                            {circularItem ? (
                                <CircularButton sx={{ flexDirection: "column" }} radius="7.5rem">
                                    <Image
                                        src={EXAMS_BUCKET + item.image}
                                        alt={item.name}
                                        width={45}
                                        height={45}
                                        style={{ borderRadius: "50%", objectFit: "contain" }}
                                    />
                                </CircularButton>
                            ) : (
                                <Image
                                    src={EXAMS_BUCKET + item.image}
                                    alt={item.name}
                                    width={75}
                                    height={75}
                                    style={{ borderRadius: "50%", objectFit: "contain" }}
                                />
                            )}

                            <b
                                style={{
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                }}
                                key={item.name}>
                                {item.name}
                            </b>
                        </Link>
                    ) : null
                )}
            </Flex>
        </FlexColumn>
    );
};

export default ExamSubjectResourceSection;
