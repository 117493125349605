import { fromEntries } from "@knowt/syncing/utils/genericUtils";

const findArrayOfTargetSumFromArrayOfNum = ({ array, targetSum }: { array: number[]; targetSum: number }) => {
    let currentSum = 0;
    const remainingArr = array;

    const newArr = array
        .sort((a, b) => b - a)
        .filter(val => {
            if (currentSum + val <= targetSum) {
                currentSum += val;
                remainingArr.splice(remainingArr.indexOf(val), 1);
                return true;
            }
        });

    if (currentSum > targetSum) {
        newArr.pop();
    }
    return { targetArr: newArr, remainingArr };
};

export const splitCategoriesIntoTwoColumns = (items: any[]) => {
    const totalItems = items.reduce((acc, { subjects }) => acc + subjects.length, 0);

    //we're trying to split into 2 columns with equal(or almost equal) amount of subjects so that they both
    //will have similar heights
    const targetHeight = totalItems / 2;

    const examsPerCategory = fromEntries(
        items.map(category => {
            return [category.category, category.subjects.length];
        })
    )!;

    const arrayOfAvailableExamNumbers = Object.values(examsPerCategory);

    //function will split array into two arrays with equal sum of value because of targetHeight
    const { targetArr: firstHalf } = findArrayOfTargetSumFromArrayOfNum({
        array: arrayOfAvailableExamNumbers,
        targetSum: targetHeight,
    });

    const firstColumn = firstHalf.reverse().map(numOfSubjects => {
        return Object.keys(examsPerCategory).find(category => examsPerCategory?.[category] === numOfSubjects);
    });

    // take the rest
    const secondColumn = Object.keys(examsPerCategory).filter(category => !firstColumn.includes(category));

    return [firstColumn, secondColumn];
};
