"use client";

import Image from "next/image";
import { spacing } from "@/utils/spacing";
import { ChevronDown } from "lucide-react";
import { useParams, usePathname } from "next/navigation";
import { useRouter } from "next13-progressbar";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import { FlexRowAlignCenter } from "@/components/Flex";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import OutlineTextButton from "@/components/CircularButton/styled/OutlineTextButton";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import Link from "@/components/wrappers/Link";

const NestedOption = ({ label, image }: { label: string; image: string }) => {
    return (
        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                <Image src={image} alt={label} width={"16"} height={"16"} style={{ objectFit: "contain" }} />
                <span className="secondaryTextBold1">{label}</span>
            </FlexRowAlignCenter>
        </FlexRowAlignCenter>
    );
};

const authBarTabs = [
    {
        label: "AI PDF Summarizer",
        route: ["/ai-pdf-summarizer"],
        icon: `${ASSETS_URL}/images/pdf-icon.svg`,
        nestable: false,
    },
    {
        label: "AI PPT Summarizer",
        route: ["/ai-powerpoint-summarizer"],
        icon: `${ASSETS_URL}/images/power-point-rounded.svg`,
        nestable: false,
    },
    {
        label: "AI Video Summarizer",
        route: ["/ai-video-summarizer"],
        icon: `${ASSETS_URL}/images/video-icon.svg`,
        nestable: false,
    },
    {
        label: "AI Excel Summarizer",
        route: ["/ai-spreadsheet-summarizer"],
        icon: `${ASSETS_URL}/images/excel-icon.svg`,
        nestable: false,
    },
    {
        label: "AI Notes",
        route: ["/ai-notes"],
        icon: `${ASSETS_URL}/images/note-icon.svg`,
        nestable: false,
    },
];

const examRoomTabs = [
    {
        label: "Practice Exam Room",
        route: ["/exams/AP/exam-practice-room"],
        icon: `${ASSETS_URL}/images/ExamIcon.png`,
        nestable: false,
    },
    {
        label: "Free Response Room",
        route: ["/exams/AP/free-response-room"],
        icon: `${ASSETS_URL}/images/response-room-icon.svg`,
        nestable: false,
    },
];

const unAuthBarTabs = [
    {
        label: "Quizlet Import",
        route: ["/", "/verify", "/reset"],
        icon: `${ASSETS_URL}/images/flashcard-icon.svg`,
        nestable: false,
    },
    {
        label: "AI Summarizers",
        route: [
            "/ai-pdf-summarizer",
            "/ai-powerpoint-summarizer",
            "/ai-video-summarizer",
            "/ai-spreadsheet-summarizer",
        ],
        icon: `${ASSETS_URL}/images/pdf-icon.svg`,
        nestable: true,
    },
    {
        label: "AI Notes",
        route: ["/ai-notes"],
        icon: `${ASSETS_URL}/images/note-icon.svg`,
        nestable: false,
    },
    ...examRoomTabs,
    {
        label: "Teachers",
        route: ["/teachers"],
        icon: `${ASSETS_URL}/images/response-room-icon.svg`,
        nestable: false,
    },
];

const LandingTabSwitcher = () => {
    const router = useRouter();
    const pathname = usePathname();
    const params = useParams();
    const { userId } = useCurrentUser();

    const modifiedPathname = "/" + pathname.split("/").at(params?.mediaId ? -2 : -1);

    const inExamRoomPages = pathname.includes("/exam-practice-room") || pathname.includes("/free-response-room");

    const data: Array<{ label: string; route: string[]; icon: string; nestable: boolean }> = userId
        ? inExamRoomPages
            ? examRoomTabs
            : authBarTabs
        : unAuthBarTabs;

    const nestedOptions = {
        "AI Summarizers": [
            {
                label: "AI PDF Summarizer",
                node: <NestedOption label={"AI PDF Summarizer"} image={`${ASSETS_URL}/images/pdf-icon.svg`} />,
                onClick: () => {
                    router.push("/ai-pdf-summarizer");
                },
            },
            {
                label: "AI PPT Summarizer",
                node: (
                    <NestedOption label={"AI PPT Summarizer"} image={`${ASSETS_URL}/images/power-point-rounded.svg`} />
                ),
                onClick: () => {
                    router.push("/ai-powerpoint-summarizer");
                },
            },
            {
                label: "AI Video Summarizer",
                node: <NestedOption label={"AI Video Summarizer"} image={`${ASSETS_URL}/images/video-icon.svg`} />,
                onClick: () => {
                    router.push("/ai-video-summarizer");
                },
            },
            {
                label: "AI Excel Summarizer",
                node: <NestedOption label={"AI Excel Summarizer"} image={`${ASSETS_URL}/images/excel-icon.svg`} />,
                onClick: () => {
                    router.push("/ai-spreadsheet-summarizer");
                },
            },
        ],
    };

    const renderLabel = ({ icon, label, nestable = false }) => {
        return (
            <FlexRowAlignCenter style={{ gap: spacing.XS }}>
                <Image src={icon} alt={label} width={20} height={20} />
                <span className="secondaryTextBold1">{label}</span>
                {nestable && <ChevronDown size={20} />}
            </FlexRowAlignCenter>
        );
    };

    const renderNestableButton = (openMenu, label, icon, isSelected) => {
        return (
            <OutlineTextButton
                onClick={e => openMenu(e)}
                text={renderLabel({ label, icon, nestable: true })}
                sx={{
                    borderRadius: borderRadius.card,
                    padding: `${spacing.XS_2} ${spacing.SM}`,
                    background: isSelected ? themeColors.background : "transparent",
                    color: themeColors.neutralBlack,
                    border: `2px solid ${themeColors.neutral2}`,
                    height: "auto",
                    "&:hover": {
                        background: themeColors.background,
                    },
                }}
            />
        );
    };

    return (
        <FlexRowAlignCenter
            className="hide-scrollbar"
            style={{ gap: spacing.SM, marginBottom: spacing.LG_2, overflowX: "scroll", paddingInline: spacing.XS }}>
            {data.map(({ label, route, icon, nestable }, idx) => {
                const isSelected = route.some(r => r.endsWith(modifiedPathname));

                return nestable ? (
                    <WithOptionsMenu
                        key={idx + label}
                        options={nestedOptions[label]}
                        menuProps={{
                            PaperProps: {
                                sx: {
                                    border: `2px solid ${themeColors.neutral1}`,
                                    marginTop: "1rem",
                                    borderRadius: "2rem",
                                    width: "25rem",
                                    "& ul": {
                                        padding: "0",
                                    },
                                },
                            },
                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                            transformOrigin: { vertical: "top", horizontal: "left" },
                        }}
                        menuItemProps={{ sx: { padding: "1rem 2rem", width: "35rem", margin: "0" } }}>
                        {({ openMenu }) => renderNestableButton(openMenu, label, icon, isSelected)}
                    </WithOptionsMenu>
                ) : (
                    <Link href={route[0]} key={idx + label}>
                        <OutlineTextButton
                            key={idx + label}
                            text={renderLabel({ icon, label })}
                            sx={{
                                borderRadius: borderRadius.card,
                                padding: `${spacing.XS_2} ${spacing.SM}`,
                                background: isSelected ? themeColors.background : "transparent",
                                color: themeColors.neutralBlack,
                                border: `2px solid ${themeColors.neutral2}`,
                                height: "auto",
                                "&:hover": {
                                    background: themeColors.background,
                                },
                                cursor: "pointer",
                            }}
                        />
                    </Link>
                );
            })}
        </FlexRowAlignCenter>
    );
};

export default LandingTabSwitcher;
