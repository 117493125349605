"use client";

import { themeColors } from "@/utils/themeColors";
import { FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { borderRadius } from "@/utils/borderRadius";
import SmallLinkedCard from "./SmallLinkedCard";
import styles from "./medias/video-summarizer.module.css";
import SectionContainer from "../../components/SectionContainer";
import br from "@/styles/breakpoints.module.css";
import { LandingPageTopic, videoWithTwoLinkedCardsContent } from "../utils/constants";
import { useInView } from "react-intersection-observer";
import LazyLoaded from "@/components/LazyLoaded";
import dynamic from "next/dynamic";

const VimeoVideo = dynamic(() => import("@/components/VimeoVideo"), { ssr: false });

const VideoCard = ({ videoCardContent }) => {
    return (
        <FlexColumnAlignJustifyCenter
            className={styles.videoCardContainer}
            style={{
                boxSizing: "border-box",
                backgroundColor: themeColors.card,
                borderRadius: borderRadius.card,
                width: "100%",
                gap: "4rem",
            }}>
            <FlexColumnAlignJustifyCenter style={{ gap: "1.6rem", textAlign: "center" }}>
                <h4 className={styles.videoCardTitle}>{videoCardContent.title}</h4>
                <p className={styles.videoCardSubtitle}>{videoCardContent.subtitle}</p>
            </FlexColumnAlignJustifyCenter>
            <div className={styles.learnbyTextingVideoContainer}>
                <VimeoVideo id={videoCardContent.videoId} />
            </div>
        </FlexColumnAlignJustifyCenter>
    );
};

const VideoWithTwoLinkedCards = ({ topic }: { topic: LandingPageTopic }) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    const content = videoWithTwoLinkedCardsContent[topic];
    if (content === null) return null;

    return (
        <div ref={ref}>
            <SectionContainer title={content.mainTitle}>
                <LazyLoaded load={inView}>
                    <VideoCard videoCardContent={content.videoComponent} />
                </LazyLoaded>
                <FlexRowAlignCenter
                    className={br.mdDownColumn}
                    style={{ flex: 1, gap: "4.8rem", alignItems: "stretch", width: "100%" }}>
                    {content.linkedCards.map((linkedCard, index) => (
                        <SmallLinkedCard key={index} content={linkedCard} />
                    ))}
                </FlexRowAlignCenter>
            </SectionContainer>
        </div>
    );
};

export default VideoWithTwoLinkedCards;
