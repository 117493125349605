"use client";

import { themeColors } from "@/utils/themeColors";
import Image from "next/image";
import SectionContainer from "../../../components/SectionContainer";
import FeaturesWithFeedbacks from "../../../components/FeaturesWithFeedbacks";
import styles from "./video-summarizer.module.css";
import { doMoreWithLessTimeCardsContent, doMoreWithLessTimeFeedbacks, LandingPageTopic } from "../../utils/constants";
import { objectWithout } from "@/utils/dataCleaning";

import { useInView } from "react-intersection-observer";
import LazyLoaded from "@/components/LazyLoaded";
import dynamic from "next/dynamic";

const VimeoVideo = dynamic(() => import("@/components/VimeoVideo"), { ssr: false });

type DoMoreWithLessTimeProps = {
    title: string;
    subtitle?: string;
    topic: LandingPageTopic;
};

const DoMoreWithLessTime = ({ title, subtitle, topic }: DoMoreWithLessTimeProps) => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });
    const content = doMoreWithLessTimeCardsContent[topic];
    if (content === null) return null;

    const imageRightBottomMargin = {
        [LandingPageTopic.PPTSummarizer]: "-8rem",
        [LandingPageTopic.VideoSummarizer]: "-8rem",
        [LandingPageTopic.PDFSummarizer]: "-12rem",
        [LandingPageTopic.SpreadSheetSummarizer]: "-12rem",
        [LandingPageTopic.AINotes]: "-14rem",
        [LandingPageTopic.AIFlashcards]: "-8rem",
        [LandingPageTopic.School]: "-8rem",
    };

    const featuresWithFeedbacks = {
        leftComponents: [
            {
                ...content.left1,
                bottomChild: content.left2.videoId ? (
                    <div className={styles.featureVideoContainer}>
                        {content.left1.videoId && <VimeoVideo id={content.left1.videoId} />}
                    </div>
                ) : (
                    content.left1.src && (
                        <Image
                            className={styles.doMoreLeftImageStyle}
                            src={content.left1.src}
                            alt={`click to learn more about ${content.left2.title}`}
                            width={450}
                            height={450}
                        />
                    )
                ),
            },
            {
                ...content.left2,
                bottomChild: content.left2.videoId ? (
                    <div ref={ref} className={styles.featureVideoContainer}>
                        <LazyLoaded load={inView}>
                            <VimeoVideo id={content.left2.videoId} />
                        </LazyLoaded>
                    </div>
                ) : (
                    content.left2.src && (
                        <Image
                            className={styles.doMoreLeftImageStyle}
                            src={content.left2.src}
                            alt={`click to learn more about ${content.left2.title}`}
                            width={450}
                            height={450}
                        />
                    )
                ),
            },
        ],
        rightComponent: {
            ...objectWithout(content.right, "src"),
            backgroundColor: themeColors.mcStudyLight3,
            bottomChild: (
                <Image
                    className={styles.mobileVideoSummarizer}
                    src={content.right.src}
                    alt="Mobile Video Summarizer"
                    width={330}
                    height={514}
                    style={{ marginBottom: imageRightBottomMargin[topic] }}
                />
            ),
        },
        feedbacks: doMoreWithLessTimeFeedbacks[topic],
    };

    return (
        <SectionContainer title={title} subtitle={subtitle || "We're all about helping you learn better, but quicker."}>
            <FeaturesWithFeedbacks content={featuresWithFeedbacks} />
        </SectionContainer>
    );
};

export default DoMoreWithLessTime;
