import Image from "next/image";
import { Flex, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import FeaturesCard from "../../components/FeaturesCard";
import SectionContainer from "../../components/SectionContainer";
import br from "@/styles/breakpoints.module.css";
import styles from "./medias/video-summarizer.module.css";
import { endlessWaysCardsContent, LandingPageTopic } from "../utils/constants";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";

const KaiSherlockImage = `${ASSETS_URL}/images/kai-sherlock.svg`;

const EndlessWays = ({
    topic,
    subtitle = "Not a video person? No problem -- you can do so much more.",
}: {
    topic: LandingPageTopic;
    subtitle?: string;
}) => {
    const data = endlessWaysCardsContent[topic];
    if (!data) return null;

    return (
        <SectionContainer title="Endless ways to use Knowt" subtitle={subtitle}>
            <FlexRowAlignCenter className={br.mdDownColumn} style={{ alignItems: "stretch", gap: "2.4rem" }}>
                <div style={{ flex: 1, position: "relative", borderRadius: "3.6rem", overflow: "hidden" }}>
                    <FeaturesCard
                        bigTitle={false}
                        title={data.card1.title}
                        desc={data.card1.desc}
                        linkText={data.card1.linkText}
                        link={data.card1.link}
                        bottomChild={
                            data.card1.src && (
                                <Image
                                    className={styles.pdfSummarizerImage}
                                    src={data.card1.src}
                                    alt={data.card1.alt ?? `click to learn more about ${data.card1.title}`}
                                    width={490}
                                    height={430}
                                />
                            )
                        }
                        containerStyle={{
                            height: "100%",
                            padding: "3.2rem 3.2rem 0rem 3.2rem",
                            boxSizing: "border-box",
                        }}
                        bottomContainerClassName={styles.pdfSummarizerImageContainer}
                    />
                    <div
                        style={{
                            position: "absolute",
                            inset: 0,
                            borderRadius: "inherit",
                            boxShadow: `inset 0 0 0 7px ${themeColors.background}`,
                            pointerEvents: "none",
                        }}></div>
                </div>
                <FlexColumnAlignJustifyCenter style={{ gap: "2.4rem", flex: 1 }}>
                    <FeaturesCard
                        bigTitle={false}
                        title={data.card2.title}
                        desc={data.card2.desc}
                        linkText={data.card2.linkText}
                        link={data.card2.link}
                        bottomChild={
                            <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                                <Image
                                    className={styles.kaiSherlockImage}
                                    src={KaiSherlockImage}
                                    alt={data.card2.alt ?? `click to learn more about ${data.card2.title}`}
                                    width={246}
                                    height={274}
                                />
                            </Flex>
                        }
                        containerStyle={{ padding: "3.2rem 3.2rem 0" }}
                    />
                    <FlexRowAlignCenter className={br.mdDownColumn} style={{ gap: "2.4rem", alignItems: "stretch" }}>
                        <FeaturesCard
                            bigTitle={false}
                            title={data.card3.title}
                            desc={data.card3.desc}
                            linkText={data.card3.linkText}
                            link={data.card3.link}
                            containerStyle={{ padding: "3.2rem 2.4rem" }}
                        />
                        <FeaturesCard
                            bigTitle={false}
                            title={data.card4.title}
                            desc={data.card4.desc}
                            linkText={data.card4.linkText}
                            link={data.card4.link}
                            containerStyle={{ padding: "3.2rem 2.4rem" }}
                        />
                    </FlexRowAlignCenter>
                </FlexColumnAlignJustifyCenter>
            </FlexRowAlignCenter>
        </SectionContainer>
    );
};

export default EndlessWays;
