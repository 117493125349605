"use client";

import { Blog } from "@knowt/syncing/graphql/schema";
import Image from "next/image";
import Link from "@/components/wrappers/Link";
import styles from "../styles/bloghome.module.css";
import { borderRadius } from "@/utils/borderRadius";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import { ASSETS_URL } from "@/config/deployConstants";
import { useCallback, useRef, useState } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { assertTruthy } from "@knowt/syncing/utils/assertions";
import useDOMEventListener from "@/hooks/useDOMEventListener";
import { useDebouncedCallback } from "@knowt/syncing/utils/hooks/useDebouncedCallback";
import { remToPx } from "@/utils/domUtils";
import clsx from "clsx";

type Props = {
    blog: Blog;
    isOwner: boolean;
    containerStyle?: React.CSSProperties;
    tagStyle?: React.CSSProperties;
};

export const BlogCard = ({ blog, isOwner, containerStyle, tagStyle }: Props) => {
    const titleAndSummaryContainerRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLParagraphElement>(null);

    const [summaryLinesCount, setSummaryLinesCount] = useState(10);

    const updateSummaryLinesCount = useCallback(() => {
        assertTruthy(titleAndSummaryContainerRef.current, "titleAndSummaryContainerRef was not set");
        assertTruthy(titleRef.current, "titleRef was not set");

        const [titleMarginTop, titleMarginBottom] = ["margin-top", "margin-bottom"].map(property => {
            assertTruthy(titleRef.current, "titleRef was not set");
            return parseFloat(window.getComputedStyle(titleRef.current).getPropertyValue(property));
        });

        const titleVerticalSpace = titleRef.current.clientHeight + titleMarginTop + titleMarginBottom;

        const summaryLineHeight = remToPx("2rem");
        const summaryAvailableHeight = titleAndSummaryContainerRef.current.clientHeight - titleVerticalSpace;

        setSummaryLinesCount(Math.floor(summaryAvailableHeight / summaryLineHeight));
    }, []);

    useIsomorphicLayoutEffect(() => {
        updateSummaryLinesCount();
    }, [updateSummaryLinesCount]);

    useDOMEventListener(
        "resize",
        useDebouncedCallback(() => updateSummaryLinesCount(), 250)
    );

    return (
        <Link
            className={styles.blogCard}
            href={isOwner ? `/blog/edit/${blog.blogId}` : `/blog/${blog.slug}`}
            style={{
                textDecoration: "inherit",
                color: themeColors.neutralBlack,
                display: "flex",
                flexDirection: "column",
                backgroundColor: themeColors.neutralWhite,
                borderRadius: borderRadius.card,
                padding: spacing.SM,
                gap: spacing.SM,
                width: "100%",
                ...containerStyle,
            }}>
            <div
                style={{
                    borderRadius: borderRadius.card,
                    overflow: "hidden",
                    position: "relative",
                    width: "100%",
                    aspectRatio: 16 / 9,
                }}>
                <Image
                    fill
                    src={blog.coverImage?.url ?? `${ASSETS_URL}/images/blog-filler.png`}
                    alt="Blog image"
                    style={{
                        objectFit: "cover",
                    }}
                />
            </div>
            {blog.tags && (
                <p
                    className="secondaryTextBold1"
                    style={{
                        backgroundColor: themeColors.background,
                        padding: `${spacing.XS} ${spacing.SM}`,
                        borderRadius: borderRadius.shortInput,
                        width: "max-content",
                        ...tagStyle,
                    }}>
                    {blog.tags}
                </p>
            )}
            <div ref={titleAndSummaryContainerRef} style={{ display: "inline", overflow: "hidden", flex: 1 }}>
                <p
                    ref={titleRef}
                    className={clsx(styles.title, "bodyBold1")}
                    style={{
                        marginBottom: spacing.SM,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 6, // TODO: 6 is an approximation, would be good to make it dynamic
                        overflow: "hidden",
                    }}>
                    {blog.title}
                </p>
                {summaryLinesCount > 0 && (
                    <p
                        className={"secondaryText1"}
                        style={{
                            fontWeight: 400,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: summaryLinesCount,
                            overflow: "hidden",
                        }}>
                        {blog.summary + "..."}
                    </p>
                )}
            </div>
        </Link>
    );
};
