"use client";

import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import clsx from "clsx";
import { ChevronDown } from "lucide-react";
import CircularButton from "../CircularButton";
import styles from "./faq.module.css";
import { themeColors } from "@/utils/themeColors";
import { spacing } from "@/utils/spacing";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";

const Ul = ({ children, style, ...ulProps }: React.HTMLAttributes<HTMLUListElement>) => {
    return (
        <ul {...ulProps} style={{ lineHeight: "2.4rem", ...style }} className="strippedList">
            {children}
        </ul>
    );
};

const Li = ({ children, style, ...liProps }: React.HTMLAttributes<HTMLLIElement>) => {
    return (
        <li {...liProps} style={{ marginBottom: "2.6rem", ...style }}>
            {children}
        </li>
    );
};

const FAQ = ({
    title = "Questions & Answers",
    titleClassName,
    titleStyle,
    description = "If you have any other questions - please get in touch",
    containerStyle,
    questStyle,
    className,
    faqs,
}: {
    title?: React.ReactNode;
    titleClassName?: string;
    titleStyle?: React.CSSProperties;
    description?: string | null;
    containerStyle?: React.CSSProperties;
    questStyle?: React.CSSProperties;
    className?: string;
    faqs: {
        question: string;
        answer: React.ReactNode[] | React.ReactNode;
    }[];
}) => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

    const toggleAnswer = (index: number) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <FlexColumn
            className={clsx(styles.faqContainer, className)}
            style={{
                background: "#262626",
                justifyContent: "center",
                marginTop: "6.5rem",
                marginBottom: spacing.XL,
                ...containerStyle,
            }}>
            <FlexColumnAlignJustifyCenter>
                <span className={titleClassName} style={{ fontSize: "2.6rem", fontWeight: "600", ...titleStyle }}>
                    {title}
                </span>
                {description && <span style={{ textAlign: "center" }}>{description}</span>}
            </FlexColumnAlignJustifyCenter>
            <FlexColumn style={{ gap: spacing.MD, marginTop: spacing.MD }}>
                {faqs.map((faq, index) => (
                    <FlexColumn
                        key={index}
                        onClick={() => toggleAnswer(index)}
                        style={{ cursor: "pointer", gap: spacing.SM }}>
                        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
                            <div style={{ maxWidth: "80%", fontWeight: "600", ...questStyle }}>{faq.question}</div>
                            <CircularButton
                                radius="3.4rem"
                                aria-label="toggle expand"
                                sx={{
                                    backgroundColor: themeColors.neutralWhite,
                                    transition: "transform 0.2s ease-in-out",
                                    transform: expandedIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                                }}
                                tooltip={expandedIndex === index ? "collapse" : "expand"}>
                                <ChevronDown size={24} />
                            </CircularButton>
                        </FlexRowAlignCenter>
                        <Collapse className={styles.answer} in={expandedIndex === index}>
                            {!Array.isArray(faq.answer) ? (
                                <span>{faq.answer}</span>
                            ) : (
                                <Ul>
                                    {faq.answer.map((ans, idx) => {
                                        const isLast = idx === (faq.answer as React.ReactNode[]).length - 1;
                                        return (
                                            <Li key={idx} style={{ marginBottom: isLast ? 0 : "3.2rem" }}>
                                                {ans}
                                            </Li>
                                        );
                                    })}
                                </Ul>
                            )}
                        </Collapse>
                    </FlexColumn>
                ))}
            </FlexColumn>
        </FlexColumn>
    );
};

export default FAQ;
