import { useRef } from "react";
import { toast } from "react-hot-toast";
import { wait } from "@knowt/syncing/utils/genericUtils";
import useDOMEventListener from "@/hooks/useDOMEventListener";

type Input = {
    successMsg: string;
    errorMsg?: string;
    save: () => Promise<unknown>;
    // time in milliseconds before the save function can be called again
    backoffTime?: number;
};

/**
 * Save on Cmd / Ctrl S.
 * Disables default page save functionality.
 */
export const useCommandSave = ({ save, successMsg, errorMsg, backoffTime = 3000 }: Input) => {
    const canSaveRef = useRef(true);

    useDOMEventListener("keydown", async (event: KeyboardEvent) => {
        if (!isCommandSave(event)) return;

        event.preventDefault();
        event.stopImmediatePropagation();

        if (!canSaveRef.current) {
            return;
        }

        toast.promise(save(), {
            loading: "Saving",
            success: successMsg,
            error: errorMsg,
        });

        canSaveRef.current = false;
        await wait(backoffTime);
        canSaveRef.current = true;
    });
};

const isCommandSave = (event: KeyboardEvent) => {
    return (event.ctrlKey || event.metaKey) && event.code === "KeyS";
};
