import LinearProgress from "@mui/material/LinearProgress";

type LinearProgressBarProps = React.ComponentPropsWithoutRef<typeof LinearProgress> & {
    barColor: string;
    backgroundColor: string;
    borderRadius?: string | number;
    height: string | number;
    value: number;
    width?: string | number;
};

/**
 * Custom LinearProgressBar to make it easy to custom MuiLinearProgress.
 * This Custom LinearProgressBar position depends on the parent container instead of relying on fixed position when it's not 100%.
 */
const LinearProgressBar = ({
    barColor,
    backgroundColor,
    borderRadius,
    height,
    value,
    width,
    ...MuiLinearProgressProps
}: LinearProgressBarProps) => {
    return (
        <LinearProgress
            sx={{
                "& .MuiLinearProgress-bar": {
                    backgroundColor: barColor,
                    borderRadius,
                },
            }}
            style={{
                borderRadius,
                backgroundColor: backgroundColor,
                height: height,
                width: width ?? "100%",
            }}
            value={value}
            {...MuiLinearProgressProps}
        />
    );
};

export default LinearProgressBar;
