import React from "react";
import clsx from "clsx";
import { ACCENT_COLOR_LIGHT_PROP, ACCENT_COLOR_DARK_PROP } from "../../utils";
import styles from "../../landing.module.css";
import { FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import { themeColors } from "@/utils/themeColors";

export const LandingMainContainer = ({
    children,
    style,
    className,
    accentColorLight,
    accentColorDark,
    ...divProps
}: {
    accentColorLight: string;
    accentColorDark: string;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumn
            className={clsx(styles.mainContainer, className)}
            {...divProps}
            style={
                {
                    [ACCENT_COLOR_LIGHT_PROP]: accentColorLight,
                    [ACCENT_COLOR_DARK_PROP]: accentColorDark,
                    backgroundColor: themeColors.neutralWhite,
                    ...style,
                } as React.CSSProperties
            }>
            {children}
        </FlexColumn>
    );
};

export const LandingCenteredHalfSection = ({
    className,
    children,
    style,
    ...divProps
}: React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <FlexColumnAlignJustifyCenter
            as={"section"}
            {...divProps}
            className={clsx(styles.halfSection, className)}
            style={{ maxWidth: "95rem", margin: "auto", ...style }}>
            {children}
        </FlexColumnAlignJustifyCenter>
    );
};
