import { themeColors } from "@/utils/themeColors";
import { Flex, FlexColumn, FlexColumnAlignJustifyCenter } from "@/components/Flex";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { ArrowRight } from "lucide-react";
import styles from "../landing.module.css";
import br from "@/styles/breakpoints.module.css";

const getComingSoonBgColor = (cardBgColor: string) => {
    switch (cardBgColor) {
        case themeColors.mcStudyLight3:
            return themeColors.mcStudyDark3;
    }
};

type FeaturesCardProps = {
    comingSoon?: boolean;
    bigTitle?: boolean;
    title: string;
    desc: string;
    linkText?: string;
    link?: string;
    cardBackgroundColor?: string;
    bottomChild?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    bottomContainerClassName?: string;
};

const FeaturesCard = ({
    comingSoon = false,
    bigTitle = true,
    title,
    desc,
    linkText,
    link,
    cardBackgroundColor = themeColors.background,
    bottomChild,
    containerStyle,
    bottomContainerClassName,
}: FeaturesCardProps) => {
    return (
        <FlexColumn
            className={styles.featuresCardContainer}
            style={{
                backgroundColor: cardBackgroundColor,
                flex: 1,
                overflow: "hidden",
                width: "100%",
                ...containerStyle,
            }}>
            {comingSoon && (
                <Flex
                    className={styles.comingSoonLabel}
                    style={{
                        padding: "0.4rem 1.6rem",
                        backgroundColor: getComingSoonBgColor(cardBackgroundColor),
                        width: "fit-content",
                        marginBottom: "3.2rem",
                    }}>
                    <p style={{ color: themeColors.pureWhite }}>coming soon</p>
                </Flex>
            )}
            <FlexColumn style={{ gap: "1.6rem", height: "100%" }}>
                <h3 className={bigTitle ? styles.bigFeatureCardTitle : styles.smallFeatureCardTitle}>{title}</h3>
                <p className="body1">{desc}</p>

                {linkText && link && (
                    <LinkWrapper
                        className={br.smDownDisplayNone}
                        href={link}
                        style={{
                            display: "flex",
                            flex: 1,
                            gap: "0.4rem",
                            flexDirection: "row",
                            color: themeColors.primary4,
                            flexWrap: "wrap",
                        }}>
                        <p style={{ fontWeight: "700", fontSize: "1.8rem" }}>{linkText}</p>
                        <ArrowRight size={18} />
                    </LinkWrapper>
                )}
                {link && (
                    <LinkWrapper
                        className={br.smUpDisplayNone}
                        href={link}
                        style={{
                            display: "flex",
                            gap: "0.4rem",
                            flexDirection: "row",
                            color: themeColors.primary4,
                        }}>
                        <p style={{ fontWeight: "700", fontSize: "1.8rem" }}>{linkText}</p>
                        <ArrowRight size={18} />
                    </LinkWrapper>
                )}
            </FlexColumn>
            {bottomChild && (
                <FlexColumnAlignJustifyCenter
                    className={bottomContainerClassName}
                    style={{ flex: 1, justifyContent: "center" }}>
                    {bottomChild}
                </FlexColumnAlignJustifyCenter>
            )}
        </FlexColumn>
    );
};

export default FeaturesCard;
