"use client";
import Image from "next/image";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import Link from "@/components/wrappers/Link";
import { spacing } from "@/utils/spacing";
import { themeColors } from "@/utils/themeColors";
import styles from "./featuredExamCategories.module.css";
import { examNameToUrlComponent } from "@knowt/syncing/hooks/exams/utils";

export default function FeaturedExamCategories({ featuredExamCategories, examCategoriesColumns }) {
    return (
        <Flex
            className={styles.examCategoryContainer}
            style={{
                flexWrap: "wrap",
                gap: "3rem",
                width: "100%",
            }}>
            {examCategoriesColumns?.map(columnCategories => {
                return (
                    <FlexColumn key={`${columnCategories}`} style={{ flex: 1, gap: "3rem" }}>
                        {columnCategories.map(category => {
                            const examCategory = featuredExamCategories.find(
                                examCategory => examCategory.category === category
                            );

                            return (
                                <FlexColumn
                                    id={`category-${category}`}
                                    key={category}
                                    className={"knowtFont"}
                                    style={{
                                        padding: "4rem",
                                        borderRadius: "4rem",
                                        justifyContent: "space-between",
                                        backgroundColor: themeColors.card,
                                        gap: spacing.LG,
                                    }}>
                                    <Link href={`/subject/${examNameToUrlComponent(category)}-flashcards`}>
                                        <FlexRowAlignCenter
                                            style={{
                                                borderRadius: "1.8rem",
                                                gap: spacing.SM,
                                                justifyContent: "center",
                                                color: themeColors.neutralBlack,
                                            }}>
                                            {examCategory.image && (
                                                <Image
                                                    src={examCategory.image}
                                                    width={42}
                                                    height={42}
                                                    alt={"Exam category image"}
                                                />
                                            )}
                                            <h3>
                                                <b>{category}</b>
                                            </h3>
                                        </FlexRowAlignCenter>
                                    </Link>
                                    <FlexColumn style={{ gap: "2.4rem" }}>
                                        {examCategory.subjects.map(subject => {
                                            return (
                                                <Link key={subject.subject} href={subject.route}>
                                                    <FlexRowAlignCenter style={{ gap: "1rem" }}>
                                                        {subject.image && (
                                                            <Image
                                                                width={20}
                                                                height={20}
                                                                src={subject.image}
                                                                alt={subject + " image"}
                                                            />
                                                        )}
                                                        <span style={{ cursor: "pointer" }}>{subject.subject}</span>
                                                    </FlexRowAlignCenter>
                                                </Link>
                                            );
                                        })}
                                    </FlexColumn>
                                </FlexColumn>
                            );
                        })}
                    </FlexColumn>
                );
            })}
        </Flex>
    );
}
